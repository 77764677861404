import {
  graphql,
  requestSubscription
} from 'react-relay';
import { pick, map, defaultTo } from 'ramda';

export const FinishedComparisonsSubscriptionQuery = graphql`
  subscription FinishedComparisonsSubscription {
    finishedComparisonsSubscription {
      id
      status
      comparisonId
      comparisonDetails {
        id
        diffPngsIds
        status
        referentPdfId
        actualPdfId
        fileName
      }
    }
  }
`;

export default (environment) => {
  return requestSubscription(
    environment,
    {
      subscription: FinishedComparisonsSubscriptionQuery,
      variables: {},
      updater: (
        store,
        { finishedComparisonsSubscription }
      ) => {
        const { id, status, comparisonId, comparisonDetails } = pick(['status', 'id', 'comparisonId', 'comparisonDetails'], finishedComparisonsSubscription);
        const document = store.get(id);
        const comparisons = document.getLinkedRecords('comparisons') || [];
        const record = store.get(comparisonId);

        if (record) {
          const filtered = map(c => {
            const dataId = c.getDataID();
            if (dataId === comparisonId) {
              c.setValue(status, 'globalStatusResult');
              const comparisonDetailsRecords = defaultTo([], comparisonDetails).map((cd) => {
                const comparisonDetailsRecord = store.get(cd.id);
                comparisonDetailsRecord.setValue(cd.status, 'status');
                comparisonDetailsRecord.setValue(cd.diffPngsIds, 'diffPngsIds');
                comparisonDetailsRecord.setValue(cd.referentPdfId, 'referentPdfId');
                comparisonDetailsRecord.setValue(cd.actualPdfId, 'actualPdfId');
                comparisonDetailsRecord.setValue(cd.fileName, 'fileName');
                return comparisonDetailsRecord;
              });
              c.setLinkedRecords(comparisonDetailsRecords, 'comparisonDetails');
            }
            return c;
          }, comparisons);
          document.setLinkedRecords(filtered, 'comparisons');
        } else {
          const comparison = store.create(comparisonId, 'ComparisonGeneratePdfResult');
          comparison.setValue(comparisonId, 'id');
          comparison.setValue((new Date()).toString(), 'when');
          comparison.setValue(status, 'globalStatusResult');
          document.setLinkedRecords([comparison, ...comparisons], 'comparisons');
        }
      },
      onCompleted: () => {},
      onError: error => {
        console.error('SUBSCRIPTION FINISHED COMPARISONS ERROR:', error.message);
      }
    }
  );
};
