import { append, isNil, prop } from 'ramda';
import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation CreateCommentMutation($values: CommentInput!) {
    createComment(values: $values) {
      ok
      error
      comment {
        id
        itemKey
        itemPath
        itemRootId
        status
        teamId
        messages {
          text
          userId
          when
        }
      }
    }
  }
`;

export default ({
  itemKey,
  itemPath,
  itemRootId
}, done) => {
  const variables = {
    values: {
      itemKey,
      itemPath,
      itemRootId
    }
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { createComment: { ok, error, comment } }) => {
        if (ok && !error && !isNil(comment)) {
          const storedCommentsList = proxyStore.get(`comments-${itemRootId}`);

          if (!isNil(storedCommentsList)) {
            let storedComments = storedCommentsList.getLinkedRecords('comments');

            if (!isNil(storedComments)) {
              const newComment = proxyStore.get(prop('id', comment));

              if (!isNil(newComment)) {
                storedComments = append(newComment, storedComments);
                storedCommentsList.setLinkedRecords(storedComments, 'comments');
              }
            }
          }
        }
      },
      onCompleted: ({ createComment }) => basicMutationOnCompleted(createComment, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
