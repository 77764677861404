import React from 'react';
import PropTypes from 'prop-types';

const AccountLayout = ({
  children,
  img = '/image/background-account-1.svg',
  imgStyle = {}
}) => {
  return (
    <div className="account-layout">
      <div>
        <img
          src="/image/background-fill.svg"
          alt="background"
        />
        <img
          src={img}
          alt="background-account"
          className="background-account"
          style={imgStyle}
        />
      </div>

      <div className="logo">
        <img src="/image/FASST_Flexi_Couleur.svg" alt="Flexidocs"/>
      </div>

      <div className="account-layout-body">
        {children}
      </div>
    </div>
  );
};

AccountLayout.propTypes = {
  img: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  imgStyle: PropTypes.object
};

export default AccountLayout;
