import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import CommentsBox from './commentsWrapper/components/CommentsBox';
import useCommentsWrapper from './commentsWrapper/hooks/useCommentsWrapper';

const CommentsWrapper = ({
  comments,
  mode,
  config = {
    colors: {
      pending: '#ffd88c',
      accepted: '#00b800',
      rejected: '#ff4040'
    }
  },
  children,
  handlers,
  itemRootId,
  isDocumentEditing
}) => {
  comments = R.reject(R.isNil, comments);

  const {
    addMessage,
    updateStatus,
    deleteComment,
    formattedChildren,
    addMessageLoading,
    updateStatusLoading,
    deleteCommentLoading
  } = useCommentsWrapper({
    children,
    comments,
    config,
    mode,
    handlers,
    itemRootId
  });

  if (R.isNil(itemRootId)) {
    return formattedChildren;
  }

  return (
    <>
      {R.not(isDocumentEditing) ? (
        R.map(comment => (
          <CommentsBox
            key={R.prop('id', comment)}
            commentItem={comment}
            addMessage={addMessage}
            updateStatus={updateStatus}
            deleteComment={deleteComment}
            addMessageLoading={addMessageLoading}
            updateStatusLoading={updateStatusLoading}
            deleteCommentLoading={deleteCommentLoading}
            mode={mode}
          />
        ))(comments)) : (
        null
      )}
      {formattedChildren}
    </>
  );
};

CommentsWrapper.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      itemPath: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ])
      ).isRequired,
      itemKey: PropTypes.string.isRequired,
      status: PropTypes.oneOf([
        'pending',
        'accepted',
        'rejected'
      ]).isRequired,
      messages: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          userId: PropTypes.string.isRequired,
          userName: PropTypes.string.isRequired,
          when: PropTypes.string.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  mode: PropTypes.oneOf([
    'disabled',
    'edition',
    'review'
  ]),
  config: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]),
  handlers: PropTypes.shape({
    onCreateComment: PropTypes.func.isRequired,
    onAddMessage: PropTypes.func.isRequired,
    onUpdateStatus: PropTypes.func.isRequired,
    onDeleteComment: PropTypes.func.isRequired
  }).isRequired,
  itemRootId: PropTypes.string
};

export default CommentsWrapper;
