import {
  commitMutation,
  graphql
} from 'react-relay';
import environment from '../.';
import { onErrorMutation } from './common/common';

const mutation = graphql`
mutation SignInMutation($email: String!, $password: String!) {
  signIn(email: $email, password: $password) { 
    ok
    error
    user {
      id
      userId
      username
      email
      roles
    }
    userTeam {
      id
      teamId
      name
      role
      members {
        user {
          id
          email
        }
        role
        active
      }
    }
    teamInvitationsCount
  }
}
`;

export default (email, password, done) => {
  const variables = {
    email,
    password
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { signIn: { ok, error, teamInvitationsCount = 0 } }) => {
        if (ok && !error && teamInvitationsCount > 0) {
          location.reload();
        }
      },
      onCompleted: ({ signIn }) => {
        const { ok, error, lockDuration } = signIn;
        done(ok, error, lockDuration);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
