import React from 'react';
import { passwordIsValid } from '../../lib/validation';
import qs from 'querystring';
import S from 'string';
import '@ant-design/compatible/assets/index.css';
import { Alert, Input, Button } from 'antd';
import AccountLayout from '../../layout/account/AccountLayout';
import * as R from 'ramda';
import usePassword from '../../hooks/usePassword';

const ResetPassword = ({
  location,
  history
}) => {
  const redirect = (route) => history.push(route);
  const onGotoSignIn = () => redirect('/account/signin');
  const onGotoResetPasswordRequest = () => redirect('/account/resetpasswordrequest');

  const getToken = () => {
    return R.propOr(
      undefined,
      'token',
      qs.parse(S(location.search).chompLeft('?').s)
    );
  };

  const {
    password,
    loading,
    error,
    sent,
    onResetPassword,
    setState
  } = usePassword(getToken());

  return (
    <AccountLayout>
      <h3>Nouveau mot de passe</h3>

      {!sent && (
        <>
          <Input
            type="password"
            placeholder="Votre nouveau mot de passe"
            size="large"
            onChange={e => setState(R.assoc('password', e.target.value))}
          />

          <Button
            size="large"
            disabled={!passwordIsValid(password)}
            onClick={onResetPassword}
            loading={loading}
            type="primary"
          >
            {'Envoyer'}
          </Button>
        </>
      )}

      {sent && (
        <div>
          <Alert
            type="success"
            message="Votre mot de passe a bien été enregistré"
          />
          <br/>
          <Button
            onClick={onGotoSignIn}
            type="primary"
          >
            {'Me connecter'}
          </Button>
        </div>
      )}

      {error && (
        <div>
          <Alert
            type="error"
            message="Votre mot de passe n'a pas pu être mis à jour, veuillez réessayer"
          />
          <br/>
          <Button
            onClick={onGotoResetPasswordRequest}
            type="primary"
          >
            {'Changer mon mot de passe'}
          </Button>
        </div>
      )}

    </AccountLayout>
  );
};

export default ResetPassword;
