import { isEmpty, is, prop, map } from 'ramda';
import { upload } from './request';
const FormData = require('form-data');

export const uploadFiles = async (files) => {
  if (isEmpty(files)) return;
  const formData = new FormData();
  if (is(Array, files)) {
    map(file => formData.append('file', prop('originFileObj', file)))(files);
  } else {
    formData.append('file', files);
  }
  return await upload(formData);
};
