import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { isEmpty, prop, propOr } from 'ramda';
import React from 'react';
import useReview from '../hooks/useReview';

const ReviewButtons = ({ item }) => {
  const version = prop('version', item);
  const reviewComments = propOr({}, 'reviewComments', version);
  const {
    loading,
    rejectionReason,
    updateState,
    acceptReview,
    rejectReview,
    isReview,
    reviewIsRejected,
    isDraft
  } = useReview(version, reviewComments, item);

  if (!isReview && (!reviewIsRejected || !isDraft)) {
    return null;
  }

  return (
    <>
      <Popconfirm
        title={(
          <>
            <textarea
              placeholder="Expliquez pourquoi vous refusez la publication de cette offre"
              value={rejectionReason}
              onChange={e => updateState('rejectionReason', e.target.value)}
              className="w-400px h-100px border p-2"
            />
          </>
        )}
        okButtonProps={{
          disabled: isEmpty(rejectionReason)
        }}
        onConfirm={rejectReview}
      >
        <Button
          type="primary"
          icon={<StopOutlined />}
          loading={prop('reject', loading)}
          size="large"
          danger
        >
          Refuser la publication
        </Button>
      </Popconfirm>

      <Button
        icon={<CheckOutlined />}
        loading={prop('accept', loading)}
        onClick={acceptReview}
        className="btn-green"
        size="large"
      >
        Accepter la publication
      </Button>
    </>
  );
};

const WrapperReviewButtons = (props) => {
  const item = prop('item', props);
  if (!item) {
    return null;
  }
  return <ReviewButtons {...props}/>;
};

export default WrapperReviewButtons;
