import React from 'react';

const UserIcon = () => (
  <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
    <title>65CCD011-1B8D-4EB4-8317-299C9CACBB1B</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-747 -257)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(289 175)">
          <g transform="translate(20 80)">
            <g transform="translate(438)">
              <g transform="translate(0 2)">
                <path d="m8 3.5c-1.5188 0-2.75 1.2312-2.75 2.75s1.2312 2.75 2.75 2.75 2.75-1.2312 2.75-2.75-1.2312-2.75-2.75-2.75zm0 4c-0.68938 0-1.25-0.56094-1.25-1.25 0-0.69062 0.55938-1.25 1.25-1.25s1.25 0.56062 1.25 1.25c0 0.69062-0.55938 1.25-1.25 1.25zm0-7.5c-4.4188 0-8 3.5812-8 8s3.5812 8 8 8 8-3.5812 8-8-3.5812-8-8-8zm0 14.5c-1.4603 0-2.805-0.49-3.8906-1.3059 0.54062-1.0378 1.5906-1.6941 2.7719-1.6941h2.2403c1.1797 0 2.2284 0.65656 2.7712 1.6941-1.0866 0.81531-2.4334 1.3059-3.8928 1.3059zm5.0062-2.3594c-0.84375-1.3188-2.2812-2.1406-3.8875-2.1406h-2.2375c-1.605 0-3.0422 0.82031-3.8875 2.14-0.9325-1.1244-1.4938-2.5681-1.4938-4.14 0-3.5844 2.9159-6.5 6.5-6.5s6.5 2.9159 6.5 6.5c0 1.5719-0.5625 3.0156-1.4938 4.1406z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default UserIcon;
