import React, { useState } from 'react';
import { Button, notification } from 'antd';
import SignInMutation from '../../_graphql/mutations/SignInMutation';
import AccountLayout from '../../layout/account/AccountLayout';
import { emailIsValid, passwordIsValid } from '../../lib/validation';
import useKeyPressEnter from '../../hooks/useKeyPressEnter';
import { Link } from 'react-router-dom';
import EmailPasswordForm from './components/EmailPasswordForm';
const R = require('ramda');

const useSignIn = () => {
  const [values, setValues] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);

  const setValue = (k, v) => setValues(R.assoc(k, v));

  const fieldIsDefined = k => !R.isEmpty(R.prop(k, values));

  const onSignIn = () => {
    setLoading(true);
    SignInMutation(R.prop('email', values), R.prop('password', values), (ok, error) => {
      if (!ok || error) {
        setLoading(false);
        notification.error({ message: 'La connexion a échoué, veuillez vérifier vos identifiants.' });
      }
    });
  };

  return {
    emailIsValid: emailIsValid(R.prop('email', values)),
    passwordIsValid: passwordIsValid(R.prop('password', values)),
    fieldIsDefined,
    loading,
    setValue,
    onSignIn
  };
};

const SignIn = () => {
  const {
    emailIsValid,
    passwordIsValid,
    loading,
    fieldIsDefined,
    setValue,
    onSignIn
  } = useSignIn();

  const buttonDisabled = !passwordIsValid || !emailIsValid;
  useKeyPressEnter(onSignIn, !buttonDisabled, { emailIsValid, passwordIsValid });

  return (
    <AccountLayout>
      <h3>Connexion</h3>

      <EmailPasswordForm setValue={setValue} fieldIsDefined={fieldIsDefined} emailIsValid={emailIsValid} passwordIsValid={passwordIsValid} />

      <div className="account-extra text-xs my-0">
        <Link to="/account/resetpasswordrequest">Mot de passe oublié</Link>
      </div>

      <br/>
      <br/>

      <Button
        type="primary"
        size="large"
        disabled={buttonDisabled}
        loading={loading}
        onClick={onSignIn}
      >
        Se connecter
      </Button>

      <div className="account-extra mt-30px">
        Première visite ? &nbsp;
        <Link to="/account/signup">Créer votre compte</Link>
      </div>
    </AccountLayout>
  );
};

export default SignIn;
