import {
  commitMutation,
  graphql
} from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation LeaveTeamMutation {
    leaveTeam {
      ok
      error
    }
  }
`;

export default ({ name }, done) => {
  const variables = {
    name
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ leaveTeam }) => basicMutationOnCompleted(leaveTeam, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
