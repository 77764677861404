import { always, applySpec, compose, descend, equals, filter, groupBy, ifElse, isNil, map, path, prop, propEq, propOr, sort } from 'ramda';

export default (widgetsList, currentDocumentOrientation) => {
  const groupedWidgets = groupBy(prop('scope'))(widgetsList);

  const filterWidgetsByOrientation = (orientation) => filter(propEq('orientation', orientation));

  const formatWidget = (showVersion = false) => widget => applySpec({
    creation: path(['dates', 'creation']),
    label: v => showVersion ? `${path(['version', 'major'], v)}.${path(['version', 'minor'], v)}` : prop('name')(v),
    value: prop('id'),
    children: compose(
      map(formatWidget(true)),
      propOr([], 'children')
    )
  })(widget);

  const formattedChildren = (widgetsListByOrientation) => ifElse(
    isNil,
    always([]),
    compose(
      sort(descend(prop('creation'))),
      map(formatWidget())
    )
  )(widgetsListByOrientation);

  const getWidgetsByOrientation = (value, label, list) => {
    return {
      value,
      label,
      children: formattedChildren(list)
    };
  };

  const orientationWidgetsByScope = (orientation, scope, widgets) => filterWidgetsByOrientation(orientation)(propOr('', scope, widgets));

  const getWidgets = (orientation, scope, label) => getWidgetsByOrientation(orientation, label, orientationWidgetsByScope(orientation, scope, groupedWidgets));

  const offerWidgetsPortrait = getWidgets('portrait', 'offer', 'Portrait');
  const offerWidgetsLandscape = getWidgets('landscape', 'offer', 'Paysage');

  const options = ifElse(
    equals('portrait'),
    always([offerWidgetsPortrait]),
    always([offerWidgetsPortrait, offerWidgetsLandscape])
  )(currentDocumentOrientation);

  return options;
};
