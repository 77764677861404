import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation CreateGlobalVariableMutation($globalVariable: GlobalVariableInput!) {
    createGlobalVariable(globalVariable: $globalVariable) {
      ok
      error
    }
  }
`;

export default ({
  globalVariable
}, done) => {
  const variables = {
    globalVariable
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ createGlobalVariable }) => basicMutationOnCompleted(createGlobalVariable, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
