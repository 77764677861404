import { equals, map, prop, includes, slice, indexOf, append, drop, length, compose, pathOr, has, dissoc, assoc, replace, values } from 'ramda';
import { isNotArray, isNotEmpty, isNotNil, isNotNilOrEmpty, notEqual } from 'ramda-adjunct';

const findVariables = (HTMLcontent, variables = [], isPredefined = false) => {
  if (isNotArray(variables)) variables = [];

  const openingBracketsIndex = indexOf('{{', HTMLcontent);
  const closingBracketsIndex = indexOf('}}', HTMLcontent);
  const openingCheckboxVarIndex = indexOf('name="{{', HTMLcontent);

  if (equals(openingBracketsIndex, -1) || equals(closingBracketsIndex, -1)) return variables;

  const variable = slice(openingBracketsIndex + 2, closingBracketsIndex, HTMLcontent);

  const appendVar = () => {
    if (isNotEmpty(variable)) variables = append(variable, variables);
  };

  if (isPredefined) {
    const PREDEFINED_VARIABLES = [
      { key: '_pageCourante', description: 'Page courante dans le document entier', regex: /{{_pageCourante}}/g },
      { key: '_pageTotal', description: 'Nombre de pages dans tout le document', regex: /{{_pageTotal}}/g },
      { key: '_pageCouranteSection', description: 'Page courante dans la section courante', regex: /{{_pageCouranteSection}}/g },
      { key: '_pageTotalSection', description: 'Nombre de pages dans la section courante', regex: /{{_pageTotalSection}}/g }
    ];
    if (includes(variable, map(prop('key'))(PREDEFINED_VARIABLES))) appendVar();
  } else {
    appendVar();
    if (notEqual(openingCheckboxVarIndex, -1)) {
      const lengthOpeningCheckboxVar = length('name="{{');
      const checkboxVar = slice((openingCheckboxVarIndex + lengthOpeningCheckboxVar), closingBracketsIndex, HTMLcontent);
      variables.pop(checkboxVar);
    }
  }

  return findVariables(drop(closingBracketsIndex + 2, HTMLcontent), variables, isPredefined);
};

export const getVariablesOnWidgetHtml = (contentsWidget) => {
  const variables = [];
  map((element) => {
    const staticVariables = pathOr(null, ['element', '0', 'variables'])(element);
    const contentHTML = pathOr('', ['element', '0', 'html'])(element);

    if (isNotNil(staticVariables)) {
      variables.push(...compose(
        map(variable => {
          if (has('dataMatrix', variable) && isNotNilOrEmpty(prop('dataMatrix', variable))) {
            variable = compose(
              dissoc('dataMatrix'),
              assoc('default', prop('dataMatrix', variable))
            )(variable);
          }
          return assoc('key', compose(replace('{{', ''), replace('}}', ''))(prop('key', variable)), variable);
        }),
        values
      )(staticVariables));
    }

    const dynamicVariables = findVariables(contentHTML);

    variables.push(
      ...compose(
        map(variable => ({ type: 'text', key: variable }))
      )(dynamicVariables)
    );
  })(contentsWidget);
  return variables;
};
