/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentScopeEnum = "global" | "offer" | "%future added value";
export type StyleInput = {|
  name?: ?string,
  offerId?: ?string,
  scope?: ?DocumentScopeEnum,
  styles?: ?$ReadOnlyArray<?StyleItemInput>,
|};
export type StyleItemInput = {|
  format?: ?string,
  type?: ?string,
  value?: ?string,
|};
export type CreateStyleMutationVariables = {|
  style: StyleInput
|};
export type CreateStyleMutationResponse = {|
  +createStyle: ?{|
    +ok: boolean,
    +error: ?string,
    +styleId: ?string,
  |}
|};
export type CreateStyleMutation = {|
  variables: CreateStyleMutationVariables,
  response: CreateStyleMutationResponse,
|};
*/


/*
mutation CreateStyleMutation(
  $style: StyleInput!
) {
  createStyle(style: $style) {
    ok
    error
    styleId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "style"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "style",
        "variableName": "style"
      }
    ],
    "concreteType": "CreateStyleResponse",
    "kind": "LinkedField",
    "name": "createStyle",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "styleId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateStyleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateStyleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fe45ac4c664bdda1e3db46d7c6fa8f3d",
    "id": null,
    "metadata": {},
    "name": "CreateStyleMutation",
    "operationKind": "mutation",
    "text": "mutation CreateStyleMutation(\n  $style: StyleInput!\n) {\n  createStyle(style: $style) {\n    ok\n    error\n    styleId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c41631733852eafdecb35d579d25066';

module.exports = node;
