import * as R from 'ramda';
import getCommentsElements from './getCommentsElements';

const hideComments = () => {
  const commentsElements = getCommentsElements();

  R.forEach(commentElement => {
    commentElement.className = R.compose(
      v => `${v} hidden`,
      R.replace('hidden', '')
    )(R.prop('className', commentElement));
  })(commentsElements);
};

export default hideComments;
