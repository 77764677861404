import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../.';
import { basicMutationOnCompleted, onErrorMutation } from './common/common';

const mutation = graphql`
mutation ResetPasswordMutation($email: String, $currentPassword: String, $password: String!, $token: String) {
  resetPassword(email: $email, currentPassword: $currentPassword, password: $password, token: $token) {
    ok
    error
  }
}
`;

export default ({ email, currentPassword, token, password }, done) => {
  const variables = {
    email,
    currentPassword,
    token,
    password
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ resetPassword }) => basicMutationOnCompleted(resetPassword, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
