import React from 'react';
import { useParams } from 'react-router-dom';
import QDocument from '../../_graphql/queries/documents/QDocument';
import DocumentForm from './components/document/DocumentForm';
import * as R from 'ramda';
import { commentsMode } from './components/helpers/elements';
import { SCOPE_OFFER } from '../../_CONST';

const EditDocument = ({ breadcrumb }) => {
  const { documentId } = useParams();
  return (
    <QDocument args={{ documentId }}>
      {data => {
        const { document = {} } = data;
        if (R.isNil(document)) return null;
        const cacheKey = R.propEq('scope', SCOPE_OFFER, document) ? ['offerViewCache', `widgets-${R.prop('offerId', document)}`] : null;
        return (
          <DocumentForm
            document={document}
            scope={SCOPE_OFFER}
            offerId={R.prop('offerId', document)}
            commentsMode={commentsMode(document)}
            breadcrumb={breadcrumb}
            documentId={documentId}
            cacheKey={cacheKey}
          />
        );
      }}
    </QDocument>
  );
};

export default EditDocument;
