import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'react-relay';

import {
  Query
} from './_graphql';

import Moment from 'moment';

const R = require('ramda');

Moment.locale('fr');

const UserQuery = graphql`
query UserQuery {
  user {
    id
    username
    email
    status
    roles
    userId
  }
  userTeam {
    id
    teamId
    apiKey
    name
    role
    flexigets
    emailsConfig {
      event
      to
    }
    remindersConfig {
      event
      frequency {
        value
        unit
      }
    }
    members {
      id
      user {
        id
        email
      }
      role
      active
      dates {
        join
        lastConnection
      }
    }
  }
  teamInvitationsCount
  teams {
    id
    name
    apiKey
  }
} 
`;

const useUser = (value) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!R.isNil(value)) setUser(value);
  }, [value]);

  return user;
};

const UserSessionMemoized = memo(function User ({
  user,
  userRefetch,
  userTeam,
  teamInvitationsCount,
  teams,
  render
}) {
  const $user = useUser(user);
  if (R.isNil($user)) return null;
  return render({ user: $user, userTeam, teamInvitationsCount, userRefetch, teams });
});

const useUserRefresh = (interval) => {
  const [userRefreshKey, setUserRefreshKey] = useState(0);
  const ref = useRef();
  useEffect(() => {
    ref.current = setInterval(
      () => {
        setUserRefreshKey(userRefreshKey + 1);
      },
      interval
    );
    return () => clearInterval(ref.current);
  }, []);
  const userRefetch = useCallback(() => setUserRefreshKey(R.inc), []);

  return { key: userRefreshKey, userRefetch };
};

const UserWrapper = ({
  refreshInterval,
  children
}) => {
  const { key, userRefetch } = useUserRefresh(refreshInterval);
  /**
   * on déclare une variable key qui à chaque fois que sa valeur change va générer une nouvelle requête.
   * on préfère cette solution à l'utilisation d'une prop key car si la clef change les enfants sont détruits.
   */
  return (
    <Query
      query={UserQuery}
      caching
      args={{ key }}
    >
      <UserSessionMemoized render={children} userRefetch={userRefetch}/>
    </Query>
  );
};

UserWrapper.propTypes = {
  refreshInterval: PropTypes.number
};

UserWrapper.defaultProps = {
  refreshInterval: 600000
};

export default UserWrapper;
