import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Section } from '../../../../components';
import React from 'react';
import { successNotification } from '../../../../utils/messageMutation';

const SectionCopyCurl = ({
  template,
  title,
  additionalActions,
  children
}) => {
  const copyCurlToClipboard = (curl) => {
    navigator.clipboard.writeText(curl);
    successNotification('CURL copié');
  };
  return (
    <Section
      title={title}
      actions={(
        <>
          {additionalActions}
          <Button
            onClick={() => copyCurlToClipboard(template)}
            icon={<CopyOutlined/>}
          >
            Copier le CURL
          </Button>
        </>
      )}
    >
      {children}
      <div className="p-4 rounded text-flexibranche-darkgray bg-flexibranche-wildsand">
        <pre>{template}</pre>
      </div>
    </Section>
  );
};

export default SectionCopyCurl;
