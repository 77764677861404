import React, { useState } from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import { Section, Title, ChevronBottom } from '../../components';
import { Trash } from '../../components/icons';
import { Input, Button, Table, Popconfirm, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CreateGroupMutation from '../../_graphql/mutations/groups/CreateGroupMutation';
import AddGroupMemberMutation from '../../_graphql/mutations/groups/AddGroupMemberMutation';
import withUser from '../../withUser';
import AvatarEmail from './AvatarEmail';
import { getEmail } from '../helpers/getEmail';
import { errorMessage, successMessage } from '../../utils/messageMutation';

const useCreateGroup = (userTeam) => {
  const history = useHistory();

  const [state, setState] = useState({
    loading: false,
    name: '',
    userToAdd: []
  });

  const [tempUserToAdd, setTempUserToAdd] = useState(undefined);

  const teamMembers = R.propOr([], 'members', userTeam);

  const teamMembersNotInGroup = R.reject(member => {
    const groupMember = R.find(R.equals(R.path(['user', 'id'], member)))(R.prop('userToAdd', state));
    return !R.isNil(groupMember);
  })(teamMembers);

  const getUserEmail = userId => getEmail(userId, teamMembers);

  const createGroup = () => {
    setState(R.assoc('loading', true));

    CreateGroupMutation({ name: R.prop('name', state) }, (ok, error, groupId) => {
      setState(R.assoc('loading', false));

      if (ok && !error && !R.isNil(groupId)) {
        successMessage('groupe', 'créé');
        R.prop('userToAdd', state).map(memberId => {
          AddGroupMemberMutation({ groupId, memberId }, (ok, error) => {
            if (ok && !error) {
              setState(R.assoc('userToAdd', R.without([memberId], R.prop('userToAdd', state))));
              successMessage('utilisateur', 'ajouté au groupe', true);
            } else {
              errorMessage();
            }
          });
        });
        history.push('/equipe');
      } else {
        errorMessage();
      }
    });
  };

  return {
    ...state,
    setState,
    createGroup,
    getUserEmail,
    teamMembersNotInGroup,
    tempUserToAdd,
    setTempUserToAdd
  };
};

const CreateGroup = ({
  userIsAdmin,
  userTeam
}) => {
  const {
    loading,
    name,
    createGroup,
    setState,
    userToAdd,
    getUserEmail,
    teamMembersNotInGroup,
    tempUserToAdd,
    setTempUserToAdd
  } = useCreateGroup(userTeam);

  if (!userIsAdmin) {
    return null;
  }

  return (
    <>
      <Title title="Créer un nouveau groupe" backTo={'/equipe'} />
      <Section>
        <Input
          placeholder="Nom du groupe"
          value={name}
          onChange={e => setState(R.assoc('name', e.target.value))}
        />

        <div className="mt-8">
          <label className="block mb-2 font-bold">Utilisateurs du groupe</label>
          <Select
            style={{ width: '100%' }}
            placeholder="Ajouter un utilisateur à ce groupe"
            value={tempUserToAdd}
            showArrow
            suffixIcon={<ChevronBottom />}
            mode="multiple"
            onChange={v => setTempUserToAdd(v)}
          >
            {R.map(member => {
              const memberId = R.path(['user', 'id'])(member);

              return (
                <Select.Option
                  key={memberId}
                  value={memberId}
                >
                  {R.path(['user', 'email'])(member)}
                </Select.Option>
              );
            })(teamMembersNotInGroup)}
          </Select>

          <Button
            onClick={() => {
              setState(R.assoc('userToAdd', [...userToAdd, ...tempUserToAdd])); setTempUserToAdd(undefined);
            }}
            disabled={R.isNil(tempUserToAdd) || !R.length(tempUserToAdd)}
            className="mt-4"
            type="primary"
            icon={<PlusOutlined />}
          >
            {R.length(userToAdd) > 1 ? 'Ajouter ces utilisateurs au groupe' : 'Ajouter l\'utilisateur au groupe'}
          </Button>
        </div>

        <Table
          dataSource={userToAdd}
          pagination={false}
          rowKey={userId => userId}
          className="mb-8 mt-10"
        >
          <Table.Column
            title="Email"
            dataIndex=""
            width={800}
            render={userId => {
              const email = getUserEmail(userId);

              return (
                <AvatarEmail email={email} />

              );
            }}
          />

          <Table.Column
            title="Actions"
            dataIndex=""
            width={100}
            render={(userId) => (
              <Popconfirm
                title="Êtes-vous sûr de vouloir retirer cet utilisateur du groupe ?"
                onConfirm={() => setState(R.assoc('userToAdd', R.without([userId], userToAdd)))}
              >
                <Trash />
              </Popconfirm>
            )}
          />
        </Table>

        <Button
          type="primary"
          loading={loading}
          disabled={R.isEmpty(name)}
          onClick={createGroup}
          className="block mt-4"
        >
          Créer le groupe
        </Button>
      </Section>
    </>
  );
};

export default withUser(CreateGroup);
