import React from 'react';
import { Trash, Edit } from '../../../../components/icons';
import { Link, useHistory } from 'react-router-dom';
import { message, Popconfirm, Table, Tooltip } from 'antd';
import DeleteGlobalVariableMutation from '../../../../_graphql/mutations/documents/DeleteGlobalVariableMutation';
import { renderDate } from '../helpers/dates';
import { EyeOutlined } from '@ant-design/icons';
import TableDates from '../tag/TableDates';
import { errorMessage, successMessage } from '../../../../utils/messageMutation';
import { T, always, compose, cond, defaultTo, equals, isNil, prop, propOr, reject } from 'ramda';
import { isTrue } from 'ramda-adjunct';

const GlobalVariablesTable = ({ globalVariables, userCanEditOffer, offerId }) => {
  const history = useHistory();
  const deleteGlobalVariable = globalVariableId => {
    DeleteGlobalVariableMutation({ globalVariableId }, (ok, error) => {
      if (ok && !error) {
        successMessage('variable globale', 'supprimée', false, true);
      } else {
        errorMessage();
      }
    });
  };

  globalVariables = compose(
    reject(isNil),
    defaultTo([])
  )(globalVariables);

  const typeToFrench = (type) => cond([
    [equals('text'), always('Chaîne de caractères')],
    [equals('number'), always('Nombre')],
    [equals('boolean'), always('Booléen')],
    [equals(''), always('')],
    [T, () => message.error('Le type est inconnu')]
  ])(type);

  const linkToVariable = (variable) => cond([
    [isNil, always(`/equipe/elements/variables-globales/${variable.id}/edition`)],
    [T, cond([
      [() => isTrue(userCanEditOffer), always(`/offre/${offerId}/variables-globales/${variable.id}/edition`)],
      [T, always(`/offre/${offerId}/variables-globales/${variable.id}/preview`)]
    ])]
  ])(offerId);

  return (
    <Table
      size="small"
      dataSource={globalVariables}
      rowKey="id"
      pagination={false}
    >
      <Table.Column
        title="Nom"
        dataIndex="name"
        defaultSortOrder="ascend"
        width={300}
        sorter={(a, b) => prop('name', a).toLowerCase().localeCompare(prop('name', b).toLowerCase())}
        render={(name, variable) => (
          <Link to={linkToVariable(variable)}>
            {name}
          </Link>
        )
        }
      />

      <Table.Column
        title="Type"
        dataIndex="type"
        width={300}
        render={(type) => (
          <span
            style={{
              textTransform: 'capitalize'
            }}
          >{typeToFrench(type)}
          </span>
        )}
      />

      <TableDates renderDate={renderDate} />

      <Table.Column
        title=""
        dataIndex="id"
        render={(globalVariableId, variable) => {
          const isUsed = propOr(false, 'isUsed', variable);
          if (userCanEditOffer) {
            return (
              <div className="flex justify-end gap-2 text-lg">
                <Tooltip
                  title="Éditer la variable"
                  color="#0197DC"
                >
                  <Edit
                    className="btn-edit-global-variable grey"
                    onClick={() => history.push(linkToVariable(variable)) }
                  />
                </Tooltip>

                <Popconfirm
                  title="Êtes-vous sûr de vouloir supprimer cette variable globale ?"
                  onConfirm={() => deleteGlobalVariable(globalVariableId)}
                  disabled={isUsed}
                >
                  <Tooltip
                    title={isUsed
                      ? 'Cette variable est utilisée dans un document, vous ne pouvez pas la supprimer.'
                      : 'Supprimer la variable'}
                    color="#0197DC"
                    className="btn-remove-global-variable"
                  >
                    <Trash style={{ cursor: isUsed ? 'not-allowed' : 'pointer' }} className="grey"/>
                  </Tooltip>
                </Popconfirm>
              </div>
            );
          }
          return (
            <div className="flex justify-end gap-2 text-lg">
              <Tooltip
                title="Voir le document"
                color="#0197DC"
              >
                <EyeOutlined onClick={() => history.push(`/offre/${offerId}/variables-globales/${globalVariableId}/preview`)} />
              </Tooltip>
            </div>
          );
        }}
      />
    </Table>
  );
};

export default GlobalVariablesTable;
