/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QGetFrameQueryVariables = {|
  frameId: string
|};
export type QGetFrameQueryResponse = {|
  +getFrame: ?{|
    +id: ?string,
    +name: ?string,
    +pdfIds: ?$ReadOnlyArray<?string>,
    +teamId: ?string,
    +offerId: ?string,
    +offerName: ?string,
    +metadata: ?string,
    +bundleTag: ?string,
    +version: ?string,
    +versionFlexidocs: ?string,
    +isReferent: ?boolean,
    +description: ?string,
    +comment: ?string,
    +who: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
      +creationData: ?{|
        +extra: ?{|
          +firstname: ?string,
          +lastname: ?string,
        |}
      |},
    |},
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
    +comparisons: ?$ReadOnlyArray<?{|
      +globalStatusResult: ?string,
      +when: ?string,
      +id: ?string,
      +comparisonDetails: ?$ReadOnlyArray<?{|
        +status: ?string,
        +diffPngsIds: ?$ReadOnlyArray<?string>,
        +referentPdfId: ?string,
        +actualPdfId: ?string,
        +fileName: ?string,
      |}>,
    |}>,
  |}
|};
export type QGetFrameQuery = {|
  variables: QGetFrameQueryVariables,
  response: QGetFrameQueryResponse,
|};
*/


/*
query QGetFrameQuery(
  $frameId: ID!
) {
  getFrame(frameId: $frameId) {
    id
    name
    pdfIds
    teamId
    offerId
    offerName
    metadata
    bundleTag
    version
    versionFlexidocs
    isReferent
    description
    comment
    who {
      creation
      lastUpdate
      creationData {
        extra {
          firstname
          lastname
        }
        id
      }
    }
    dates {
      creation
      lastUpdate
    }
    comparisons {
      globalStatusResult
      when
      id
      comparisonDetails {
        status
        diffPngsIds
        referentPdfId
        actualPdfId
        fileName
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "frameId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "frameId",
    "variableName": "frameId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pdfIds",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metadata",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bundleTag",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "versionFlexidocs",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isReferent",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comment",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creation",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "UserExtra",
  "kind": "LinkedField",
  "name": "extra",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Dates",
  "kind": "LinkedField",
  "name": "dates",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    (v16/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "globalStatusResult",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "when",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "diffPngsIds",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referentPdfId",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPdfId",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QGetFrameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Frame",
        "kind": "LinkedField",
        "name": "getFrame",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Who",
            "kind": "LinkedField",
            "name": "who",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creationData",
                "plural": false,
                "selections": [
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v18/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ComparisonGlobalResult",
            "kind": "LinkedField",
            "name": "comparisons",
            "plural": true,
            "selections": [
              (v19/*: any*/),
              (v20/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ComparisonDetails",
                "kind": "LinkedField",
                "name": "comparisonDetails",
                "plural": true,
                "selections": [
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QGetFrameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Frame",
        "kind": "LinkedField",
        "name": "getFrame",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Who",
            "kind": "LinkedField",
            "name": "who",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creationData",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v18/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ComparisonGlobalResult",
            "kind": "LinkedField",
            "name": "comparisons",
            "plural": true,
            "selections": [
              (v19/*: any*/),
              (v20/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ComparisonDetails",
                "kind": "LinkedField",
                "name": "comparisonDetails",
                "plural": true,
                "selections": [
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0dd0b8d5e301f15a697d0bca3e35b275",
    "id": null,
    "metadata": {},
    "name": "QGetFrameQuery",
    "operationKind": "query",
    "text": "query QGetFrameQuery(\n  $frameId: ID!\n) {\n  getFrame(frameId: $frameId) {\n    id\n    name\n    pdfIds\n    teamId\n    offerId\n    offerName\n    metadata\n    bundleTag\n    version\n    versionFlexidocs\n    isReferent\n    description\n    comment\n    who {\n      creation\n      lastUpdate\n      creationData {\n        extra {\n          firstname\n          lastname\n        }\n        id\n      }\n    }\n    dates {\n      creation\n      lastUpdate\n    }\n    comparisons {\n      globalStatusResult\n      when\n      id\n      comparisonDetails {\n        status\n        diffPngsIds\n        referentPdfId\n        actualPdfId\n        fileName\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '43b3f04ad2976b312039c790a7b346b7';

module.exports = node;
