/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QGetHistoriesFramesQueryVariables = {|
  offerId: string,
  bundleTag: string,
  version: string,
  nbOfFrame?: ?string,
|};
export type QGetHistoriesFramesQueryResponse = {|
  +getHistoryFrames: ?$ReadOnlyArray<?{|
    +createdAt: ?string,
    +externalId: ?string,
    +metadata: ?string,
    +id: ?string,
  |}>
|};
export type QGetHistoriesFramesQuery = {|
  variables: QGetHistoriesFramesQueryVariables,
  response: QGetHistoriesFramesQueryResponse,
|};
*/


/*
query QGetHistoriesFramesQuery(
  $offerId: ID!
  $bundleTag: String!
  $version: String!
  $nbOfFrame: String
) {
  getHistoryFrames(offerId: $offerId, bundleTag: $bundleTag, version: $version, nbOfFrame: $nbOfFrame) {
    createdAt
    externalId
    metadata
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bundleTag"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nbOfFrame"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offerId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "version"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bundleTag",
        "variableName": "bundleTag"
      },
      {
        "kind": "Variable",
        "name": "nbOfFrame",
        "variableName": "nbOfFrame"
      },
      {
        "kind": "Variable",
        "name": "offerId",
        "variableName": "offerId"
      },
      {
        "kind": "Variable",
        "name": "version",
        "variableName": "version"
      }
    ],
    "concreteType": "ExternalFrame",
    "kind": "LinkedField",
    "name": "getHistoryFrames",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "externalId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadata",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QGetHistoriesFramesQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "QGetHistoriesFramesQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "0c2981a519b6720aa85737d4ef41da35",
    "id": null,
    "metadata": {},
    "name": "QGetHistoriesFramesQuery",
    "operationKind": "query",
    "text": "query QGetHistoriesFramesQuery(\n  $offerId: ID!\n  $bundleTag: String!\n  $version: String!\n  $nbOfFrame: String\n) {\n  getHistoryFrames(offerId: $offerId, bundleTag: $bundleTag, version: $version, nbOfFrame: $nbOfFrame) {\n    createdAt\n    externalId\n    metadata\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e342af9e084aa2ec1a1d0c992d2ce76b';

module.exports = node;
