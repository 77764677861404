/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaginationInput = {|
  limit?: ?number,
  page?: ?number,
|};
export type QTeamActionsQueryVariables = {|
  pagination?: ?PaginationInput
|};
export type QTeamActionsQueryResponse = {|
  +teamActions: ?{|
    +docs: ?$ReadOnlyArray<?{|
      +user: ?{|
        +email: ?string
      |},
      +team: ?{|
        +name: ?string
      |},
      +actionName: ?string,
      +when: ?string,
      +data: ?{|
        +offer: ?{|
          +offerNumber: ?number
        |}
      |},
    |}>,
    +pagination: ?{|
      +page: ?number,
      +pages: ?number,
      +limit: ?number,
      +total: ?number,
    |},
  |}
|};
export type QTeamActionsQuery = {|
  variables: QTeamActionsQueryVariables,
  response: QTeamActionsQueryResponse,
|};
*/


/*
query QTeamActionsQuery(
  $pagination: PaginationInput
) {
  teamActions(pagination: $pagination) {
    docs {
      user {
        email
        id
      }
      team {
        name
        id
      }
      actionName
      when
      data {
        offer {
          offerNumber
          id
        }
      }
    }
    pagination {
      page
      pages
      limit
      total
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pagination"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pagination",
    "variableName": "pagination"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actionName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "when",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Pagination",
  "kind": "LinkedField",
  "name": "pagination",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "page",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "limit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QTeamActionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActionsWithPagination",
        "kind": "LinkedField",
        "name": "teamActions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Action",
            "kind": "LinkedField",
            "name": "docs",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "team",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ActionData",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Offer",
                    "kind": "LinkedField",
                    "name": "offer",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QTeamActionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActionsWithPagination",
        "kind": "LinkedField",
        "name": "teamActions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Action",
            "kind": "LinkedField",
            "name": "docs",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "team",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ActionData",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Offer",
                    "kind": "LinkedField",
                    "name": "offer",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "07a8b87b666e4077e9ee287942e23eb0",
    "id": null,
    "metadata": {},
    "name": "QTeamActionsQuery",
    "operationKind": "query",
    "text": "query QTeamActionsQuery(\n  $pagination: PaginationInput\n) {\n  teamActions(pagination: $pagination) {\n    docs {\n      user {\n        email\n        id\n      }\n      team {\n        name\n        id\n      }\n      actionName\n      when\n      data {\n        offer {\n          offerNumber\n          id\n        }\n      }\n    }\n    pagination {\n      page\n      pages\n      limit\n      total\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '575943917b8659b3f5f8caa58710ace1';

module.exports = node;
