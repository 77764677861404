const fr = {
  messages: {
    siret: 'n° SIRET',
    'connection.title.id': 'Identifiant',
    'connection.title.password': 'Mot de passe',

    'menu.choice.1': 'Effectuer une nouvelle demande',
    'menu.choice.2': 'Modifier un contrat existant',
    'menu.choice.3': 'Reprendre une proposition en cours',
    'menu.choice.4': 'Suite économique',
    'menu.choice.5': 'Générer un duplicata',
    'menu.choice.6': 'Effectuer une suite économique',

    'companySelect.btn.select': 'Sélectionner l\'établissement'
  }
};

export default fr;
