import * as R from 'ramda';
import { elementResizable, getMinWidth } from './elements';

const RA = require('ramda-adjunct');

const WIDTH_A4_WITHOUT_MARGINS = 794;
const WIDTH_LANDSCAPE_WITHOUT_MARGINS = 1123;

const editorWidth = (orientation) => R.equals('portrait', orientation) ? WIDTH_A4_WITHOUT_MARGINS : WIDTH_LANDSCAPE_WITHOUT_MARGINS;
const widthOneGrid = (nbColsGrid, orientation) => editorWidth(orientation) / nbColsGrid;

export const getSizeElement = (type, element) => R.path(['size', type], element);

/**
 * It takes a list of elements, and returns a list of elements with the same properties, but with the
 * width and height properties converted to a grid system
 * @param contents - the array of elements that you want to display
 * @param nbColsGrid - the number of columns in the grid
 * @param heightRowsGrid - the height of a row in the grid
 * @param setLayout - a function that sets the layout state
 * @param widgetEditable - boolean
 * @param isDownNbColsGrid - boolean
 * @param elements - the array of elements that are being rendered
 * @param widgetMinSize - the minimum size of a widget
 */
const updateLayout = (contents, nbColsGrid, heightRowsGrid, setLayout, widgetEditable, isDownNbColsGrid, elements, widgetMinSize, orientation) => {
  const newLayout = contents.map((element, i) => {
    const widthSizeElement = getSizeElement('width', element);
    const heightSizeElement = getSizeElement('height', element);
    const elementType = R.prop('type', element);

    const roundWidth = R.ifElse(
      R.equals(true),
      R.always(RA.round(widthSizeElement / widthOneGrid(nbColsGrid, orientation))),
      R.always(RA.ceil(widthSizeElement / widthOneGrid(nbColsGrid, orientation)))
    )(isDownNbColsGrid);

    const width = R.ifElse(
      R.allPass([R.equals(true), () => !R.equals(nbColsGrid, 1), () => !R.equals(roundWidth, nbColsGrid), () => R.gt(roundWidth, 1)]),
      R.always(roundWidth - 1),
      R.always(roundWidth)
    )(isDownNbColsGrid);

    const minHeightElement = 1;

    return {
      i,
      x: element.position.x / widthOneGrid(nbColsGrid, orientation),
      y: element.position.y / heightRowsGrid,
      h: R.is(Number, heightSizeElement) ? heightSizeElement / heightRowsGrid : minHeightElement,
      w: R.is(Number, widthSizeElement) ? width : nbColsGrid,
      maxW: nbColsGrid,
      minW: getMinWidth(elementType, widthOneGrid(nbColsGrid, orientation), widgetMinSize),
      minH: minHeightElement,
      isDraggable: widgetEditable,
      isResizable: widgetEditable && elementResizable(element, elements),
      isBounded: widgetEditable,
      static: !widgetEditable,
      uuid: R.prop('uuid', element)
    };
  });
  setLayout(newLayout);
};

/**
 * It takes a list of widgets and an id, and returns the height of the widget with the given id
 * @param widgetsList - The list of widgets
 * @param id - the id of the widget you want to get the height of
 */
const widgetHeight = (widgetsList = [], id) => R.compose(
  R.ifElse(
    R.isNil,
    R.always(0),
    R.compose(
      (elements) => {
        const elementsTop = R.map(R.path(['y']))(elements);
        const elementsHeight = R.map(R.path(['h']))(elements);
        const heightTopElement = elementsHeight.map((e, i) => ({
          id: i,
          h: e.h,
          t: elementsTop[i]
        }));
        return R.compose(
          R.cond([
            [R.all(R.equals(elementsTop[0])), () => R.sort((a, b) => b - a, elementsHeight)[0]],
            [R.T, () => R.compose(
              R.path(['0']),
              R.sort((a, b) => b - a),
              (hs) => hs.map((h, i) => R.reduceWhile(
                R.equals,
                () => (h += R.find((t) => i === R.prop('id', t), heightTopElement).t),
                0,
                R.sort((a, b) => a - b, elementsTop)
              )))(elementsHeight)]
          ])
        )(elementsTop);
      },
      R.map((element) => ({ h: getSizeElement('height', element), y: R.path(['position', 'y'], element) })),
      JSON.parse,
      R.path(['contents'])
    )
  ),
  () => R.find(R.propEq('id', id))(widgetsList)
);

export {
  WIDTH_A4_WITHOUT_MARGINS,
  WIDTH_LANDSCAPE_WITHOUT_MARGINS,
  editorWidth,
  widthOneGrid,
  updateLayout,
  widgetHeight
};
