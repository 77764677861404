const defaultColors = [
  { color: '', title: 'transparent' },
  { color: '#000000', title: 'noir' },
  { color: '#ffffff', title: 'blanc' },
  { color: '#a6a6a6', title: 'gris' },
  { color: '#ff0000', title: 'rouge' },
  { color: '#ffa500', title: 'orange' },
  { color: '#ffff00', title: 'jaune' },
  { color: '#00ff00', title: 'vert' },
  { color: '#00ffff', title: 'bleu' },
  { color: '#0000ff', title: 'violet' }
];

export default defaultColors;
