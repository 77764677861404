/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CommentStatusEnum = "accepted" | "pending" | "rejected" | "%future added value";
export type QCommentsQueryVariables = {|
  itemRootId?: ?string
|};
export type QCommentsQueryResponse = {|
  +comments: ?{|
    +id: ?string,
    +comments: ?$ReadOnlyArray<?{|
      +id: ?string,
      +itemKey: ?string,
      +itemPath: ?$ReadOnlyArray<?any>,
      +itemRootId: ?string,
      +status: ?CommentStatusEnum,
      +teamId: ?string,
      +widgetName: ?string,
      +widgetId: ?string,
      +messages: ?$ReadOnlyArray<?{|
        +text: ?string,
        +userId: ?string,
        +userName: ?string,
        +when: ?string,
      |}>,
    |}>,
  |}
|};
export type QCommentsQuery = {|
  variables: QCommentsQueryVariables,
  response: QCommentsQueryResponse,
|};
*/


/*
query QCommentsQuery(
  $itemRootId: ID
) {
  comments(itemRootId: $itemRootId) {
    id
    comments {
      id
      itemKey
      itemPath
      itemRootId
      status
      teamId
      widgetName
      widgetId
      messages {
        text
        userId
        userName
        when
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "itemRootId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "itemRootId",
        "variableName": "itemRootId"
      }
    ],
    "concreteType": "CommentsList",
    "kind": "LinkedField",
    "name": "comments",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "comments",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemKey",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemPath",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemRootId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "teamId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "widgetName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "widgetId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CommentMessage",
            "kind": "LinkedField",
            "name": "messages",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "when",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QCommentsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QCommentsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5ea3b7a3aa4eb5212c65ec97386300c2",
    "id": null,
    "metadata": {},
    "name": "QCommentsQuery",
    "operationKind": "query",
    "text": "query QCommentsQuery(\n  $itemRootId: ID\n) {\n  comments(itemRootId: $itemRootId) {\n    id\n    comments {\n      id\n      itemKey\n      itemPath\n      itemRootId\n      status\n      teamId\n      widgetName\n      widgetId\n      messages {\n        text\n        userId\n        userName\n        when\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f50735912c5f61e60d3e80d039a0d812';

module.exports = node;
