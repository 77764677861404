import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Alert, Input, Button } from 'antd';
import AccountLayout from '../../layout/account/AccountLayout';
import * as R from 'ramda';
import { MailOutlined } from '@ant-design/icons';
import { emailIsValid } from '../../lib/validation';
import usePassword from '../../hooks/usePassword';

const RequestPassword = () => {
  const {
    loading,
    email,
    error,
    sent,
    setState,
    onRequestPassword
  } = usePassword();

  return (
    <AccountLayout>
      <h3>Réinitialiser votre mot de passe</h3>

      {!sent && !error && (
        <>
          <Input
            prefix={<MailOutlined/>}
            placeholder="E-mail"
            size="large"
            onChange={e => setState(R.assoc('email', e.target.value))}
          />

          <br/>

          <Button
            type="primary"
            htmlType="submit"
            disabled={!emailIsValid(email)}
            loading={loading}
            onClick={onRequestPassword}
          >
            Envoyer
          </Button>
        </>
      )}

      {error && (
        <div>
          <Alert
            type="error"
            message={'Votre demande n\'a pas pu aboutir. Veuillez vérifier votre adresse mail et réessayer.'}
          />
          <br/>
          <Button
            size="large"
            onClick={() => setState(R.assoc('error', false))}
            type="primary"
          >
            {'Réessayer'}
          </Button>
        </div>
      )}

      {sent && !error && (
        <div>
          <Alert
            type="success"
            message={<span>{'Un email vient de vous être envoyé à l\'adresse'}&nbsp;<b>{email}</b>&nbsp;{'avec un lien pour changer votre mot de passe'}</span>}
          />
          <br/>
          <Button
            size="large"
            onClick={() => setState(R.assoc('sent', false))}
            type="primary"
          >
            {'Je n\'ai pas reçu le mail'}
          </Button>
        </div>
      )}
    </AccountLayout>
  );
};

export default RequestPassword;
