import { isNil, prop } from 'ramda';
import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QDocumentRightsQuery = graphql`
  query QDocumentRightsQuery($documentId: ID!) {
    documentRights(documentId: $documentId) {
      userIsDocumentValidator
      userIsDocumentEditor
      userIsDocumentTechEditor
      userIsDocumentReader
    }
  }
`;

const QDocumentRights = ({
  children,
  args,
  childDataProp
}) => {
  if (isNil(prop('documentId', args))) {
    return populateChildren()(children)({});
  }

  return (
    <Query
      query={QDocumentRightsQuery}
      args={args}
      caching={false}
    >
      {populateChildren('documentRights')(children, childDataProp)}
    </Query>
  );
};

export default QDocumentRights;
