/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddGroupRoleMutationVariables = {|
  groupId: string,
  itemType: string,
  itemId: string,
  role: string,
|};
export type AddGroupRoleMutationResponse = {|
  +addGroupRole: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type AddGroupRoleMutation = {|
  variables: AddGroupRoleMutationVariables,
  response: AddGroupRoleMutationResponse,
|};
*/


/*
mutation AddGroupRoleMutation(
  $groupId: ID!
  $itemType: String!
  $itemId: String!
  $role: String!
) {
  addGroupRole(groupId: $groupId, itemType: $itemType, itemId: $itemId, role: $role) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemType"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "role"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "groupId",
        "variableName": "groupId"
      },
      {
        "kind": "Variable",
        "name": "itemId",
        "variableName": "itemId"
      },
      {
        "kind": "Variable",
        "name": "itemType",
        "variableName": "itemType"
      },
      {
        "kind": "Variable",
        "name": "role",
        "variableName": "role"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "addGroupRole",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddGroupRoleMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddGroupRoleMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "654f5d2a6317a4529c599f76d646764a",
    "id": null,
    "metadata": {},
    "name": "AddGroupRoleMutation",
    "operationKind": "mutation",
    "text": "mutation AddGroupRoleMutation(\n  $groupId: ID!\n  $itemType: String!\n  $itemId: String!\n  $role: String!\n) {\n  addGroupRole(groupId: $groupId, itemType: $itemType, itemId: $itemId, role: $role) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '50cc7db220a73acc630e81fbc50f5f84';

module.exports = node;
