/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ElementReviewStatusEnum = "accepted" | "pending" | "rejected" | "%future added value";
export type SaveElementReviewMutationVariables = {|
  elementReviewId?: ?string,
  itemRootId: string,
  itemId: string,
  groupId: string,
  status: ElementReviewStatusEnum,
  itemType?: ?string,
|};
export type SaveElementReviewMutationResponse = {|
  +saveElementReview: ?{|
    +ok: boolean,
    +error: ?string,
    +elementReview: ?{|
      +id: ?string
    |},
  |}
|};
export type SaveElementReviewMutation = {|
  variables: SaveElementReviewMutationVariables,
  response: SaveElementReviewMutationResponse,
|};
*/


/*
mutation SaveElementReviewMutation(
  $elementReviewId: ID
  $itemRootId: String!
  $itemId: String!
  $groupId: String!
  $status: ElementReviewStatusEnum!
  $itemType: String
) {
  saveElementReview(elementReviewId: $elementReviewId, itemRootId: $itemRootId, itemId: $itemId, groupId: $groupId, status: $status, itemType: $itemType) {
    ok
    error
    elementReview {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "elementReviewId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemRootId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemType"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "elementReviewId",
        "variableName": "elementReviewId"
      },
      {
        "kind": "Variable",
        "name": "groupId",
        "variableName": "groupId"
      },
      {
        "kind": "Variable",
        "name": "itemId",
        "variableName": "itemId"
      },
      {
        "kind": "Variable",
        "name": "itemRootId",
        "variableName": "itemRootId"
      },
      {
        "kind": "Variable",
        "name": "itemType",
        "variableName": "itemType"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "ElementReviewMutationResponse",
    "kind": "LinkedField",
    "name": "saveElementReview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ElementReview",
        "kind": "LinkedField",
        "name": "elementReview",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SaveElementReviewMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "SaveElementReviewMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "250d0d624e6d67beb3419827408d1088",
    "id": null,
    "metadata": {},
    "name": "SaveElementReviewMutation",
    "operationKind": "mutation",
    "text": "mutation SaveElementReviewMutation(\n  $elementReviewId: ID\n  $itemRootId: String!\n  $itemId: String!\n  $groupId: String!\n  $status: ElementReviewStatusEnum!\n  $itemType: String\n) {\n  saveElementReview(elementReviewId: $elementReviewId, itemRootId: $itemRootId, itemId: $itemId, groupId: $groupId, status: $status, itemType: $itemType) {\n    ok\n    error\n    elementReview {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a8e4737f1d93176f066d14933d092d04';

module.exports = node;
