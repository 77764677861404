import { message } from 'antd';
import { assoc, assocPath, compose, concat, pathOr, prop, propEq, find, pick, propOr, path, omit, anyPass, gt, length, dissocPath } from 'ramda';
import { isNilOrEmpty, isNotNil, notEqual } from 'ramda-adjunct';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { errorMessage, successMessage } from '../utils/messageMutation';
import CreateFrameMutation from '../_graphql/mutations/documents/CreateFrameMutation';
import DeleteFrameMutation from '../_graphql/mutations/documents/DeleteFrameMutation';
import UpdateFrameMutation from '../_graphql/mutations/documents/UpdateFrameMutation';
import UpdateReferentFrameMutation from '../_graphql/mutations/documents/UpdateReferentFrameMutation';

const useFrame = ({ frame = {} }) => {
  const history = useHistory();
  const frameExist = isNotNil(prop('id', frame));

  const setDefaultValues = (frame) => ({
    id: propOr(undefined, 'id', frame),
    name: propOr('', 'name', frame),
    description: propOr('', 'description', frame),
    offerId: propOr(undefined, 'offerId', frame),
    offerName: propOr('', 'offerName', frame),
    bundleTag: propOr(undefined, 'bundleTag', frame),
    metadata: propOr('', 'metadata', frame),
    version: propOr(undefined, 'version', frame),
    versionFlexidocs: propOr('', 'versionFlexidocs', frame),
    isReferent: propOr(false, 'isReferent', frame),
    who: propOr({}, 'who', frame),
    pdfIds: propOr([], 'pdfIds', frame),
    comparisons: propOr([], 'comparisons', frame),
    comment: propOr('', 'comment', frame)
  });

  const [state, setState] = useState({
    values: setDefaultValues(frame),
    loading: false
  });

  useEffect(() => {
    if (frameExist) setState(assoc('values', setDefaultValues(frame)));
  }, [frame, frameExist]);

  const [selectedExternalFrame, setSelectedExternalFrame] = useState(null);

  const setValues = (k, v) => setState(assocPath(['values', k], v));

  const onSave = () => {
    setState(assoc('loading', true));

    const callback = (ok, error, frameId) => {
      setState(assoc('loading', false));

      if (ok && !error) {
        successMessage('trame', 'créé', false, true);
        history.push(`/trames/${frameId}/informations`);
      } else {
        errorMessage();
      }
    };

    setState(assoc('loading', true));
    message.info(
      'Sauvegarde en cours, veuillez patienter..'
    );

    const valuesCreation = pick(['name', 'description', 'offerId', 'offerName', 'bundleTag', 'metadata', 'version'])(prop('values', state));
    CreateFrameMutation(valuesCreation, callback);
  };

  const onSelectExternalFrame = (historyFrames, id) => {
    setSelectedExternalFrame(id);
    const metadataFrame = compose(
      prop('metadata'),
      find(propEq('id', id))
    )(historyFrames);
    setValues('metadata', metadataFrame);
  };

  const onClearFrame = (metadata) => {
    setValues('metadata', metadata);
    setSelectedExternalFrame(null);
  };

  const filterOptionFrame = (inputValue, option) => {
    const date = pathOr('', ['children', 0, 'props', 'children', 2], option);
    const externalId = pathOr('', ['children', 2], option);
    const combineDateAndExternalIdInfos = concat(date, externalId);
    return combineDateAndExternalIdInfos.toLowerCase().includes(inputValue.toLowerCase());
  };

  const onDelete = (ids, reFetch) => {
    setState(assoc('loading', true));
    const isPlural = gt(length(ids), 1);
    DeleteFrameMutation(
      { ids },
      (ok, error) => {
        setState(assoc('loading', false));

        if (ok && !error) {
          successMessage('trame', 'supprimée', false, true, isPlural);
          if (reFetch) {
            reFetch(true);
          } else {
            history.push('/trames');
          }
        } else {
          errorMessage();
        }
      }
    );
  };

  const onUpdate = (setReFetch, id, data) => {
    setState(assoc('loading', true));
    const deleteCreationData = dissocPath(['who', 'creationData']);
    const dataToSet = deleteCreationData(data || omit(['id', 'pdfIds', 'comparisons'])(prop('values', state)));
    const metadataHasChanged = data ? false : notEqual(
      path(['values', 'metadata'], state),
      prop('metadata', frame)
    );

    const frameId = id || path(['values', 'id'], state);
    UpdateFrameMutation(
      {
        frameId,
        dataToSet,
        metadataHasChanged
      },
      (ok, error) => {
        setState(assoc('loading', false));
        if (ok && !error) {
          successMessage('trame', 'mise à jour', false, true);
          const isReferentHasChanged = notEqual(
            prop('isReferent', frame),
            prop('isReferent', dataToSet)
          );
          if ((metadataHasChanged || isReferentHasChanged || data) && setReFetch) setReFetch(true);
        } else {
          errorMessage();
        }
      }
    );
  };

  const changeReferentDocumentOnFrame = (ids, reFetch) => {
    setState(assoc('loading', true));
    const isPlural = gt(length(ids), 1);
    UpdateReferentFrameMutation(
      { ids },
      (ok, error) => {
        setState(assoc('loading', false));
        if (ok && !error) {
          successMessage('pdf référent', 'mis à jour', false, false, false, isPlural);
          reFetch(true);
        } else {
          errorMessage();
        }
      }
    );
  };

  const buttonIsDisabled = anyPass([
    (v) => isNilOrEmpty(prop('name', v)),
    (v) => isNilOrEmpty(prop('offerId', v)),
    (v) => isNilOrEmpty(prop('offerName', v)),
    (v) => isNilOrEmpty(prop('bundleTag', v)),
    (v) => isNilOrEmpty(prop('metadata', v)),
    (v) => isNilOrEmpty(prop('version', v))
  ])(prop('values', state));

  return {
    ...state,
    onSave,
    setValues,
    setState,
    buttonIsDisabled,
    setDefaultValues,
    filterOptionFrame,
    onClearFrame,
    onSelectExternalFrame,
    selectedExternalFrame,
    onDelete,
    onUpdate,
    changeReferentDocumentOnFrame
  };
};

export default useFrame;
