import {
  commitMutation,
  graphql
} from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateTeamUserRoleMutation($teamId: ID!, $userId: ID!, $role: String!) {
    updateTeamUserRole(teamId: $teamId, userId: $userId, role: $role) {
      ok
      error
    }
  }
`;

export default ({ teamId, userId, role }, done) => {
  const variables = {
    teamId,
    userId,
    role
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateTeamUserRole }) => basicMutationOnCompleted(updateTeamUserRole, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
