/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentScopeEnum = "global" | "offer" | "%future added value";
export type StyleInput = {|
  name?: ?string,
  offerId?: ?string,
  scope?: ?DocumentScopeEnum,
  styles?: ?$ReadOnlyArray<?StyleItemInput>,
|};
export type StyleItemInput = {|
  format?: ?string,
  type?: ?string,
  value?: ?string,
|};
export type UpdateStyleMutationVariables = {|
  styleId: string,
  style: StyleInput,
|};
export type UpdateStyleMutationResponse = {|
  +updateStyle: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateStyleMutation = {|
  variables: UpdateStyleMutationVariables,
  response: UpdateStyleMutationResponse,
|};
*/


/*
mutation UpdateStyleMutation(
  $styleId: ID!
  $style: StyleInput!
) {
  updateStyle(styleId: $styleId, style: $style) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "style"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "styleId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "style",
        "variableName": "style"
      },
      {
        "kind": "Variable",
        "name": "styleId",
        "variableName": "styleId"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateStyle",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateStyleMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateStyleMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "fc1f62ee703247427e037d0b132c87e6",
    "id": null,
    "metadata": {},
    "name": "UpdateStyleMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateStyleMutation(\n  $styleId: ID!\n  $style: StyleInput!\n) {\n  updateStyle(styleId: $styleId, style: $style) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a31a9be89907b39165e649987b87a027';

module.exports = node;
