export const onErrorMutation = (error, done) => {
  console.error(error);
  done(false, error);
};

export const basicMutationOnCompleted = (item, done) => {
  const { ok, error } = item;
  done(ok, error);
};

export const updaterBasicDelete = (proxyStore, ok, error, id) => {
  if (ok && !error) {
    proxyStore.delete(id);
  }
};
