import { Input, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNilOrEmpty, notEqual } from 'ramda-adjunct';
import { path, pathOr } from 'ramda';
import { EditOutlined } from '@ant-design/icons';

const FrameCommentInput = ({ comment, frameId, handleCommentChange }) => {
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showAddComment, setShowAddComment] = useState(true);
  const [commentValue, setCommentValue] = useState(comment);

  const handleOnBlurOrPressEnter = (e) => {
    const currentValue = pathOr('', ['target', 'value'], e);
    const shouldUpdate = notEqual(comment, currentValue);

    shouldUpdate && handleCommentChange(frameId, e);
    setShowCommentInput(false);
    setShowAddComment(true);
  };

  useEffect(() => {
    setCommentValue(comment);
  }, [comment]);

  return (
    <>
      <div className="flex items-center w-250px">
        {showCommentInput ? (
          <Input
            autoFocus
            allowClear
            value={commentValue}
            onChange={(e) => setCommentValue(path(['target', 'value'], e))}
            onBlur={handleOnBlurOrPressEnter}
            onPressEnter={handleOnBlurOrPressEnter}
          />
        ) : (
          <Tooltip title={comment} color="#003a55">
            <p
              className="truncate"
              onClick={() => setShowCommentInput(true)}
            >
              {comment}
            </p>
          </Tooltip>
        )}
      </div>
      {isNilOrEmpty(comment) && showAddComment && (
        <div className="flex items-center w-250px">
          <Tooltip
            title="Ajouter un commentaire"
            color="#0197DC"
            onClick={() => {
              setShowCommentInput(true);
              setShowAddComment(!showAddComment);
            }}
          >
            <EditOutlined className="ml-2 grey"/>
          </Tooltip>
        </div>
      )}
    </>
  );
};

FrameCommentInput.propTypes = {
  comment: PropTypes.string,
  frameId: PropTypes.string,
  handleCommentChange: PropTypes.func
};

export default FrameCommentInput;
