/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DuplicateWidgetMutationVariables = {|
  widgetId: string
|};
export type DuplicateWidgetMutationResponse = {|
  +duplicateWidget: ?{|
    +ok: boolean,
    +error: ?string,
    +widgetId: ?string,
    +name: ?string,
  |}
|};
export type DuplicateWidgetMutation = {|
  variables: DuplicateWidgetMutationVariables,
  response: DuplicateWidgetMutationResponse,
|};
*/


/*
mutation DuplicateWidgetMutation(
  $widgetId: ID!
) {
  duplicateWidget(widgetId: $widgetId) {
    ok
    error
    widgetId
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "widgetId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "widgetId",
        "variableName": "widgetId"
      }
    ],
    "concreteType": "DuplicateWidgetMutation",
    "kind": "LinkedField",
    "name": "duplicateWidget",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "widgetId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DuplicateWidgetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DuplicateWidgetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "09e0188d983e5d7e8b057216d72cc221",
    "id": null,
    "metadata": {},
    "name": "DuplicateWidgetMutation",
    "operationKind": "mutation",
    "text": "mutation DuplicateWidgetMutation(\n  $widgetId: ID!\n) {\n  duplicateWidget(widgetId: $widgetId) {\n    ok\n    error\n    widgetId\n    name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa2e1ca53de1a69066dea96ea56960c6';

module.exports = node;
