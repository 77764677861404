/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type useTeamsQueryVariables = {|
  userId?: ?string
|};
export type useTeamsQueryResponse = {|
  +teams: ?$ReadOnlyArray<?{|
    +id: ?string,
    +members: ?$ReadOnlyArray<?{|
      +id: ?string,
      +user: ?{|
        +id: ?string,
        +email: ?string,
      |},
      +role: ?string,
      +active: ?boolean,
      +dates: ?{|
        +join: ?string,
        +lastConnection: ?string,
      |},
    |}>,
    +apiKey: ?string,
    +name: ?string,
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
  |}>
|};
export type useTeamsQuery = {|
  variables: useTeamsQueryVariables,
  response: useTeamsQueryResponse,
|};
*/


/*
query useTeamsQuery(
  $userId: ID
) {
  teams(userId: $userId) {
    id
    members {
      id
      user {
        id
        email
      }
      role
      active
      dates {
        join
        lastConnection
      }
    }
    apiKey
    name
    dates {
      creation
      lastUpdate
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "Team",
    "kind": "LinkedField",
    "name": "teams",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamMember",
        "kind": "LinkedField",
        "name": "members",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamMemberDates",
            "kind": "LinkedField",
            "name": "dates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "join",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastConnection",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "apiKey",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Dates",
        "kind": "LinkedField",
        "name": "dates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useTeamsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useTeamsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "bd9540916c461ee0d5e8bd2de0fc985f",
    "id": null,
    "metadata": {},
    "name": "useTeamsQuery",
    "operationKind": "query",
    "text": "query useTeamsQuery(\n  $userId: ID\n) {\n  teams(userId: $userId) {\n    id\n    members {\n      id\n      user {\n        id\n        email\n      }\n      role\n      active\n      dates {\n        join\n        lastConnection\n      }\n    }\n    apiKey\n    name\n    dates {\n      creation\n      lastUpdate\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '49f29a887a3bd76be229899473c9b3b3';

module.exports = node;
