import { FontSizeOutlined } from '@ant-design/icons';
import React from 'react';
import VariableContainer from './VariableContainer';

const JSONVariable = ({ label, key, value, onChange, onChangeIsDefaultValue, isDefaultValue }) => (
  <VariableContainer
    label={label}
    icon={<FontSizeOutlined />}
    onChangeIsDefaultValue={onChangeIsDefaultValue}
    isDefaultValue={isDefaultValue}
    value={JSON.parse(value)}
  >
    <textarea
      id={key}
      name={key}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      cols="90"
      rows="20"
    />
  </VariableContainer>
);

export default JSONVariable;
