import { Button, Input } from 'antd';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import Loading from '../../Loading';

const CommentsBox = ({
  commentItem,
  addMessage,
  updateStatus,
  deleteComment,
  addMessageLoading,
  updateStatusLoading,
  deleteCommentLoading,
  mode
}) => {
  const {
    messages,
    itemPath,
    itemKey,
    id: commentId,
    status
  } = commentItem;

  const [message, setMessage] = useState('');
  const statusIs = R.equals(status);

  const isReview = R.equals(mode, 'review');

  const onUpdateStatus = (status) => () => updateStatus(commentId, status);

  return (
    <div
      className="fixed bottom-2 right-5 hidden bg-white border border-solid border-gray-100 rounded-md shadow-2xl px-6 w-300px pb-2"
      style={{ zIndex: 9999 }}
      data-comment-path={itemPath}
      data-comment-key={itemKey}
    >
      <div className="text-center">
        {deleteCommentLoading && <Loading/>}

        {!deleteCommentLoading && isReview && !R.isEmpty(messages) && (
          <Button
            danger
            onClick={() => deleteComment(commentId)}
            className="m-2"
            type="primary"
          >
            Supprimer
          </Button>
        )}
      </div>

      <div>
        {updateStatusLoading && <Loading/>}

        {!updateStatusLoading && !R.isEmpty(messages) && isReview && (
          <div className="grid grid-cols-2 text-center">
            {!statusIs('accepted') && (
              <Button
                className="btn-green"
                onClick={onUpdateStatus('accepted')}
              >
                Accepter
              </Button>
            )}
            {!statusIs('pending') && (
              <Button
                className="btn-yellow"
                onClick={onUpdateStatus('pending')}
              >
                À traiter
              </Button>
            )}

            {!statusIs('rejected') && (
              <Button
                className="ml-2"
                danger
                onClick={onUpdateStatus('rejected')}
              >
                Rejeter
              </Button>
            )}
          </div>
        )}
      </div>

      <div className="overflow-y-auto max-h-[66vh]">
        {R.compose(
          R.map(({ text, userName, when }) => (
            <div
              key={when}
              className="my-4 hover:bg-gray-100 p-2"
            >
              <span className="text-justify text-black">{text}</span>
              <br/>
              <span className="text-xs text-gray-500 italic">{userName} le {when}</span>
            </div>
          )),
          R.sort(R.descend(R.prop('when')))
        )(messages)}

      </div>

      {addMessageLoading && <Loading/>}

      {!addMessageLoading && (
        <Input
          placeholder="Ajouter un message"
          value={message}
          onChange={e => setMessage(e.target.value)}
          onKeyPress={event => {
            if (event.key === 'Enter' && !R.isEmpty(R.trim(message))) {
              addMessage(commentId, message);
              setMessage('');
            }
          }}
        />
      )}
    </div>
  );
};

CommentsBox.propTypes = {
  addMessage: PropTypes.func.isRequired,
  commentItem: PropTypes.shape({
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        userName: PropTypes.string.isRequired,
        when: PropTypes.string.isRequired
      })
    ),
    itemPath: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
      ])
    ).isRequired,
    itemKey: PropTypes.string.isRequired
  }).isRequired
};

export default CommentsBox;
