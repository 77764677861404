import React from 'react';
import TagForm from '../../docs/components/tag/TagForm';

const CreateTag = () => {
  return (
    <TagForm
      scope="global"
    />
  );
};

export default CreateTag;
