import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation, updaterBasicDelete } from '../common/common';

const mutation = graphql`
  mutation DeleteTagMutation($tagId: ID!) {
    deleteTag(tagId: $tagId) {
      ok
      error
    }
  }
`;

export default ({
  tagId
}, done) => {
  const variables = {
    tagId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { deleteTag: { ok, error } }) => updaterBasicDelete(proxyStore, ok, error, tagId),
      onCompleted: ({ deleteTag }) => basicMutationOnCompleted(deleteTag, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
