/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QGroupsQueryVariables = {||};
export type QGroupsQueryResponse = {|
  +groups: ?$ReadOnlyArray<?{|
    +id: ?string,
    +members: ?$ReadOnlyArray<?{|
      +userId: ?string
    |}>,
    +name: ?string,
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
    +roles: ?$ReadOnlyArray<?{|
      +itemId: ?string,
      +itemType: ?string,
      +roles: ?$ReadOnlyArray<?string>,
    |}>,
  |}>
|};
export type QGroupsQuery = {|
  variables: QGroupsQueryVariables,
  response: QGroupsQueryResponse,
|};
*/


/*
query QGroupsQuery {
  groups {
    id
    members {
      userId
    }
    name
    dates {
      creation
      lastUpdate
    }
    roles {
      itemId
      itemType
      roles
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Group",
    "kind": "LinkedField",
    "name": "groups",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GroupMember",
        "kind": "LinkedField",
        "name": "members",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Dates",
        "kind": "LinkedField",
        "name": "dates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GroupRole",
        "kind": "LinkedField",
        "name": "roles",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QGroupsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QGroupsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "e7183a170098ed2339296a2b6affd96a",
    "id": null,
    "metadata": {},
    "name": "QGroupsQuery",
    "operationKind": "query",
    "text": "query QGroupsQuery {\n  groups {\n    id\n    members {\n      userId\n    }\n    name\n    dates {\n      creation\n      lastUpdate\n    }\n    roles {\n      itemId\n      itemType\n      roles\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f6057dd6ba098140e3d562a643ea093';

module.exports = node;
