import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';

import {
  populateChildren
} from '../toolbox';

const QDocumentQuery = graphql`
  query QDocumentQuery($documentId: ID!) {
    rawDocument(rawDocumentId: $documentId) {
      id
      name
    }
    document(documentId: $documentId) {
      id
      name
      scope
      offerId
      variables
      padding {
        top
        right
        bottom
        left
      }
      orientation
      renderingEngineVersion
      formatPdf
      isVariablesHighlighted
      pages {
        breakPage
        padding {
          top
          bottom
          left
          right
        }
        rules {
              action
              conditions {
                condition
                variable
                value
                operator
              }
            }
        widgets {
          widgets {
            widgetId
            type
            rules {
              action
              conditions {
                condition
                variable
                value
                operator
              }
            }
          }
          breakPage
          rulesBreakPage {
            action
            conditions {
              condition
              variable
              value
              operator
            }
          }
          padding {
            top
            right
            bottom
            left
          }
        }
        header {
          widgets {
            widgetId
            type
            rules {
              action
              conditions {
                condition
                variable
                value
                operator
              }
            }
          }
          padding {
            top
            right
            bottom
            left
          }
        }
        footer {
          widgets {
            widgetId
            type
            rules {
              action
              conditions {
                condition
                variable
                value
                operator
              }
            }
          }
          padding {
            top
            right
            bottom
            left
          }
        }
      }
      version {
        versionId
        itemId
        status
        itemScope
        itemType
        major
        minor
        reviewComments {
          comments
          rejectionReason
          status
        }
      }
      offerVersion {
        versionId
        status
      }
    }
  }
`;

const QDocument = ({
  args,
  children,
  childDataProp
}) => (
  <Query
    args={args}
    query={QDocumentQuery}
    caching={false}
  >
    {populateChildren()(children, childDataProp)}
  </Query>
);

export default QDocument;
