import React from 'react';
import Menu from '../../containers/menu';
import Layout from '../../layout/default';
import { Breadcrumb } from '../../components';
import { useBreadcrumb } from '../../hooks';

export const home = ['/', 'Accueil'];

// eslint-disable-next-line react/display-name
export const renderWithBreadcrumb = (Component, breadcrumb) => () => {
  return (
    <Layout menu={<Menu/>}>
      <Breadcrumb value={breadcrumb}/>
      <Component/>
    </Layout>
  );
};

// eslint-disable-next-line react/display-name
export const RoutesBreadcrumbChildren = ({ routes, children }) => {
  const breadcrumb = useBreadcrumb(routes, home);
  return (
    <Layout menu={<Menu/>}>
      <Breadcrumb value={breadcrumb}/>
      {children}
    </Layout>
  );
};

// eslint-disable-next-line react/display-name
export const renderWithoutBreadcrumb = (Component, breadcrumb) => () => {
  return (
    <Layout
      menu={<Menu />}
      contentStyle={{ padding: '0', overflow: 'hidden', height: '100%' }}
    >
      <Component breadcrumb={breadcrumb}/>
    </Layout>
  );
};
