import { errorMessage } from '../utils/messageMutation';
import AddItemToTagMutation from '../_graphql/mutations/documents/AddItemToTagMutation';
import RemoveItemToTagMutation from '../_graphql/mutations/documents/RemoveItemToTagMutation';

const useDocumentsTags = (documentId) => {
  const onAddItem = (tagId) => {
    AddItemToTagMutation({ itemId: documentId, tagId }, (ok, error) => {
      if (!ok || error) {
        errorMessage();
      }
    });
  };

  const onRemoveItem = (tagId) => {
    RemoveItemToTagMutation({ itemId: documentId, tagId }, (ok, error) => {
      if (!ok || error) {
        errorMessage();
      }
    });
  };

  return {
    onAddItem,
    onRemoveItem
  };
};

export default useDocumentsTags;
