import React from 'react';
import { useParams } from 'react-router-dom';
import QColor from '../../../_graphql/queries/documents/QColor';
import ColorForm from './components/ColorForm';

const EditStyle = () => {
  const { colorId } = useParams();
  return (
    <QColor args={{ colorId }}>
      {color => (
        <ColorForm
          colorId={colorId}
          color={color}
        />
      )}
    </QColor>
  );
};

export default EditStyle;
