import { Button, Input, message, Select } from 'antd';
import * as R from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ContentBlock } from '../../components';
import SwitchByExisting from '../../components/SwitchByExisting';
import Title from '../../components/Title';
import useKeyPressEnter from '../../hooks/useKeyPressEnter';
import { errorMessage, successMessage } from '../../utils/messageMutation';
import CreateOfferFromExistingMutation from '../../_graphql/mutations/offers/CreateOfferFromExistingMutation';
import CreateOfferMutation from '../../_graphql/mutations/offers/CreateOfferMutation';
import { QOffers } from '../../_graphql/queries';
import { removeNull } from '../helpers/removeNull';

const useCreateOffer = () => {
  const [state, setState] = useState({
    values: {
      offerName: '',
      fromOfferId: undefined,
      isCopyOffer: false
    },
    loading: false
  });

  const setValue = (k, v) => setState(R.assocPath(['values', k], v));
  const history = useHistory();

  const onCreate = () => {
    setState(R.assoc('loading', true));

    const callback = (ok, error) => {
      setState(R.assoc('loading', false));

      if (ok && !error) {
        successMessage('offre', 'créée avec succès', true);
        history.push('/offre');
      } else {
        errorMessage();
      }
    };

    if (!R.path(['values', 'isCopyOffer'], state)) {
      CreateOfferMutation(R.prop('values', state), callback);
    } else {
      setState(R.assoc('loading', true));
      message.info('Offre en cours de création, veuillez patienter...');
      CreateOfferFromExistingMutation(R.prop('values', state), callback);
    }
  };

  const buttonIsDisabled = R.or(
    isNilOrEmpty(R.path(['values', 'offerName'], state)),
    R.and(R.path(['values', 'isCopyOffer'], state), isNilOrEmpty(R.path(['values', 'fromOfferId'], state)))
  );

  useKeyPressEnter(onCreate, !buttonIsDisabled, R.prop('values', state));

  return {
    ...state,
    onCreate,
    setValue,
    buttonIsDisabled
  };
};

const CreateOffer = () => {
  const {
    values,
    loading,
    setValue,
    onCreate,
    buttonIsDisabled
  } = useCreateOffer();

  return (
    <QOffers>
      {offers => (
        <>
          <Title title="Création d'une offre" backTo={'/offre'} />
          <ContentBlock>
            <h4 className="font-bold mb-4">{'Nom de l\'offre'}</h4>
            <Input
              placeholder="Nom de l'offre"
              value={R.prop('offerName', values)}
              onChange={e => setValue('offerName', e.target.value)}
            />
            {isNotNilOrEmpty(removeNull(offers)) && (
              <SwitchByExisting
                checked={R.prop('isCopyOffer', values)}
                onChange={checked => setValue('isCopyOffer', checked)}
                onClick={() => setValue('isCopyOffer', !R.prop('isCopyOffer', values))}
                label="Créer à partir d'une offre existante"
                fromValue={R.prop('fromOfferId', values)}
                onSelect={v => setValue('fromOfferId', v)}
                placeholder="Offre à dupliquer"
                options={R.map(({ offerName, offerId, version }) => {
                  const title = `${offerName} - ${R.prop('major', version)}.${R.prop('minor', version)}`;
                  return (
                    <Select.Option
                      key={offerId}
                      value={offerId}
                      title={title}
                    >
                      {title}
                    </Select.Option>
                  );
                })(removeNull(offers))}
              />
            )}
            <Button
              loading={loading}
              disabled={buttonIsDisabled}
              onClick={onCreate}
              type="primary"
              size="large"
              className="block mt-6"
            >
              Créer la nouvelle offre
            </Button>
          </ContentBlock>
        </>
      )}
    </QOffers>
  );
};

export default CreateOffer;
