import React from 'react';

import {
  QTeamActions
} from '../../../_graphql/queries';

import {
  Section
} from '../../../components';

import {
  List
} from 'antd';
import TeamInvite from './TeamInvite';
import { Link } from 'react-router-dom';
const R = require('ramda');

const Activity = () => (
  <>
    <Section title="Activité de mon équipe">
      <QTeamActions page={1}>
        {({ teamActions }) => {
          if (R.isNil(teamActions)) {
            return (
              <p>{'Vous n\'avez pas d\'équipe active, vous pouvez en créer une'} <Link to="/equipe/créer">ici</Link></p>
            );
          }

          const { docs, pagination } = teamActions;
          return (
            <List
              dataSource={docs}
              pagination={pagination}
              renderItem={item => {
                return (
                  <List.Item>
                    {R.prop('when', item)}
                    {R.path(['user', 'email'], item)}
                    {R.pathOr('not defined', ['data', 'offer', 'offerNumber'], item)}
                    {R.prop('actionName', item)}
                  </List.Item>
                );
              }}
            />
          );
        }}
      </QTeamActions>
    </Section>
    <Section title="Mes invitations">
      <TeamInvite />
    </Section>
  </>
);

export default Activity;
