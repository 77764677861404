/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CommentStatusEnum = "accepted" | "pending" | "rejected" | "%future added value";
export type CommentInput = {|
  itemKey?: ?string,
  itemPath?: ?$ReadOnlyArray<?any>,
  itemRootId?: ?string,
|};
export type CreateCommentMutationVariables = {|
  values: CommentInput
|};
export type CreateCommentMutationResponse = {|
  +createComment: ?{|
    +ok: boolean,
    +error: ?string,
    +comment: ?{|
      +id: ?string,
      +itemKey: ?string,
      +itemPath: ?$ReadOnlyArray<?any>,
      +itemRootId: ?string,
      +status: ?CommentStatusEnum,
      +teamId: ?string,
      +messages: ?$ReadOnlyArray<?{|
        +text: ?string,
        +userId: ?string,
        +when: ?string,
      |}>,
    |},
  |}
|};
export type CreateCommentMutation = {|
  variables: CreateCommentMutationVariables,
  response: CreateCommentMutationResponse,
|};
*/


/*
mutation CreateCommentMutation(
  $values: CommentInput!
) {
  createComment(values: $values) {
    ok
    error
    comment {
      id
      itemKey
      itemPath
      itemRootId
      status
      teamId
      messages {
        text
        userId
        when
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "values"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "values",
        "variableName": "values"
      }
    ],
    "concreteType": "CommentMutationResponse",
    "kind": "LinkedField",
    "name": "createComment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "comment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemKey",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemPath",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemRootId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "teamId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CommentMessage",
            "kind": "LinkedField",
            "name": "messages",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "when",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCommentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCommentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "005cfbc5d8577bbda35372096e93fc5a",
    "id": null,
    "metadata": {},
    "name": "CreateCommentMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCommentMutation(\n  $values: CommentInput!\n) {\n  createComment(values: $values) {\n    ok\n    error\n    comment {\n      id\n      itemKey\n      itemPath\n      itemRootId\n      status\n      teamId\n      messages {\n        text\n        userId\n        when\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3daddb6cd342fc28648954d9fec34d15';

module.exports = node;
