import React from 'react';
import { Popconfirm, Table } from 'antd';
import * as R from 'ramda';
import { Edit, Trash } from '../../components/icons';
import { useHistory } from 'react-router-dom';
import withUser from '../../withUser';
import RemoveGroupMutation from '../../_graphql/mutations/groups/RemoveGroupMutation';
import { renderDate } from '../docs/components/helpers/dates';
import { deleteMutationMessage } from '../../utils/messageMutation';

const GroupsTable = ({
  userIsAdmin,
  groups
}) => {
  const history = useHistory();

  const onRemoveGroup = groupId => {
    RemoveGroupMutation({ groupId }, (ok, error) => deleteMutationMessage(ok, error, 'groupe'));
  };

  return (
    <Table
      size="small"
      dataSource={R.reject(R.isNil, groups)}
      rowKey="id"
      pagination={false}
    >
      <Table.Column
        title="Nom"
        dataIndex="name"
        width={500}
      />

      <Table.Column
        title="création"
        dataIndex="dates"
        width={400}
        render={(dates) => dates && renderDate(R.prop('creation', dates))}
      />

      {userIsAdmin && (
        <Table.Column
          title=""
          dataIndex="id"
          render={(id) => {
            return (
              <div className="flex justify-end gap-2 text-lg">
                <Edit
                  onClick={() => history.push(`/equipe/groupes/${id}/gestion`)}
                />

                <Popconfirm
                  placement="left"
                  title="Êtes-vous sûr de vouloir supprimer ce groupe ?"
                  onConfirm={() => onRemoveGroup(id)}
                  okText="Oui"
                  cancelText="Non"
                >
                  <Trash />
                </Popconfirm>

              </div>
            );
          }}
        />
      )}
    </Table>
  );
};

export default withUser(GroupsTable);
