import React, { useEffect, useState } from 'react';
import Spin, { Button, Input, Modal, notification } from 'antd';
import PropTypes from 'prop-types';
import Title from '../../../components/Title';
import { MailOutlined } from '@ant-design/icons';
import ActionButton from '../components/ActionButton';
import { emailIsValid } from '../../../lib/validation';
import InviteUserToTeamMutation from '../../../_graphql/mutations/InviteUserToTeamMutation';
import { successNotification } from '../../../utils/messageMutation';
const R = require('ramda');

const mapIndexed = R.addIndex(R.map);
const forEachIndexed = R.addIndex(R.forEach);
const defaultEmail = { value: '', loading: false };

const useInviteUsers = (team, onCancel) => {
  const [emails, setEmails] = useState(R.repeat(defaultEmail, 3));
  const [invited, setInvited] = useState(false);
  const addEmail = () => setEmails(R.append(defaultEmail));
  const updateEmail = (index, value) => setEmails(R.update(index, { value, loading: false }));
  const updateLoading = (index, loading) => setEmails(R.adjust(index, R.assoc('loading', loading)));

  useEffect(() => {
    if (invited) {
      const stillLoading = R.compose(
        R.reduce((acc, l) => acc || l, false),
        R.pluck('loading')
      )(emails);

      if (!stillLoading) {
        onCancel();
        setInvited(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invited, emails]);

  const definedEmails = R.compose(
    R.uniq,
    R.reject(v => R.isNil(v) || R.isEmpty(v)),
    R.pluck('value')
  )(emails);

  const emailsAreValid = R.reduce((acc, email) => acc && emailIsValid(email), true)(definedEmails);

  const onInviteUsers = () => {
    forEachIndexed(email => {
      updateLoading(true);
      setInvited(true);
      InviteUserToTeamMutation({ teamId: R.prop('teamId', team), teamName: R.prop('name', team), email }, (ok, error) => {
        updateLoading(false);
        if (ok && !error) {
          successNotification(`Une invitation a été envoyée à ${email}.`);
        } else {
          notification.error({
            message: `L'invitation n'a pas pu être envoyée à ${email}.`
          });
        }
      });
    })(definedEmails);
    setEmails(R.repeat(defaultEmail, 3));
  };

  return {
    emails,
    addEmail,
    updateEmail,
    definedEmails,
    emailsAreValid,
    onInviteUsers
  };
};

const ModalInviteMembers = ({
  visible,
  onCancel,
  team
}) => {
  const {
    emails,
    addEmail,
    updateEmail,
    definedEmails,
    emailsAreValid,
    onInviteUsers
  } = useInviteUsers(team, onCancel);

  return (
    <Modal
      title="Inviter des collaborateurs"
      className="team-modal"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={640}
    >
      <p>Vous pouvez inviter un ou plusieurs collaborateurs à rejoindre votre équipe</p>

      <Title
        title={`Équipe ${R.prop('name', team)}`}
        icon="/icon/icon-equipe-dark.svg"
      />

      <b>E-mails</b>

      {mapIndexed(({ value, loading }, index) => (
        <Input
          value={value}
          placeholder="E-mail collaborateur"
          onChange={e => updateEmail(index, e.target.value)}
          key={index}
          prefix={loading ? <Spin spinning/> : <MailOutlined style={{ width: '20px' }}/>}
          width={300}
        />
      ))(emails)}

      <ActionButton
        onClick={addEmail}
        style={{ fontSize: '12px' }}
      >
        {'+ Ajouter un autre collaborateur'}
      </ActionButton>

      <div className="team-modal-buttons">
        <Button onClick={onCancel}>Annuler</Button>
        <Button
          onClick={onInviteUsers}
          type="primary"
          disabled={!emailsAreValid || R.length(definedEmails) === 0}
        >
          Inviter
        </Button>
      </div>
    </Modal>
  );
};

ModalInviteMembers.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired
};

export default ModalInviteMembers;
