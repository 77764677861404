import * as R from 'ramda';

const associateVersions = (items, rootIdField, idField = 'id') => R.compose(
  R.map(item => {
    const versions = R.compose(
      R.reverse,
      R.filter(R.pathEq(rootIdField.split('.'), R.prop(idField, item)))
    )(items);

    return R.assoc('versions', versions)(item);
  })
)(items);

export {
  associateVersions
};
