import { equals, isNil } from 'ramda';

export const updaterReview = (type, proxyStore, item, itemId, reason, versionId) => {
  const { ok, error } = item;

  if (ok && !error && !isNil(itemId)) {
    const storedItem = proxyStore.get(itemId);

    if (!isNil(storedItem)) {
      const storedVersion = storedItem.getLinkedRecord('version');

      if (!isNil(storedVersion)) {
        if (equals('reject', type)) {
          storedVersion.setValue('draft', 'status');
          const reviewComments = storedVersion.getOrCreateLinkedRecord('reviewComments', 'ReviewComment');

          if (!isNil(reviewComments)) {
            reviewComments.setValue(reason, 'rejectionReason');
            reviewComments.setValue('rejected', 'status');
          }
        } else {
          storedVersion.setValue('release', 'status');
        }
      }
      if (!isNil(versionId) && equals('accept', type)) {
        const storedVersion = proxyStore.get(versionId);

        if (!isNil(storedVersion)) {
          storedVersion.setValue('release', 'status');
        }
      }
    }
  }
};
