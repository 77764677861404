import React from 'react';
import { useParams } from 'react-router-dom';
import { SCOPE_OFFER } from '../../_CONST';
import QGlobalVariable from '../../_graphql/queries/documents/QGlobalVariable';
import GlobalVariableForm from './components/globalVariable/GlobalVariableForm';

const GlobalVariableDocument = () => {
  const { offerId, globalVariableId } = useParams();
  return (
    <QGlobalVariable args={{ globalVariableId }}>
      {globalVariable => (
        <GlobalVariableForm
          scope={SCOPE_OFFER}
          offerId={offerId}
          globalVariableId={globalVariableId}
          globalVariable={globalVariable}
        />
      )}
    </QGlobalVariable>
  )
  ;
};

export default GlobalVariableDocument;
