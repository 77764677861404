import { useState } from 'react';
import * as R from 'ramda';
import { message } from 'antd';
import UpdateVersionStatusMutation from '../_graphql/mutations/versions/UpdateVersionStatusMutation';
import { errorMessage } from '../utils/messageMutation';

const typeToLabel = R.compose(
  R.cond([
    [R.equals('offer'), R.always('L\'offre est passée')],
    [R.equals('widget'), R.always('Le widget est passé')],
    [R.equals('document'), R.always('Le document est passé')],
    [R.equals('style'), R.always('Le style est passé')]
  ]),
  R.prop('itemType')
);

const useStatusActions = ({ version }) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateStatus = status => () => {
    setIsLoading(true);

    UpdateVersionStatusMutation({ versionId: R.prop('versionId', version), status, itemId: R.prop('itemId', version) }, (ok, error) => {
      if (ok && !error) {
        message.success(`${typeToLabel(version)} en mode ${status === 'review' ? 'revue' : 'édition'}.`);
      } else {
        errorMessage();
      }
      setIsLoading(false);
    });
  };

  const isDraft = R.propEq('status', 'draft');
  const isReview = R.propEq('status', 'review');
  const isAnOffer = R.propEq('itemType', 'offer');
  const isAnOfferInDraft = R.both(isDraft, isAnOffer);
  const isAnOfferInReview = R.both(isReview, isAnOffer);

  return {
    updateStatus,
    isLoading,
    canSendToReview: isAnOfferInDraft(version),
    canSendToDraft: isAnOfferInReview(version)
  };
};

export default useStatusActions;
