import { BorderOutlined, CheckSquareOutlined } from '@ant-design/icons';
import React from 'react';

const logoChecked = {
  checked: <CheckSquareOutlined />,
  unchecked: <BorderOutlined />
};

const DefaultValuesCheck = ({ onChangeIsDefaultValue, checked }) => {
  const format = checked ? 'checked' : 'unchecked';
  return (
    <div>
      <span onClick={() => onChangeIsDefaultValue(!checked)}>
        {logoChecked[format]}&nbsp;
      </span>
      <span
        className="text-xs inline-block align-middle cursor-pointer"
        onClick={() => onChangeIsDefaultValue(!checked)}
      >
        Valeur par défaut
      </span>
    </div>
  );
};

export default DefaultValuesCheck;
