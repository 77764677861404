import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateStyleMutation($styleId: ID!, $style: StyleInput!) {
    updateStyle(styleId: $styleId, style: $style) {
      ok
      error
    }
  }
`;

export default ({
  styleId,
  style
}, done) => {
  const variables = {
    styleId,
    style
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateStyle }) => basicMutationOnCompleted(updateStyle, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
