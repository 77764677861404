import { path, pathOr } from 'ramda';
import React from 'react';
import { SCOPE_OFFER } from '../../../_CONST';
import QDocumentsByRootOfferId from '../../../_graphql/queries/documents/QDocumentsByRootOfferId';
import DocumentsList from './documentsSection/DocumentsList';

const DocumentsSection = ({
  offerId,
  offer,
  userCanCreateOfferDocument,
  isOfferPath,
  setRefetchWidgets,
  isPublished,
  folders,
  reFetchFolders,
  setIsLoadingOfferItem,
  isLoadingOfferItem,
  setFullscreenLoading
}) => {
  return (
    <QDocumentsByRootOfferId
      args={{
        rootOfferId: pathOr(offerId, ['version', 'itemRootId'], offer)
      }}
    >
      {(documentsByRootOfferId) => {
        return (
          <DocumentsList
            isOfferPath={isOfferPath}
            args={{ scope: SCOPE_OFFER, offerId, type: 'DOCUMENT' }}
            offerStatus={path(['version', 'status'], offer)}
            documentsByRootOfferId={documentsByRootOfferId}
            userCanCreateOfferDocument={userCanCreateOfferDocument}
            setRefetchWidgets={setRefetchWidgets}
            isPublished={isPublished}
            cacheKey={['offerViewCache', `documents-${offerId}`]}
            folders={folders}
            reFetchFolders={reFetchFolders}
            setIsLoadingOfferItem={setIsLoadingOfferItem}
            isLoadingOfferItem={isLoadingOfferItem}
            setFullscreenLoading={setFullscreenLoading}
          />
        );
      }}
    </QDocumentsByRootOfferId>
  );
};

export default DocumentsSection;
