import React from 'react';
import SignOutMutation from '../../_graphql/mutations/SignOutMutation';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

const SignOut = () => {
  const history = useHistory();
  const onSignOut = () => {
    SignOutMutation(() => {
      history.push('/account/signin');
    });
  };
  return (
    <Button
      onClick={onSignOut}
    >
      {'Me déconnecter'}
    </Button>
  );
};
export default SignOut;
