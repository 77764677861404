import React from 'react';

const Layout = ({
  children
}) => (
  <div style={{ minHeight: '100%', display: 'flex' }}>
    {children}
  </div>
);

export default Layout;
