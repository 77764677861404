/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddItemToTagMutationVariables = {|
  tagId: string,
  itemId: string,
|};
export type AddItemToTagMutationResponse = {|
  +addItemToTag: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type AddItemToTagMutation = {|
  variables: AddItemToTagMutationVariables,
  response: AddItemToTagMutationResponse,
|};
*/


/*
mutation AddItemToTagMutation(
  $tagId: ID!
  $itemId: ID!
) {
  addItemToTag(tagId: $tagId, itemId: $itemId) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "itemId",
        "variableName": "itemId"
      },
      {
        "kind": "Variable",
        "name": "tagId",
        "variableName": "tagId"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "addItemToTag",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddItemToTagMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddItemToTagMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c496e3090257ab125fb8af599da81754",
    "id": null,
    "metadata": {},
    "name": "AddItemToTagMutation",
    "operationKind": "mutation",
    "text": "mutation AddItemToTagMutation(\n  $tagId: ID!\n  $itemId: ID!\n) {\n  addItemToTag(tagId: $tagId, itemId: $itemId) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5be71b0981ce8d2212bf7398c500768e';

module.exports = node;
