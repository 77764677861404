import * as R from 'ramda';
import attributesNames from '../data/attributesNames';
import { getElement } from './utils';

const getComment = (element, comments) => {
  const itemPath = R.prop('attributes', element).getNamedItem(R.prop('itemPath', attributesNames));
  const itemKey = R.prop('attributes', element).getNamedItem(R.prop('itemKey', attributesNames));

  return R.find((comment) => getElement(itemKey, itemPath, comment))(comments);
};

export default getComment;
