import React from 'react';

const UploadIcon = () => (
  <svg version="1.1" viewBox="0 0 11 13" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
    <title>2B9A1A2C-36BF-451A-BD37-1A62035EB7CD</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-899 -837)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(289 800)">
          <g transform="translate(583 22)">
            <g transform="translate(27 15)">
              <path d="m10.312 11.581h-9.625c-0.37956 0-0.6875 0.31221-0.6875 0.69695 0 0.40854 0.30794 0.72191 0.6875 0.72191h9.625c0.37956 0 0.6875-0.31221 0.6875-0.69695 0-0.38533-0.30651-0.72191-0.6875-0.72191zm-7.9787-6.4647 2.4787-2.6694v7.0682c0 0.38475 0.30794 0.69782 0.6875 0.69782s0.6875-0.31308 0.6875-0.69782v-7.0682l2.4787 2.6688c0.13664 0.14566 0.31711 0.2182 0.50044 0.2182 0.16918 0 0.33831-0.062615 0.47094-0.18965 0.2766-0.26311 0.2899-0.70421 0.029534-0.9845l-3.6667-3.949c-0.25959-0.28128-0.74135-0.28128-1.0009 0l-3.6667 3.949c-0.26042 0.28046-0.24696 0.7215 0.029477 0.98554 0.27586 0.26404 0.71099 0.22632 0.97138-0.029015z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default UploadIcon;
