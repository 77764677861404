import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../.';
import { populateChildren } from './toolbox';

const QEnvsQuery = graphql`
  query QEnvsQuery {
    envs {
      envs
    }
  }
`;

const QEnvs = ({
  children,
  childDataProp,
  args
}) => (
  <Query
    query={QEnvsQuery}
    caching={false}
    args={args}
    fetchPolicy={'store-and-network'}
  >
    {populateChildren('envs')(children, childDataProp)}
  </Query>
);

export default QEnvs;
