import { ROLES } from '../../lib/rights';
import { all, always, compose, find, includes, isNil, not, pathEq, prop, propOr, values, when } from 'ramda';

export const checkUserRole = (user, userTeam, roles = []) => {
  const rolesAreValid = all((role) => includes(role)(values(ROLES)));
  if (not(rolesAreValid(roles))) {
    return false;
  }

  return compose(
    (currentRole) => includes(currentRole)(roles),
    prop('role'),
    when(isNil, always({})),
    find(pathEq(['user', 'id'], prop('userId', user))),
    propOr([], 'members')
  )(userTeam);
};
