import { is } from 'ramda';
import { useEffect } from 'react';

export const useEffectMounted = (cb, comparator = []) => {
  useEffect(() => {
    let mounted = true;
    if (is(Function, cb))(cb(mounted));

    return () => (mounted = false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, comparator);
};

export default useEffectMounted;
