import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';

import {
  populateChildren
} from '../toolbox';

const QFileQuery = graphql`
  query QFileQuery($fileId: ID!) {
    getFile(fileId: $fileId) {
      fileBase64
      fileName
    }
  }
`;

const QFile = ({
  args,
  children,
  childDataProp
}) => {
  return (
    <Query
      args={args}
      query={QFileQuery}
      caching={false}
    >
      {populateChildren()(children, childDataProp)}
    </Query>
  );
};

export {
  QFile,
  QFileQuery
};
