/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AcceptOrDeclineInvitationMutationVariables = {|
  invitationId: string,
  action: string,
|};
export type AcceptOrDeclineInvitationMutationResponse = {|
  +acceptOrDeclineInvitation: ?{|
    +ok: boolean,
    +error: ?string,
    +teamId: ?string,
    +teamName: ?string,
    +apiKey: ?string,
  |}
|};
export type AcceptOrDeclineInvitationMutation = {|
  variables: AcceptOrDeclineInvitationMutationVariables,
  response: AcceptOrDeclineInvitationMutationResponse,
|};
*/


/*
mutation AcceptOrDeclineInvitationMutation(
  $invitationId: ID!
  $action: String!
) {
  acceptOrDeclineInvitation(invitationId: $invitationId, action: $action) {
    ok
    error
    teamId
    teamName
    apiKey
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "action"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "invitationId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "action",
        "variableName": "action"
      },
      {
        "kind": "Variable",
        "name": "invitationId",
        "variableName": "invitationId"
      }
    ],
    "concreteType": "AcceptOrDeclineInvitationMutationResponse",
    "kind": "LinkedField",
    "name": "acceptOrDeclineInvitation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "teamId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "teamName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "apiKey",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AcceptOrDeclineInvitationMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AcceptOrDeclineInvitationMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a9ae514209740aab86fce86e032456ef",
    "id": null,
    "metadata": {},
    "name": "AcceptOrDeclineInvitationMutation",
    "operationKind": "mutation",
    "text": "mutation AcceptOrDeclineInvitationMutation(\n  $invitationId: ID!\n  $action: String!\n) {\n  acceptOrDeclineInvitation(invitationId: $invitationId, action: $action) {\n    ok\n    error\n    teamId\n    teamName\n    apiKey\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '02c91094a8793b0f1c04efb4bd01da82';

module.exports = node;
