import * as R from 'ramda';

export const getElement = (itemKey, itemPath, comment) => R.both(
  R.propEq('itemKey', R.prop('value', itemKey)),
  R.propSatisfies(
    R.compose(
      R.equals(R.prop('value', itemPath)),
      R.join(',')
    ),
    'itemPath'
  )
)(comment);
