import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { path } from 'ramda';

const StyledBreadcrumb = styled.div`
  display: flex;
  color: ${props => path(['theme', 'lightGrey'], props)};
  font-size: 14px;
  margin-bottom: 20px;
  a {
    color: ${props => path(['theme', 'lightGrey'], props)};
  }
`;

const Breadcrumb = ({
  value = []
}) => {
  return (
    <StyledBreadcrumb>
      {value.map((item, index) => (
        <React.Fragment key={index}>
          <Link to={item[0]}>{item[1]}</Link>
          {(index < value.length - 1) && <span>&nbsp;/&nbsp;</span>}
        </React.Fragment>
      ))}
    </StyledBreadcrumb>
  );
};

export default Breadcrumb;
