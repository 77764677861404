import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';

import {
  populateChildren
} from '../toolbox';

const QVersionQuery = graphql`
  query QVersionQuery($versionId: ID!) {
    version(versionId: $versionId) {
      id
      versionId
      itemId
      itemScope
      itemType
      isLastMajor
      isLastMinor
      major
      minor
      status
    }
  }
`;

const QVersion = ({
  args,
  children,
  childDataProp
}) => (
  <Query
    args={args}
    query={QVersionQuery}
    caching={false}
  >
    {populateChildren('version')(children, childDataProp)}
  </Query>
);

export default QVersion;
