import React from 'react';

const DoubleFontIcon = () => (
  <svg version="1.1" viewBox="-.02 0 28.03 20" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
    <title>631C5733-689D-427E-8084-E03EAE84B984</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-609 -1770)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(289 1659)">
          <g transform="translate(297 76)">
            <g transform="translate(23 35)">
              <path d="m8.9744 0.9263c-0.16624-0.55623-0.6912-0.9263-1.273-0.9263-0.58183 0-1.1068 0.37007-1.3124 0.9263l-6.2996 17.142c-0.27136 0.73925 0.095718 1.5624 0.81938 1.8401 0.72401 0.27343 1.5303-0.096246 1.8024-0.83568l1.2341-3.3592h7.507l1.2341 3.3592c0.21655 0.57319 0.74589 0.92585 1.3146 0.92585 0.16335 0 0.32946-0.029293 0.49128-0.090666 0.72401-0.27762 1.091-1.1008 0.81938-1.8401l-6.3372-17.142zm-3.9766 11.93 2.7036-7.3613 2.7044 7.3613h-5.408zm21.602-7.1425c-0.57484 0-1.0661 0.35458-1.2818 0.86023-0.85307-0.53435-1.8461-0.86023-2.9179-0.86023-3.0877 0-5.5996 2.5628-5.5996 5.714v2.8539c0 3.1512 2.512 5.7185 5.5603 5.7185 1.0722 0 2.0653-0.32436 2.9179-0.85978 0.2537 0.50578 0.74804 0.85844 1.3211 0.85844 0.77301 0 1.3999-0.63881 1.3999-1.4285v-11.428c0-0.79014-0.62558-1.4285-1.3999-1.4285zm-1.3999 8.571c0 1.5749-1.2555 2.857-2.7998 2.857-1.5443 0-2.7998-1.2821-2.7998-2.857v-2.857c0-1.5749 1.2555-2.8168 2.7998-2.8168 1.5443 0 2.7998 1.2821 2.7998 2.8168v2.857z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DoubleFontIcon;
