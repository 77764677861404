/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QOfferMetadataQueryVariables = {|
  offerId: string,
  bundleTag: string,
|};
export type QOfferMetadataQueryResponse = {|
  +offerMetadata: ?{|
    +ok: ?boolean,
    +error: ?string,
    +data: ?$ReadOnlyArray<?{|
      +type: ?string,
      +key: ?string,
      +dynamicTableData: ?{|
        +colsToHide: ?$ReadOnlyArray<?string>,
        +rows: ?{|
          +rowName: ?string,
          +cells: ?$ReadOnlyArray<?{|
            +value: ?string
          |}>,
        |},
      |},
      +dataMatrix: ?string,
      +options: ?$ReadOnlyArray<?{|
        +value: ?string,
        +title: ?string,
      |}>,
      +default: ?{|
        +type: ?string,
        +value: ?string,
      |},
    |}>,
  |}
|};
export type QOfferMetadataQuery = {|
  variables: QOfferMetadataQueryVariables,
  response: QOfferMetadataQueryResponse,
|};
*/


/*
query QOfferMetadataQuery(
  $offerId: ID!
  $bundleTag: String!
) {
  offerMetadata(offerId: $offerId, bundleTag: $bundleTag) {
    ok
    error
    data {
      type
      key
      dynamicTableData {
        colsToHide
        rows {
          rowName
          cells {
            value
          }
        }
      }
      dataMatrix
      options {
        value
        title
      }
      default {
        type
        value
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bundleTag"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offerId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bundleTag",
        "variableName": "bundleTag"
      },
      {
        "kind": "Variable",
        "name": "offerId",
        "variableName": "offerId"
      }
    ],
    "concreteType": "OfferMetadataResponse",
    "kind": "LinkedField",
    "name": "offerMetadata",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OfferMetadataType",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DynamicTableData",
            "kind": "LinkedField",
            "name": "dynamicTableData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "colsToHide",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Rows",
                "kind": "LinkedField",
                "name": "rows",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rowName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Cell",
                    "kind": "LinkedField",
                    "name": "cells",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataMatrix",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OfferMetadataOptionType",
            "kind": "LinkedField",
            "name": "options",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OfferMetadataDefaultType",
            "kind": "LinkedField",
            "name": "default",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QOfferMetadataQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QOfferMetadataQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "e704c0fd306165fcfcd528e00a9960f5",
    "id": null,
    "metadata": {},
    "name": "QOfferMetadataQuery",
    "operationKind": "query",
    "text": "query QOfferMetadataQuery(\n  $offerId: ID!\n  $bundleTag: String!\n) {\n  offerMetadata(offerId: $offerId, bundleTag: $bundleTag) {\n    ok\n    error\n    data {\n      type\n      key\n      dynamicTableData {\n        colsToHide\n        rows {\n          rowName\n          cells {\n            value\n          }\n        }\n      }\n      dataMatrix\n      options {\n        value\n        title\n      }\n      default {\n        type\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ed9e485db5a34461a5d9b549cde6efe8';

module.exports = node;
