import * as R from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import conditions from './conditions';
const RA = require('ramda-adjunct');

export const reduceIndexed = R.addIndex(R.reduce);

export const propsAreDefined = (props) => (object) => R.reduce((acc, prop) => {
  return acc && !R.propSatisfies(isNilOrEmpty, prop)(object);
}, true)(props);

export const conditionIsWithValue = condition => R.compose(
  R.propOr(false, 'withValue'),
  R.defaultTo({}),
  R.find(R.propEq('key', condition))
)(conditions);

const useRules = (state) => {
  const getRulesAreValid = R.reduce((acc, rule) => {
    return acc && propsAreDefined(['action'])(rule);
  }, true);

  const getConditionsAreValid = reduceIndexed((acc, condition, conditionIndex) => {
    let isValid = propsAreDefined(['condition', 'variable'])(condition);
    if (conditionIndex !== 0) isValid = isValid && propsAreDefined(['operator'])(condition);
    if (conditionIsWithValue(R.prop('condition', condition))) isValid = isValid && propsAreDefined(['value'])(condition);
    return acc && isValid;
  }, true);

  const contentsRuleValid = (rules) => R.reduce((accRule, rule) => {
    const conditions = R.propOr([], 'conditions', rule);
    return accRule && RA.isNotEmpty(conditions) && getConditionsAreValid(conditions);
  }, true)(rules);

  const allRulesAreValid = R.compose(
    R.reduce((accSection, page) => {
      const rulesPage = R.propOr([], 'rules', page);
      const validRulesPage = getRulesAreValid(rulesPage) && contentsRuleValid(rulesPage);

      const getValidRulesType = (type) => R.reduce((accWidgetRow, widgetRow) => {
        return accWidgetRow && R.reduce((accWidget, widget) => {
          const rules = R.propOr([], 'rules', widget);
          return accWidget && getRulesAreValid(rules) && contentsRuleValid(rules);
        }, true)(R.propOr([], 'widgets', widgetRow));
      }, true)(R.propOr([], type, page));

      const validRulesWidgets = getValidRulesType('widgets');
      const validRulesHeader = getValidRulesType('header');
      const validRulesFooter = getValidRulesType('footer');
      return accSection && validRulesPage && validRulesWidgets && validRulesHeader && validRulesFooter;
    }, true),
    R.pathOr([], ['values', 'pages'])
  )(state);

  return {
    getRulesAreValid,
    getConditionsAreValid,
    allRulesAreValid
  };
};

export default useRules;
