import { Input as InputAntd } from 'antd';
import { omit, propOr } from 'ramda';
import React from 'react';
const RA = require('ramda-adjunct');

const Input = React.forwardRef((props, ref) => {
  const { className = '', size = 'default', inputsize, type, allowClear } = props;

  let builtClassName = '';

  if (size === 'small') {
    builtClassName = 'text-sm';
  }

  const disabled = propOr(false, 'disabled')(props);

  return (
    <InputAntd
      {...omit(['className', 'size', 'ref'], props)}
      ref={ref}
      size={inputsize}
      maxLength={inputsize}
      type={RA.isNotNil(type) ? type : 'text'}
      allowClear={allowClear}
      className={`flex border border-solid border-gray-300 ${builtClassName} ${className}`}
      disabled={disabled}
    />
  );
});

export default Input;

Input.displayName = 'Input';
