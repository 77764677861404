/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AcceptReviewMutationVariables = {|
  versionId: string
|};
export type AcceptReviewMutationResponse = {|
  +acceptReview: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type AcceptReviewMutation = {|
  variables: AcceptReviewMutationVariables,
  response: AcceptReviewMutationResponse,
|};
*/


/*
mutation AcceptReviewMutation(
  $versionId: ID!
) {
  acceptReview(versionId: $versionId) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "versionId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "versionId",
        "variableName": "versionId"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "acceptReview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AcceptReviewMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AcceptReviewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "09300717a5c6cadbadbe30d7fa6e0afc",
    "id": null,
    "metadata": {},
    "name": "AcceptReviewMutation",
    "operationKind": "mutation",
    "text": "mutation AcceptReviewMutation(\n  $versionId: ID!\n) {\n  acceptReview(versionId: $versionId) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b4f5f99445e087087b816c6063c7278';

module.exports = node;
