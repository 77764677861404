import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import { always, cond, equals } from 'ramda';

const GenericModalTeam = ({
  visible,
  onCancel,
  teamName,
  type,
  callback
}) => {
  const title = cond([
    [equals('delete'), always('Supprimer l\'équipe')],
    [equals('leave'), always('Quitter l\'équipe')]
  ])(type);

  const description = cond([
    [equals('delete'), always(`Souhaitez vous vraiment supprimer l'équipe ${teamName} ?`)],
    [equals('leave'), always(`Souhaitez vous vraiment quitter l'équipe ${teamName} ?`)]
  ])(type);

  return (
    <Modal
      title={title}
      className="team-modal"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <p>{description}</p>

      <div className="team-modal-buttons">
        <Button onClick={onCancel}>Annuler</Button>
        <Button
          type="primary"
          onClick={callback}
        >
          Oui
        </Button>
      </div>
    </Modal>
  );
};

GenericModalTeam.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  teamName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired
};

export default GenericModalTeam;
