import React from 'react';
import { Button } from 'antd';
import { cond, equals } from 'ramda';

const TextAttributesButton = ({ attribute, icon, setAttribute, currentAttribute, neutralAttribute }) => {
  const handleChangeTextAttributes = (currentStyle, newStyle, setter) => cond([
    [equals(currentStyle), () => setter(newStyle)],
    [equals(newStyle), () => setter(currentStyle)]
  ]);

  return (
    <Button
      size="normal"
      style={equals(attribute, currentAttribute)
        ? {
          color: 'white',
          background: '#005982',
          borderColor: '#005982'
        }
        : {
          color: '#005982',
          background: 'white',
          borderColor: '#005982'
        }}
      onClick={() => handleChangeTextAttributes(neutralAttribute, attribute, setAttribute)(currentAttribute)}
      icon={icon}
      shape="round"
    />
  );
};

export default TextAttributesButton;
