import { Col, Space, Tooltip, Typography } from 'antd';
import { path } from 'ramda';
import React from 'react';
const { Text } = Typography;

const DocumentDates = ({ renderDate, element }) => {
  return (
    <>
      <Col>
      </Col>
      <Col>
        <Space>
          • {/* Separator */}
          <Tooltip
            title="Date de création"
            color="#0197DC"
          >
            <Text type="secondary">
              {renderDate(path(['dates', 'creation'], element))}
            </Text>
          </Tooltip>
          <Tooltip
            title="Date de modification"
            color="#0197DC"
          >
            <Text type="secondary">
              {renderDate(path(['dates', 'lastUpdate'], element))}
            </Text>
          </Tooltip>
        </Space>
      </Col>
    </>
  );
};

export default DocumentDates;
