import { Select, Tag } from 'antd';
import React from 'react';
import QTags from '../../../../_graphql/queries/documents/QTags';
import * as R from 'ramda';
import useDocumentsTags from '../../../../hooks/useDocumentsTags';
import { SCOPE_OFFER } from '../../../../_CONST';

const SelectTags = ({
  offerId,
  documentId
}) => {
  const {
    onAddItem,
    onRemoveItem
  } = useDocumentsTags(documentId);

  return (
    <QTags args={{ scope: SCOPE_OFFER, offerId, itemType: 'document' }}>
      {tags => {
        tags = R.compose(
          R.defaultTo([])
        )(tags);

        const defaultTags = R.compose(
          R.uniq,
          R.pluck('id'),
          R.filter(
            R.propSatisfies(
              R.compose(
                R.includes(documentId),
                R.defaultTo([])
              ),
              'itemsIds'
            )
          )
        )(tags);

        return (
          <Select
            mode="multiple"
            showArrow
            placeholder="Sélectionner les tags"
            className="w-400px"
            onSelect={onAddItem}
            onDeselect={onRemoveItem}
            defaultValue={defaultTags}
          >
            {R.map(tag => {
              const { id, name, color } = tag;

              return (
                <Select.Option
                  key={id}
                  value={id}
                  className="w-full"
                >
                  <Tag color={color} className="max-w-full text-ellipsis whitespace-nowrap overflow-hidden" title={name}>
                    {name}
                  </Tag>
                </Select.Option>
              );
            })(tags)}
          </Select>
        );
      }}
    </QTags>
  );
};

export default SelectTags;
