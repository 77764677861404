/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCacheMutationVariables = {|
  date: string,
  key: string,
|};
export type UpdateCacheMutationResponse = {|
  +updateCache: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateCacheMutation = {|
  variables: UpdateCacheMutationVariables,
  response: UpdateCacheMutationResponse,
|};
*/


/*
mutation UpdateCacheMutation(
  $date: String!
  $key: String!
) {
  updateCache(date: $date, key: $key) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "date"
      },
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateCache",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCacheMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCacheMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bde82132ac2a20dfc347152bf9e9eccc",
    "id": null,
    "metadata": {},
    "name": "UpdateCacheMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCacheMutation(\n  $date: String!\n  $key: String!\n) {\n  updateCache(date: $date, key: $key) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7fd275bf77058b0cbc11a6e83d915a34';

module.exports = node;
