import React from 'react';
import {
  Switch,
  Route
} from 'react-router-dom';
import Styles from './styles/Styles';
import CreateStyle from './styles/CreateStyle';
import EditStyle from './styles/EditStyle';
import Elements from './Elements';
import Widgets from './Widgets';
import StyleHistory from './styles/StyleHistory';
import CreateColor from './colors/CreateColor';
import EditColor from './colors/EditColor';
import Colors from './colors/Colors';
import Tags from './tags/Tags';
import CreateTag from './tags/CreateTag';
import TagDocument from '../docs/TagDocument';
import { home, RoutesBreadcrumbChildren } from '../helpers/breadCrumb';
import { prop } from 'ramda';
import DefaultFont from './defaultFont/DefaultFont';
import PreviewWidget from '../docs/PreviewWidget';
import EditWidget from '../docs/EditWidget';

const myTeam = ['/equipe', 'Mon équipe'];
const elements = ['/equipe/elements', 'Mes éléments'];
const widgets = ['/equipe/elements/widgets', 'Mes widgets'];
const updateWidget = ({ widgetId }) => [`/equipe/elements/widgets/${widgetId}/editer`, 'Modifier un widget'];
const previewWidget = ({ widgetId }) => [`/equipe/elements/widgets/${widgetId}/preview`, 'Prévisualiser un widget'];

const styles = ['/equipe/elements/styles', 'Mes styles'];
const createStyle = ['/equipe/elements/styles/creer', 'Créer'];
const editStyle = ({ styleId }) => [`/equipe/elements/styles/${styleId}/edition`, 'Éditer'];
const styleHistory = ({ styleId }) => [`/equipe/elements/styles/${styleId}/historique`, 'Historique'];
const colors = ['/equipe/elements/couleurs', 'Mes couleurs'];
const createColor = ['/equipe/elements/couleurs/creer', 'Créer'];
const editColor = ({ colorId }) => [`/equipe/elements/couleurs/${colorId}/edition`, 'Éditer'];
const tags = ['/equipe/elements/tags', 'Mes tags'];
const createTag = ['/equipe/elements/tags/creer', 'Créer'];
const editTag = ({ tagId }) => [`/equipe/elements/tags/${tagId}/edition`, 'Éditer'];
const font = ['/equipe/elements/police', 'Ma police par défaut'];

const routes = [
  ['/equipe/elements/widgets', [home, myTeam, elements, widgets]],
  ['/equipe/elements/widgets/:widgetId/editer', [home, myTeam, elements, widgets, updateWidget]],
  ['/equipe/elements/widgets/:widgetId/preview', [home, myTeam, elements, widgets, previewWidget]],
  ['/equipe/elements/tags/creer', [home, myTeam, elements, tags, createTag]],
  ['/equipe/elements/tags/:tagId/edition', [home, myTeam, elements, tags, editTag]],
  ['/equipe/elements/tags', [home, myTeam, elements, tags]],

  ['/equipe/elements/couleurs/creer', [home, myTeam, elements, colors, createColor]],
  ['/equipe/elements/couleurs/:colorId/edition', [home, myTeam, elements, colors, editColor]],
  ['/equipe/elements/police', [home, myTeam, elements, font]],
  ['/equipe/elements/styles/creer', [home, myTeam, elements, styles, createStyle]],
  ['/equipe/elements/styles/:styleId/historique', [home, myTeam, elements, styles, editStyle, styleHistory]],
  ['/equipe/elements/styles/:styleId/edition', [home, myTeam, elements, styles, editStyle]],
  ['/equipe/elements/styles', [home, myTeam, elements, styles]],
  ['/equipe/elements', [home, myTeam, elements]]
];

const Routes = ({
  match
}) => {
  const matchUrl = prop('url', match);
  return (
    <RoutesBreadcrumbChildren routes={routes}>
      <Switch>
        <Route exact path={matchUrl} component={Elements}/>

        <Route exact path={`${matchUrl}/widgets`} component={Widgets}/>

        <Route path={`${matchUrl}/widgets/:widgetId/preview`} component={PreviewWidget}/>
        <Route path={`${matchUrl}/widgets/:widgetId/editer`} component={EditWidget}/>

        <Route path={`${matchUrl}/styles/creer`} component={CreateStyle}/>
        <Route path={`${matchUrl}/styles/:styleId/historique`} component={StyleHistory}/>
        <Route path={`${matchUrl}/styles/:styleId/edition`} component={EditStyle}/>
        <Route exact path={`${matchUrl}/styles`} component={Styles}/>

        <Route path={`${matchUrl}/couleurs/creer`} component={CreateColor}/>
        <Route path={`${matchUrl}/couleurs/:colorId/edition`} component={EditColor}/>
        <Route exact path={`${matchUrl}/couleurs`} component={Colors}/>

        <Route exact path={`${matchUrl}/police`} component={DefaultFont}/>

        <Route path={`${matchUrl}/tags/:tagId/preview`} component={TagDocument}/>
        <Route path={`${matchUrl}/tags/creer`} component={CreateTag}/>
        <Route path={`${matchUrl}/tags/:tagId/edition`} component={TagDocument}/>
        <Route exact path={`${matchUrl}/tags`} component={Tags}/>
      </Switch>
    </RoutesBreadcrumbChildren>
  );
};

export default Routes;
