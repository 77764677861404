const attributesNames = {
  itemPath: 'data-item-path',
  itemProcessed: 'data-item-processed',
  itemKey: 'data-item-key',
  itemRoot: 'data-item-root',
  commentPath: 'data-comment-path',
  commentKey: 'data-comment-key'
};

export default attributesNames;
