import React from 'react';
import { Section } from './';
import VersionHistory from './VersionHistory';

const History = ({ id, type }) => (
  <Section title={`Historique du ${type}`} >
    <VersionHistory itemId={id} />
  </Section>
);

export default History;
