import { equals } from 'ramda';
import {
  commitMutation,
  graphql
} from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
mutation AcceptOrDeclineInvitationMutation($invitationId: ID!, $action: String!) {
  acceptOrDeclineInvitation(invitationId: $invitationId, action: $action) {
    ok
    error,
    teamId
    teamName
    apiKey
  }
}
`;

export default ({ invitationId, action }, done) => {
  const variables = {
    invitationId,
    action
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { acceptOrDeclineInvitation: { ok, teamId, teamName, apiKey } }) => {
        if (ok) {
          const teamInvitation = proxyStore.get(invitationId);
          if (equals(action, 'accept')) {
            const root = proxyStore.get('userTeam');
            root.setValue(teamId, 'teamId');
            root.setValue(teamName, 'name');
            root.setValue(apiKey, 'apiKey');
            teamInvitation.setValue('confirmed', 'status');
          } else {
            teamInvitation.setValue('declined', 'status');
          }
        }
      },
      onCompleted: ({ acceptOrDeclineInvitation }) => basicMutationOnCompleted(acceptOrDeclineInvitation, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
