import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation CreateColorMutation($color: ColorInput!) {
    createColor(color: $color) {
      ok
      error
    }
  }
`;

export default ({
  color
}, done) => {
  const variables = {
    color
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ createColor }) => basicMutationOnCompleted(createColor, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
