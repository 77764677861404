/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveFoldersMutationVariables = {|
  foldersIds: $ReadOnlyArray<?string>
|};
export type RemoveFoldersMutationResponse = {|
  +removeFolders: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type RemoveFoldersMutation = {|
  variables: RemoveFoldersMutationVariables,
  response: RemoveFoldersMutationResponse,
|};
*/


/*
mutation RemoveFoldersMutation(
  $foldersIds: [ID]!
) {
  removeFolders(foldersIds: $foldersIds) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "foldersIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "foldersIds",
        "variableName": "foldersIds"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "removeFolders",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveFoldersMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveFoldersMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "200e9716c906b074cb9a0246c55c68aa",
    "id": null,
    "metadata": {},
    "name": "RemoveFoldersMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveFoldersMutation(\n  $foldersIds: [ID]!\n) {\n  removeFolders(foldersIds: $foldersIds) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '48b60b7875266e3df070938734fa453c';

module.exports = node;
