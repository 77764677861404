/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QGetAllFramesQueryVariables = {|
  teamId: string
|};
export type QGetAllFramesQueryResponse = {|
  +allFrames: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +pdfIds: ?$ReadOnlyArray<?string>,
    +teamId: ?string,
    +offerId: ?string,
    +offerName: ?string,
    +metadata: ?string,
    +bundleTag: ?string,
    +version: ?string,
    +description: ?string,
    +isReferent: ?boolean,
    +versionFlexidocs: ?string,
    +comment: ?string,
    +who: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
    +comparisons: ?$ReadOnlyArray<?{|
      +id: ?string,
      +globalStatusResult: ?string,
      +when: ?string,
      +comparisonDetails: ?$ReadOnlyArray<?{|
        +status: ?string,
        +diffPngsIds: ?$ReadOnlyArray<?string>,
        +referentPdfId: ?string,
        +actualPdfId: ?string,
        +fileName: ?string,
      |}>,
    |}>,
  |}>
|};
export type QGetAllFramesQuery = {|
  variables: QGetAllFramesQueryVariables,
  response: QGetAllFramesQueryResponse,
|};
*/


/*
query QGetAllFramesQuery(
  $teamId: ID!
) {
  allFrames(teamId: $teamId) {
    id
    name
    pdfIds
    teamId
    offerId
    offerName
    metadata
    bundleTag
    version
    description
    isReferent
    versionFlexidocs
    comment
    who {
      creation
      lastUpdate
    }
    dates {
      creation
      lastUpdate
    }
    comparisons {
      id
      globalStatusResult
      when
      comparisonDetails {
        status
        diffPngsIds
        referentPdfId
        actualPdfId
        fileName
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "teamId",
    "variableName": "teamId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pdfIds",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metadata",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bundleTag",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isReferent",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "versionFlexidocs",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comment",
  "storageKey": null
},
v15 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "creation",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastUpdate",
    "storageKey": null
  }
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Who",
  "kind": "LinkedField",
  "name": "who",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Dates",
  "kind": "LinkedField",
  "name": "dates",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "globalStatusResult",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "when",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "diffPngsIds",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referentPdfId",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPdfId",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QGetAllFramesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Frame",
        "kind": "LinkedField",
        "name": "allFrames",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ComparisonGlobalResult",
            "kind": "LinkedField",
            "name": "comparisons",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ComparisonDetails",
                "kind": "LinkedField",
                "name": "comparisonDetails",
                "plural": true,
                "selections": [
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QGetAllFramesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Frame",
        "kind": "LinkedField",
        "name": "allFrames",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ComparisonGlobalResult",
            "kind": "LinkedField",
            "name": "comparisons",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ComparisonDetails",
                "kind": "LinkedField",
                "name": "comparisonDetails",
                "plural": true,
                "selections": [
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c13d28e2137138a4feb453d6c5340fd",
    "id": null,
    "metadata": {},
    "name": "QGetAllFramesQuery",
    "operationKind": "query",
    "text": "query QGetAllFramesQuery(\n  $teamId: ID!\n) {\n  allFrames(teamId: $teamId) {\n    id\n    name\n    pdfIds\n    teamId\n    offerId\n    offerName\n    metadata\n    bundleTag\n    version\n    description\n    isReferent\n    versionFlexidocs\n    comment\n    who {\n      creation\n      lastUpdate\n    }\n    dates {\n      creation\n      lastUpdate\n    }\n    comparisons {\n      id\n      globalStatusResult\n      when\n      comparisonDetails {\n        status\n        diffPngsIds\n        referentPdfId\n        actualPdfId\n        fileName\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f8c755dfa6b102979739dd6341224db2';

module.exports = node;
