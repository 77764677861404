import React from 'react';
const R = require('ramda');

const Section = ({
  img,
  title,
  children,
  actions,
  style = {},
  titleStyle = {},
  className,
  optionTitle
}) => (
  <div className={`bg-white px-8 py-4 my-6 relative ${className}`}>
    <div
      className={`flex mb-4 ${actions ? 'justify-between items-center' : ''}`}
      style={style}
    >
      {!R.isNil(img) && (
        <img
          src={img}
          width="97px"
          alt={img}
        />
      )}

      <div className="inline-flex space-x-2 items-center w-full">
        <h2
          style={{
            textTransform: 'uppercase',
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#005982',
            marginBottom: 0,
            ...titleStyle
          }}
        >
          {title}
        </h2>
        {optionTitle}
      </div>
      <div className="flex gap-4">
        {actions}
      </div>
    </div>
    {children}
  </div>
);

export default Section;
