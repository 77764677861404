import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation, updaterBasicDelete } from '../common/common';

const mutation = graphql`
  mutation DeleteDocumentMutation($documentId: ID!) {
    deleteDocument(documentId: $documentId) {
      ok
      error
    }
  }
`;

export default ({
  documentId
}, done) => {
  const variables = {
    documentId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { deleteDocument: { ok, error } }) => updaterBasicDelete(proxyStore, ok, error, documentId),
      onCompleted: ({ deleteDocument }) => basicMutationOnCompleted(deleteDocument, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
