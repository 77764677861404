import React from 'react';

const PreviewColor = ({ color, className = '' }) => {
  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <div
        style={{
          width: 18,
          height: 18,
          borderRadius: '50%',
          backgroundColor: color
        }}
      >
      </div>
      <div
        className="uppercase"
        style={{
          color: color
        }}
      >
        {color}
      </div>
    </div>
  );
};

export default PreviewColor;
