import useFetching from './useFetching';
import UpdateOfferStatusMutation from '../_graphql/mutations/offers/UpdateOfferStatusMutation';

const useOffer = ({ offerId }, successCb, errorCb) => {
  const fetching = useFetching('pending', successCb, errorCb);

  const onArchiveOffer = () => {
    fetching.start();
    UpdateOfferStatusMutation({ offerId, status: 'archived' }, () => {
      fetching.stop();
    });
  };

  return {
    fetching,
    onArchiveOffer
  };
};

export default useOffer;
