import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Button } from 'antd';
import { EyeOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import DocumentWrapper from './DocumentWrapper';

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const PaginationButton = ({ disabled, children, onClick, className }) => {
  return (
    <Button
      className={className}
      onClick={onClick}
      disabled={disabled}
      style={{
        height: '37px',
        width: '37px',
        borderRadius: '6px',
        backgroundColor: 'white',
        border: '1px solid #e7ecf5',
        cursor: disabled ? 'initial' : 'pointer',
        color: disabled ? '#E6EBEF' : '#0197DC'
      }}
    >
      {children}
    </Button>
  );
};

const Pagination = ({
  numberOfPages,
  currentPageIndex,
  setCurrentPageIndex
}) => {
  if (numberOfPages === 0) {
    return null;
  }

  return (
    <div className="flex items-center gap-x-2 mr-4">
      <PaginationButton
        className={'previous-section-button'}
        disabled={currentPageIndex <= 0}
        onClick={() => setCurrentPageIndex(R.dec)}
      >
        <LeftOutlined/>
      </PaginationButton>

      <PaginationButton
        className={'next-section-button'}
        disabled={currentPageIndex >= (numberOfPages - 1)}
        onClick={() => setCurrentPageIndex(R.inc)}
      >
        <RightOutlined/>
      </PaginationButton>

      <span className="section-number font-semibold">Section {currentPageIndex + 1}</span>
    </div>
  );
};

const PreviewDocument = ({
  documentPreviewUrl,
  pagesHtml,
  previewDocumentHtml,
  previewHtmlLoading,
  classNameOrientationDocument,
  pageHeightDocument,
  activeKey
}) => {
  const getNumberOfPages = R.compose(
    R.length,
    R.defaultTo([])
  );

  const [numberOfPages, setNumberOfPages] = useState(getNumberOfPages(pagesHtml));
  const [currentPageIndex, setCurrentPageIndex] = useState(Number(activeKey));

  useEffect(() => {
    setNumberOfPages(getNumberOfPages(pagesHtml));
    setCurrentPageIndex(Number(activeKey));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesHtml, activeKey]);

  const currentPageHtml = R.nth(currentPageIndex, R.defaultTo([], pagesHtml));

  return (
    <div className="h-full">
      <div className="bg-white py-4 pl-8 flex place-content-between items-center">
        <span className="text-xl uppercase text-flexibranche-blue font-bold">Prévisualisation du document</span>

        <Pagination
          currentPageIndex={currentPageIndex}
          numberOfPages={numberOfPages}
          setCurrentPageIndex={setCurrentPageIndex}
        />
      </div>

      <div
        className="document-previewer bg-flexibranche-gray p-4 overflow-x-auto"
        style={{ height: pageHeightDocument }}
      >
        {isNilOrEmpty(documentPreviewUrl) && isNilOrEmpty(currentPageHtml) && (
          <Button
            style={{ height: '45px', fontSize: '15px' }}
            className="block mt-250px mx-auto mb-0"
            onClick={previewDocumentHtml}
            loading={previewHtmlLoading}
            icon={<EyeOutlined style={{ fontSize: '24px' }} />}
          >
            Prévisualiser
          </Button>
        )}

        {!isNilOrEmpty(currentPageHtml) && (
          <div
            dangerouslySetInnerHTML={{ __html: currentPageHtml }}
            className={classNameOrientationDocument}
          />
        )}

        {!isNilOrEmpty(documentPreviewUrl) && (
          <DocumentWrapper documentUrl={documentPreviewUrl} setNumberOfPages={setNumberOfPages} numberOfPages={numberOfPages} />
        )}
      </div>
    </div>
  );
};

export default PreviewDocument;
