import React from 'react';
import { IFRAME_PDF_HEIGHT, IFRAME_PDF_WIDTH } from '../../../_CONST';
import useFrameSync from '../../../hooks/useFrameSync';

const FrameResult = ({ fileId, isImg, pdfFileInfo, isScrollSynchronized, frameRef, otherFrameRef, showDiff }) => {
  const IS_CHROME_BROWSER = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const { iframeHeight, sourceElement, targetElement, fileDataReturned, synchronizeScrolling } = useFrameSync({ fileId, frameRef, otherFrameRef, showDiff });

  if (pdfFileInfo) {
    return (
      <div className="inline-flex w-full justify-center items-center mt-2 space-x-6 ">
        {IS_CHROME_BROWSER ? (
          <embed {...pdfFileInfo} width="100%"/>
        ) : (
          <iframe {...pdfFileInfo} width="100%"/>
        )}
      </div>
    );
  }

  const { fileName, IMAGE_SRC, pdfUrl } = fileDataReturned;

  const propsBasePdfFile = {
    title: fileName,
    height: `${IFRAME_PDF_HEIGHT}px`,
    src: pdfUrl
  };

  if (isImg) {
    return (
      <div className="inline-flex w-full justify-center items-center space-x-6">
        <div className="flex flex-col">
          <h2 className="text-sm text-flexibranche-lightblue mb-2 mt-8">
            {fileName && fileName.replace('diff', 'page').replace('.png', '')}
          </h2>
          <img
            src={IMAGE_SRC}
            alt="img"
            style={{ width: IFRAME_PDF_WIDTH, height: 'auto', boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.2)' }}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {IS_CHROME_BROWSER ? (
        <embed
          {...propsBasePdfFile}
          width={IFRAME_PDF_WIDTH}
        />
      ) : (
        <div ref={frameRef} className="trames-iframe-container" id={`trames-iframe-container-${fileId}`} onScroll={(e) => { isScrollSynchronized && synchronizeScrolling(sourceElement, targetElement); }}>
          <iframe
            {...propsBasePdfFile}
            width={IFRAME_PDF_WIDTH}
            style={{ height: `${iframeHeight}px` }}
          />
        </div>
      )}
    </>
  );
};

export default FrameResult;
