import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../..';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateFrameMutation($frameId: ID!, $dataToSet: FrameInput!, $metadataHasChanged: Boolean!) {
    updateFrame(frameId: $frameId, dataToSet: $dataToSet, metadataHasChanged: $metadataHasChanged) {
      ok
      error
    }
  }
`;

export default ({
  frameId,
  dataToSet,
  metadataHasChanged
}, done) => {
  const variables = {
    frameId,
    dataToSet,
    metadataHasChanged
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateFrame }) => basicMutationOnCompleted(updateFrame, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
