import { path } from 'ramda';
import React from 'react';
import CopyVersion from '../../../../components/CopyVersion';
import useDocumentsTable from '../../../../hooks/useDocumentsTable';

const DocumentDuplicate = ({ doc, documentsByRootOfferId }) => {
  const {
    documentToCopy,
    setDocumentToCopy,
    updateDocumentWidgets
  } = useDocumentsTable();
  return (
    <CopyVersion
      setDocumentToCopy={setDocumentToCopy}
      updateDocumentWidgets={updateDocumentWidgets}
      document={doc}
      documentToCopy={documentToCopy}
      documentsByRootOfferId={documentsByRootOfferId}
    />
  );
};

const WrapperDocumentDuplicate = (props) => {
  const rootDocumentId = path(['doc', 'rootDocumentId'], props);
  if (!rootDocumentId) {
    return null;
  }
  return <DocumentDuplicate {...props}/>;
};

export default WrapperDocumentDuplicate;
