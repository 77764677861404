import { EyeOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Switch } from 'antd';
import moment from 'moment';
import { always, assocPath, complement, compose, cond, defaultTo, dissocPath, equals, filter, find, flatten, identity, ifElse, isEmpty, isNil, map, path, pathOr, pick, pluck, prop, propEq, propOr, propSatisfies, reject, startsWith, T, uniq, uniqBy, whereEq } from 'ramda';
import React, { useEffect, useState } from 'react';
import { correspondenceVariableLabel } from '../../helpers/flexigets';
import { useDocumentContext } from '../../../../../context/Document';
import ModalAddMetadata from '../ModalAddMetadata';
import TextVariable from './variablesTab/TextVariable';
import DateVariable from './variablesTab/DateVariable';
import SelectVariable from './variablesTab/SelectVariable';
import ListVariables from './variablesTab/ListVariables';
import JSONVariable from './variablesTab/JSONVariable';
import { isNotEmpty, isNotNil } from 'ramda-adjunct';

const VariablesTab = () => {
  const {
    isEdit,
    previewDocumentHtml,
    widgetsAreDefined,
    previewHtmlLoading,
    onSave,
    nameIsDefined,
    loading,
    setState,
    values,
    globalVariables,
    predefinedVariables,
    widgets,
    updateIsVariablesHighlighted
  } = useDocumentContext();
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [currentIsVariablesHightlighted, setCurrentIsVariablesHightlighted] = useState(prop('isVariablesHighlighted', values));
  const [previewHighlightedVariables, setPreviewHighlightedVariables] = useState(false);
  const getWidgetById = widgetId => find(propEq('id', widgetId))(widgets);

  const getVariables = (type) => {
    const getDocVariables = (typeContent) => compose(
      uniq,
      reject(isNil),
      flatten,
      pluck(type),
      map(({ widgetId }) => getWidgetById(widgetId)),
      reject(propSatisfies(isNil, 'widgetId')),
      uniqBy(prop('widgetId')),
      reject(isNil),
      compose(flatten, pluck('widgets')),
      flatten,
      pluck(typeContent),
      propOr([], 'pages')
    )(values);

    const allVariables = [...getDocVariables('header'), ...getDocVariables('widgets'), ...getDocVariables('footer')];
    return uniq(allVariables);
  };
  const variables = compose(
    filter(propSatisfies(complement(startsWith('_summary-')), 'key')),
    filter(v => !map(prop('key'))(predefinedVariables).includes(prop('key', v)))
  )(getVariables('variables'));

  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  useEffect(() => {
    if (isComponentMounted) {
      previewDocumentHtml();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewHighlightedVariables]);

  if ((isEmpty(globalVariables)) && (isEmpty(variables) || !widgetsAreDefined)) {
    return null;
  }

  const onRemoveVariable = (name) => setState(dissocPath(['values', 'variables', name]));

  const onChangeMultipleVariables = (metadata) => {
    map((variable) => {
      const { name, type, value } = variable;
      const namePath = ['values', 'variables', name];
      return cond([
        [whereEq({ value: '' }), () => onRemoveVariable(name)],
        [T, () => setState(assocPath(namePath, { value, type }))]
      ])(variable);
    }, metadata);
  };

  const onChangeVariable = (key, type) => ifElse(
    isEmpty,
    () => onRemoveVariable(key),
    (value) => setState(compose(
      assocPath(['values', 'variables', key, 'value'], equals('JSON', type) ? JSON.parse(value) : value),
      assocPath(['values', 'variables', key, 'type'], type)
    ))
  );

  const onChangeIsDefaultValue = (key) => (bool) => setState(assocPath(['values', 'variables', key, 'isDefaultValue'], bool));

  const onChangeIsVariablesHighlighted = async () => {
    const newValue = !currentIsVariablesHightlighted;
    await updateIsVariablesHighlighted(newValue);
    setCurrentIsVariablesHightlighted(newValue);
    setPreviewHighlightedVariables(!previewHighlightedVariables);
  };

  return (
    <div className="mt-4">
      <span className="text-xl uppercase text-flexibranche-blue font-bold">Saisir des variables</span>
      <p className="italic text-xs">Les valeurs sont utilisées dans la génération seulement si elles sont considérées comme valeurs par défaut.</p>
      <div className="scrollbar overflow-y-scroll h-582px pt-3">
        <div className="w-11/12">
          <div className="flex items-center space-x-5">
            <ModalAddMetadata variablesValues={prop('variables', values)} globalVariables={globalVariables} variables={variables} onChangeMultipleVariables={onChangeMultipleVariables}/>
            <div className="flex items-center">
              <Switch checked={currentIsVariablesHightlighted} onChange={onChangeIsVariablesHighlighted} loading={previewHtmlLoading} />
              <p className="ml-3">Surligner les variables dans la prévisualisation</p>
            </div>
          </div>
          {isNotEmpty(globalVariables) && (
            <>
              <hr className="my-8"/>
              <span className="text-lg font-medium">{'Variables globales de l\'offre'}</span>
              {map(({ name, type }) => {
                const isDefaultValue = pathOr(false, ['variables', name, 'isDefaultValue'])(values);
                const value = pathOr('', ['variables', name, 'value'])(values);

                if (equals('boolean', type)) {
                  return (
                    <SelectVariable
                      key={name}
                      label={name}
                      value={value}
                      options={[{ value: true, title: 'Oui' }, { value: false, title: 'Non' }]}
                      onChange={onChangeVariable(name, type)}
                      onChangeIsDefaultValue={onChangeIsDefaultValue(name)}
                      isDefaultValue={isDefaultValue}
                    />
                  );
                }

                return (
                  <TextVariable
                    key={name}
                    label={name}
                    value={value}
                    onBlur={onChangeVariable(name, type)}
                    type={type}
                    onChangeIsDefaultValue={onChangeIsDefaultValue(name)}
                    isDefaultValue={isDefaultValue}
                  />
                );
              })(globalVariables)}
            </>
          )}
          {isNotEmpty(globalVariables) && isNotEmpty(variables) && <hr className="my-8"/>}
          {isNotEmpty(variables) && (
            <>
              <span className="text-lg font-medium">{'Variables du document'}</span>
              {map(({ type, key, fields, options }) => {
                const getStringInHtmlTag = (key) => {
                  const regex = /<[^>]*>/g;
                  return key.replace(regex, '');
                };
                const labelWithoutHTML = key && getStringInHtmlTag(key);
                const label = propOr(labelWithoutHTML, key, correspondenceVariableLabel);

                const defaultValue = compose(
                  cond([
                    [equals('false'), always('Non')],
                    [equals('true'), always('Oui')],
                    [T, identity]
                  ]),
                  (variable) => {
                    const type = prop('type', variable);
                    const valueByDefault = equals('checkbox', type) ? 'false' : '';
                    return propOr(valueByDefault, 'default', variable);
                  },
                  find(v => v.key === key)
                )(variables);

                const isDefaultValue = pathOr(false, ['variables', key, 'isDefaultValue'])(values);

                if (equals('list', type)) {
                  return (
                    <ListVariables
                      key={key}
                      label={label}
                      values={pathOr([], ['variables', key])(values)}
                      fields={defaultTo([], fields)}
                      setState={setState}
                      onChangeIsDefaultValue={onChangeIsDefaultValue(key)}
                      isDefaultValue={isDefaultValue}
                    />
                  );
                }

                if (equals('checkbox', type)) {
                  return (
                    <SelectVariable
                      key={key}
                      label={label}
                      value={pathOr(defaultValue, ['variables', key, 'value'])(values)}
                      options={options}
                      onChange={onChangeVariable(key, type)}
                      onChangeIsDefaultValue={onChangeIsDefaultValue(key)}
                      isDefaultValue={isDefaultValue}
                    />
                  );
                }

                if (equals('date', type)) {
                  return (
                    <DateVariable
                      key={key}
                      label={label}
                      format="DD/MM/YYYY"
                      value={isNotNil(path(['variables', key, 'value'])(values)) ? moment(path(['variables', key, 'value'])(values), 'DD/MM/YYYY') : ''}
                      onChange={onChangeVariable(key, type)}
                      onChangeIsDefaultValue={onChangeIsDefaultValue(key)}
                      isDefaultValue={isDefaultValue}
                    />
                  );
                }

                if (equals('multi_select', type)) {
                  return (
                    <SelectVariable
                      key={key}
                      label={label}
                      value={path(['variables', key, 'value'])(values)}
                      options={options}
                      onChange={onChangeVariable(key, type)}
                      multiple
                      onChangeIsDefaultValue={onChangeIsDefaultValue(key)}
                      isDefaultValue={isDefaultValue}
                    />
                  );
                }

                if (equals('JSON', type)) {
                  const JSONValue = compose(
                    pick(['rows']),
                    pathOr({}, ['variables', key, 'value'])
                  )(values);

                  return (
                    <JSONVariable
                      key={key}
                      label={label}
                      value={JSON.stringify(JSONValue, null, 2)}
                      isDefaultValue={isDefaultValue}
                      onChangeIsDefaultValue={onChangeIsDefaultValue(key)}
                      onChange={onChangeVariable(key, type)}
                    />
                  );
                }

                return (
                  <TextVariable
                    key={key}
                    label={label}
                    value={pathOr(defaultValue, ['variables', key, 'value'])(values)}
                    isDefaultValue={isDefaultValue}
                    onBlur={onChangeVariable(key, type)}
                    onChangeIsDefaultValue={onChangeIsDefaultValue(key)}
                  />
                );
              })(variables)}
            </>
          )}
        </div>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 items-stretch mt-10">
        <Button
          style={{ height: '45px', fontSize: '15px' }}
          onClick={previewDocumentHtml}
          loading={previewHtmlLoading}
          icon={<EyeOutlined style={{ fontSize: '24px' }} />}
        >
          Prévisualiser
        </Button>

        <Button
          type="primary"
          style={{ height: '45px', fontSize: '15px' }}
          onClick={onSave}
          disabled={!nameIsDefined}
          loading={loading}
          icon={<ThunderboltOutlined style={{ fontSize: '24px' }} />}
        >
          <span className="font-semibold">{isEdit ? 'Sauvegarder le document' : 'Créer le document'}</span>
        </Button>
      </div>
    </div>
  );
};

export default VariablesTab;
