import { CloseOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Row, Space, Tooltip } from 'antd';
import {
  allPass,
  always,
  append,
  compose,
  cond,
  equals,
  filter,
  head,
  isNil,
  length,
  not,
  path,
  pathEq,
  prop,
  propOr,
  propSatisfies,
  T,
  toLower
} from 'ramda';
import { isNotNil, propNotEq } from 'ramda-adjunct';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTreeContext } from '../../../components/treeStructure/treeContext';
import { deleteMutationMessage, errorMessage, successMessage } from '../../../utils/messageMutation';
import { SCOPE_OFFER } from '../../../_CONST';
import CreateWidgetMutation from '../../../_graphql/mutations/documents/CreateWidgetMutation';
import DeleteWidgetMutation from '../../../_graphql/mutations/documents/DeleteWidgetMutation';
import { renderDate } from '../../docs/components/helpers/dates';
import DocumentDates from './documentsSection/DocumentDates';
import { updateKeys } from '../../../components/treeStructure/treeHelper';

const WidgetTreeRow = ({
  folder,
  elementId,
  showAction,
  refRow,
  offerIsDraft,
  scope,
  userIsAdmin,
  args,
  setWidgets,
  isOfferPath,
  isPublished,
  widgets,
  setIsLoading,
  setRefetchWidgets
}) => {
  const { offerId } = args;
  const { removeTree, expandedKeys, setExpandedKeys, trees } = useTreeContext();
  const element = prop('element', folder);
  const elementName = prop('name', element);
  const isUsed = propOr(false, 'isUsed', element);
  const userCanEditWidgets =
    (equals(scope, SCOPE_OFFER) && offerIsDraft) ||
    (equals(scope, 'global') && userIsAdmin);

  const handleDeleteWidget = () => {
    if (isNil(prop('treeId', element))) {
      const nodeKey = compose(
        path([0, 'key']),
        filter(pathEq(['element', 'elmId'], elementId))
      )(trees);

      const updatedKeys = updateKeys(expandedKeys, [nodeKey], 'remove');

      setExpandedKeys(updatedKeys);
      DeleteWidgetMutation({ widgetId: elementId }, (ok, error) => deleteMutationMessage(ok, error, 'widget', setWidgets(filter(propNotEq('id', elementId)))));
    } else {
      removeTree(prop('treeId', element), folder);
      DeleteWidgetMutation({ widgetId: prop('elmId', element) }, (ok, error) => {
        deleteMutationMessage(ok, error, 'widget', setWidgets(filter(propNotEq('id', prop('elmId', element)))));
        setRefetchWidgets(true);
      });
    }
  };

  const handleDeleteWidgetFromDirectory = () => {
    removeTree(prop('treeId', element), folder, true);
  };

  const handleDuplicate = () => {
    setIsLoading(true);
    const widgetsHasSameRootWidgetId = filter(w => equals(prop('id', element), prop('rootWidgetId', w)))(widgets);
    const lengthSameParentWidget = length(widgetsHasSameRootWidgetId);
    const duplicateWidget = {
      name: `${prop('name', element)} (${lengthSameParentWidget + 1})`,
      nbColsGrid: prop('nbColsGrid', element),
      heightRowsGrid: prop('heightRowsGrid', element),
      contents: prop('contents', element),
      orientation: propOr('portrait', 'orientation', element),
      scope,
      offerId,
      frameStyle: prop('frameStyle', element),
      rootWidgetId: prop('id', element)
    };

    const nodeKey = compose(
      prop('key'),
      head,
      filter(pathEq(['element', 'isFolder'], true))
    )(trees);

    const updatedKeys = updateKeys(expandedKeys, [nodeKey], 'duplicate');
    setExpandedKeys(updatedKeys);
    CreateWidgetMutation({ widget: duplicateWidget }, (ok, error, id) => {
      if (ok && !error) {
        successMessage('widget', 'dupliqué');
        setWidgets(append({ id, ...duplicateWidget }));
      } else {
        errorMessage();
      }
      setIsLoading(false);
    });
  };

  const hasParentFolder = allPass([
    propSatisfies(isNotNil, 'parent'),
    always(prop('elmId', element))
  ])(folder);
  const widgetUrl = cond([
    [() => userCanEditWidgets, always(`/offre/${offerId}/widgets/${prop('elmId', element)}/editer`)],
    [() => not(offerIsDraft), always(`/offre/${offerId}/widgets/${prop('elmId', element)}/preview`)],
    [T, always(null)]
  ]);

  const displayActions = !isPublished && userIsAdmin;

  return (
    <>
      <Col ref={refRow}>
        <Link to={widgetUrl}>{elementName}</Link>
      </Col>
      <Col>
        <Row gutter={6} style={{ opacity: showAction ? '1' : '0' }}>
          {displayActions && (
            <Col>
              <Space>
                {hasParentFolder && (
                  <Tooltip
                    title="Retirer le widget de l'arborescence"
                    color="#0197DC"
                  >
                    <CloseOutlined
                      onClick={handleDeleteWidgetFromDirectory}
                      className="text-black hover:text-flexibranche-darkgray btn-remove-item-root"
                    />
                  </Tooltip>
                )}

                {userCanEditWidgets && (
                  <Tooltip title="Dupliquer le widget" color="#0197DC">
                    <CopyOutlined
                      onClick={() => handleDuplicate()}
                      className="btn-duplicate-widget text-black hover:text-flexibranche-darkgray"
                    />
                  </Tooltip>
                )}

                <Popconfirm
                  placement="left"
                  title="Êtes-vous sûr de vouloir supprimer ce widget ?"
                  onConfirm={handleDeleteWidget}
                  disabled={isUsed}
                >
                  <Tooltip
                    title={
                      isUsed
                        ? 'Ce widget est utilisé dans un document, vous ne pouvez pas le supprimer.'
                        : 'Supprimer le widget'
                    }
                    color="#0197DC"
                  >
                    <DeleteOutlined className={`${toLower(elementName)} btn-remove-widget hover:text-red-300`}/>
                  </Tooltip>
                </Popconfirm>
              </Space>
            </Col>
          )}

          {prop('dates', element) && (
            <DocumentDates renderDate={renderDate} element={element} />
          )}
        </Row>
      </Col>
    </>
  );
};

export default WidgetTreeRow;
