import React from 'react';
import { useParams } from 'react-router-dom';
import History from '../../../components/History';

const StyleHistory = () => {
  const { styleId } = useParams();
  return <History id={styleId} type="style" />;
};

export default StyleHistory;
