import {
  useLocation,
  matchPath
} from 'react-router-dom';

const R = require('ramda');

const useMatchLocation = (options = { strict: false }) => {
  const location = useLocation();
  const matchLocation = (path) => {
    const match = matchPath(R.prop('pathname', location), { path, ...options });
    return !R.isNil(match);
  };

  const getParams = (path) => {
    const match = matchPath(R.prop('pathname', location), { path, ...options });
    if (R.isNil(match)) return null;
    return R.prop('params', match);
  };

  return {
    getParams,
    matchLocation
  };
};

export default useMatchLocation;
