import {
  commitMutation,
  graphql
} from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateOrAddGroupRolesMutation($groupId: ID!, $formerGroupId: ID, $itemType: String!, $itemId: String!, $roles: [String!]!) {
    updateOrAddGroupRoles(groupId: $groupId, formerGroupId: $formerGroupId, itemType: $itemType, itemId: $itemId, roles: $roles) {
      ok
      error
    }
  }
`;

export default ({ groupId, formerGroupId, itemType, itemId, roles }, done) => {
  const variables = {
    groupId,
    formerGroupId,
    itemType,
    itemId,
    roles
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateOrAddGroupRoles }) => basicMutationOnCompleted(updateOrAddGroupRoles, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
