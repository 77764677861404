import React, { useRef, useState } from 'react';
import { Components, usePopup, defaultBorders } from '@fasstech/flexiget';
import { Button } from 'antd';
import useColors from '../../../../hooks/useColors';
import defaultColors from '../../../../utils/defaultColors';
import { T, all, always, assocPath, cond, dec, equals, head, inc, path, prop, values as RValues, tail } from 'ramda';
import { FormatPainterOutlined } from '@ant-design/icons';
import { DEFAULT_COLOR } from '../../../../_CONST';

const FrameBorderStyle = ({ values, setState, borderSide }) => {
  const getBorderInfo = (type) => path(['frameStyle', type], values);
  const isSetForAll = (type) => all(equals(head(RValues(getBorderInfo(type)))), tail(RValues(getBorderInfo(type))));
  const getBorderStyleAll = (type, baseValue) => ({ all: isSetForAll(type) ? prop('top', getBorderInfo(type)) : baseValue });
  const [allBorders, setAllBorders] = useState({ borderColor: getBorderStyleAll('borderColor', 'transparent'), borderStyle: getBorderStyleAll('borderStyle', 'none'), borderWidth: getBorderStyleAll('borderWidth', 0) });

  const colorPickerRef = useRef(null);
  const [showOptions, setShowOptions] = usePopup(colorPickerRef);
  const { colors } = useColors();
  const favoritesColors = [defaultColors, colors];
  const isAllSide = equals('all', borderSide);
  const valuesBorder = isAllSide ? allBorders : prop('frameStyle', values);
  const borderColor = path(['borderColor', borderSide], valuesBorder);
  const borderStyle = path(['borderStyle', borderSide], valuesBorder);
  const borderWidth = path(['borderWidth', borderSide], valuesBorder);

  const updateBorderProperty = (property, value) => {
    if (isAllSide) {
      setAllBorders(assocPath([property, borderSide], value));
      const newObject = { top: value, left: value, right: value, bottom: value };
      setState(assocPath(['values', 'frameStyle', property], newObject));
    } else {
      setState(assocPath(['values', 'frameStyle', property, borderSide], value));
    }
  };

  const changeBorderColor = (value) => {
    updateBorderProperty('borderColor', value);
    if (equals('transparent', value)) {
      updateBorderProperty('borderStyle', 'none');
      updateBorderProperty('borderWidth', 0);
    } else {
      if (equals('none', borderStyle)) updateBorderProperty('borderStyle', 'solid');
      if (equals(0, borderWidth)) updateBorderProperty('borderWidth', 1);
    }
  };

  const changeBorderWidth = (value) => {
    updateBorderProperty('borderWidth', value);
    if (equals(0, value)) {
      updateBorderProperty('borderStyle', 'none');
      updateBorderProperty('borderColor', 'transparent');
    } else {
      if (equals('none', borderStyle)) updateBorderProperty('borderStyle', 'solid');
      if (equals('transparent', borderColor)) updateBorderProperty('borderColor', DEFAULT_COLOR);
    }
  };

  const changeBorderStyle = (value) => {
    updateBorderProperty('borderStyle', value);
    if (equals('none', value)) {
      updateBorderProperty('borderColor', 'transparent');
      updateBorderProperty('borderWidth', 0);
    } else {
      if (equals(0, borderWidth)) updateBorderProperty('borderWidth', 1);
      if (equals('transparent', borderColor)) updateBorderProperty('borderColor', DEFAULT_COLOR);
    }
  };

  const tooltipText = cond([
    [equals('right'), always(' droite')],
    [equals('left'), always(' gauche')],
    [equals('bottom'), always(' basse')],
    [equals('top'), always(' haute')],
    [T, always('')]
  ])(borderSide);

  const colorIsNotDefine = showOptions || equals('transparent', borderColor);

  return (
    <div className={`section-border-${borderSide} flex flex-col items-center m-2 space-y-2`}>
      <div className="flex space-x-2">
        <div className="popup-wrapper" ref={colorPickerRef}>
          <Components.TooltipLayout placement="topLeft" title={`Couleur des bordures${tooltipText}`}>
            <Button
              type="button"
              style={{
                color: colorIsNotDefine ? DEFAULT_COLOR : borderColor
              }}
              onClick={() => setShowOptions((prev) => !prev)}
              shape="round"
              size="small"
              icon={<FormatPainterOutlined />}
              className={`btn-border-color-${borderSide}`}
            />
          </Components.TooltipLayout>
          {showOptions && (
            <Components.ColorPickerBody
              color={equals('transparent', borderColor) ? DEFAULT_COLOR : borderColor}
              setColor={(v) => changeBorderColor(v)}
              handleColorChange={(v) => changeBorderColor(v)}
              setShowOptions={setShowOptions}
              favoritesColors={favoritesColors}
              isDraggable
            />
          )}
        </div>
        <Components.SelectButton
          options={defaultBorders}
          onChange={(v) => changeBorderStyle(v)}
          value={borderStyle}
          tooltip={`Type de bordure${tooltipText}`}
          borderPosition={borderSide}
          type={`border-${borderSide}`}
        />
      </div>
      <Components.TooltipLayout title={`Largeur de bordure${tooltipText}`}>
        <div>
          <Components.InputNumbers
            inputClassName={`!w-10 input-border-width-${borderSide}`}
            value={borderWidth}
            defaultValue={borderWidth}
            minimumValue={0}
            handleNewValue={(e) => {
              e.preventDefault();
              changeBorderWidth(Number(e.target.value));
            }}
            onClickPlus={() => changeBorderWidth(inc(borderWidth))}
            onClickMinus={() => changeBorderWidth(dec(borderWidth))}
          />
        </div>
      </Components.TooltipLayout>
    </div>
  );
};

export default FrameBorderStyle;
