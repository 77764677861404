/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QFileQueryVariables = {|
  fileId: string
|};
export type QFileQueryResponse = {|
  +getFile: ?{|
    +fileBase64: ?string,
    +fileName: ?string,
  |}
|};
export type QFileQuery = {|
  variables: QFileQueryVariables,
  response: QFileQueryResponse,
|};
*/


/*
query QFileQuery(
  $fileId: ID!
) {
  getFile(fileId: $fileId) {
    fileBase64
    fileName
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "fileId",
        "variableName": "fileId"
      }
    ],
    "concreteType": "FileBuffer",
    "kind": "LinkedField",
    "name": "getFile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fileBase64",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fileName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QFileQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QFileQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "396b38651f0a3049f854c9a5403038d6",
    "id": null,
    "metadata": {},
    "name": "QFileQuery",
    "operationKind": "query",
    "text": "query QFileQuery(\n  $fileId: ID!\n) {\n  getFile(fileId: $fileId) {\n    fileBase64\n    fileName\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3e01c26279246fcb9b91b2b52a852086';

module.exports = node;
