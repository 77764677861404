/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ColorInput = {|
  name?: ?string,
  value?: ?string,
|};
export type CreateColorMutationVariables = {|
  color: ColorInput
|};
export type CreateColorMutationResponse = {|
  +createColor: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type CreateColorMutation = {|
  variables: CreateColorMutationVariables,
  response: CreateColorMutationResponse,
|};
*/


/*
mutation CreateColorMutation(
  $color: ColorInput!
) {
  createColor(color: $color) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "color"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "color",
        "variableName": "color"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "createColor",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateColorMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateColorMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c59c58fc00ac4b6a03490262c790504c",
    "id": null,
    "metadata": {},
    "name": "CreateColorMutation",
    "operationKind": "mutation",
    "text": "mutation CreateColorMutation(\n  $color: ColorInput!\n) {\n  createColor(color: $color) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2ec9399b401c3a7c8dcc08d1ea790954';

module.exports = node;
