import * as R from 'ramda';
import getCommentsElements from './getCommentsElements';
import attributesNames from '../data/attributesNames';
import { getElement } from './utils';

const getCommentElement = (comment) => {
  const commentsElements = getCommentsElements();

  return R.find(commentElement => {
    const commentPath = R.prop('attributes', commentElement).getNamedItem(R.prop('commentPath', attributesNames));
    const commentKey = R.prop('attributes', commentElement).getNamedItem(R.prop('commentKey', attributesNames));

    return getElement(commentKey, commentPath, comment);
  })(commentsElements);
};

export default getCommentElement;
