/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteRawDocumentsMutationVariables = {|
  rawDocumentsIds: $ReadOnlyArray<?string>
|};
export type DeleteRawDocumentsMutationResponse = {|
  +deleteRawDocuments: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type DeleteRawDocumentsMutation = {|
  variables: DeleteRawDocumentsMutationVariables,
  response: DeleteRawDocumentsMutationResponse,
|};
*/


/*
mutation DeleteRawDocumentsMutation(
  $rawDocumentsIds: [ID]!
) {
  deleteRawDocuments(rawDocumentsIds: $rawDocumentsIds) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rawDocumentsIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "rawDocumentsIds",
        "variableName": "rawDocumentsIds"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "deleteRawDocuments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteRawDocumentsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteRawDocumentsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b6b7784a3a53797fd9c2a3c055bf2855",
    "id": null,
    "metadata": {},
    "name": "DeleteRawDocumentsMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteRawDocumentsMutation(\n  $rawDocumentsIds: [ID]!\n) {\n  deleteRawDocuments(rawDocumentsIds: $rawDocumentsIds) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ba5600b9bf045b0ae9b245dd6a1d52af';

module.exports = node;
