import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation GenerateComparePdfMutation($ids: [String]!) {
    generateComparePdf(ids: $ids) {
      ok
      error
      allComparisons {
        id
        status
        comparisonId
      }
    }
  }
`;

export default ({
  ids
}, done) => {
  const variables = { ids };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ generateComparePdf }) => {
        const { ok, error, allComparisons } = generateComparePdf;
        done(ok, error, allComparisons);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
