import React from 'react';
import TagForm from './components/tag/TagForm';
import { useParams } from 'react-router-dom';
import QTag from '../../_graphql/queries/documents/QTag';
import { SCOPE_OFFER } from '../../_CONST';

const PreviewTagDocument = () => {
  const { offerId, tagId } = useParams();
  return (
    <QTag args={{ tagId }}>
      {tag => (
        <TagForm
          scope={SCOPE_OFFER}
          offerId={offerId}
          tagId={tagId}
          tag={tag}
          preview
        />
      )}
    </QTag>
  )
  ;
};

export default PreviewTagDocument;
