import React from 'react';

const Sider = ({
  children
}) => (
  <div style={{ minHeight: '100%' }}>
    {children}
  </div>
);

export default Sider;
