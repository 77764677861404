/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QGroupQueryVariables = {|
  groupId: string
|};
export type QGroupQueryResponse = {|
  +group: ?{|
    +id: ?string,
    +members: ?$ReadOnlyArray<?{|
      +userId: ?string
    |}>,
    +name: ?string,
    +roles: ?$ReadOnlyArray<?{|
      +itemId: ?string,
      +itemType: ?string,
      +roles: ?$ReadOnlyArray<?string>,
    |}>,
  |}
|};
export type QGroupQuery = {|
  variables: QGroupQueryVariables,
  response: QGroupQueryResponse,
|};
*/


/*
query QGroupQuery(
  $groupId: ID!
) {
  group(groupId: $groupId) {
    id
    members {
      userId
    }
    name
    roles {
      itemId
      itemType
      roles
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "groupId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "groupId",
        "variableName": "groupId"
      }
    ],
    "concreteType": "Group",
    "kind": "LinkedField",
    "name": "group",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GroupMember",
        "kind": "LinkedField",
        "name": "members",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GroupRole",
        "kind": "LinkedField",
        "name": "roles",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QGroupQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QGroupQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2e9fb45d37e0e7d17659b504cb48edf2",
    "id": null,
    "metadata": {},
    "name": "QGroupQuery",
    "operationKind": "query",
    "text": "query QGroupQuery(\n  $groupId: ID!\n) {\n  group(groupId: $groupId) {\n    id\n    members {\n      userId\n    }\n    name\n    roles {\n      itemId\n      itemType\n      roles\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1616106ed1dfeb4096abee6ff1bba592';

module.exports = node;
