import { Alert } from 'antd';
import { isEmpty, prop, uniqBy, map, length, gt } from 'ramda';
import React from 'react';

const WidgetIsUsed = ({ isUsedBy, isEditing, modalMode }) => {
  const isMultipleUsed = gt(length(isUsedBy), 1);
  const type = isEditing ? 'warning' : 'error';
  const text = isMultipleUsed ? 'les documents suivants' : 'le document suivant';
  const msg = isEditing ? `La modification de ce widget impactera ${text} :` : `Le widget est utilisé par ${text} et ne peut pas être supprimé :`;

  if (modalMode && !isEditing) return null;

  return (
    <div className="flex justify-center">
      {!isEmpty(isUsedBy) && (
        <Alert
          type={type}
          showIcon
          description={(
            <ul className="flex flex-col">
              {map((doc) => (
                <li key={prop('id', doc)}>
                  - {prop('name', doc)}
                </li>
              ))(uniqBy(prop('id'), isUsedBy))}
            </ul>
          )}
          message={(
            <>
              <span className="font-medium">Attention!</span> {msg}
            </>
          )}
        />
      )}
    </div>
  );
};

export default WidgetIsUsed;
