/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CommentStatusEnum = "accepted" | "pending" | "rejected" | "%future added value";
export type QOfferCommentsQueryVariables = {|
  offerId: string
|};
export type QOfferCommentsQueryResponse = {|
  +offerComments: ?$ReadOnlyArray<?{|
    +itemId: ?string,
    +type: ?string,
    +name: ?string,
    +comments: ?$ReadOnlyArray<?{|
      +id: ?string,
      +itemKey: ?string,
      +itemPath: ?$ReadOnlyArray<?any>,
      +itemRootId: ?string,
      +status: ?CommentStatusEnum,
      +teamId: ?string,
      +messages: ?$ReadOnlyArray<?{|
        +text: ?string,
        +userId: ?string,
        +userName: ?string,
        +when: ?string,
      |}>,
    |}>,
  |}>
|};
export type QOfferCommentsQuery = {|
  variables: QOfferCommentsQueryVariables,
  response: QOfferCommentsQueryResponse,
|};
*/


/*
query QOfferCommentsQuery(
  $offerId: ID!
) {
  offerComments(offerId: $offerId) {
    itemId
    type
    name
    comments {
      id
      itemKey
      itemPath
      itemRootId
      status
      teamId
      messages {
        text
        userId
        userName
        when
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "offerId",
        "variableName": "offerId"
      }
    ],
    "concreteType": "OfferComments",
    "kind": "LinkedField",
    "name": "offerComments",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "comments",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemKey",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemPath",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemRootId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "teamId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CommentMessage",
            "kind": "LinkedField",
            "name": "messages",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "when",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QOfferCommentsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QOfferCommentsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "193350a47fa1dd38880f7c28ee98bc17",
    "id": null,
    "metadata": {},
    "name": "QOfferCommentsQuery",
    "operationKind": "query",
    "text": "query QOfferCommentsQuery(\n  $offerId: ID!\n) {\n  offerComments(offerId: $offerId) {\n    itemId\n    type\n    name\n    comments {\n      id\n      itemKey\n      itemPath\n      itemRootId\n      status\n      teamId\n      messages {\n        text\n        userId\n        userName\n        when\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ec955c3d555226366963d1a4bb37e417';

module.exports = node;
