import { isNil, reject } from 'ramda';
import {
  commitMutation,
  graphql
} from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation RemoveGroupMemberMutation($groupId: ID!, $memberId: ID!) {
    removeGroupMember(groupId: $groupId, memberId: $memberId) {
      ok
      error
    }
  }
`;

export default ({ groupId, memberId }, done) => {
  const variables = {
    groupId,
    memberId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { removeGroupMember: { ok, error } }) => {
        if (ok && !error) {
          const group = proxyStore.get(groupId);

          if (!isNil(group)) {
            let members = group.getLinkedRecords('members');

            members = reject(member => member.getValue('userId') === memberId)(members);

            group.setLinkedRecords(members, 'members');
          }
        }
      },
      onCompleted: ({ removeGroupMember }) => basicMutationOnCompleted(removeGroupMember, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
