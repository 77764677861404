/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentRuleActionEnum = "display" | "hide" | "%future added value";
export type DocumentRuleConditionEnum = "isDefined" | "isEqualTo" | "isGreaterThan" | "isLessThan" | "isNotEqualTo" | "isUndefined" | "%future added value";
export type DocumentRuleOperatorEnum = "AND" | "OR" | "%future added value";
export type DocumentWidgetTypeEnum = "content" | "footer" | "header" | "%future added value";
export type OrientationEnum = "landscape" | "portrait" | "%future added value";
export type formatPdfEnum = "A_1a" | "A_2b" | "A_3b" | "PDF" | "%future added value";
export type DocumentSectionInput = {|
  breakPage?: ?boolean,
  footer?: ?$ReadOnlyArray<?DocumentWidgetHeaderFooterInput>,
  header?: ?$ReadOnlyArray<?DocumentWidgetHeaderFooterInput>,
  padding?: ?PaddingInput,
  rules?: ?$ReadOnlyArray<?DocumentRuleInput>,
  widgets?: ?$ReadOnlyArray<?DocumentWidgetRowInput>,
|};
export type DocumentWidgetHeaderFooterInput = {|
  padding?: ?PaddingInput,
  widgets?: ?$ReadOnlyArray<?DocumentWidgetInput>,
|};
export type PaddingInput = {|
  bottom?: ?number,
  left?: ?number,
  right?: ?number,
  top?: ?number,
|};
export type DocumentWidgetInput = {|
  rules?: ?$ReadOnlyArray<?DocumentRuleInput>,
  type?: ?DocumentWidgetTypeEnum,
  widgetId?: ?string,
|};
export type DocumentRuleInput = {|
  action?: ?DocumentRuleActionEnum,
  conditions?: ?$ReadOnlyArray<?DocumentRuleConditionInput>,
|};
export type DocumentRuleConditionInput = {|
  condition?: ?DocumentRuleConditionEnum,
  operator?: ?DocumentRuleOperatorEnum,
  value?: ?string,
  variable?: ?string,
|};
export type DocumentWidgetRowInput = {|
  breakPage?: ?boolean,
  padding?: ?PaddingInput,
  rulesBreakPage?: ?$ReadOnlyArray<?DocumentRuleInput>,
  widgets?: ?$ReadOnlyArray<?DocumentWidgetInput>,
|};
export type UpdateDocumentMutationVariables = {|
  documentId: string,
  name: string,
  pages: $ReadOnlyArray<DocumentSectionInput>,
  variables?: ?string,
  padding?: ?PaddingInput,
  orientation: OrientationEnum,
  renderingEngineVersion?: ?string,
  formatPdf?: ?formatPdfEnum,
  isVariablesHighlighted?: ?boolean,
|};
export type UpdateDocumentMutationResponse = {|
  +updateDocument: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateDocumentMutation = {|
  variables: UpdateDocumentMutationVariables,
  response: UpdateDocumentMutationResponse,
|};
*/


/*
mutation UpdateDocumentMutation(
  $documentId: ID!
  $name: String!
  $pages: [DocumentSectionInput!]!
  $variables: String
  $padding: PaddingInput
  $orientation: OrientationEnum!
  $renderingEngineVersion: String
  $formatPdf: formatPdfEnum
  $isVariablesHighlighted: Boolean
) {
  updateDocument(documentId: $documentId, name: $name, pages: $pages, variables: $variables, padding: $padding, orientation: $orientation, renderingEngineVersion: $renderingEngineVersion, formatPdf: $formatPdf, isVariablesHighlighted: $isVariablesHighlighted) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "formatPdf"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isVariablesHighlighted"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orientation"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "padding"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pages"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "renderingEngineVersion"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "variables"
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "documentId"
      },
      {
        "kind": "Variable",
        "name": "formatPdf",
        "variableName": "formatPdf"
      },
      {
        "kind": "Variable",
        "name": "isVariablesHighlighted",
        "variableName": "isVariablesHighlighted"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "orientation",
        "variableName": "orientation"
      },
      {
        "kind": "Variable",
        "name": "padding",
        "variableName": "padding"
      },
      {
        "kind": "Variable",
        "name": "pages",
        "variableName": "pages"
      },
      {
        "kind": "Variable",
        "name": "renderingEngineVersion",
        "variableName": "renderingEngineVersion"
      },
      {
        "kind": "Variable",
        "name": "variables",
        "variableName": "variables"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateDocumentMutation",
    "selections": (v9/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v8/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateDocumentMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "93a443a679236ad9ab32a5507c36f558",
    "id": null,
    "metadata": {},
    "name": "UpdateDocumentMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDocumentMutation(\n  $documentId: ID!\n  $name: String!\n  $pages: [DocumentSectionInput!]!\n  $variables: String\n  $padding: PaddingInput\n  $orientation: OrientationEnum!\n  $renderingEngineVersion: String\n  $formatPdf: formatPdfEnum\n  $isVariablesHighlighted: Boolean\n) {\n  updateDocument(documentId: $documentId, name: $name, pages: $pages, variables: $variables, padding: $padding, orientation: $orientation, renderingEngineVersion: $renderingEngineVersion, formatPdf: $formatPdf, isVariablesHighlighted: $isVariablesHighlighted) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a6e10aa1b349cb0792350d3fd36926d';

module.exports = node;
