import React from 'react';

const DoubleTags = () => (
  <svg version="1.1" viewBox="0 0 28 23" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
    <title>266334EA-90DF-425F-AC71-D1A0AD391E17</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1139 -1770)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(289 1659)">
          <g transform="translate(827 76)">
            <g transform="translate(23 35)">
              <path d="m25.967 7.1207-6.9846-6.7716c-0.48655-0.47329-1.2698-0.46205-1.7469 0.025669-0.47528 0.48772-0.46406 1.2676 0.025735 1.7424l6.9743 6.7613c0.83074 0.82964 1.294 1.9437 1.294 3.1245s-0.46164 2.2913-1.3022 3.1291l-5.7493 5.7705c-0.48094 0.48289-0.47929 1.2624 0.0048279 1.7424 0.24223 0.23667 0.5562 0.35475 0.87017 0.35475 0.31768 0 0.63463-0.12111 0.87603-0.36338l5.7493-5.7654c1.3012-1.3 2.0218-3.0301 2.0218-4.8681 0-1.8379-0.72059-3.5732-2.0331-4.8823zm-3.7522 2.8185-9.1206-9.0973c-0.46324-0.46308-1.3794-0.84196-2.0382-0.84196h-9.6147c-0.79574 0-1.4412 0.64379-1.4412 1.4375v9.5901c0 0.65509 0.3799 1.5699 0.84412 2.033l9.1206 9.0973c1.1257 1.1228 2.9503 1.1228 4.0765 1.027e-4l8.1735-8.1526c1.1272-1.1244 1.1272-2.9418 0-4.0661zm-16.45-2.5464c-0.90949 0-1.6471-0.73517-1.6471-1.6428s0.73757-1.6428 1.6471-1.6428c0.90949 0 1.6471 0.73517 1.6471 1.6428 0 0.9087-0.73603 1.6428-1.6471 1.6428z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DoubleTags;
