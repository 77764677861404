import { Button, Modal } from 'antd';
import * as R from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useEffect } from 'react';
import ElementReviewButtons from '../../../../components/reviews/ElementReviewButtons';
import PreviewDocument from './PreviewDocument';

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const ModalPreviewDocument = ({
  documentUrl,
  html = [],
  visible,
  hide,
  setIsLoaded,
  isReview,
  offerId,
  documentId
}) => {
  useEffect(() => {
    if (!R.isNil(setIsLoaded) && R.is(Function, setIsLoaded) && visible) {
      setIsLoaded(true);
    }
  }, [setIsLoaded, visible]);

  return (
    <Modal
      closable
      centered
      visible={visible}
      onCancel={hide}
      footer={[
        <Button
          key="ok"
          type="primary"
          onClick={hide}
        >
          OK
        </Button>
      ]}
      width="auto"
    >

      {isNilOrEmpty(documentUrl) && isNilOrEmpty(html) && <span>Aucun document à afficher.</span>}

      {isNotNilOrEmpty(html) && (
        <>
          {isReview && (
            <ElementReviewButtons
              itemId={documentId}
              itemRootId={offerId}
              itemType="document"
              html={html}
            />
          )}
          <PreviewDocument
            documentUrl={documentUrl}
            pagesHtml={html}
          />
        </>
      )}

    </Modal>

  );
};

export default ModalPreviewDocument;
