import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

const ModalChangeWidgetRules = ({ visible, onHide, onClick }) => {
  return (
    <Modal
      title="Changer de widget"
      visible={visible}
      onCancel={onHide}
      footer={[
        <Button
          key="ok"
          type="primary"
          onClick={onClick}
        >
          Accepter
        </Button>
      ]}
    >
      <p>Si vous acceptez ce changement de widget, seulement les règles liées aux variables globales seront prises en compte.</p>
    </Modal>
  );
};

ModalChangeWidgetRules.propTypes = {
  visible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ModalChangeWidgetRules;
