/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentScopeEnum = "global" | "offer" | "%future added value";
export type FolderTypeEnum = "DOCUMENT" | "WIDGET" | "%future added value";
export type QFoldersQueryVariables = {|
  scope: DocumentScopeEnum,
  offerId?: ?string,
  teamId?: ?string,
|};
export type QFoldersQueryResponse = {|
  +folders: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +parent: ?string,
    +element: ?{|
      +elmId: ?string,
      +type: ?string,
    |},
    +type: ?FolderTypeEnum,
  |}>
|};
export type QFoldersQuery = {|
  variables: QFoldersQueryVariables,
  response: QFoldersQueryResponse,
|};
*/


/*
query QFoldersQuery(
  $scope: DocumentScopeEnum!
  $offerId: ID
  $teamId: ID
) {
  folders(scope: $scope, offerId: $offerId, teamId: $teamId) {
    id
    name
    parent
    element {
      elmId
      type
    }
    type
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "offerId",
        "variableName": "offerId"
      },
      {
        "kind": "Variable",
        "name": "scope",
        "variableName": "scope"
      },
      {
        "kind": "Variable",
        "name": "teamId",
        "variableName": "teamId"
      }
    ],
    "concreteType": "Folder",
    "kind": "LinkedField",
    "name": "folders",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parent",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ElementFolder",
        "kind": "LinkedField",
        "name": "element",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "elmId",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QFoldersQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "QFoldersQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "3d910a92b10833a26f571b1840731a94",
    "id": null,
    "metadata": {},
    "name": "QFoldersQuery",
    "operationKind": "query",
    "text": "query QFoldersQuery(\n  $scope: DocumentScopeEnum!\n  $offerId: ID\n  $teamId: ID\n) {\n  folders(scope: $scope, offerId: $offerId, teamId: $teamId) {\n    id\n    name\n    parent\n    element {\n      elmId\n      type\n    }\n    type\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b5d9cb1fc19813f83b69c5549a62cb6c';

module.exports = node;
