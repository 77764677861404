import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation, updaterBasicDelete } from '../common/common';

const mutation = graphql`
  mutation DeleteRawDocumentMutation($rawDocumentId: ID!) {
    deleteRawDocument(rawDocumentId: $rawDocumentId) {
      ok
      error
    }
  }
`;

export default ({
  rawDocumentId
}, done) => {
  const variables = {
    rawDocumentId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { deleteRawDocument: { ok, error } }) => updaterBasicDelete(proxyStore, ok, error, rawDocumentId),
      onCompleted: ({ deleteRawDocument }) => basicMutationOnCompleted(deleteRawDocument, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
