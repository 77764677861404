/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentScopeEnum = "global" | "offer" | "%future added value";
export type OfferStatus = "archived" | "draft" | "ready" | "release" | "review" | "%future added value";
export type OrientationEnum = "landscape" | "portrait" | "%future added value";
export type WidgetVariableTypeEnum = "JSON" | "checkbox" | "dataMatrix" | "date" | "iban" | "list" | "multi_select" | "number" | "text" | "%future added value";
export type QWidgetsQueryVariables = {|
  scope?: ?DocumentScopeEnum,
  offerId?: ?string,
  status?: ?OfferStatus,
|};
export type QWidgetsQueryResponse = {|
  +widgets: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +parentFolder: ?string,
    +scope: ?DocumentScopeEnum,
    +offerId: ?string,
    +rootWidgetId: ?string,
    +offerName: ?string,
    +nbColsGrid: ?number,
    +heightRowsGrid: ?number,
    +contents: ?string,
    +orientation: ?OrientationEnum,
    +frameStyle: ?{|
      +backgroundColor: ?string,
      +borderColor: ?{|
        +top: ?string,
        +left: ?string,
        +right: ?string,
        +bottom: ?string,
      |},
      +borderWidth: ?{|
        +top: ?number,
        +left: ?number,
        +right: ?number,
        +bottom: ?number,
      |},
      +borderStyle: ?{|
        +top: ?string,
        +left: ?string,
        +right: ?string,
        +bottom: ?string,
      |},
      +borderRadius: ?{|
        +topLeft: ?number,
        +topRight: ?number,
        +bottomLeft: ?number,
        +bottomRight: ?number,
      |},
    |},
    +version: ?{|
      +status: ?string,
      +major: ?number,
      +minor: ?number,
      +itemRootId: ?string,
      +versionId: ?string,
      +itemId: ?string,
    |},
    +variables: ?$ReadOnlyArray<?{|
      +type: ?WidgetVariableTypeEnum,
      +key: ?string,
      +dynamicTableData: ?{|
        +colsToHide: ?$ReadOnlyArray<?string>,
        +rows: ?{|
          +rowName: ?string,
          +cells: ?$ReadOnlyArray<?{|
            +value: ?string
          |}>,
        |},
      |},
      +dataMatrix: ?string,
      +fields: ?$ReadOnlyArray<?string>,
      +options: ?$ReadOnlyArray<?{|
        +value: ?string,
        +title: ?string,
      |}>,
      +default: ?string,
    |}>,
    +isUsed: ?boolean,
    +isUsedBy: ?$ReadOnlyArray<?{|
      +id: ?string,
      +name: ?string,
    |}>,
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
  |}>
|};
export type QWidgetsQuery = {|
  variables: QWidgetsQueryVariables,
  response: QWidgetsQueryResponse,
|};
*/


/*
query QWidgetsQuery(
  $scope: DocumentScopeEnum
  $offerId: ID
  $status: OfferStatus
) {
  widgets(scope: $scope, offerId: $offerId, status: $status) {
    id
    name
    parentFolder
    scope
    offerId
    rootWidgetId
    offerName
    nbColsGrid
    heightRowsGrid
    contents
    orientation
    frameStyle {
      backgroundColor
      borderColor {
        top
        left
        right
        bottom
      }
      borderWidth {
        top
        left
        right
        bottom
      }
      borderStyle {
        top
        left
        right
        bottom
      }
      borderRadius {
        topLeft
        topRight
        bottomLeft
        bottomRight
      }
    }
    version {
      status
      major
      minor
      itemRootId
      versionId
      itemId
      id
    }
    variables {
      type
      key
      dynamicTableData {
        colsToHide
        rows {
          rowName
          cells {
            value
          }
        }
      }
      dataMatrix
      fields
      options {
        value
        title
      }
      default
    }
    isUsed
    isUsedBy {
      id
      name
    }
    dates {
      creation
      lastUpdate
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v3 = [
  {
    "kind": "Variable",
    "name": "offerId",
    "variableName": "offerId"
  },
  {
    "kind": "Variable",
    "name": "scope",
    "variableName": "scope"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentFolder",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scope",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootWidgetId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nbColsGrid",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heightRowsGrid",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contents",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientation",
  "storageKey": null
},
v15 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "top",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "left",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "right",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bottom",
    "storageKey": null
  }
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "FrameStyleData",
  "kind": "LinkedField",
  "name": "frameStyle",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BorderColorAndStyle",
      "kind": "LinkedField",
      "name": "borderColor",
      "plural": false,
      "selections": (v15/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BorderWidth",
      "kind": "LinkedField",
      "name": "borderWidth",
      "plural": false,
      "selections": (v15/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BorderColorAndStyle",
      "kind": "LinkedField",
      "name": "borderStyle",
      "plural": false,
      "selections": (v15/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BorderRadius",
      "kind": "LinkedField",
      "name": "borderRadius",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "topLeft",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "topRight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bottomLeft",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bottomRight",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "major",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minor",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemRootId",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "versionId",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "WidgetVariable",
  "kind": "LinkedField",
  "name": "variables",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DynamicTableData",
      "kind": "LinkedField",
      "name": "dynamicTableData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "colsToHide",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Rows",
          "kind": "LinkedField",
          "name": "rows",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rowName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Cell",
              "kind": "LinkedField",
              "name": "cells",
              "plural": true,
              "selections": [
                (v23/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataMatrix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fields",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Options",
      "kind": "LinkedField",
      "name": "options",
      "plural": true,
      "selections": [
        (v23/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "default",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isUsed",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "WidgetDocument",
  "kind": "LinkedField",
  "name": "isUsedBy",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "WidgetDates",
  "kind": "LinkedField",
  "name": "dates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QWidgetsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Widget",
        "kind": "LinkedField",
        "name": "widgets",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/)
            ],
            "storageKey": null
          },
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "QWidgetsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Widget",
        "kind": "LinkedField",
        "name": "widgets",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fd3557e6efca9af56eb99f51294bff42",
    "id": null,
    "metadata": {},
    "name": "QWidgetsQuery",
    "operationKind": "query",
    "text": "query QWidgetsQuery(\n  $scope: DocumentScopeEnum\n  $offerId: ID\n  $status: OfferStatus\n) {\n  widgets(scope: $scope, offerId: $offerId, status: $status) {\n    id\n    name\n    parentFolder\n    scope\n    offerId\n    rootWidgetId\n    offerName\n    nbColsGrid\n    heightRowsGrid\n    contents\n    orientation\n    frameStyle {\n      backgroundColor\n      borderColor {\n        top\n        left\n        right\n        bottom\n      }\n      borderWidth {\n        top\n        left\n        right\n        bottom\n      }\n      borderStyle {\n        top\n        left\n        right\n        bottom\n      }\n      borderRadius {\n        topLeft\n        topRight\n        bottomLeft\n        bottomRight\n      }\n    }\n    version {\n      status\n      major\n      minor\n      itemRootId\n      versionId\n      itemId\n      id\n    }\n    variables {\n      type\n      key\n      dynamicTableData {\n        colsToHide\n        rows {\n          rowName\n          cells {\n            value\n          }\n        }\n      }\n      dataMatrix\n      fields\n      options {\n        value\n        title\n      }\n      default\n    }\n    isUsed\n    isUsedBy {\n      id\n      name\n    }\n    dates {\n      creation\n      lastUpdate\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bab553ecffaaa99f34202afa23ae9933';

module.exports = node;
