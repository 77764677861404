import * as R from 'ramda';

const ROLES = Object.freeze({
  admin: 'admin',
  regular: 'regular',
  qa: 'qa'
});

const TEAM_ROLES = [{
  label: 'Administrateur',
  value: ROLES.admin
}, {
  label: 'Lecteur',
  value: ROLES.regular
},
{
  label: 'QA',
  value: ROLES.qa
}];

const ITEM_ROLES = itemType => R.filter(
  role => R.isNil(itemType) || R.includes(itemType, R.prop('itemTypes', role))
)([{
  label: 'Éditeur',
  value: 'editor',
  itemTypes: ['document']
}, {
  label: 'Éditeur des variables techniques',
  value: 'techEditor',
  itemTypes: ['document']
}, {
  label: 'Lecteur',
  value: 'reader',
  itemTypes: ['document']
}, {
  label: 'Valideur du document',
  value: 'documentValidator',
  itemTypes: ['document']
}, {
  label: 'Inactif',
  value: 'inactive',
  itemTypes: ['document']
}, {
  label: 'Valideur d\'offre',
  value: 'offerValidator',
  itemTypes: ['offer']
}]);

export {
  ROLES,
  TEAM_ROLES,
  ITEM_ROLES
};
