import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation DeleteCommentMutation($commentId: ID!) {
    deleteComment(commentId: $commentId) {
      ok
      error
    }
  }
`;

export default ({
  commentId
}, done) => {
  const variables = {
    commentId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { deleteComment: { ok, error } }) => {
        if (ok && !error) {
          proxyStore.delete(commentId);
        }
      },
      onCompleted: ({ deleteComment }) => basicMutationOnCompleted(deleteComment, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
