import { HistoryOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Breadcrumb } from '../../../../components';
import CommentsWrapper from '../../../../components/CommentsWrapper';
import useComments from '../../../../hooks/useComments';
import withUser from '../../../../withUser';
import DocumentFormComments from './DocumentFormComments';
import DocumentFormTabs from './DocumentFormTabs';
import EditWidgetModal from './EditWidgetModal';
import PreviewDocument from './PreviewDocument';
import { DocumentProvider, useDocumentContext } from '../../../../context/Document';
import { BASE_PADDING_DOCUMENT, SCOPE_OFFER } from '../../../../_CONST';
import Loading from '../../../../components/Loading';
import { compose, equals, isNil, path, pick, prop, propOr, reject, sum, values as RValues, includes, propEq } from 'ramda';
import CreateWidgetModal from './CreateWidgetModal';
import useFetchQuery from '../../../../hooks/useFetchQuery';
import { QCommentsQuery } from '../../../../_graphql/queries/comments/QComments';
import { QGlobalVariablesQuery } from '../../../../_graphql/queries/documents/QGlobalVariables';
import { QPredefinedVariablesQuery } from '../../../../_graphql/queries/QPredefinedVariables';
import { QWidgetsQuery } from '../../../../_graphql/queries/documents/QWidgets';
import { QOfferQuery } from '../../../../_graphql/queries/offers/QOffer';

const DocumentForm = ({
  commentsMode,
  breadcrumb
}) => {
  const {
    values,
    previewHtmlLoading,
    previewHtml,
    documentPreviewUrl,
    previewDocumentHtml,
    isEdit,
    widgetIdEditing,
    rowIndexEditing,
    document,
    offerId,
    documentId,
    classNameOrientationDocument,
    pageHeightDocument,
    comments,
    widgets,
    isOpenCreateWidgetModal
  } = useDocumentContext();

  const [showSettings, setShowSettings] = useState(true);
  const [activeKey, setActiveKey] = useState('0');

  const history = useHistory();
  const commentsHandlers = useComments();

  const { offer } = useFetchQuery({
    query: QOfferQuery,
    args: { offerId },
    dataProp: 'offer'
  });

  const docIsNotEditable = useMemo(() => {
    const isRawDocument = propEq('type', 'rawDocument')(document);
    const offerStatus = path(['version', 'status'], offer);
    return includes(offerStatus, ['review', 'release']) && !isRawDocument;
  }, [document, offer]);

  const displaySettings = !docIsNotEditable && showSettings;

  useEffect(() => {
    if (docIsNotEditable && !previewHtmlLoading) {
      previewDocumentHtml();
    }
  }, [docIsNotEditable]);

  const dataItemPrefix = reject(isNil, [offerId, documentId]);

  const calculatePaddingX = compose(
    sum,
    RValues,
    pick(['left', 'right']),
    propOr(BASE_PADDING_DOCUMENT, 'padding')
  );

  const actualSection = path(['pages', activeKey], values);
  const paddingXDocument = calculatePaddingX(values);
  const paddingXActualSection = calculatePaddingX(actualSection);
  const paddingXActualRow = calculatePaddingX(path(rowIndexEditing, actualSection));
  const totalPaddingX = sum([paddingXDocument, paddingXActualSection, paddingXActualRow]);

  const settingsClassNames = classNames('relative transition-all duration-300 ease-in-out opacity-100', {
    'w-full col-span-3': showSettings,
    'w-4 bg-white shadow-lg': !showSettings
  });

  return (
    <>
      {widgetIdEditing && <EditWidgetModal totalPaddingX={totalPaddingX} />}
      {isOpenCreateWidgetModal && <CreateWidgetModal totalPaddingX={totalPaddingX} />}
      <CommentsWrapper
        comments={comments}
        mode={commentsMode}
        handlers={commentsHandlers}
        itemRootId={documentId}
        isDocumentEditing
      >
        {({ setIsLoaded }) => (
          <DocumentFormComments setIsLoaded={setIsLoaded}>
            {() => (
              <div className={`${displaySettings ? 'grid md:grid-cols-7 grid-cols-1' : 'flex h-full'}`}>
                <div className={displaySettings ? 'col-span-4' : 'w-full'}>
                  <div className="mt-4 ml-10">
                    <Breadcrumb value={breadcrumb} />

                    {isEdit && (
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => history.push(`/offre/${offerId}/documents/${prop('id', document)}/historique`)}
                        icon={<HistoryOutlined />}
                      >
                        Historique du document
                      </Button>
                    )}

                    <div className="font-bold text-xl uppercase my-6 flex items-center">
                      <img
                        alt={''}
                        src="/icon/icon-fleche.svg"
                        className="cursor-pointer mr-3 h-4"
                        onClick={() => history.push(`/offre/${offerId}/edition`)}
                      />
                      {isEdit ? 'Modifier le document' : 'Créer un document'}
                    </div>
                  </div>

                  <PreviewDocument
                    documentPreviewUrl={documentPreviewUrl}
                    pagesHtml={previewHtml}
                    previewDocumentHtml={previewDocumentHtml}
                    previewHtmlLoading={previewHtmlLoading}
                    classNameOrientationDocument={classNameOrientationDocument}
                    pageHeightDocument={pageHeightDocument}
                    activeKey={activeKey}
                  />
                </div>

                {!docIsNotEditable && (
                  <div className={settingsClassNames} style={{ overflow: 'visible' }}>
                    <Tooltip
                      title={showSettings ? 'Réduire' : 'Agrandir'}
                      color="#0197DC"
                      placement="left"
                    >
                      {showSettings ? (
                        <RightCircleOutlined
                          style={{ fontSize: '22px', color: '#0197dc' }}
                          className="absolute -left-2.5 top-10 bg-white rounded-full cursor-pointer"
                          onClick={() => setShowSettings(!showSettings)}
                        />
                      ) : (
                        <LeftCircleOutlined
                          style={{ fontSize: '22px', color: '#0197dc' }}
                          className="absolute -left-2.5 top-10 bg-white rounded-full cursor-pointer"
                          onClick={() => setShowSettings(!showSettings)}
                        />
                      )}
                    </Tooltip>
                    <DocumentFormTabs
                      dataItemPrefix={dataItemPrefix}
                      comments={comments}
                      showSettings={showSettings}
                      activeKey={activeKey}
                      setActiveKey={setActiveKey}
                      widgets={widgets}
                    />
                  </div>
                )}
              </div>
            )}
          </DocumentFormComments>
        )}
      </CommentsWrapper>
    </>
  );
};

const DocumentFormProvider = ({
  document,
  scope,
  offerId,
  userIsAdmin,
  commentsMode,
  breadcrumb,
  cacheKey
}) => {
  const { comments, isLoading: isLoadingComments } = useFetchQuery({
    query: QCommentsQuery,
    args: { itemRootId: propOr('', 'id', document) },
    dataProp: 'comments',
    defaultData: { id: 'comments-null', comments: [] }
  });

  const { globalVariables, isLoading: isLoadingGlobalVariables } = useFetchQuery({
    query: QGlobalVariablesQuery,
    args: {
      scope,
      offerId
    },
    dataProp: 'globalVariables',
    defaultData: []
  });

  const { predefinedVariables, isLoading: isLoadingPredefinedVariables } = useFetchQuery({
    query: QPredefinedVariablesQuery,
    args: {},
    dataProp: 'predefinedVariables',
    defaultData: []
  });

  const { widgets: widgetsFetch, isLoading: isLoadingWidgets, reFetch } = useFetchQuery({
    query: QWidgetsQuery,
    args: {
      scope: equals(scope, SCOPE_OFFER) ? undefined : scope,
      offerId
    },
    dataProp: 'widgets',
    defaultData: [],
    cacheKey
  });

  if (isLoadingWidgets || isLoadingComments || isLoadingGlobalVariables || isLoadingPredefinedVariables) return <Loading/>;

  return (
    <DocumentProvider
      document={document}
      options={{
        scope,
        offerId
      }}
      comments={comments}
      globalVariables={globalVariables}
      predefinedVariables={predefinedVariables}
      widgetsFetch={widgetsFetch}
      setRefetch={reFetch}
    >
      <DocumentForm
        userIsAdmin={userIsAdmin}
        commentsMode={commentsMode}
        breadcrumb={breadcrumb}
      />
    </DocumentProvider>
  );
};

export default withUser(DocumentFormProvider);
