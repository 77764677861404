import { CopyOutlined } from '@ant-design/icons';
import { Popconfirm, Select, Tooltip } from 'antd';
import { compose, either, filter, isEmpty, isNil, map, path, pathOr, prop, propEq, reject } from 'ramda';
import React from 'react';
import { useTreeContext } from './treeStructure/treeContext';

const CopyVersion = ({ setDocumentToCopy, document, documentToCopy, documentsByRootOfferId, updateDocumentWidgets }) => {
  const { reFetchElement } = useTreeContext();

  return (
    <Popconfirm
      placement="left"
      title={(
        <div className="flex flex-col w-72">
          <p>Sélectionner la version à copier</p>
          <Select
            getPopupContainer={(node) => prop('parentNode', node)}
            className="w-full"
            onChange={v => setDocumentToCopy({
              currentDocument: document,
              documentToCopyId: v
            })}
            value={pathOr(null, ['documentToCopyId'], documentToCopy)}
            placeholder="Sélectionnez un document"
          >
            {compose(
              map(d => (
                <Select.Option
                  key={prop('id', d)}
                  value={prop('id', d)}
                >
                  {prop('name', d)} - {path(['offerVersion', 'major'], d)}.{path(['offerVersion', 'minor'], d)}
                </Select.Option>
              )),
              filter(
                either(
                  propEq('rootDocumentId', prop('rootDocumentId', document)),
                  propEq('id', prop('rootDocumentId', document))
                )
              ),
              reject(
                propEq('id', prop('id', document))
              ),
              reject(either(isNil, isEmpty))
            )(documentsByRootOfferId)}
          </Select>
        </div>
      )}
      onConfirm={() => updateDocumentWidgets((ok) => {
        if (ok) reFetchElement();
      }, false)}
    >
      <Tooltip
        title="Copier une version du document"
        color="#0197DC"
      >
        <CopyOutlined />
      </Tooltip>
    </Popconfirm>
  );
};

export default CopyVersion;
