import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Card, Select } from 'antd';
import moment from 'moment';
import { TEAM_ROLES } from '../../../lib/rights';
const R = require('ramda');

const useRole = (member) => {
  const [role, setRole] = useState(R.propOr('regular', 'role', member));

  const firstChar = R.compose(
    R.toUpper,
    R.take(1),
    R.pathOr('', ['user', 'email'])
  )(member);

  const joinDate = R.compose(
    R.when(R.complement(R.isNil), date => moment(date).format('DD/MM/YYYY')),
    R.path(['dates', 'join'])
  )(member);

  return {
    firstChar,
    joinDate,
    role,
    setRole
  };
};

const Member = ({
  member,
  updateRole
}) => {
  const {
    firstChar,
    joinDate,
    role,
    setRole
  } = useRole(member);

  return (
    <Card
      className="member-rights"
      title={(
        <div>
          <Avatar size={64}>{firstChar}</Avatar>

          <div>
            <b>{R.pathOr('', ['user', 'email'], member)}</b>

            <br/>

            <span style={{ color: '#8a98a5', display: 'flex' }}>
              <img src="/icon/icon-calendar-check.svg" alt="calendrier" style={{ marginRight: '5px' }}/>
              Inscrit le {joinDate}
            </span>

          </div>

        </div>
      )}
    >

      <b>Rôle : &nbsp;</b>

      <Select
        placeholder="Choisir un rôle"
        value={role}
        onChange={setRole}
      >
        {R.map(({ label, value }) => {
          return (
            <Select.Option
              key={value}
              value={value}
            >
              {label}
            </Select.Option>
          );
        })(TEAM_ROLES)}
      </Select>

      <div>
        <Button
          type="primary"
          onClick={() => updateRole(R.path(['user', 'id'], member), role)}
        >
          Valider
        </Button>
      </div>

    </Card>
  );
};

Member.propTypes = {
  member: PropTypes.object.isRequired,
  updateRole: PropTypes.func.isRequired
};

export default Member;
