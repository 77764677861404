import React from 'react';

const CubeIcon = () => (
  <svg version="1.1" viewBox="0 0 29 25" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
    <title>89500165-1D66-42E4-9465-97FC318A6D42</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-345 -1768)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(289 1659)">
          <g transform="translate(32 76)">
            <g transform="translate(24 33)">
              <path d="m27.953 12.529-5.3972-1.9922v-6.6821c0-0.66064-0.41652-1.252-1.0452-1.4844l-5.8806-2.1689c-0.36451-0.13442-0.74715-0.20166-1.1298-0.20166s-0.76528 0.067236-1.1328 0.2019l-5.8755 2.1692c-0.62934 0.23193-1.0472 0.82324-1.0472 1.4839v6.6797l-5.3992 1.9946c-0.62869 0.22949-1.0452 0.8252-1.0452 1.4844v7.1289c0 0.66064 0.41652 1.2524 1.0452 1.4844l5.8806 2.1689c0.36451 0.13574 0.74715 0.2041 1.1298 0.2041 0.38264 0 0.76679-0.067334 1.1313-0.2019l5.3131-1.9612 5.3116 1.9604c0.36753 0.13428 0.75017 0.20264 1.1328 0.20264 0.38264 0 0.76679-0.067334 1.1313-0.2019l5.8806-2.1689c0.62582-0.23169 1.0437-0.82251 1.0437-1.4866v-7.1289c0-0.65918-0.41788-1.2549-1.0472-1.4844zm-19.897 3.3154-4.9078-1.5869 4.5907-1.6943 4.8872 1.8032-4.57 1.478zm5.2361 4.9316-4.0278 1.4863v-4.3379l4.0278-1.3022v4.1538zm-3.7005-16.673 4.636-1.7104c0.086597-0.032227 0.18226-0.048828 0.27288-0.048828s0.18437 0.016401 0.27208 0.04873l4.6355 1.7104-4.9075 1.5855-4.9089-1.5854zm10.548 2.2349v3.9331c-0.10905 0.027754-0.21946 0.048975-0.3259 0.088281l-4.1047 1.5108v-4.1016l4.4306-1.4307zm0.80556 9.5068-4.571-1.4775 4.8867-1.8032 4.5917 1.6943-4.9073 1.5864zm5.6389 4.7803-4.4306 1.6348v-4.335l4.4306-1.4321v4.1323z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CubeIcon;
