import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { map } from 'ramda';

const Card = ({ type, label, options, hasDynamicTable, setIsFocus, setUseCSSTransforms }) => {
  const [isDragging, setIsDragging] = useState(false);

  const [, drag] = useDrag(() => ({
    type: 'CARD',
    item: { label, type, options },
    collect: (monitor) => {
      const currentlyDragging = monitor.isDragging();
      if (currentlyDragging !== isDragging) {
        setIsDragging(currentlyDragging);
      }
      return {
        isDragging: currentlyDragging,
        handlerId: monitor.getHandlerId()
      };
    }
  }));

  useEffect(() => {
    setIsFocus(map(() => false));
    setUseCSSTransforms(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const isDynamicTable = type === 'dynamicTable';
  const shouldBeDisabled = isDynamicTable && hasDynamicTable;

  const className = `${shouldBeDisabled ? 'bg-gray-400 text-gray-100 cursor-not-allowed' : 'bg-white cursor-move'} rounded w-36 h-auto whitespace-normal flex justify-center p-2 items-center text-center border-solid border-gray-300 border-2`;

  return (
    <div
      ref={shouldBeDisabled ? null : drag}
      role="Card"
      className={className}
    >
      {label}
    </div>
  );
};

export default Card;
