/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateOrAddGroupRolesMutationVariables = {|
  groupId: string,
  formerGroupId?: ?string,
  itemType: string,
  itemId: string,
  roles: $ReadOnlyArray<string>,
|};
export type UpdateOrAddGroupRolesMutationResponse = {|
  +updateOrAddGroupRoles: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateOrAddGroupRolesMutation = {|
  variables: UpdateOrAddGroupRolesMutationVariables,
  response: UpdateOrAddGroupRolesMutationResponse,
|};
*/


/*
mutation UpdateOrAddGroupRolesMutation(
  $groupId: ID!
  $formerGroupId: ID
  $itemType: String!
  $itemId: String!
  $roles: [String!]!
) {
  updateOrAddGroupRoles(groupId: $groupId, formerGroupId: $formerGroupId, itemType: $itemType, itemId: $itemId, roles: $roles) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "formerGroupId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roles"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "formerGroupId",
        "variableName": "formerGroupId"
      },
      {
        "kind": "Variable",
        "name": "groupId",
        "variableName": "groupId"
      },
      {
        "kind": "Variable",
        "name": "itemId",
        "variableName": "itemId"
      },
      {
        "kind": "Variable",
        "name": "itemType",
        "variableName": "itemType"
      },
      {
        "kind": "Variable",
        "name": "roles",
        "variableName": "roles"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateOrAddGroupRoles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateOrAddGroupRolesMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateOrAddGroupRolesMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "17f63dfbd5a40a04af9402659e770cba",
    "id": null,
    "metadata": {},
    "name": "UpdateOrAddGroupRolesMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateOrAddGroupRolesMutation(\n  $groupId: ID!\n  $formerGroupId: ID\n  $itemType: String!\n  $itemId: String!\n  $roles: [String!]!\n) {\n  updateOrAddGroupRoles(groupId: $groupId, formerGroupId: $formerGroupId, itemType: $itemType, itemId: $itemId, roles: $roles) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '36921b2ac8e69f80f6cca82c96f26253';

module.exports = node;
