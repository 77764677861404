import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateRawDocumentMutation($rawDocumentId: ID!, $filename: String!) {
    updateRawDocument(rawDocumentId: $rawDocumentId,filename: $filename) {
      ok
      error
    }
  }
`;

export default ({
  rawDocumentId,
  filename
}, done) => {
  const variables = {
    rawDocumentId,
    filename
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateRawDocument }) => basicMutationOnCompleted(updateRawDocument, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
