import React from 'react';
import VersionsTree from '../../../components/VersionTree/VersionsTree';
import { associateVersions } from '../../../lib/versions';

const OfferList = ({
  style,
  userCanEdit,
  offers,
  setRefetch
}) => {
  return (
    <div style={style}>
      <VersionsTree
        items={associateVersions(offers, 'version.itemRootId')}
        editLinkPattern="/offre/:itemId/edition"
        suppressionLabel="Voulez-vous vraiment supprimer cette offre ?"
        archiveLabel="Voulez-vous vraiment archiver cette offre ?"
        userCanEdit={userCanEdit}
        nameField="offerName"
        setRefetch={setRefetch}
      />
    </div>
  );
};

OfferList.defaultProps = {
  style: {
    marginTop: 16
  }
};

export default OfferList;
