import { CalendarOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import { equals, isNil, prop } from 'ramda';
import React from 'react';

const renderDate = (date, tooltip, icon) => {
  if (isNil(date) || equals('0', date)) {
    return;
  }

  return (
    <span>
      <Tooltip
        title={tooltip}
        color="#0197DC"
      >
        {icon || <CalendarOutlined className="!text-flexidocs-turquoise" />}
      </Tooltip>
      &nbsp;{moment(date).format('DD/MM/YYYY HH:mm:ss')}
    </span>
  );
};

export const RenderLastUpdate = (renderDate) => (
  <Table.Column
    title="Dernière mise à jour"
    dataIndex="dates"
    width={400}
    render={(dates) => dates && renderDate(prop('lastUpdate', dates))}
  />
);

export {
  renderDate
};
