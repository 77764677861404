import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import {
  populateChildren
} from '../toolbox';

export const QGetHistoriesFramesQuery = graphql`
  query QGetHistoriesFramesQuery($offerId: ID!, $bundleTag: String!, $version: String!, $nbOfFrame: String) {
    getHistoryFrames(offerId: $offerId, bundleTag: $bundleTag, version: $version, nbOfFrame: $nbOfFrame) {
      createdAt
      externalId
      metadata
      id
    }
  }
`;

const QGetHistoriesFrames = ({
  children,
  childDataProp,
  args
}) => {
  return (
    <Query
      query={QGetHistoriesFramesQuery}
      caching={false}
      args={args}
    >
      {populateChildren()(children, childDataProp)}
    </Query>
  );
};

export default QGetHistoriesFrames;
