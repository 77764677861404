import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const Loading = ({
  fullPage,
  children,
  spinning = true,
  style = {}
}) => fullPage
  ? (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 60 }} spin/>}
      style={{
        width: '100%',
        height: '100vh',
        lineHeight: '100vh'
      }}
    />
  )
  : (
    <Spin
      style={{
        width: '100%',
        ...style
      }}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>}
      spinning={spinning}
    >
      {children}
    </Spin>
  );

Loading.propTypes = {
  fullPage: PropTypes.bool,
  spinning: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ])
};

export default Loading;
