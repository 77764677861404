/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteFrameMutationVariables = {|
  ids: $ReadOnlyArray<?string>
|};
export type DeleteFrameMutationResponse = {|
  +deleteFrame: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type DeleteFrameMutation = {|
  variables: DeleteFrameMutationVariables,
  response: DeleteFrameMutationResponse,
|};
*/


/*
mutation DeleteFrameMutation(
  $ids: [ID]!
) {
  deleteFrame(ids: $ids) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "deleteFrame",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteFrameMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteFrameMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "41255a229b9f794a079e333791ca0c08",
    "id": null,
    "metadata": {},
    "name": "DeleteFrameMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteFrameMutation(\n  $ids: [ID]!\n) {\n  deleteFrame(ids: $ids) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '08fa06bc67a03c10fca0131aee609231';

module.exports = node;
