import React from 'react';
import { Result } from 'antd';
import { Link } from 'react-router-dom';
import AccountLayout from '../../../layout/account/AccountLayout';

const JoinTeam = () => {
  return (
    <AccountLayout>
      <Result
        title="Vous avez reçu une invitation"
        subTitle="Pour pouvoir l'accepter, vous devez d'abord vous inscrire."
        extra={<Link to="/account/signup">{'M\'inscrire'}</Link>}
      />
    </AccountLayout>
  );
};

export default JoinTeam;
