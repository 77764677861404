import React from 'react';
import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Section } from '../../../components';

const TeamApiKey = ({ apiKey }) => {
  const copyKeyToClipboard = () => navigator.clipboard.writeText(apiKey);

  return (
    <Section
      title="Clé d'API de mon équipe"
      actions={(
        <Button
          onClick={copyKeyToClipboard}
          icon={<CopyOutlined />}
          size="large"
        >
          Copier la clé
        </Button>
      )}
    >
      <div className="break-all p-4 rounded" style={{ color: '#8A98A5', backgroundColor: '#f5f5f5' }}>{apiKey}</div>

    </Section>
  );
};

export default TeamApiKey;
