import { QEnvs } from '../_graphql/queries';
import React from 'react';

const withEnvs = Component => {
  return function WithEnv (props) {
    return (
      <QEnvs>
        {({ envs }) => {
          const envsToObject = JSON.parse(envs);
          return <Component {...props} env={envsToObject}/>;
        }}
      </QEnvs>
    );
  };
};

export default withEnvs;
