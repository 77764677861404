import { graphql } from 'react-relay';

export const QCacheQuery = graphql`
  query QCacheQuery($key: String!) {
    cache(key: $key) {
      key
      date
    }
  }
`;
