/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteWidgetMutationVariables = {|
  widgetId: string
|};
export type DeleteWidgetMutationResponse = {|
  +deleteWidget: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type DeleteWidgetMutation = {|
  variables: DeleteWidgetMutationVariables,
  response: DeleteWidgetMutationResponse,
|};
*/


/*
mutation DeleteWidgetMutation(
  $widgetId: ID!
) {
  deleteWidget(widgetId: $widgetId) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "widgetId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "widgetId",
        "variableName": "widgetId"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "deleteWidget",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteWidgetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteWidgetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1d7394165afc8c2b249fe394750176ab",
    "id": null,
    "metadata": {},
    "name": "DeleteWidgetMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteWidgetMutation(\n  $widgetId: ID!\n) {\n  deleteWidget(widgetId: $widgetId) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '27bcac88b042e4e63beac33e6b6039be';

module.exports = node;
