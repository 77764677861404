import React from 'react';
import { Section, Title } from '../../components';
import WidgetsTable from '../docs/components/widget/WidgetsTable';

const Widgets = () => {
  return (
    <>
      <Title title="Les widgets de mon équipe" backTo={'/equipe'} />
      <Section>
        <WidgetsTable isTeamPath/>
      </Section>
    </>
  );
};

export default Widgets;
