const getCurlTemplate = ({
  apiUrl,
  xApiKey,
  offerId,
  teamApiKey,
  version
}) => {
  const getDocumentVariables = (bundleTag) => `
curl --location --request POST '${apiUrl}/offers/${offerId}/_searchmetadata' \n
--header 'Authorization: Bearer ${teamApiKey}' \n
--header 'x-api-key: ${xApiKey}' \n
--header 'Content-Type: application/json' \n
--data-raw '{
    "bundleTag": "${bundleTag}",
    "version": "${version}"
}'`;

  const getDocumentUrl = (bundleTag, variables) => `
curl --location --request POST '${apiUrl}/offers/${offerId}/_generate' \n
--header 'Authorization: Bearer ${teamApiKey}' \n
--header 'x-api-key: ${xApiKey}' \n
--header 'Content-Type: application/json' \n
--data-raw '{
    "bundleTag": "${bundleTag}",
    "version": "${version}",
    "externalId": "",
    "metadata": ${variables}
}'`;

  const getPdf = (idPdfFile, teamId) => `
curl --location --request GET '${apiUrl}/downloadFile/document-${idPdfFile}.pdf?teamId=${teamId}' \n
--header 'Authorization: Bearer ${teamApiKey}' \n
--header 'x-api-key: ${xApiKey}'
`;

  const getRawPdf = (idRawFile, teamId) => `
curl --location --request GET '${apiUrl}/downloadRawFile/${idRawFile}?teamId=${teamId}' \n
--header 'Authorization: Bearer ${teamApiKey}' \n
--header 'x-api-key: ${xApiKey}'
`;

  return {
    getDocumentVariables,
    getDocumentUrl,
    getPdf,
    getRawPdf
  };
};

export default getCurlTemplate;
