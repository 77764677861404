import React from 'react';

const BriefCaseIcon = () => (
  <svg version="1.1" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
    <title>5BA73521-5D58-41CC-8B65-7397A1969FBA</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-522 -556)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(289 175)">
          <g transform="translate(24 379)">
            <g transform="translate(209)">
              <g transform="translate(0 2)">
                <path d="m14.875 3.2h-2.125v-1.0667c0-1.1783-0.95127-2.1333-2.125-2.1333h-4.25c-1.1721 0-2.125 0.955-2.125 2.1333v1.0667h-2.125c-1.1737 0-2.125 0.95667-2.125 2.1333v8.5333c0 1.1783 0.95127 2.1333 2.125 2.1333h12.75c1.1737 0 2.125-0.955 2.125-2.1333v-8.5333c0-1.1767-0.95293-2.1333-2.125-2.1333zm-8.5-1.6h4.25c0.29342 0 0.53125 0.23877 0.53125 0.53333v1.0667h-5.3125v-1.0667c0-0.29467 0.23906-0.53333 0.53125-0.53333zm-4.25 3.2h12.75c0.29219 0 0.53125 0.24 0.53125 0.53333v3.2h-13.812v-3.2c0-0.29333 0.23906-0.53333 0.53125-0.53333zm12.75 9.6h-12.75c-0.29219 0-0.53125-0.24-0.53125-0.53333v-3.7333h4.7812v0.53333c0 0.589 0.4758 1.0667 1.0625 1.0667h2.125c0.5867 0 1.0625-0.47767 1.0625-1.0667v-0.53333h4.7812v3.7333c0 0.29333-0.23906 0.53333-0.53125 0.53333z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BriefCaseIcon;
