/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ColorInput = {|
  name?: ?string,
  value?: ?string,
|};
export type UpdateColorMutationVariables = {|
  colorId: string,
  color: ColorInput,
|};
export type UpdateColorMutationResponse = {|
  +updateColor: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateColorMutation = {|
  variables: UpdateColorMutationVariables,
  response: UpdateColorMutationResponse,
|};
*/


/*
mutation UpdateColorMutation(
  $colorId: ID!
  $color: ColorInput!
) {
  updateColor(colorId: $colorId, color: $color) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "color"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "colorId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "color",
        "variableName": "color"
      },
      {
        "kind": "Variable",
        "name": "colorId",
        "variableName": "colorId"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateColor",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateColorMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateColorMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d8a76a48dfd6348e2b5d105d5696e929",
    "id": null,
    "metadata": {},
    "name": "UpdateColorMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateColorMutation(\n  $colorId: ID!\n  $color: ColorInput!\n) {\n  updateColor(colorId: $colorId, color: $color) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9bba9e7c5b17388b89716326346cb86c';

module.exports = node;
