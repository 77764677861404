/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateOfferFromExistingMutationVariables = {|
  offerName: string,
  fromOfferId: string,
  isCopyOffer?: ?boolean,
|};
export type CreateOfferFromExistingMutationResponse = {|
  +createOfferFromExisting: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type CreateOfferFromExistingMutation = {|
  variables: CreateOfferFromExistingMutationVariables,
  response: CreateOfferFromExistingMutationResponse,
|};
*/


/*
mutation CreateOfferFromExistingMutation(
  $offerName: String!
  $fromOfferId: ID!
  $isCopyOffer: Boolean
) {
  createOfferFromExisting(offerName: $offerName, fromOfferId: $fromOfferId, isCopyOffer: $isCopyOffer) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fromOfferId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCopyOffer"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offerName"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "fromOfferId",
        "variableName": "fromOfferId"
      },
      {
        "kind": "Variable",
        "name": "isCopyOffer",
        "variableName": "isCopyOffer"
      },
      {
        "kind": "Variable",
        "name": "offerName",
        "variableName": "offerName"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "createOfferFromExisting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateOfferFromExistingMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateOfferFromExistingMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "90fa4861db9e0876a9c29f8d7a54985b",
    "id": null,
    "metadata": {},
    "name": "CreateOfferFromExistingMutation",
    "operationKind": "mutation",
    "text": "mutation CreateOfferFromExistingMutation(\n  $offerName: String!\n  $fromOfferId: ID!\n  $isCopyOffer: Boolean\n) {\n  createOfferFromExisting(offerName: $offerName, fromOfferId: $fromOfferId, isCopyOffer: $isCopyOffer) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ace6167012925b86fcb04c7ada180c3f';

module.exports = node;
