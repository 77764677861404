import React from 'react';

const CheckCircleIcon = () => (
  <svg version="1.1" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
    <title>C84CCAF3-4A35-440A-AC86-E4B04BB4D09A</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-522 -356)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(289 175)">
          <g transform="translate(20 180)">
            <g transform="translate(213)">
              <g transform="translate(0 1)">
                <path d="m11.123 5.8105-3.6855 3.6889-1.5904-1.5639c-0.31128-0.31128-0.81547-0.31128-1.1269 0-0.31145 0.31128-0.31128 0.81547 0 1.1269l2.125 2.125c0.18395 0.15805 0.38648 0.23441 0.59234 0.23441 0.20586 0 0.40773-0.077828 0.56346-0.23345l4.25-4.25c0.31128-0.31128 0.31128-0.81547 0-1.1269-0.31128-0.31145-0.8158-0.30975-1.1279-9.629e-4zm-2.623-5.8105c-4.6949 0-8.5 3.8051-8.5 8.5 0 4.6949 3.8051 8.5 8.5 8.5 4.6949 0 8.5-3.8051 8.5-8.5 0-4.6949-3.8051-8.5-8.5-8.5zm0 15.406c-3.8084 0-6.9062-3.0982-6.9062-6.9062 0-3.8081 3.0979-6.9062 6.9062-6.9062 3.8084 0 6.9062 3.0982 6.9062 6.9062 0 3.8081-3.0979 6.9062-6.9062 6.9062z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CheckCircleIcon;
