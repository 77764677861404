import React from 'react';
import * as R from 'ramda';
import LinkButton from './LinkButton';
import classNames from 'classnames';
import { Button } from 'antd';

const Actions = ({
  actions = [],
  buttons,
  title,
  listEmpty,
  alignButtonEndRight = false,
  icon
}) => (
  <div className={classNames('flex justify-end w-full items-end', { 'justify-between': title }, { 'items-baseline': listEmpty })}>
    {title}
    <div className={classNames({ 'inline-flex items-center space-x-4': alignButtonEndRight })}>
      {actions.map((action, index) => (
        <div key={index} className={classNames({ 'pt-4': listEmpty })}>
          {R.prop('to', action) ? (
            <LinkButton
              to={R.prop('to', action)}
              type="primary"
              size="large"
              disabled={R.propOr(false, 'disabled', action)}
              icon={icon}
            >
              {R.prop('label', action)}
            </LinkButton>
          ) : (
            <Button
              loading={R.prop('loading', action)}
              disabled={R.prop('disabled', action)}
              onClick={R.prop('onClick', action)}
              size="large"
              type="primary"
              icon={R.prop('icon', action)}
              className={R.prop('className', action)}
            >
              {R.prop('label', action)}
            </Button>
          )}
        </div>
      ))}
      {buttons && (
        <div className="flex items-center gap-4">
          {buttons}
        </div>
      )}
    </div>
  </div>
);

export default Actions;
