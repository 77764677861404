import React from 'react';
import * as R from 'ramda';

const getColors = type => R.propOr({
  bg: 'bg-lightblue',
  border: 'border-blue'
}, type, {
  danger: {
    bg: 'bg-red-100',
    border: 'border-red-300'
  }
});

const Alert = ({ type, message }) => {
  const colors = getColors(type);

  return (
    <div className={`${R.prop('bg', colors)} ${R.prop('border', colors)} border rounded-xl my-6 py-6 px-8`}>
      {message}
    </div>
  );
};

export default Alert;
