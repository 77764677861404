import {
  commitMutation,
  graphql
} from 'react-relay';
import environment from '../../index';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation CreateGroupMutation($name: String!) {
    createGroup(name: $name) {
      ok
      error
      id
    }
  }
`;

export default ({ name }, done) => {
  const variables = {
    name
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ createGroup }) => {
        const { ok, error, id } = createGroup;
        done(ok, error, id);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
