/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentScopeEnum = "global" | "offer" | "%future added value";
export type GlobalVariableTypeEnum = "boolean" | "number" | "text" | "%future added value";
export type QGlobalVariablesQueryVariables = {|
  scope: DocumentScopeEnum,
  offerId?: ?string,
|};
export type QGlobalVariablesQueryResponse = {|
  +globalVariables: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +type: ?GlobalVariableTypeEnum,
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
    +isUsed: ?boolean,
  |}>
|};
export type QGlobalVariablesQuery = {|
  variables: QGlobalVariablesQueryVariables,
  response: QGlobalVariablesQueryResponse,
|};
*/


/*
query QGlobalVariablesQuery(
  $scope: DocumentScopeEnum!
  $offerId: ID
) {
  globalVariables(scope: $scope, offerId: $offerId) {
    id
    name
    type
    dates {
      creation
      lastUpdate
    }
    isUsed
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "offerId",
        "variableName": "offerId"
      },
      {
        "kind": "Variable",
        "name": "scope",
        "variableName": "scope"
      }
    ],
    "concreteType": "GlobalVariable",
    "kind": "LinkedField",
    "name": "globalVariables",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Dates",
        "kind": "LinkedField",
        "name": "dates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isUsed",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QGlobalVariablesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QGlobalVariablesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1eb876bb80b2f73c33236ae71a3d194c",
    "id": null,
    "metadata": {},
    "name": "QGlobalVariablesQuery",
    "operationKind": "query",
    "text": "query QGlobalVariablesQuery(\n  $scope: DocumentScopeEnum!\n  $offerId: ID\n) {\n  globalVariables(scope: $scope, offerId: $offerId) {\n    id\n    name\n    type\n    dates {\n      creation\n      lastUpdate\n    }\n    isUsed\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c242da9d13277a999dca9a09ffd6b088';

module.exports = node;
