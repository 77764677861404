import React from 'react';
import { useParams } from 'react-router-dom';
import QStyle from '../../../_graphql/queries/documents/QStyle';
import StyleFormPreview from './components/StyleFormPreview';

const EditStyle = () => {
  const { styleId } = useParams();
  return (
    <QStyle args={{ styleId }}>
      {style =>
        <StyleFormPreview styleId={styleId} style={style} />
      }
    </QStyle>
  );
};

export default EditStyle;
