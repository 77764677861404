import '../../less/docs.less';
import React from 'react';

import {
  withRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import MyFrames from './MyFrames';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { home, renderWithBreadcrumb } from '../helpers/breadCrumb';
import { prop } from 'ramda';
import CreateFrame from './CreateFrame';
import Frame from './Frame';

const frames = ['/trames', 'Mes trames'];
const createFrame = ['/trames/creer', 'Création d\'une trame'];
const frame = ['/trames/:frameId/informations', 'Informations de trame'];

const routes = [
  ['/trames/:frameId/informations', [home, frames, frame]],
  ['/trames/creer', [home, frames, createFrame]],
  ['/trames', [home, frames]]
];

const Frames = ({
  match
}) => {
  const breadcrumb = useBreadcrumb(routes, home);
  const matchUrl = prop('url', match);
  return (
    <Switch>
      <Route exact path={matchUrl} render={renderWithBreadcrumb(MyFrames, breadcrumb)}/>
      <Route exact path={`${matchUrl}/creer`} render={renderWithBreadcrumb(CreateFrame, breadcrumb)}/>
      <Route exact path={`${matchUrl}/:frameId/informations`} render={renderWithBreadcrumb(Frame, breadcrumb)}/>
      <Redirect to={matchUrl}/>
    </Switch>
  );
};

export default withRouter(Frames);
