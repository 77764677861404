import { graphql } from 'relay-runtime';
import useFetchQuery from './useFetchQuery';

const query = graphql`
  query useTeamsQuery($userId: ID) {
    teams(userId: $userId) {
      id
      members {
        id
        user {
          id
          email
        }
        role
        active
        dates {
          join
          lastConnection
        }
      }
      apiKey
      name
      dates {
        creation
        lastUpdate
      }
    }
  }
`;

const useTeams = (userId) => {
  return useFetchQuery({
    query,
    dataProp: 'teams',
    args: { userId },
    defaultData: []
  });
};

export default useTeams;
