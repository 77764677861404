/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveAllByTeamIdMutationVariables = {||};
export type RemoveAllByTeamIdMutationResponse = {|
  +removeAllByTeamId: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type RemoveAllByTeamIdMutation = {|
  variables: RemoveAllByTeamIdMutationVariables,
  response: RemoveAllByTeamIdMutationResponse,
|};
*/


/*
mutation RemoveAllByTeamIdMutation {
  removeAllByTeamId {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "removeAllByTeamId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveAllByTeamIdMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RemoveAllByTeamIdMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ea385aa0243283193834c2b4a9f1cb05",
    "id": null,
    "metadata": {},
    "name": "RemoveAllByTeamIdMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveAllByTeamIdMutation {\n  removeAllByTeamId {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd2e54442b15e6209a9bbfc165e8ac81c';

module.exports = node;
