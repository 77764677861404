/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddCommentMessageMutationVariables = {|
  commentId: string,
  message: string,
|};
export type AddCommentMessageMutationResponse = {|
  +addCommentMessage: ?{|
    +ok: boolean,
    +error: ?string,
    +message: ?{|
      +text: ?string,
      +userId: ?string,
      +userName: ?string,
      +when: ?string,
    |},
  |}
|};
export type AddCommentMessageMutation = {|
  variables: AddCommentMessageMutationVariables,
  response: AddCommentMessageMutationResponse,
|};
*/


/*
mutation AddCommentMessageMutation(
  $commentId: ID!
  $message: String!
) {
  addCommentMessage(commentId: $commentId, message: $message) {
    ok
    error
    message {
      text
      userId
      userName
      when
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "commentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "message"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "commentId",
        "variableName": "commentId"
      },
      {
        "kind": "Variable",
        "name": "message",
        "variableName": "message"
      }
    ],
    "concreteType": "CommentMessageMutationResponse",
    "kind": "LinkedField",
    "name": "addCommentMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CommentMessage",
        "kind": "LinkedField",
        "name": "message",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "when",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCommentMessageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddCommentMessageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "957cd0e5b8d5792eca6b551d781a1966",
    "id": null,
    "metadata": {},
    "name": "AddCommentMessageMutation",
    "operationKind": "mutation",
    "text": "mutation AddCommentMessageMutation(\n  $commentId: ID!\n  $message: String!\n) {\n  addCommentMessage(commentId: $commentId, message: $message) {\n    ok\n    error\n    message {\n      text\n      userId\n      userName\n      when\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0f59d14b63ee0b68f5b6470ba7e4f769';

module.exports = node;
