import { assoc, prop } from 'ramda';
import { useState } from 'react';
import ResetPasswordMutation from '../_graphql/mutations/ResetPasswordMutation';
import ResetPasswordRequestMutation from '../_graphql/mutations/ResetPasswordRequestMutation';

const usePassword = (token) => {
  const [state, setState] = useState({
    email: '',
    password: '',
    loading: false,
    error: false,
    sent: false
  });

  const callback = (ok, error) => {
    setState(assoc('loading', false));
    if (ok && !error) {
      setState(assoc('sent', true));
    } else {
      setState(assoc('error', true));
    }
  };

  const onRequestPassword = () => {
    setState(assoc('loading', true));
    ResetPasswordRequestMutation(prop('email', state), callback);
  };

  const onResetPassword = () => {
    setState(assoc('loading', true));
    ResetPasswordMutation({ token, password: prop('password', state) }, callback);
  };

  return {
    ...state,
    setState,
    onRequestPassword,
    onResetPassword
  };
};

export default usePassword;
