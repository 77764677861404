/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CommentStatusEnum = "accepted" | "pending" | "rejected" | "%future added value";
export type UpdateCommentStatusMutationVariables = {|
  commentId: string,
  status: CommentStatusEnum,
|};
export type UpdateCommentStatusMutationResponse = {|
  +updateCommentStatus: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateCommentStatusMutation = {|
  variables: UpdateCommentStatusMutationVariables,
  response: UpdateCommentStatusMutationResponse,
|};
*/


/*
mutation UpdateCommentStatusMutation(
  $commentId: ID!
  $status: CommentStatusEnum!
) {
  updateCommentStatus(commentId: $commentId, status: $status) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "commentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "commentId",
        "variableName": "commentId"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateCommentStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCommentStatusMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCommentStatusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "13e83b0de3260c35acff06466abce136",
    "id": null,
    "metadata": {},
    "name": "UpdateCommentStatusMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCommentStatusMutation(\n  $commentId: ID!\n  $status: CommentStatusEnum!\n) {\n  updateCommentStatus(commentId: $commentId, status: $status) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '992ffc6e4911895a3aa8dd011005d9dd';

module.exports = node;
