import React from 'react';
import * as R from 'ramda';
import { Button } from 'antd';
import { Section } from '../components';
import ReviewButtons from './ReviewButtons';
import useReview from '../hooks/useReview';
import Alert from './Alert';
import withUser from '../withUser';

const Review = ({
  item,
  version,
  reviewComments,
  userCanValidate
}) => {
  const {
    loading,
    comments,
    updateState,
    isValid,
    updateComments,
    isReview,
    isDraft,
    reviewIsRejected
  } = useReview(version, reviewComments, item);

  if (!isReview && (!reviewIsRejected || !isDraft)) {
    return null;
  }

  if (reviewIsRejected && isDraft) {
    return (
      <Alert
        type="danger"
        message={(
          <div>
            La publication de cette offre a été rejetée pour les raisons suivantes :
            <br />
            <br />
            <span className="font-bold">{R.propOr('', 'rejectionReason', reviewComments)}</span>
          </div>
        )}
      />
    );
  }

  return (
    <Section title="ma revue">
      {userCanValidate && (
        <div className="flex justify-end gap-3 absolute top-6 right-8">
          <ReviewButtons item={item} />
        </div>
      )}

      <label className="mb-2 block">Commentaire de revue</label>
      <textarea
        value={comments}
        onChange={e => updateState('comments', e.target.value)}
        className="w-full h-100px p-2"
        placeholder="Commentaires..."
      />

      <Button
        loading={loading.updateComment}
        className="block mt-4"
        disabled={!isValid}
        onClick={updateComments}
      >
        Mettre à jour les commentaires
      </Button>
    </Section>
  );
};

const ReviewWrapper = ({ item, userCanValidate }) => {
  if (!item) {
    return null;
  }

  const version = R.prop('version', item);
  const reviewComments = R.propOr({}, 'reviewComments', version);

  return (
    <Review
      item={item}
      version={version}
      reviewComments={reviewComments}
      userCanValidate={userCanValidate}
    />
  );
};

export default withUser(ReviewWrapper);
