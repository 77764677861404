import React from 'react';

const style = {
  transform: 'rotate(90deg)',
  width: '7px'
};

const ChevronBottom = () => {
  return (
    <img src={'/images/chevron-left.svg'} style={style} />
  );
};

export default ChevronBottom;
