import { Select } from 'antd';
import { map, prop } from 'ramda';
import { fonts as fontsFlexiget } from '@fasstech/flexiget';
import React from 'react';

export const fontOptions = map(font => (
  <Select.Option
    key={prop('value', font)}
    value={prop('value', font)}
  >
    {prop('name', font)}
  </Select.Option>
)
)(fontsFlexiget);
