import * as R from 'ramda';
import { useState } from 'react';

const useComments = ({ handlers, itemRootId }) => {
  const [state, setState] = useState({
    addMessageLoading: false,
    updateStatusLoading: false,
    deleteCommentLoading: false
  });

  const {
    onCreateComment,
    onAddMessage,
    onUpdateStatus,
    onDeleteComment
  } = handlers;

  const addMessage = (commentId, message) => {
    setState(R.assoc('addMessageLoading', true));

    onAddMessage({ commentId, message }, () => {
      setState(R.assoc('addMessageLoading', false));
    });
  };

  const createComment = (itemPath, itemKey, onCreate) => {
    onCreateComment({
      itemPath,
      itemKey,
      itemRootId
    }, (comment) => {
      onCreate(comment);
    });
  };

  const updateStatus = (commentId, status) => {
    setState(R.assoc('updateStatusLoading', true));

    onUpdateStatus({ commentId, status }, () => {
      setState(R.assoc('updateStatusLoading', false));
    });
  };

  const deleteComment = (commentId) => {
    setState(R.assoc('deleteCommentLoading', true));

    onDeleteComment({ commentId }, () => {
      setState(R.assoc('deleteCommentLoading', false));
    });
  };

  return {
    ...state,
    addMessage,
    updateStatus,
    createComment,
    deleteComment
  };
};

export default useComments;
