import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const FullscreenLoading = () => {
  return (
    <>
      <div className="fixed top-0 left-0 z-50 w-full h-full bg-white opacity-60" />
      <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center">
        <Spin size="large" indicator={<LoadingOutlined spin />} />
      </div>
    </>
  );
};

export default FullscreenLoading;
