import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';

import {
  populateChildren
} from '../toolbox';

const QDocumentsByRootOfferIdQuery = graphql`
  query QDocumentsByRootOfferIdQuery($rootOfferId: ID) {
    documentsByRootOfferId(rootOfferId: $rootOfferId) {
      id
      rootDocumentId
      name
      dates {
        creation
        lastUpdate
      }
      offerVersion {
        major
        minor
      }
    }
  }
`;

const QDocumentsByRootOfferId = ({
  args,
  children,
  childDataProp
}) => (
  <Query
    query={QDocumentsByRootOfferIdQuery}
    caching={false}
    args={args}
  >
    {populateChildren('documentsByRootOfferId')(children, childDataProp)}
  </Query>
);

export default QDocumentsByRootOfferId;
