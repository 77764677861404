import { QDefaultFontQuery } from '../_graphql/queries/documents/QDefaultFont';
import useFetchQuery from './useFetchQuery';

const useDefaultFont = ({ teamId }) => {
  const { defaultFont, isLoading } = useFetchQuery({
    query: QDefaultFontQuery,
    args: { teamId },
    dataProp: 'defaultFont',
    defaultData: {}
  });

  return {
    defaultFont,
    isLoading
  };
};
export default useDefaultFont;
