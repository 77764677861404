import { Select, Switch } from 'antd';
import React from 'react';
import ChevronBottom from './ChevronBottom';

const SwitchByExisting = ({ checked, onChange, onClick, label, fromValue, onSelect, placeholder, options }) => {
  return (
    <div className="mt-4">
      <Switch
        checked={checked}
        onChange={(checked) => onChange(checked)}
      />
      <span
        className="inline-block align-middle ml-2.5 cursor-pointer"
        onClick={onClick}
      >
        {label}
      </span>
      {checked && (
        <div className="mt-2">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            value={fromValue}
            onSelect={onSelect}
            placeholder={placeholder}
            className="select-by-existing mt-5 w-1/2"
            suffixIcon={<ChevronBottom />}
          >
            {options}
          </Select>
        </div>
      )}
    </div>
  );
};

export default SwitchByExisting;
