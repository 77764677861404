import React from 'react';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import QDocument from '../../_graphql/queries/documents/QDocument';
import DocumentForm from './components/document/DocumentForm';
import RawDocumentForm from './components/rawDocument/RawDocumentForm';
import { commentsMode } from './components/helpers/elements';
import { SCOPE_OFFER } from '../../_CONST';

const EditOfferDocument = ({ breadcrumb }) => {
  const { offerId, documentId } = useParams();
  return (
    <QDocument args={{ documentId }}>
      {data => {
        const { document = {}, rawDocument = {} } = data;
        if (R.isNil(document) && R.isNil(rawDocument)) return null;

        if (rawDocument) {
          return (
            <RawDocumentForm
              rawDocumentId={documentId}
              rawDocument={rawDocument}
              offerId={offerId}
            />
          );
        }
        return (
          <DocumentForm
            documentId={documentId}
            document={document}
            scope={SCOPE_OFFER}
            offerId={offerId}
            commentsMode={commentsMode(document)}
            breadcrumb={breadcrumb}
            cacheKey={['offerViewCache', `widgets-${offerId}`]}
          />
        );
      }}
    </QDocument>
  );
};

export default EditOfferDocument;
