import 'react-select/dist/react-select.css';
import 'react-datepicker/dist/react-datepicker.css';
import './less/app.less';
import './less/custom.less';
import './less/teams.less';

import React from 'react';
import ReactDOM from 'react-dom';

import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import Tokens from './lib/Tokens';
import reducers from './reducers';
import Routes from './Routes';
import { addLocaleData } from 'react-intl';
import { CookiesProvider } from 'react-cookie';
import Moment from 'moment';
import { ThemeProvider } from 'styled-components';
import Environment from './_graphql/Environment';
import theme from './theme';
import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';
import frIntl from 'react-intl/locale-data/fr';

import fr from './intl/fr';

import { logger } from 'redux-logger';
import { RelayEnvironmentProvider } from 'react-relay';

let environment;

addLocaleData([...frIntl]);

// eslint-disable-next-line
Tokens.setKey(API_USER_ID, API_KEY);

const initialState = {
  intl: {
    defaultLocale: 'fr',
    locale: 'fr',
    messages: fr.messages
  }
};

const store = createStore(
  reducers,
  initialState,
  applyMiddleware(thunkMiddleware, logger)
);

const getDocumentRoot = () => {
  const element = document.createElement('div');
  document.body.appendChild(element);
  return element;
};

Moment.locale('fr');

(async () => {
  environment = await Environment();
  ReactDOM.render(
    <RelayEnvironmentProvider environment={environment}>
      <ConfigProvider locale={frFR}>
        <ThemeProvider theme={theme}>
          <CookiesProvider>
            <Routes store={store}/>
          </CookiesProvider>
        </ThemeProvider>
      </ConfigProvider>
    </RelayEnvironmentProvider>,
    getDocumentRoot()
  );
})();

export {
  environment
};
