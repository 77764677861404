/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateFrameMutationVariables = {|
  name: string,
  offerId: string,
  offerName: string,
  version: string,
  metadata: string,
  bundleTag: string,
  description?: ?string,
|};
export type CreateFrameMutationResponse = {|
  +createFrame: ?{|
    +ok: boolean,
    +error: ?string,
    +frameId: ?string,
  |}
|};
export type CreateFrameMutation = {|
  variables: CreateFrameMutationVariables,
  response: CreateFrameMutationResponse,
|};
*/


/*
mutation CreateFrameMutation(
  $name: String!
  $offerId: ID!
  $offerName: String!
  $version: String!
  $metadata: String!
  $bundleTag: String!
  $description: String
) {
  createFrame(name: $name, offerId: $offerId, offerName: $offerName, version: $version, metadata: $metadata, bundleTag: $bundleTag, description: $description) {
    ok
    error
    frameId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bundleTag"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metadata"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offerId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offerName"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "version"
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bundleTag",
        "variableName": "bundleTag"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "metadata",
        "variableName": "metadata"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "offerId",
        "variableName": "offerId"
      },
      {
        "kind": "Variable",
        "name": "offerName",
        "variableName": "offerName"
      },
      {
        "kind": "Variable",
        "name": "version",
        "variableName": "version"
      }
    ],
    "concreteType": "CreateFrameMutationResponse",
    "kind": "LinkedField",
    "name": "createFrame",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "frameId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateFrameMutation",
    "selections": (v7/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateFrameMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "0d8ced287c8727d0ce78956ae7b0bf53",
    "id": null,
    "metadata": {},
    "name": "CreateFrameMutation",
    "operationKind": "mutation",
    "text": "mutation CreateFrameMutation(\n  $name: String!\n  $offerId: ID!\n  $offerName: String!\n  $version: String!\n  $metadata: String!\n  $bundleTag: String!\n  $description: String\n) {\n  createFrame(name: $name, offerId: $offerId, offerName: $offerName, version: $version, metadata: $metadata, bundleTag: $bundleTag, description: $description) {\n    ok\n    error\n    frameId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fb93b6046ed41021c26a931990c75dea';

module.exports = node;
