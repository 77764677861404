/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QDocumentRightsQueryVariables = {|
  documentId: string
|};
export type QDocumentRightsQueryResponse = {|
  +documentRights: ?{|
    +userIsDocumentValidator: ?boolean,
    +userIsDocumentEditor: ?boolean,
    +userIsDocumentTechEditor: ?boolean,
    +userIsDocumentReader: ?boolean,
  |}
|};
export type QDocumentRightsQuery = {|
  variables: QDocumentRightsQueryVariables,
  response: QDocumentRightsQueryResponse,
|};
*/


/*
query QDocumentRightsQuery(
  $documentId: ID!
) {
  documentRights(documentId: $documentId) {
    userIsDocumentValidator
    userIsDocumentEditor
    userIsDocumentTechEditor
    userIsDocumentReader
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "documentId"
      }
    ],
    "concreteType": "DocumentRights",
    "kind": "LinkedField",
    "name": "documentRights",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userIsDocumentValidator",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userIsDocumentEditor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userIsDocumentTechEditor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userIsDocumentReader",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QDocumentRightsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QDocumentRightsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8ced9f90827e13de8a50fb53a61a1252",
    "id": null,
    "metadata": {},
    "name": "QDocumentRightsQuery",
    "operationKind": "query",
    "text": "query QDocumentRightsQuery(\n  $documentId: ID!\n) {\n  documentRights(documentId: $documentId) {\n    userIsDocumentValidator\n    userIsDocumentEditor\n    userIsDocumentTechEditor\n    userIsDocumentReader\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f38de4020c42d566101950ebeac48c5';

module.exports = node;
