import React from 'react';
import * as R from 'ramda';

const Select = props => {
  const { className = '', children } = props;

  return (
    <select
      {...R.omit(['className', 'children'], props)}
      className={`p-2 text-lg rounded border-flexibranche-lightblue ${className}`}
    >
      {children}
    </select>
  );
};

export default Select;
