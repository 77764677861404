/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteDocumentsMutationVariables = {|
  documentsIds: $ReadOnlyArray<?string>
|};
export type DeleteDocumentsMutationResponse = {|
  +deleteDocuments: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type DeleteDocumentsMutation = {|
  variables: DeleteDocumentsMutationVariables,
  response: DeleteDocumentsMutationResponse,
|};
*/


/*
mutation DeleteDocumentsMutation(
  $documentsIds: [ID]!
) {
  deleteDocuments(documentsIds: $documentsIds) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentsIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentsIds",
        "variableName": "documentsIds"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "deleteDocuments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteDocumentsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteDocumentsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0fa59bc39a42f2f32acf2704eff0cf97",
    "id": null,
    "metadata": {},
    "name": "DeleteDocumentsMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteDocumentsMutation(\n  $documentsIds: [ID]!\n) {\n  deleteDocuments(documentsIds: $documentsIds) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0c0a1f6c9ad16ce16f2f85511e24a128';

module.exports = node;
