import { FontSizeOutlined } from '@ant-design/icons';
import { always, cond, includes, T } from 'ramda';
import React, { useEffect, useState } from 'react';
import Input from '../../Input';
import VariableContainer from './VariableContainer';

const TextVariable = ({
  label,
  value,
  onBlur,
  type,
  onChangeIsDefaultValue,
  isDefaultValue
}) => {
  const [valueInput, setValueInput] = useState(value);
  useEffect(() => setValueInput(value), [value]);
  const inputsize = cond([
    [includes('IBAN'), always(27)],
    [includes('N° de sécurité sociale'), always(15)],
    [includes('Code postal'), always(5)],
    [includes('BIC'), always(11)],
    [includes('Référence Mandat'), always(35)],
    [T, always(undefined)]
  ])(label);
  return (
    <VariableContainer
      label={label}
      icon={<FontSizeOutlined />}
      onChangeIsDefaultValue={onChangeIsDefaultValue}
      isDefaultValue={isDefaultValue}
      value={value}
    >
      <Input
        format={label}
        inputsize={inputsize}
        placeholder="Texte à afficher"
        value={valueInput}
        onBlur={e => onBlur(e.target.value)}
        onChange={(e) => setValueInput(e.target.value)}
        className="w-full"
        type={type}
        allowClear={true}
      />
    </VariableContainer>
  );
};

export default TextVariable;
