/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentRuleActionEnum = "display" | "hide" | "%future added value";
export type DocumentRuleConditionEnum = "isDefined" | "isEqualTo" | "isGreaterThan" | "isLessThan" | "isNotEqualTo" | "isUndefined" | "%future added value";
export type DocumentRuleOperatorEnum = "AND" | "OR" | "%future added value";
export type DocumentScopeEnum = "global" | "offer" | "%future added value";
export type TagInput = {|
  color?: ?string,
  itemType?: ?string,
  itemsIds?: ?$ReadOnlyArray<?string>,
  name?: ?string,
  offerId?: ?string,
  rules?: ?$ReadOnlyArray<?DocumentTagInput>,
  scope?: ?DocumentScopeEnum,
|};
export type DocumentTagInput = {|
  itemId?: ?string,
  rules?: ?$ReadOnlyArray<?DocumentRuleInput>,
|};
export type DocumentRuleInput = {|
  action?: ?DocumentRuleActionEnum,
  conditions?: ?$ReadOnlyArray<?DocumentRuleConditionInput>,
|};
export type DocumentRuleConditionInput = {|
  condition?: ?DocumentRuleConditionEnum,
  operator?: ?DocumentRuleOperatorEnum,
  value?: ?string,
  variable?: ?string,
|};
export type UpdateTagMutationVariables = {|
  tagId: string,
  tag: TagInput,
|};
export type UpdateTagMutationResponse = {|
  +updateTag: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateTagMutation = {|
  variables: UpdateTagMutationVariables,
  response: UpdateTagMutationResponse,
|};
*/


/*
mutation UpdateTagMutation(
  $tagId: ID!
  $tag: TagInput!
) {
  updateTag(tagId: $tagId, tag: $tag) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tag"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tag",
        "variableName": "tag"
      },
      {
        "kind": "Variable",
        "name": "tagId",
        "variableName": "tagId"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateTag",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateTagMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateTagMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c30f7c914710cb8f5e28eb15ba14fa4e",
    "id": null,
    "metadata": {},
    "name": "UpdateTagMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateTagMutation(\n  $tagId: ID!\n  $tag: TagInput!\n) {\n  updateTag(tagId: $tagId, tag: $tag) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b762ea6f4f9cc71302087e97f09ba194';

module.exports = node;
