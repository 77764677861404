export default [{
  label: 'Heure(s)',
  value: 'HOUR'
}, {
  label: 'Jour(s)',
  value: 'DAY'
}, {
  label: 'Semaine(s)',
  value: 'WEEK'
}, {
  label: 'Mois',
  value: 'MONTH'
}];
