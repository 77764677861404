import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../index';
import { populateChildren } from './toolbox';

const QVersionActionsQuery = graphql`
  query QVersionActionsQuery($versionId: ID, $itemId: ID) {
    versionActions(versionId: $versionId, itemId: $itemId) {
      userId
      action
      userName
      date
      metadata {
        comments
        status
        rejectionReason
        major
        minor
        newVersion
      } 
    }
  }
`;

const QVersionActions = ({
  children,
  args,
  childDataProp
}) => (
  <Query
    query={QVersionActionsQuery}
    args={args}
    caching={false}
  >
    {populateChildren('versionActions')(children, childDataProp)}
  </Query>
);

export default QVersionActions;
