import { CloseOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import * as R from 'ramda';
import React from 'react';
import { ITEM_ROLES } from '../../../../../../lib/rights';
import AddGroupRoleMutation from '../../../../../../_graphql/mutations/groups/AddGroupRoleMutation';
import RemoveGroupRoleMutation from '../../../../../../_graphql/mutations/groups/RemoveGroupRoleMutation';
import QGroups from '../../../../../../_graphql/queries/groups/QGroups';
import { useDocumentContext } from '../../../../../../context/Document';
import { errorMessage, successMessage } from '../../../../../../utils/messageMutation';

const useDocumentConfigRole = ({ roleValue, groups, documentId }) => {
  const handleResponse = (ok, error) => {
    if (ok && !error) {
      successMessage('groupe', 'mis à jour');
    } else {
      errorMessage();
    }
  };

  const onAddRole = (groupId) => {
    AddGroupRoleMutation({ groupId, itemType: 'document', itemId: documentId, role: roleValue }, (ok, error) => {
      handleResponse(ok, error);
    });
  };

  const onRemoveRole = (groupId) => {
    RemoveGroupRoleMutation({ groupId, itemType: 'document', itemId: documentId, role: roleValue }, (ok, error) => {
      handleResponse(ok, error);
    });
  };

  const roleGroupsIds = R.compose(
    R.pluck('id'),
    R.filter(
      R.compose(
        R.find(
          R.allPass([
            R.propEq('itemType', 'document'),
            R.propEq('itemId', documentId),
            R.propSatisfies(R.includes(roleValue), 'roles')
          ])
        ),
        R.propOr([], 'roles')
      )
    )
  )(groups);

  return {
    onAddRole,
    onRemoveRole,
    roleGroupsIds
  };
};

const DocumentConfigRole = ({
  groups,
  roleValue,
  roleLabel
}) => {
  const { document } = useDocumentContext();

  const {
    onAddRole,
    onRemoveRole,
    roleGroupsIds
  } = useDocumentConfigRole({
    roleValue,
    groups,
    documentId: R.prop('id', document)
  });

  return (
    <div>
      <div className="font-bold mt-4">{roleLabel}</div>

      <Select
        mode="multiple"
        onSelect={onAddRole}
        onDeselect={onRemoveRole}
        placeholder="Sélectionner un ou plusieurs groupe(s)"
        defaultValue={roleGroupsIds}
        className="w-full select-doc-role"
        removeIcon={<CloseOutlined />}
      >
        {R.map(group => {
          const { id, name } = group;

          return (
            <Select.Option
              key={id}
              value={id}
            >
              {name}
            </Select.Option>
          );
        })(groups)}
      </Select>
    </div>
  );
};

const DocumentConfig = ({
  groups
}) => {
  return (
    <div>

      {R.map(role => {
        const { label, value } = role;

        return (
          <DocumentConfigRole
            key={value}
            groups={groups}
            roleLabel={label}
            roleValue={value}
          />
        );
      })(ITEM_ROLES('document'))}

    </div>
  );
};

const DocumentConfigWrapper = () => {
  return (
    <QGroups>
      {groups => {
        groups = R.defaultTo([], groups);

        return (
          <DocumentConfig
            groups={groups}
          />
        );
      }}
    </QGroups>
  );
};

export default DocumentConfigWrapper;
