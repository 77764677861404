import React from 'react';
import { defs } from './common';

const GlassesIcon = () => (
  <svg version="1.1" viewBox="43 43 22 17.02" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
    <title>7CD74486-E682-494F-BED3-DCCB8402B6F4</title>
    {defs}
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1112 -68)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(895 96)" filter="url(#a)">
          <g transform="translate(232)">
            <g transform="translate(28 15)">
              <path d="m21.93 9.4262-1.7334-6.8984c-0.22442-0.89664-0.82585-1.6696-1.6425-2.1154-0.82127-0.44585-1.7609-0.53616-2.6834-0.2419l-0.58253 0.19447c-0.31991 0.10435-0.49161 0.45078-0.38198 0.76838l0.19099 0.5741c0.10505 0.31779 0.4538 0.48873 0.77352 0.38438l0.50116-0.16601c0.4156-0.13755 0.87856-0.13755 1.2701 0.066403 0.39153 0.20395 0.66847 0.5502 0.77848 0.97708l1.4661 5.8397c-0.84495-0.26087-1.9004-0.47431-3.099-0.47431-1.5279 0-3.2709 0.35099-5.0842 1.3804h-1.4046c-1.8106-1.0289-3.5563-1.3818-5.0842-1.3818-1.1987 0-2.2537 0.21344-3.0987 0.47431l1.4661-5.8397c0.10982-0.42688 0.38657-0.77332 0.78307-0.97708 0.38733-0.20225 0.84953-0.20225 1.2659-0.064886l0.50116 0.16601c0.31991 0.10435 0.66847-0.066403 0.77352-0.38438l0.19099-0.5741c0.1081-0.31741-0.063791-0.66346-0.38848-0.76781l-0.58253-0.19447c-0.88353-0.29407-1.8622-0.20395-2.6789 0.2419-0.8163 0.44585-1.4179 1.2192-1.6425 2.1154l-1.7321 6.8976c-0.047744 0.18972-0.071618 0.38704-0.071618 0.58814v2.6656c0 2.3507 1.9722 4.32 4.4043 4.32h1.4183c2.3015 0 4.2171-1.7455 4.389-3.9994l0.10982-1.4658h1.3656l0.10982 1.4658c0.17075 2.2198 2.0845 3.9994 4.384 3.9994h1.4183c2.4302 0 4.4007-1.9352 4.4007-4.3219v-2.6637c0.0024645-0.20111-0.020454-0.39463-0.070112-0.58814zm-13.549 3.4036c-0.099316 1.3319-1.23 2.3146-2.5669 2.3146h-1.4133c-1.4179 0-2.5688-1.1194-2.5688-2.4998v-1.8069c0.75442-0.3083 1.9577-0.68301 3.3851-0.68301 1.1364 0 2.2491 0.23716 3.3137 0.70198l-0.14974 1.9731zm11.788-0.15178c0 1.3804-1.1505 2.4998-2.5688 2.4998h-1.4183c-1.332 0-2.4592-1.015-2.5593-2.3146l-0.15279-2.0065c1.0696-0.46482 2.1773-0.70198 3.3187-0.70198 1.4279 0 2.6261 0.3747 3.3806 0.68301v1.8403z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

);

export default GlassesIcon;
