import assert from 'assert';
import { isNil, pathOr, isEmpty } from 'ramda';
import { HttpUnauthorized, HttpHone } from './httpError';
const fetch = require('node-fetch');

export const request = async (body, refreshToken = false) => {
  const response = await fetch('/files/upload', {
    method: 'POST',
    body
  });

  if (response.status === 200) return response;

  if (response.status === 401) {
    assert(!refreshToken, 'can not refresh access token');
    throw new HttpUnauthorized('Accès non autorisé');
  }

  // Aucun document
  if (response.status === 410) {
    throw new HttpHone('Aucune resource disponible');
  }

  return new Error('request failed');
};

export const upload = async (body) => {
  const response = await request(body);
  if (response.status === 200) {
    const json = await response.json();
    const { errors } = json;

    if (isNil(errors) || isEmpty(errors)) return json;
    throw Error(pathOr('request failed', [0, 'message'])(errors));
  }
};
