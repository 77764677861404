import { useEffect } from 'react';

const DocumentFormComments = ({ setIsLoaded, children }) => {
  useEffect(() => {
    setIsLoaded(true);
  }, [setIsLoaded]);

  return children();
};

export default DocumentFormComments;
