/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteTeamMutationVariables = {||};
export type DeleteTeamMutationResponse = {|
  +deleteTeam: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type DeleteTeamMutation = {|
  variables: DeleteTeamMutationVariables,
  response: DeleteTeamMutationResponse,
|};
*/


/*
mutation DeleteTeamMutation {
  deleteTeam {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "deleteTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteTeamMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeleteTeamMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "fccb8c2e89928a49a3d2d16227d4b09f",
    "id": null,
    "metadata": {},
    "name": "DeleteTeamMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteTeamMutation {\n  deleteTeam {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a136140443f7504afac873f575ece077';

module.exports = node;
