/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QDocumentsByRootOfferIdQueryVariables = {|
  rootOfferId?: ?string
|};
export type QDocumentsByRootOfferIdQueryResponse = {|
  +documentsByRootOfferId: ?$ReadOnlyArray<?{|
    +id: ?string,
    +rootDocumentId: ?string,
    +name: ?string,
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
    +offerVersion: ?{|
      +major: ?number,
      +minor: ?number,
    |},
  |}>
|};
export type QDocumentsByRootOfferIdQuery = {|
  variables: QDocumentsByRootOfferIdQueryVariables,
  response: QDocumentsByRootOfferIdQueryResponse,
|};
*/


/*
query QDocumentsByRootOfferIdQuery(
  $rootOfferId: ID
) {
  documentsByRootOfferId(rootOfferId: $rootOfferId) {
    id
    rootDocumentId
    name
    dates {
      creation
      lastUpdate
    }
    offerVersion {
      major
      minor
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rootOfferId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rootOfferId",
    "variableName": "rootOfferId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootDocumentId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "WidgetDates",
  "kind": "LinkedField",
  "name": "dates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "major",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minor",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QDocumentsByRootOfferIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "documentsByRootOfferId",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "offerVersion",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QDocumentsByRootOfferIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "documentsByRootOfferId",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "offerVersion",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d0df265cd6a72faa48cbd7c37f42563b",
    "id": null,
    "metadata": {},
    "name": "QDocumentsByRootOfferIdQuery",
    "operationKind": "query",
    "text": "query QDocumentsByRootOfferIdQuery(\n  $rootOfferId: ID\n) {\n  documentsByRootOfferId(rootOfferId: $rootOfferId) {\n    id\n    rootDocumentId\n    name\n    dates {\n      creation\n      lastUpdate\n    }\n    offerVersion {\n      major\n      minor\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '45869423af86a33062deb53cf3ca3550';

module.exports = node;
