import React, { useState } from 'react';
import * as R from 'ramda';
import { Button } from 'antd';
import { AppstoreAddOutlined } from '@ant-design/icons';
import ModalCreateVersion from '../pages/offer/editOffer/ModalCreateVersion';
import QVersion from '../_graphql/queries/versions/QVersion';

const useCreateVersion = () => {
  const [showModal, setShowModal] = useState(false);

  return {
    showModal,
    setShowModal
  };
};

const CreateVersion = ({
  versionId,
  userCanCreateVersion,
  redirect
}) => {
  const {
    showModal,
    setShowModal
  } = useCreateVersion();

  if (!userCanCreateVersion) {
    return null;
  }

  return (
    <QVersion args={{ versionId }}>
      {version => {
        if (R.isNil(version) || !R.propEq('status', 'release', version)) {
          return null;
        }

        const {
          isLastMajor,
          isLastMinor
        } = version;

        return (
          <>
            <ModalCreateVersion
              versionId={versionId}
              redirect={redirect}
              isLastMinor={isLastMinor}
              isLastMajor={isLastMajor}
              visible={showModal}
              onHide={() => setShowModal(false)}
            />

            {(isLastMinor || isLastMajor) && (
              <Button
                size="large"
                type="primary"
                icon={<AppstoreAddOutlined />}
                onClick={() => setShowModal(true)}
              >
                Créer une nouvelle version
              </Button>
            )}
          </>
        );
      }}

    </QVersion>
  );
};

export default CreateVersion;
