import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { includes, prop } from 'ramda';
import withUser from './withUser';

const ProtectedRoute = ({ roles = [], userTeam, Component, ...rest }) => {
  const currentRole = prop('role', userTeam);
  const renderFn = () =>
    includes(currentRole, roles) ? <Component /> : <Redirect to="/" />;

  return <Route {...rest} render={renderFn} />;
};

export default withUser(ProtectedRoute);
