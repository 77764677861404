import React from 'react';
import { useParams } from 'react-router-dom';
import History from '../../components/History';

const DocumentHistory = () => {
  const { documentId } = useParams();
  return <History id={documentId} type="document" />;
};

export default DocumentHistory;
