/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FolderTypeEnum = "DOCUMENT" | "WIDGET" | "%future added value";
export type FolderInput = {|
  element?: ?ElementInput,
  name?: ?string,
  offerId?: ?string,
  parent?: ?string,
  scope?: ?string,
  teamId?: ?string,
  type?: ?FolderTypeEnum,
|};
export type ElementInput = {|
  elmId: string,
  type?: ?string,
|};
export type CreateFolderMutationVariables = {|
  folder: FolderInput
|};
export type CreateFolderMutationResponse = {|
  +createFolder: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type CreateFolderMutation = {|
  variables: CreateFolderMutationVariables,
  response: CreateFolderMutationResponse,
|};
*/


/*
mutation CreateFolderMutation(
  $folder: FolderInput!
) {
  createFolder(folder: $folder) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "folder"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "folder",
        "variableName": "folder"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "createFolder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateFolderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateFolderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a1c7d490b5c40a5a1b308b9c1b4ebd1c",
    "id": null,
    "metadata": {},
    "name": "CreateFolderMutation",
    "operationKind": "mutation",
    "text": "mutation CreateFolderMutation(\n  $folder: FolderInput!\n) {\n  createFolder(folder: $folder) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '27699a45ac76862848fb6a6dea8ed36d';

module.exports = node;
