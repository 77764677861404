/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FolderTypeEnum = "DOCUMENT" | "WIDGET" | "%future added value";
export type FolderInput = {|
  element?: ?ElementInput,
  name?: ?string,
  offerId?: ?string,
  parent?: ?string,
  scope?: ?string,
  teamId?: ?string,
  type?: ?FolderTypeEnum,
|};
export type ElementInput = {|
  elmId: string,
  type?: ?string,
|};
export type UpdateFolderMutationVariables = {|
  id: string,
  folder: FolderInput,
|};
export type UpdateFolderMutationResponse = {|
  +updateFolder: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateFolderMutation = {|
  variables: UpdateFolderMutationVariables,
  response: UpdateFolderMutationResponse,
|};
*/


/*
mutation UpdateFolderMutation(
  $id: ID!
  $folder: FolderInput!
) {
  updateFolder(id: $id, folder: $folder) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "folder"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "folder",
        "variableName": "folder"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateFolder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateFolderMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateFolderMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "cd420624eed024488ba167db9810b718",
    "id": null,
    "metadata": {},
    "name": "UpdateFolderMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateFolderMutation(\n  $id: ID!\n  $folder: FolderInput!\n) {\n  updateFolder(id: $id, folder: $folder) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '639761df33b7da9d9d668b6fe885101a';

module.exports = node;
