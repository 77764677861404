import useFetchQuery from './useFetchQuery';
import { QStylesQuery } from '../_graphql/queries/documents/QStyles';
import * as R from 'ramda';
const useStyles = () => {
  const { styles: customStyles } = useFetchQuery({
    query: QStylesQuery,
    args: { scope: 'global' },
    dataProp: 'styles',
    defaultData: []
  });

  const styles = R.map(({ id, name, styles }) => ({
    id,
    name,
    styles: R.reduce(
      (acc, { format, value }) => ({
        ...acc,
        [format]: value
      }),
      {},
      styles
    )
  })
  )(customStyles);

  return { styles };
};

export default useStyles;
