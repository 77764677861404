import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Section, Title, ChevronBottom } from '../../../../components';
import UpdateGlobalVariableMutation from '../../../../_graphql/mutations/documents/UpdateGlobalVariableMutation';
import CreateGlobalVariableMutation from '../../../../_graphql/mutations/documents/CreateGlobalVariableMutation';
import { Input, Select, Button, Tooltip } from 'antd';
import { ThunderboltOutlined } from '@ant-design/icons';
import { callbackSave } from '../../../../utils/messageMutation';
import { T, always, and, assoc, compose, cond, defaultTo, isNil, not, or, path, pick, prop } from 'ramda';
import { isNotNil, isNotNilOrEmpty, isTrue } from 'ramda-adjunct';

const useGlobalVariable = ({ globalVariableId, globalVariable, scope, offerId }) => {
  const globalVariableExist = isNotNil(globalVariableId);
  const [state, setState] = useState(defaultTo({}, globalVariable));

  const history = useHistory();

  const isEdit = !isNil(globalVariableId);

  const isValid = isNotNilOrEmpty(prop('name', state)) && isNotNilOrEmpty(prop('type', state));

  const onSave = () => {
    if (isEdit) {
      const globalVariable = pick(['name', 'type'], state);
      UpdateGlobalVariableMutation({ globalVariableId, globalVariable }, callbackSave('variables-globales', 'La variable globale a bien été mise à jour.', history, scope, state, offerId));
    } else {
      const globalVariable = compose(
        assoc('offerId', offerId),
        assoc('scope', scope)
      )(state);
      CreateGlobalVariableMutation({ globalVariable }, callbackSave('variables-globales', 'La variable globale a bien été créée.', history, scope, state, offerId));
    }
  };

  return {
    ...state,
    setState,
    isEdit,
    onSave,
    isValid,
    globalVariableExist
  };
};

const GlobalVariableForm = ({
  globalVariableId,
  globalVariable,
  scope,
  offerId,
  preview
}) => {
  const {
    isEdit,
    name,
    type,
    setState,
    onSave,
    isValid,
    globalVariableExist
  } = useGlobalVariable({ globalVariableId, globalVariable, scope, offerId });
  const globalVariableEditable = and(or(isEdit, globalVariableExist), not(preview));
  const title = cond([
    [() => globalVariableEditable, always('Modifier la variable globale')],
    [() => isTrue(preview), always('Prévisualiser la variable globale')],
    [T, always('Créer une variable globale')]
  ])(globalVariable);

  return (
    <>
      <Title
        title={title}
        backTo={offerId ? `/offre/${offerId}/edition` : '/equipe/elements/variables-globales'}
      />
      <Section title="nom de la variable globale">
        <div className="flex gap-4">
          <Input
            value={name}
            placeholder="Nom de la variable globale"
            onChange={(e) => setState(assoc('name', path(['target', 'value'], e)))}
            size="large"
            disabled={preview}
          />
          <Tooltip
            title={prop('isUsed', globalVariable) && 'Cette variable est utilisée dans un document, vous ne pouvez pas modifier son type.'}
            color="#0197DC"
          >
            <Select
              value={type}
              onChange={(v) => setState(assoc('type', v))}
              placeholder="Type de la variable globale"
              style={{ width: '300px' }}
              size="large"
              suffixIcon={<ChevronBottom />}
              disabled={preview || prop('isUsed', globalVariable)}
            >
              <Select.Option value="text">Chaîne de caractère</Select.Option>
              <Select.Option value="number">Nombre</Select.Option>
              <Select.Option value="boolean">Booléen</Select.Option>
            </Select>
          </Tooltip>
        </div>
      </Section>
      {!preview && (
        <Button
          onClick={onSave}
          disabled={!isValid}
          className="block mx-auto mt-4"
          size="large"
          type="primary"
          icon={<ThunderboltOutlined />}
        >
          {isEdit ? 'Mettre à jour la variable globale' : 'Créer la variable globale'}
        </Button>
      )}
    </>
  );
};

export default GlobalVariableForm;
