import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';

import {
  populateChildren
} from '../toolbox';

export const QStylesQuery = graphql`
  query QStylesQuery($scope: DocumentScopeEnum, $offerId: ID, $status: OfferStatus) {
    styles(scope: $scope, offerId: $offerId, status: $status) {
      id
      name
      predefined
      styles {
        format
        value
      }
      dates {
        creation
        lastUpdate
      }
      version {
        minor
        major
        status
        itemRootId
        versionId
        itemId
      }
    }
  }
`;

const QStyles = ({
  args = {},
  children,
  childDataProp
}) => (
  <Query
    query={QStylesQuery}
    caching={false}
    args={args}
  >
    {populateChildren('styles')(children, childDataProp)}
  </Query>
);

export default QStyles;
