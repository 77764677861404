/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FrameInput = {|
  bundleTag?: ?string,
  comment?: ?string,
  description?: ?string,
  isReferent?: ?boolean,
  metadata?: ?string,
  name?: ?string,
  offerId?: ?string,
  offerName?: ?string,
  version?: ?string,
  versionFlexidocs?: ?string,
  who?: ?WhoInput,
|};
export type WhoInput = {|
  creation?: ?string,
  lastUpdate?: ?string,
|};
export type UpdateFrameMutationVariables = {|
  frameId: string,
  dataToSet: FrameInput,
  metadataHasChanged: boolean,
|};
export type UpdateFrameMutationResponse = {|
  +updateFrame: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateFrameMutation = {|
  variables: UpdateFrameMutationVariables,
  response: UpdateFrameMutationResponse,
|};
*/


/*
mutation UpdateFrameMutation(
  $frameId: ID!
  $dataToSet: FrameInput!
  $metadataHasChanged: Boolean!
) {
  updateFrame(frameId: $frameId, dataToSet: $dataToSet, metadataHasChanged: $metadataHasChanged) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dataToSet"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "frameId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metadataHasChanged"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dataToSet",
        "variableName": "dataToSet"
      },
      {
        "kind": "Variable",
        "name": "frameId",
        "variableName": "frameId"
      },
      {
        "kind": "Variable",
        "name": "metadataHasChanged",
        "variableName": "metadataHasChanged"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateFrame",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateFrameMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateFrameMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "df1860fe9a12fe0bf659472f54d5052d",
    "id": null,
    "metadata": {},
    "name": "UpdateFrameMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateFrameMutation(\n  $frameId: ID!\n  $dataToSet: FrameInput!\n  $metadataHasChanged: Boolean!\n) {\n  updateFrame(frameId: $frameId, dataToSet: $dataToSet, metadataHasChanged: $metadataHasChanged) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '114645221c03fb6061b28966d9205aac';

module.exports = node;
