import { CheckSquareOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { prop } from 'ramda';
import React from 'react';
import VariableContainer from './VariableContainer';

const SelectVariable = ({ label, value, onChange, options, multiple, onChangeIsDefaultValue, isDefaultValue }) => (
  <VariableContainer
    label={label}
    icon={<CheckSquareOutlined />}
    onChangeIsDefaultValue={onChangeIsDefaultValue}
    isDefaultValue={isDefaultValue}
    value={value}
  >
    <Select
      mode={multiple && 'multiple'}
      allowClear={true}
      className="w-full"
      {...{ onChange, value }}
    >
      {options.map((option) => (
        <Select.Option
          key={prop('value', option)}
          value={prop('value', option)}
        >
          {prop('title', option)}
        </Select.Option>
      ))}
    </Select>
  </VariableContainer>
);

export default SelectVariable;
