/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateRawDocumentMutationVariables = {|
  rawDocumentId: string,
  filename: string,
|};
export type UpdateRawDocumentMutationResponse = {|
  +updateRawDocument: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateRawDocumentMutation = {|
  variables: UpdateRawDocumentMutationVariables,
  response: UpdateRawDocumentMutationResponse,
|};
*/


/*
mutation UpdateRawDocumentMutation(
  $rawDocumentId: ID!
  $filename: String!
) {
  updateRawDocument(rawDocumentId: $rawDocumentId, filename: $filename) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filename"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rawDocumentId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filename",
        "variableName": "filename"
      },
      {
        "kind": "Variable",
        "name": "rawDocumentId",
        "variableName": "rawDocumentId"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateRawDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateRawDocumentMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateRawDocumentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f72d260ea83f5d74ff72679d57b16909",
    "id": null,
    "metadata": {},
    "name": "UpdateRawDocumentMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateRawDocumentMutation(\n  $rawDocumentId: ID!\n  $filename: String!\n) {\n  updateRawDocument(rawDocumentId: $rawDocumentId, filename: $filename) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bbe13e954de5417e1ac52c0604ac645c';

module.exports = node;
