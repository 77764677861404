import React from 'react';
import { useParams } from 'react-router-dom';
import History from '../../components/History';

const WidgetHistory = () => {
  const { widgetId } = useParams();
  return <History id={widgetId} type="widget" />;
};

export default WidgetHistory;
