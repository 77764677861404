import React, { useState } from 'react';
import * as R from 'ramda';
import withUser from '../../../withUser';
import { Section, ChevronBottom } from '../../../components';
import QGroups from '../../../_graphql/queries/groups/QGroups';
import { Select } from 'antd';
import UpdateOrAddGroupRolesMutation from '../../../_graphql/mutations/groups/UpdateOrAddGroupRolesMutation';
import { errorMessage, successMessage } from '../../../utils/messageMutation';

const useEditOfferValidator = (offerId, currentGroupId) => {
  const [state, setState] = useState({
    groupId: currentGroupId,
    loading: false
  });

  const onUpdateOfferValidator = (groupId) => {
    const formerGroupId = state.groupId;

    setState({
      groupId,
      loading: true
    });

    UpdateOrAddGroupRolesMutation({ groupId, formerGroupId, itemId: offerId, itemType: 'offer', roles: ['offerValidator'] }, (ok, error) => {
      setState(R.assoc('loading', false));

      if (ok && !error) {
        successMessage('groupe valideur de l\'offre', 'mis à jour');
      } else {
        setState(R.assoc('groupId', formerGroupId));
        errorMessage();
      }
    });
  };

  return {
    ...state,
    onUpdateOfferValidator
  };
};

const EditOfferValidator = ({
  offerId,
  currentGroupId,
  groups,
  userCanEditOffer
}) => {
  const {
    groupId,
    loading,
    onUpdateOfferValidator
  } = useEditOfferValidator(offerId, currentGroupId);

  return (
    <Section
      title="Valideur de l'offre"
    >
      <div className="mb-2">Sélectionner le groupe en charge de la validation de cette offre :</div>

      <Select
        placeholder="Groupe valideur"
        value={groupId}
        onChange={onUpdateOfferValidator}
        style={{ width: '100%' }}
        loading={loading}
        size="large"
        suffixIcon={<ChevronBottom />}
        disabled={!userCanEditOffer}
      >
        {R.map(group => {
          const { name, id } = group;

          return (
            <Select.Option
              value={id}
              key={id}
            >
              {name}
            </Select.Option>
          );
        })(groups)}
      </Select>
    </Section>
  );
};

const EditOfferValidatorWrapper = ({
  offerId,
  userIsAdmin,
  userCanEditOffer
}) => {
  if (!userIsAdmin) {
    return null;
  }

  return (
    <QGroups>
      {groups => {
        if (R.either(R.isNil, R.isEmpty)(groups)) {
          return null;
        }

        const currentGroupId = R.compose(
          R.prop('id'),
          R.defaultTo({}),
          R.find(
            R.compose(
              R.find(
                R.both(
                  R.propEq('itemType', 'offer'),
                  R.propEq('itemId', offerId)
                )
              ),
              R.propOr([], 'roles')
            )
          )
        )(groups);

        return (
          <EditOfferValidator
            offerId={offerId}
            groups={groups}
            currentGroupId={currentGroupId}
            userCanEditOffer={userCanEditOffer}
          />
        );
      }}
    </QGroups>
  );
};

export default withUser(EditOfferValidatorWrapper);
