import { CalendarOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import React from 'react';
import VariableContainer from './VariableContainer';

const DateVariable = ({ label, value, onChange, onChangeIsDefaultValue, isDefaultValue }) => (
  <VariableContainer
    label={label}
    icon={<CalendarOutlined />}
    onChangeIsDefaultValue={onChangeIsDefaultValue}
    isDefaultValue={isDefaultValue}
    value={value}
  >
    <DatePicker
      className="w-full"
      format="DD/MM/YYYY"
      value={value}
      onChange={(_date, dateString) => onChange(dateString)}
      allowClear={true}
    />
  </VariableContainer>
);

export default DateVariable;
