import { useEffect, useState } from 'react';
import { useRelayEnvironment, fetchQuery } from 'react-relay';
import useFetching from './useFetching';
import { isNotNil, isNotNilOrEmpty } from 'ramda-adjunct';
import { QCacheQuery } from '../_graphql/queries/QCache';
import UpdateCacheMutation from '../_graphql/mutations/UpdateCacheMutation';
import { equals, inc, isNil, prop, propOr } from 'ramda';

const openDatabase = (dbName, version) => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(dbName, version);

    request.onerror = (event) => {
      reject(event.target.error);
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore('cache');
    };
  });
};

export const useFetchQuery = ({
  query,
  args,
  verbose = false,
  skip = false,
  defaultData = {},
  dataProp,
  cacheKey
}) => {
  const fetching = useFetching(null);
  const environment = useRelayEnvironment();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [count, setCount] = useState(0);
  const [resultData, setResultData] = useState(defaultData);
  const [forceFetch, setForceFetch] = useState(false);

  useEffect(() => {
    if (skip) return () => {};
    const fetchData = async () => {
      const actualDate = new Date();
      let dateCache = actualDate;
      if (cacheKey && !forceFetch) {
        const result = await getCachedDataFromDB(cacheKey[1]);
        const { cache: { date } } = await fetchQuery(environment, QCacheQuery, { key: cacheKey[1] }).toPromise();
        if (isNil(result) && isNotNil(date)) dateCache = new Date(date);
        if (isNotNilOrEmpty(prop('data', result)) && (isNil(date) || equals(new Date(date), new Date(prop('date', result))))) {
          setIsLoading(true);
          setResultData(prop('data', result));
          setIsLoading(false);
          return () => {};
        }
      }
      const subscription = fetchQuery(environment, query, args)
        .subscribe({
          start: () => {
            if (verbose) console.log('start fetchQuery...', query, args);
            fetching.start();
            setIsLoading(true);
          },
          complete: () => {
            if (verbose) console.log('completed fetchQuery!');
          },
          error: (error) => {
            if (verbose) console.error('oops, fetchQuery error...', error);
            setErrorMsg(JSON.stringify(error, null, 4));
            setIsLoading(false);
            fetching.stop(error);
          },
          next: async (data) => {
            if (verbose) console.log(' next packet received...', data);
            if (cacheKey) await saveDataToCache(cacheKey[1], data, dateCache, actualDate);
            setResultData(data);
            setIsLoading(false);
            setForceFetch(false);
            fetching.stop(null, data);
          }
        });
      return () => subscription.unsubscribe();
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, count]);

  const reFetch = () => {
    setForceFetch(true);
    setCount(inc);
  };

  const saveDataToCache = async (key, data, dateCache, actualDate) => {
    try {
      const db = await openDatabase(cacheKey[0], 1);
      const tx = db.transaction('cache', 'readwrite');
      const store = tx.objectStore('cache');
      store.put({ date: dateCache.toString(), data }, key);
      await tx.complete;
      if (equals(dateCache, actualDate)) UpdateCacheMutation({ date: dateCache, key }, () => {});
      db.close();
    } catch (error) {
      console.error('Error saving data to cache:', error);
    }
  };

  const getCachedDataFromDB = async (key) => {
    try {
      const db = await openDatabase(cacheKey[0], 1);
      const tx = db.transaction('cache', 'readonly');
      const store = tx.objectStore('cache');
      const request = await store.get(key);
      return new Promise((resolve, reject) => {
        request.onsuccess = (event) => {
          const data = event.target.result;
          db.close();
          resolve(data);
        };

        request.onerror = (event) => {
          reject(event.target.error);
        };
      });
    } catch (error) {
      console.error('Error getting cached data from cache:', error);
      return null;
    }
  };

  return {
    isLoading,
    error: errorMsg,
    data: resultData,
    [dataProp]: propOr(defaultData, dataProp, resultData),
    fetching,
    reFetch
  };
};
export default useFetchQuery;
