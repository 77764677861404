/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddGroupMemberMutationVariables = {|
  groupId: string,
  memberId: string,
|};
export type AddGroupMemberMutationResponse = {|
  +addGroupMember: ?{|
    +ok: boolean,
    +error: ?string,
    +newMember: ?{|
      +userId: ?string
    |},
  |}
|};
export type AddGroupMemberMutation = {|
  variables: AddGroupMemberMutationVariables,
  response: AddGroupMemberMutationResponse,
|};
*/


/*
mutation AddGroupMemberMutation(
  $groupId: ID!
  $memberId: ID!
) {
  addGroupMember(groupId: $groupId, memberId: $memberId) {
    ok
    error
    newMember {
      userId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "groupId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "memberId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "groupId",
        "variableName": "groupId"
      },
      {
        "kind": "Variable",
        "name": "memberId",
        "variableName": "memberId"
      }
    ],
    "concreteType": "AddGroupMemberMutationResponse",
    "kind": "LinkedField",
    "name": "addGroupMember",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GroupMember",
        "kind": "LinkedField",
        "name": "newMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddGroupMemberMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddGroupMemberMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b44db61598ca215bc1d6e0f534bb7b03",
    "id": null,
    "metadata": {},
    "name": "AddGroupMemberMutation",
    "operationKind": "mutation",
    "text": "mutation AddGroupMemberMutation(\n  $groupId: ID!\n  $memberId: ID!\n) {\n  addGroupMember(groupId: $groupId, memberId: $memberId) {\n    ok\n    error\n    newMember {\n      userId\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '31c3e3fc5e107c9243f03c34bcb84b74';

module.exports = node;
