/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FrequencyUnitEnum = "DAY" | "HOUR" | "MONTH" | "WEEK" | "%future added value";
export type TeamReminderConfigInput = {|
  event?: ?string,
  frequency?: ?TeamReminderConfigFrequencyInput,
|};
export type TeamReminderConfigFrequencyInput = {|
  unit?: ?FrequencyUnitEnum,
  value?: ?number,
|};
export type UpdateTeamRemindersConfigMutationVariables = {|
  remindersConfig: $ReadOnlyArray<TeamReminderConfigInput>
|};
export type UpdateTeamRemindersConfigMutationResponse = {|
  +updateRemindersConfig: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateTeamRemindersConfigMutation = {|
  variables: UpdateTeamRemindersConfigMutationVariables,
  response: UpdateTeamRemindersConfigMutationResponse,
|};
*/


/*
mutation UpdateTeamRemindersConfigMutation(
  $remindersConfig: [TeamReminderConfigInput!]!
) {
  updateRemindersConfig(remindersConfig: $remindersConfig) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "remindersConfig"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "remindersConfig",
        "variableName": "remindersConfig"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateRemindersConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateTeamRemindersConfigMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateTeamRemindersConfigMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2d3abca55d530106cbef6f45154844e0",
    "id": null,
    "metadata": {},
    "name": "UpdateTeamRemindersConfigMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateTeamRemindersConfigMutation(\n  $remindersConfig: [TeamReminderConfigInput!]!\n) {\n  updateRemindersConfig(remindersConfig: $remindersConfig) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fafc23cd6ecb4b71a717c2cabc53350b';

module.exports = node;
