import { Button, message, Modal } from 'antd';
import { includes, is, replace } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { errorMessage } from '../../../utils/messageMutation';
import CreateNewItemVersionMutation from '../../../_graphql/mutations/versions/CreateNewItemVersionMutation';

const useCreateVersion = (versionId, redirect) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onCreate = (type) => () => {
    setLoading(true);
    message.loading({ content: 'Version en cours de création, veuillez patienter...', key: 'version-create', duration: 0 });
    CreateNewItemVersionMutation({ versionId, type }, (ok, error, itemId) => {
      setLoading(false);
      if (ok && !error) {
        message.success({ content: 'La nouvelle version a bien été créée.', key: 'version-create' });

        if (isNotNil(redirect) && is(String, redirect) && includes(':itemId', redirect)) {
          history.push(replace(':itemId', itemId)(redirect));
        }
      } else {
        errorMessage();
      }
    });
  };

  return {
    loading,
    onCreate
  };
};

const ModalCreateVersion = ({
  visible,
  versionId,
  onHide,
  isLastMinor,
  isLastMajor,
  redirect
}) => {
  const {
    loading,
    onCreate
  } = useCreateVersion(versionId, redirect);

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={onHide}
    >
      {isLastMinor && (
        <Button
          onClick={onCreate('minor')}
          size="small"
          className="mr-4"
          type="primary"
          disabled={loading}
        >
          Version mineure
        </Button>
      )}

      {isLastMajor && isLastMinor && (
        <Button
          onClick={onCreate('major')}
          size="small"
          className="btn-green"
          disabled={loading}
        >
          Version majeure
        </Button>
      )}
    </Modal>
  );
};

export default ModalCreateVersion;
