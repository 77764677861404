import { isNil } from 'ramda';
import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateVersionStatusMutation($versionId: ID!, $status: String!) {
    updateVersionStatus(versionId: $versionId, status: $status) {
      ok
      error
    }
  }
`;

export default ({ versionId, status, itemId }, done) => {
  const variables = {
    versionId,
    status
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { updateVersionStatus }) => {
        const { ok, error } = updateVersionStatus;

        if (ok && !error) {
          const item = proxyStore.get(itemId);

          let version;

          if (!isNil(item)) {
            version = item.getLinkedRecord('version');
          } else {
            const storedVersion = proxyStore.get(versionId);

            if (!isNil(storedVersion)) {
              version = storedVersion.getLinkedRecord('version');
            }
          }

          if (!isNil(version)) {
            version.setValue(status, 'status');
          }
        }
      },
      onCompleted: ({ updateVersionStatus }) => basicMutationOnCompleted(updateVersionStatus, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
