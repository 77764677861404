/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ElementReviewStatusEnum = "accepted" | "pending" | "rejected" | "%future added value";
export type QElementReviewQueryVariables = {|
  itemRootId: string,
  itemId: string,
  itemType: string,
|};
export type QElementReviewQueryResponse = {|
  +elementReview: ?{|
    +id: ?string,
    +status: ?ElementReviewStatusEnum,
    +userId: ?string,
    +itemRootId: ?string,
    +itemId: ?string,
    +groupId: ?string,
  |}
|};
export type QElementReviewQuery = {|
  variables: QElementReviewQueryVariables,
  response: QElementReviewQueryResponse,
|};
*/


/*
query QElementReviewQuery(
  $itemRootId: ID!
  $itemId: ID!
  $itemType: String!
) {
  elementReview(itemRootId: $itemRootId, itemId: $itemId, itemType: $itemType) {
    id
    status
    userId
    itemRootId
    itemId
    groupId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemRootId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemType"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "itemId",
        "variableName": "itemId"
      },
      {
        "kind": "Variable",
        "name": "itemRootId",
        "variableName": "itemRootId"
      },
      {
        "kind": "Variable",
        "name": "itemType",
        "variableName": "itemType"
      }
    ],
    "concreteType": "ElementReview",
    "kind": "LinkedField",
    "name": "elementReview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemRootId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "groupId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QElementReviewQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "QElementReviewQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "86102fe000466fa1479500350ffc962c",
    "id": null,
    "metadata": {},
    "name": "QElementReviewQuery",
    "operationKind": "query",
    "text": "query QElementReviewQuery(\n  $itemRootId: ID!\n  $itemId: ID!\n  $itemType: String!\n) {\n  elementReview(itemRootId: $itemRootId, itemId: $itemId, itemType: $itemType) {\n    id\n    status\n    userId\n    itemRootId\n    itemId\n    groupId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ecb6faff4baddd57cebc7e2a418adbcc';

module.exports = node;
