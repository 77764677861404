import React from 'react';
import GlobalVariableForm from './components/globalVariable/GlobalVariableForm';
import { useParams } from 'react-router-dom';
import { SCOPE_OFFER } from '../../_CONST';

const CreateGlobalVariableDocument = () => {
  const { offerId } = useParams();
  return (
    <GlobalVariableForm
      scope={SCOPE_OFFER}
      offerId={offerId}
    />
  );
};

export default CreateGlobalVariableDocument;
