import {
  commitMutation,
  graphql
} from 'react-relay';
import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation RemoveGroupRoleMutation($groupId: ID!, $itemType: String!, $itemId: String!, $role: String!) {
    removeGroupRole(groupId: $groupId, itemType: $itemType, itemId: $itemId, role: $role) {
      ok
      error
    }
  }
`;

export default ({ groupId, itemType, itemId, role }, done) => {
  const variables = {
    groupId,
    itemType,
    itemId,
    role
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ removeGroupRole }) => basicMutationOnCompleted(removeGroupRole, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
