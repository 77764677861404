import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';

import {
  populateChildren
} from '../toolbox';

const QStyleQuery = graphql`
  query QStyleQuery($styleId: ID!) {
    style(styleId: $styleId) {
      id
      name
      styles {
        format
        type
        value
      }
      version {
        versionId
        itemId
        status
        itemScope
        itemType
        major
        minor
        reviewComments {
          comments
          rejectionReason
          status
        }
      }
    }
  }
`;

const QStyle = ({
  args,
  children,
  childDataProp
}) => (
  <Query
    args={args}
    query={QStyleQuery}
    caching={false}
  >
    {populateChildren('style')(children, childDataProp)}
  </Query>
);

export default QStyle;
