import { commitMutation, graphql } from 'react-relay';
import environment from '../..';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateFolderMutation($id: ID!, $folder: FolderInput!) {
    updateFolder(id: $id, folder: $folder) {
      ok
      error
    }
  }
`;

export default ({ id, folder }, done) => {
  const variables = {
    id,
    folder
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateFolder }) => basicMutationOnCompleted(updateFolder, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
