/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QVersionQueryVariables = {|
  versionId: string
|};
export type QVersionQueryResponse = {|
  +version: ?{|
    +id: ?string,
    +versionId: ?string,
    +itemId: ?string,
    +itemScope: ?string,
    +itemType: ?string,
    +isLastMajor: ?boolean,
    +isLastMinor: ?boolean,
    +major: ?number,
    +minor: ?number,
    +status: ?string,
  |}
|};
export type QVersionQuery = {|
  variables: QVersionQueryVariables,
  response: QVersionQueryResponse,
|};
*/


/*
query QVersionQuery(
  $versionId: ID!
) {
  version(versionId: $versionId) {
    id
    versionId
    itemId
    itemScope
    itemType
    isLastMajor
    isLastMinor
    major
    minor
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "versionId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "versionId",
        "variableName": "versionId"
      }
    ],
    "concreteType": "Version",
    "kind": "LinkedField",
    "name": "version",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "versionId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemScope",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isLastMajor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isLastMinor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "major",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QVersionQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QVersionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e2a0211fa41a67182399eb9ef1e23992",
    "id": null,
    "metadata": {},
    "name": "QVersionQuery",
    "operationKind": "query",
    "text": "query QVersionQuery(\n  $versionId: ID!\n) {\n  version(versionId: $versionId) {\n    id\n    versionId\n    itemId\n    itemScope\n    itemType\n    isLastMajor\n    isLastMinor\n    major\n    minor\n    status\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c2a06883cc96fb4ecbc8fc2af3a17e5e';

module.exports = node;
