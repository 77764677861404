import * as R from 'ramda';
import { BASE_PADDING_DOCUMENT } from '../_CONST';
import { isNotNilOrEmpty } from 'ramda-adjunct';

export const defaultPage = {
  header: [],
  widgets: [],
  footer: [],
  breakPage: true,
  padding: BASE_PADDING_DOCUMENT,
  rules: []
};

export const useSections = (state, setState) => {
  const pathPages = ['values', 'pages'];
  const pages = R.pathOr([], pathPages, state);
  const actionToSection = (actionToDo) => R.over(R.lensPath(pathPages), actionToDo);

  const addSection = () => setState(actionToSection(R.append(defaultPage)));

  const updatePaddingSection = (index) => (key, value) =>
    setState(
      R.over(R.lensPath([...pathPages, index, 'padding']), R.assoc(key, value))
    );

  const addRuleSection = (index) => setState(R.over(
    R.lensPath([...pathPages, index, 'rules']),
    R.compose(
      R.append({}),
      R.defaultTo([])
    )
  ));

  const updateRuleSection = (index) => (ruleIndex) => (fn) => setState(R.over(
    R.lensPath([...pathPages, index, 'rules']),
    R.adjust(ruleIndex, fn)
  ));

  const removeRuleSection = (index) => (ruleIndex) => () => setState(R.over(
    R.lensPath([...pathPages, index, 'rules']),
    R.remove(ruleIndex, 1)
  ));

  const removeSection = (index) => {
    setState(actionToSection(R.remove(index, 1)));
    if (isNotNilOrEmpty(R.prop('previewHtml', state))) {
      setState(R.assoc('previewHtml', R.remove(index, 1, R.prop('previewHtml', state))));
    }
  };

  const editSections = (index, action, oldIndex) => {
    R.cond([
      [R.equals('add'), () => addSection()],
      [R.equals('remove'), () => removeSection(index)],
      [R.equals('addSectionAfter'), () => setState(actionToSection(R.insert(index + 1, defaultPage)))],
      [R.equals('addSectionBefore'), () => setState(actionToSection(R.insert(index, defaultPage)))],
      [R.equals('duplicateSection'), () => setState(actionToSection(R.append(R.nth(index, pages))))],
      [R.equals('swapSections'), () => setState(actionToSection(R.compose(
        R.set(R.lensIndex(index), R.nth(oldIndex, pages)),
        R.set(R.lensIndex(oldIndex), R.nth(index, pages))
      )))],
      [R.equals('moveSectionBefore'), () => setState(actionToSection(R.compose(
        R.insert(oldIndex, R.nth(index, pages)),
        R.remove(index, 1)
      )))],
      [R.T, () => state]
    ])(action);
  };

  return {
    editSections,
    updatePaddingSection,
    addRuleSection,
    updateRuleSection,
    removeRuleSection
  };
};
