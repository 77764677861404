/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentScopeEnum = "global" | "offer" | "%future added value";
export type OfferStatus = "archived" | "draft" | "ready" | "release" | "review" | "%future added value";
export type QStylesQueryVariables = {|
  scope?: ?DocumentScopeEnum,
  offerId?: ?string,
  status?: ?OfferStatus,
|};
export type QStylesQueryResponse = {|
  +styles: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +predefined: ?boolean,
    +styles: ?$ReadOnlyArray<?{|
      +format: ?string,
      +value: ?string,
    |}>,
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
    +version: ?{|
      +minor: ?number,
      +major: ?number,
      +status: ?string,
      +itemRootId: ?string,
      +versionId: ?string,
      +itemId: ?string,
    |},
  |}>
|};
export type QStylesQuery = {|
  variables: QStylesQueryVariables,
  response: QStylesQueryResponse,
|};
*/


/*
query QStylesQuery(
  $scope: DocumentScopeEnum
  $offerId: ID
  $status: OfferStatus
) {
  styles(scope: $scope, offerId: $offerId, status: $status) {
    id
    name
    predefined
    styles {
      format
      value
    }
    dates {
      creation
      lastUpdate
    }
    version {
      minor
      major
      status
      itemRootId
      versionId
      itemId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v3 = [
  {
    "kind": "Variable",
    "name": "offerId",
    "variableName": "offerId"
  },
  {
    "kind": "Variable",
    "name": "scope",
    "variableName": "scope"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "predefined",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "StyleItem",
  "kind": "LinkedField",
  "name": "styles",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "format",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "WidgetDates",
  "kind": "LinkedField",
  "name": "dates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "major",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemRootId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "versionId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QStylesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Style",
        "kind": "LinkedField",
        "name": "styles",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "QStylesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Style",
        "kind": "LinkedField",
        "name": "styles",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab766b2a37e0e9d2570cff5aa4f6d309",
    "id": null,
    "metadata": {},
    "name": "QStylesQuery",
    "operationKind": "query",
    "text": "query QStylesQuery(\n  $scope: DocumentScopeEnum\n  $offerId: ID\n  $status: OfferStatus\n) {\n  styles(scope: $scope, offerId: $offerId, status: $status) {\n    id\n    name\n    predefined\n    styles {\n      format\n      value\n    }\n    dates {\n      creation\n      lastUpdate\n    }\n    version {\n      minor\n      major\n      status\n      itemRootId\n      versionId\n      itemId\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b0eeaaba45508d1935fcf26367dfebb';

module.exports = node;
