/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentScopeEnum = "global" | "offer" | "%future added value";
export type OrientationEnum = "landscape" | "portrait" | "%future added value";
export type QWidgetQueryVariables = {|
  widgetId: string
|};
export type QWidgetQueryResponse = {|
  +widget: ?{|
    +id: ?string,
    +name: ?string,
    +nbColsGrid: ?number,
    +heightRowsGrid: ?number,
    +contents: ?string,
    +scope: ?DocumentScopeEnum,
    +offerId: ?string,
    +isUsed: ?boolean,
    +isUsedBy: ?$ReadOnlyArray<?{|
      +id: ?string,
      +name: ?string,
    |}>,
    +version: ?{|
      +versionId: ?string,
      +status: ?string,
      +itemScope: ?string,
      +itemType: ?string,
      +itemId: ?string,
      +major: ?number,
      +minor: ?number,
      +reviewComments: ?{|
        +comments: ?string,
        +rejectionReason: ?string,
        +status: ?string,
      |},
    |},
    +orientation: ?OrientationEnum,
    +frameStyle: ?{|
      +backgroundColor: ?string,
      +borderColor: ?{|
        +top: ?string,
        +left: ?string,
        +right: ?string,
        +bottom: ?string,
      |},
      +borderWidth: ?{|
        +top: ?number,
        +left: ?number,
        +right: ?number,
        +bottom: ?number,
      |},
      +borderStyle: ?{|
        +top: ?string,
        +left: ?string,
        +right: ?string,
        +bottom: ?string,
      |},
      +borderRadius: ?{|
        +topLeft: ?number,
        +topRight: ?number,
        +bottomLeft: ?number,
        +bottomRight: ?number,
      |},
    |},
  |}
|};
export type QWidgetQuery = {|
  variables: QWidgetQueryVariables,
  response: QWidgetQueryResponse,
|};
*/


/*
query QWidgetQuery(
  $widgetId: ID!
) {
  widget(widgetId: $widgetId) {
    id
    name
    nbColsGrid
    heightRowsGrid
    contents
    scope
    offerId
    isUsed
    isUsedBy {
      id
      name
    }
    version {
      versionId
      status
      itemScope
      itemType
      itemId
      major
      minor
      reviewComments {
        comments
        rejectionReason
        status
        id
      }
      id
    }
    orientation
    frameStyle {
      backgroundColor
      borderColor {
        top
        left
        right
        bottom
      }
      borderWidth {
        top
        left
        right
        bottom
      }
      borderStyle {
        top
        left
        right
        bottom
      }
      borderRadius {
        topLeft
        topRight
        bottomLeft
        bottomRight
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "widgetId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "widgetId",
    "variableName": "widgetId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nbColsGrid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heightRowsGrid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contents",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scope",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isUsed",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "WidgetDocument",
  "kind": "LinkedField",
  "name": "isUsedBy",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "versionId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemScope",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemType",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "major",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minor",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comments",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rejectionReason",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientation",
  "storageKey": null
},
v21 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "top",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "left",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "right",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bottom",
    "storageKey": null
  }
],
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "FrameStyleData",
  "kind": "LinkedField",
  "name": "frameStyle",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BorderColorAndStyle",
      "kind": "LinkedField",
      "name": "borderColor",
      "plural": false,
      "selections": (v21/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BorderWidth",
      "kind": "LinkedField",
      "name": "borderWidth",
      "plural": false,
      "selections": (v21/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BorderColorAndStyle",
      "kind": "LinkedField",
      "name": "borderStyle",
      "plural": false,
      "selections": (v21/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BorderRadius",
      "kind": "LinkedField",
      "name": "borderRadius",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "topLeft",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "topRight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bottomLeft",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bottomRight",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QWidgetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Widget",
        "kind": "LinkedField",
        "name": "widget",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReviewComment",
                "kind": "LinkedField",
                "name": "reviewComments",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v20/*: any*/),
          (v22/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QWidgetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Widget",
        "kind": "LinkedField",
        "name": "widget",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReviewComment",
                "kind": "LinkedField",
                "name": "reviewComments",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v12/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v20/*: any*/),
          (v22/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "212a79d59cfd25db4fb9acdc3a8b2f5a",
    "id": null,
    "metadata": {},
    "name": "QWidgetQuery",
    "operationKind": "query",
    "text": "query QWidgetQuery(\n  $widgetId: ID!\n) {\n  widget(widgetId: $widgetId) {\n    id\n    name\n    nbColsGrid\n    heightRowsGrid\n    contents\n    scope\n    offerId\n    isUsed\n    isUsedBy {\n      id\n      name\n    }\n    version {\n      versionId\n      status\n      itemScope\n      itemType\n      itemId\n      major\n      minor\n      reviewComments {\n        comments\n        rejectionReason\n        status\n        id\n      }\n      id\n    }\n    orientation\n    frameStyle {\n      backgroundColor\n      borderColor {\n        top\n        left\n        right\n        bottom\n      }\n      borderWidth {\n        top\n        left\n        right\n        bottom\n      }\n      borderStyle {\n        top\n        left\n        right\n        bottom\n      }\n      borderRadius {\n        topLeft\n        topRight\n        bottomLeft\n        bottomRight\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6cac1db7703d46fde5c6fe1f00082190';

module.exports = node;
