import { compose, filter, map, prop } from 'ramda';

const getReferentsOnList = filter(prop('isReferent'));

const idsAllReferentFrames = compose(
  map(prop('id')),
  getReferentsOnList
);

export {
  getReferentsOnList,
  idsAllReferentFrames
};
