import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation AddItemToTagMutation($tagId: ID!, $itemId: ID!) {
    addItemToTag(tagId: $tagId, itemId: $itemId) {
      ok
      error
    }
  }
`;

export default ({
  tagId,
  itemId
}, done) => {
  const variables = {
    tagId,
    itemId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ addItemToTag }) => basicMutationOnCompleted(addItemToTag, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
