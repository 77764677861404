/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateOfferNameMutationVariables = {|
  rootOfferId: string,
  offerName: string,
|};
export type UpdateOfferNameMutationResponse = {|
  +updateOfferName: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateOfferNameMutation = {|
  variables: UpdateOfferNameMutationVariables,
  response: UpdateOfferNameMutationResponse,
|};
*/


/*
mutation UpdateOfferNameMutation(
  $rootOfferId: ID!
  $offerName: String!
) {
  updateOfferName(rootOfferId: $rootOfferId, offerName: $offerName) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offerName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rootOfferId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "offerName",
        "variableName": "offerName"
      },
      {
        "kind": "Variable",
        "name": "rootOfferId",
        "variableName": "rootOfferId"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateOfferName",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateOfferNameMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateOfferNameMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d546ae3e49b75e459d1b359751ce87c2",
    "id": null,
    "metadata": {},
    "name": "UpdateOfferNameMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateOfferNameMutation(\n  $rootOfferId: ID!\n  $offerName: String!\n) {\n  updateOfferName(rootOfferId: $rootOfferId, offerName: $offerName) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '91c180c61ee724c2998e1a07409a1107';

module.exports = node;
