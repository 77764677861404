import TextArea from 'antd/lib/input/TextArea';
import { Select } from 'antd';
import { map, prop, path, equals } from 'ramda';
import React, { useEffect } from 'react';
import Loading from '../../components/Loading';
import useOfferMetadata from '../docs/components/tag/useOfferMetadata';
import useFetchQuery from '../../hooks/useFetchQuery';
import { QGetHistoriesFramesQuery } from '../../_graphql/queries/documents/QGetHistoriesFrames';
import { renderDate } from '../docs/components/helpers/dates';
import { isNilOrEmpty, isNotEmpty } from 'ramda-adjunct';

const Metadata = ({ setValues, values, onSelectExternalFrame, onClearFrame, filterOptionFrame, selectedExternalFrame }) => {
  const { offerId, bundleTag, version, metadata: metadataFrame } = values;
  const { metadata, isLoading, reFetch } = useOfferMetadata(offerId, bundleTag);

  const {
    getHistoryFrames: historyFrames,
    isLoading: isLoadingHistoriesFrames,
    reFetch: reFetchHistoriesFrames
  } = useFetchQuery({
    defaultData: [],
    args: {
      offerId,
      bundleTag,
      version,
      nbOfFrame: '20'
    },
    query: QGetHistoriesFramesQuery,
    dataProp: 'getHistoryFrames'
  });

  useEffect(() => {
    reFetch();
    reFetchHistoriesFrames();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleTag]);

  useEffect(() => {
    if (isNilOrEmpty(metadataFrame) || equals('{}', metadataFrame)) setValues('metadata', metadata);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata]);

  if (isLoadingHistoriesFrames) return <Loading />;

  return (
    <div className="mt-4">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="space-y-2">
          {isNotEmpty(historyFrames) && (
            <Select
              style={{ width: 608 }}
              onSelect={(id) => onSelectExternalFrame(historyFrames, id)}
              value={selectedExternalFrame}
              placeholder="Sélectionnez une trame (date - id)"
              allowClear
              onClear={() => onClearFrame(metadata)}
              showSearch
              filterOption={filterOptionFrame}
            >
              {map(frame => (
                <Select.Option
                  key={prop('id', frame)}
                  value={prop('id', frame)}
                >
                  {renderDate(prop('createdAt', frame))} - {prop('externalId', frame)}
                </Select.Option>
              ))(historyFrames)}
            </Select>
          )}
          <TextArea
            rows={15}
            placeholder="Indiquez votre metadata"
            value={metadataFrame}
            onChange={(e) => setValues('metadata', path(['target', 'value'], e))}
          />
        </div>
      )
      }
    </div>
  );
};

export default Metadata;
