import { message } from 'antd';
import {
  all,
  always,
  append,
  compose,
  cond,
  defaultTo,
  equals,
  filter,
  has,
  includes,
  is,
  isNil,
  join,
  map,
  mergeDeepRight,
  path,
  prop,
  propOr,
  reject,
  split,
  take,
  when
} from 'ramda';
import { isNilOrEmpty, propNotEq } from 'ramda-adjunct';
import React, { useContext, useEffect, useState } from 'react';
import treeUtil from 'tree-util';
import { errorMessage } from '../../utils/messageMutation';
import CreateFolderMutation from '../../_graphql/mutations/folders/CreateFolderMutation';
import UpdateFolderMutation from '../../_graphql/mutations/folders/UpdateFolderMutation';
import useEffectMounted from '../commentsWrapper/hooks/useEffectMounted';
import {
  addAllowedAccessFolders,
  addKeys,
  appendElementToFolder,
  cleanFolders,
  setDefaultPropsToElement,
  updateKeys
} from './treeHelper';
import RemoveFoldersMutation from '../../_graphql/mutations/folders/RemoveFoldersMutation';

const defaultValue = {
  data: []
};
const standardConfig = { id: 'id', parentid: 'parent' };

export const TreeContext = React.createContext(defaultValue);

export const useInternalTreeContext = ({ folders: _folders, reFetch, reFetchElement, args, elements, userCanEditOffer, userCanCreateOfferDocument }) => {
  const { offerId, scope, type } = args;
  const [folders, setFolders] = useState([]);
  const [isLoadingOnDelete, setIsLoadingOnDelete] = useState(false);
  const [trees, setTrees] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState(() => {
    const savedExpandedKeys = localStorage.getItem(`${type}-${offerId}-expandedKeys`);
    return savedExpandedKeys ? JSON.parse(savedExpandedKeys) : [];
  });
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState([]);

  useEffect(() => {
    localStorage.setItem(`${type}-${offerId}-expandedKeys`, JSON.stringify(expandedKeys));
    setAutoExpandParent(false);
  }, [expandedKeys, offerId, type]);

  useEffectMounted((mounted) => {
    if (mounted) setFolders(_folders);
  }, [_folders]);

  useEffectMounted((mounted) => {
    const dataElmIds = compose(reject(isNil), map(path(['element', 'elmId'])))(folders);
    const elms = compose(
      map(elm => ({ ...elm, elmId: prop('id', elm) })),
      map(setDefaultPropsToElement),
      reject(w => includes(prop('id', w), dataElmIds)),
      defaultTo([])
    )(elements);

    const foldersTree = compose(
      when(isNilOrEmpty, always([])),
      appendElementToFolder(elements),
      addAllowedAccessFolders(userCanEditOffer || userCanCreateOfferDocument)
    )(folders);

    const trees = compose(
      addKeys('0', 'key'),
      cleanFolders,
      map(propOr([], 'rootNode'))
    )(treeUtil.buildTrees([...elms, ...foldersTree], standardConfig));
    if (mounted) setTrees(trees);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders, elements]);

  const handleResponse = (updatedKeys, type, fnNewData, cb) => (ok, error) => {
    if (!ok) {
      errorMessage();
      return;
    }
    const msg = cond([
      [equals('creation'), always('Création réussie')],
      [equals('update'), always('Modification réussie')],
      [equals('move'), always('L\'élément a été déplacé')],
      [equals('remove'), always('Le dossier a été supprimé')]
    ])(type);
    message.success(msg);
    setExpandedKeys(updatedKeys);
    reFetch();
    if (fnNewData) setFolders(fnNewData);
    if (is(Function, cb)) cb(ok, error);
  };

  const addTree = (parentId = null, updatedKeys) => (data = {}) => {
    const newTree = mergeDeepRight({
      name: 'Nouveau dossier',
      teamId: null,
      offerId,
      parent: parentId,
      scope,
      type
    }, data);

    CreateFolderMutation({ folder: newTree }, handleResponse(updatedKeys, 'creation', append(newTree)));
  };

  const handleUpdateFolderMutation = (parentId, data, updatedKeys, isMoved) => {
    UpdateFolderMutation({
      id: parentId,
      folder: {
        ...data
      }
    }, handleResponse(updatedKeys, isMoved ? 'move' : 'update'));
  };
  const updateTree = (parentId, updatedKeys, isWithdraw) => (data) => {
    const isMoved = has('parent', data);
    if (isWithdraw === true) {
      handleUpdateFolderMutation(parentId, data, null, isMoved);
    } else {
      handleUpdateFolderMutation(parentId, data, updatedKeys, isMoved);
    }
  };

  const handleRemoveFoldersMutation = (foldersIds, updatedKeys, type) => {
    RemoveFoldersMutation({ foldersIds }, handleResponse(updatedKeys, type, filter(propNotEq('id', foldersIds))));
  };

  const removeTree = (foldersIds, folder, isFile) => {
    const nodeKey = prop('key', folder);
    if (isFile) {
      const updatedKeys = updateKeys(expandedKeys, [nodeKey], 'move');
      handleRemoveFoldersMutation(foldersIds, updatedKeys, 'move');
    } else {
      const updatedKeys = updateKeys(expandedKeys, [nodeKey], 'remove');
      handleRemoveFoldersMutation(foldersIds, updatedKeys, 'remove');
    }
  };

  const getElement = folder => ({ ...prop('element', folder), key: prop('key', folder) });
  const onExpand = (keys) => {
    const parentKeys = map(key => join('-', take(2, split('-', key))), keys);
    const allParentsPresent = all(parent => includes(parent, keys), parentKeys);
    const updatedKeysWithParent = allParentsPresent ? keys : filter(key => includes(key, parentKeys))(keys);
    setExpandedKeys(updatedKeysWithParent);
    setAutoExpandParent(false);
  };

  return {
    trees,
    addTree,
    updateTree,
    removeTree,
    getElement,
    reFetchElement,
    reFetchFolder: reFetch,
    args,
    expandedKeys,
    setExpandedKeys,
    isLoadingOnDelete,
    setIsLoadingOnDelete,
    autoExpandParent,
    setAutoExpandParent,
    checkedKeys,
    setCheckedKeys,
    onExpand
  };
};

export const useTreeContext = () => useContext(TreeContext);

export const TreeProvider = ({ children, args, reFetch: reFetchElement, folders, reFetchFolders, ...restProps }) => {
  const context = useInternalTreeContext({ args, folders, reFetch: reFetchFolders, reFetchElement, ...restProps });
  return (
    <TreeContext.Provider value={context}>
      {/* NE PAS TOUCHER */}
      {children && children}
    </TreeContext.Provider>
  );
};
