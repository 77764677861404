import { useState } from 'react';
import * as R from 'ramda';
import DeleteDocumentMutation from '../_graphql/mutations/documents/DeleteDocumentMutation';
import DeleteRawDocumentMutation from '../_graphql/mutations/documents/DeleteRawDocumentMutation';
import DeleteRawDocumentsMutation from '../_graphql/mutations/documents/DeleteRawDocumentsMutation';
import DuplicateDocumentMutation from '../_graphql/mutations/documents/DuplicateDocumentMutation';
import PreviewDocumentHtmlMutation from '../_graphql/mutations/documents/PreviewDocumentHtmlMutation';
import PreviewDocumentMutation from '../_graphql/mutations/documents/PreviewDocumentMutation';
import { deleteMutationMessage, errorMessage, updateMutationMessage } from '../utils/messageMutation';
import DeleteDocumentsMutation from '../_graphql/mutations/documents/DeleteDocumentsMutation';

export const useDocumentsTable = () => {
  const [documentToCopy, setDocumentToCopy] = useState({});
  const [previewHtml, setPreviewHtml] = useState();
  const [documentPreviewUrl, setDocumentPreviewUrl] = useState();
  const [documentIdModalToShow, setDocumentIdModalToShow] = useState();

  const deleteDocument = (documentId, cb) => () => DeleteDocumentMutation({ documentId }, (ok, error) => deleteMutationMessage(ok, error, 'document', cb));

  const deleteDocuments = (documentsIds, cb) => DeleteDocumentsMutation({ documentsIds: documentsIds }, cb);

  const deleteRawDocument = (rawDocumentId, cb) => DeleteRawDocumentMutation({ rawDocumentId }, cb);

  const deleteRawDocuments = (rawDocumentsIds, cb) => DeleteRawDocumentsMutation({ rawDocumentsIds }, (ok, error) => deleteMutationMessage(ok, error, 'document importé', cb, false, false, true));

  const updateDocumentWidgets = (cb, reload = true) => {
    const { currentDocument, documentToCopyId } = documentToCopy;
    DuplicateDocumentMutation({
      documentToCopyId,
      actualDocumentId: R.prop('id', currentDocument)
    }, (ok, error) => updateMutationMessage(ok, error, 'document', cb, reload));
  };

  const previewDocumentHtml = (documentId) => {
    setPreviewHtml(undefined);
    setDocumentIdModalToShow(undefined);

    PreviewDocumentHtmlMutation({ variables: '{}', documentId }, (ok, error, pagesHtml) => {
      if (ok && !error) {
        setPreviewHtml(pagesHtml);
        setDocumentIdModalToShow(documentId);
      } else {
        errorMessage();
      }
    });
  };

  const previewDocument = (pages, variables, padding, documentId) => {
    variables = R.isNil(variables) ? '[]' : JSON.stringify(variables);
    PreviewDocumentMutation({
      pages,
      variables,
      padding,
      documentId
    }, (ok, error, url) => {
      if (ok && !error) {
        setDocumentPreviewUrl(url);
      } else {
        errorMessage();
      }
    });
  };

  return {
    setDocumentToCopy,
    documentToCopy,
    documentIdModalToShow,
    deleteDocument,
    deleteDocuments,
    deleteRawDocument,
    deleteRawDocuments,
    updateDocumentWidgets,
    previewHtml,
    setPreviewHtml,
    previewDocumentHtml,
    previewDocument,
    documentPreviewUrl
  };
};

export default useDocumentsTable;
