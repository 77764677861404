import React from 'react';

const CopyIcon = () => (
  <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
    <title>32EE5F1E-6B5B-4292-95BB-C290664B847A</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1233 -978)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(289 800)">
          <g transform="translate(35 80)">
            <g transform="translate(874 90)">
              <g transform="translate(35 8)">
                <path d="m15.706 2.2072-1.914-1.9141c-0.18593-0.18778-0.44218-0.29312-0.7078-0.29312h-5.1124c-1.1047 0-2 0.89562-2 2l6.0936e-4 8c0.027515 1.1062 0.92437 2 2.0275 2h5.9999c1.1 0 2-0.9 2-2v-7.0859c0-0.265-0.10625-0.51938-0.29374-0.70688zm-1.2062 7.7928c0 0.27612-0.22387 0.5-0.49999 0.5h-6.028c-0.27619 0-0.5-0.22388-0.5-0.5v-7.9959c0-0.27612 0.22387-0.5 0.5-0.5h3.9999l0.028124 1.4959c0 0.55219 0.4478 1 0.99998 1h1.4718v6h0.028124zm-5.9999 4c0 0.27612-0.22387 0.5-0.49999 0.5h-6.028c-0.27618 0-0.49999-0.22388-0.49999-0.5l0.027499-7.9969c0-0.27612 0.22387-0.5 0.49999-0.5h3.0006v-1.5031h-3.0003c-1.1047 0-2 0.89531-2 2l3.0624e-4 8c6.8749e-5 1.1031 0.89561 2 2 2h5.9999c1.1 0 2-0.9 2-2v-1h-1.4718l-0.028124 1z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CopyIcon;
