import { times } from 'ramda';
import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const DocumentWrapper = ({ documentUrl, setNumberOfPages, numberOfPages }) => {
  return (
    <Document
      file={documentUrl}
      onLoadSuccess={({ numPages }) => setNumberOfPages(numPages)}
      renderMode="canvas"
      className="pdf-document"
      error="Une erreur est survenue pendant le chargement du document."
    >
      {times(index => {
        return (
          <Page
            key={`${documentUrl}Page${index}`}
            pageNumber={index + 1}
            scale={1.5}
            className="pdf-document-page"
          />
        );
      })(numberOfPages)}
    </Document>
  );
};

export default DocumentWrapper;
