import React from 'react';
import * as R from 'ramda';
import { Dot } from '..';
import { CaretRightFilled } from '@ant-design/icons';
import { Collapse, Timeline, Popconfirm } from 'antd';
import VersionsTreeItem from './VersionsTreeItem';
import { Link } from 'react-router-dom';
import UpdateVersionStatusMutation from '../../_graphql/mutations/versions/UpdateVersionStatusMutation';
import RemoveVersionMutation from '../../_graphql/mutations/versions/RemoveVersionMutation';
import { getStatusLabel } from '../../lib/status';
import { errorMessage, successMessage } from '../../utils/messageMutation';
import RemoveCacheMutation from '../../_graphql/mutations/RemoveCacheMutation';

const mapIndexed = R.addIndex(R.map);

const timelineItemStyle = {
  paddingBottom: '10px'
};

const panelItemStyle = {
  borderBottom: '1px solid #EBEAEA'
};

const VersionsTree = ({
  items,
  userCanEdit,
  editLinkPattern,
  suppressionLabel,
  archiveLabel,
  nameField = 'name',
  setRefetch
}) => {
  const onArchive = (versionId, itemId) => () => {
    UpdateVersionStatusMutation({ versionId, status: 'archived', itemId }, (ok, error) => {
      if (ok && !error) {
        successMessage('version', 'archivée', false, true);
      } else {
        errorMessage();
      }
    });
  };

  const onRemove = (versionId, itemId) => () => {
    RemoveVersionMutation({ versionId }, (ok, error) => {
      if (ok && !error) {
        successMessage('version', 'supprimée', false, true);
        RemoveCacheMutation({ key: `documents-${itemId}` }, () => {});
        RemoveCacheMutation({ key: `widgets-${itemId}` }, () => {});
        !!setRefetch && setRefetch(true);
      } else {
        errorMessage();
      }
    });
  };

  const statusIs = (status, version) => R.propEq('status', status, version);
  const buildEditLink = R.replace(':itemId', R.__, editLinkPattern);

  items = R.reject(R.propSatisfies(R.isNil, 'version'))(items);

  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <CaretRightFilled
          style={{ fontSize: '18px', position: 'relative', top: '3px', marginRight: '5px', color: isActive ? '#0197DC' : '#42505C' }}
          rotate={isActive ? 90 : 0}
        />
      )}
      bordered={false}
      className="versions_tree bg-white"
    >
      {R.map(item => {
        if (R.either(R.isNil, R.isEmpty)(R.prop('versions', item))) {
          return null;
        }

        const lastVersion = R.compose(
          R.head,
          R.propOr([], 'versions')
        )(item);

        const majorVersions = R.compose(
          R.groupBy(R.path(['version', 'major'])),
          R.propOr([], 'versions')
        )(item);

        const majorVersionsNumbers = R.compose(
          R.reverse,
          R.keys
        )(majorVersions);

        return (
          <Collapse.Panel
            key={R.prop('id', item)}
            style={panelItemStyle}
            header={(
              <VersionsTreeItem
                name={R.prop(nameField, lastVersion)}
                id={R.prop('id', lastVersion)}
                dates={R.prop('dates', lastVersion)}
                version={R.prop('version', lastVersion)}
                editLink={buildEditLink(R.prop('id', lastVersion))}
                suppressionLabel={suppressionLabel}
                archiveLabel={archiveLabel}
              />
            )}
          >
            <Timeline mode="left">
              {R.map(versionNumber => {
                const minorVersions = R.propOr([], versionNumber, majorVersions);

                return (
                  <Timeline.Item
                    key={`${item.id}-version-${versionNumber}`}
                    color="green"
                    dot={<Dot color="#61c397" />}
                    style={timelineItemStyle}
                  >
                    Version {versionNumber}

                    <Timeline mode="left" style={{ marginTop: '12px' }}>
                      {mapIndexed((itemVersion, index) => {
                        const itemId = R.path(['version', 'itemId'], itemVersion);
                        const versionId = R.path(['version', 'versionId'], itemVersion);
                        const major = R.path(['version', 'major'], itemVersion);
                        const minor = R.path(['version', 'minor'], itemVersion);

                        return (
                          <Timeline.Item
                            key={`${R.prop('id', item)}-version-${versionNumber}-${itemVersion.id}`}
                            color={index !== 0 ? 'gray' : 'blue'}
                            dot={<Dot color={index !== 0 ? 'gray' : '#0197dc'} />}
                            style={timelineItemStyle}
                          >
                            <Link to={buildEditLink(R.prop('id', itemVersion))}>Version {`${major}.${minor}`}</Link>
                            <span>
                      &nbsp;({getStatusLabel(R.path(['version', 'status'], itemVersion))})
                            </span>

                            {userCanEdit && (
                              <div className="float-right">
                                {statusIs('archived', R.prop('version', itemVersion))
                                  ? (
                                    <Popconfirm
                                      placement="topRight"
                                      title={suppressionLabel}
                                      onConfirm={onRemove(versionId, itemId)}
                                      okText="Oui"
                                      cancelText="Non"
                                    >
                                      <a href="#">Supprimer</a>
                                    </Popconfirm>
                                  )
                                  : (
                                    <Popconfirm
                                      placement="topRight"
                                      title={archiveLabel}
                                      onConfirm={onArchive(versionId, itemId)}
                                      okText="Oui"
                                      cancelText="Non"
                                    >
                                      <a href="#">Archiver</a>
                                    </Popconfirm>
                                  )
                                }
                              </div>
                            )}
                          </Timeline.Item>
                        );
                      })(minorVersions)}
                    </Timeline>
                  </Timeline.Item>
                );
              })(majorVersionsNumbers)}
            </Timeline>
          </Collapse.Panel>
        );
      })(items)}
    </Collapse>
  );
};

export default VersionsTree;
