import React from 'react';
import styled from 'styled-components';

import {
  Row, Col
} from 'antd';

import {
  Link
} from 'react-router-dom';

import { Activity } from '../team/components';
import Menu from '../../containers/menu';
import Layout from '../../layout/default';
import withUser from '../../withUser';

const gridStyle = {
  padding: '15px 40px 15px 30px',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  gap: '30px'
};

const chevronStyle = {
  marginLeft: 'auto'
};

const MenuItem = styled(Link)`
  font-size: 19px;
  color: #005982;
  text-transform: uppercase;
  font-weight: bold;
`;

const Home = ({ userIsAdmin }) => {
  return (
    <Layout menu={<Menu />}>
      <div>
        <Row gutter={40}>
          {userIsAdmin && (
            <Col span="12">
              <MenuItem to="/offre/creer">
                <div style={gridStyle} className="bg-white">
                  <img src="/images/illu-1@2x.png" alt="créer une offre" width="86" />
                  {'Créer une offre'}
                  <img src="/images/chevron-left.svg" alt="créer une offre" style={chevronStyle} />
                </div>
              </MenuItem>
            </Col>
          )}
          <Col span="12">
            <MenuItem to="/equipe">
              <div style={gridStyle} className="bg-white">
                <img src="/images/illu-2-bis@2x.png" alt="gérer mon équipe" width="86" />
                {'Gérer mon équipe'}
                <img src="/images/chevron-left.svg" alt="gérer mon équipe" style={chevronStyle} />
              </div>
            </MenuItem>
          </Col>
        </Row>
        <Activity />
      </div>
    </Layout>
  );
};

export default withUser(Home);
