import { commitMutation, graphql } from 'react-relay';
import environment from '../../.';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation DuplicateDocumentAndItsWidgetsMutation($documentId: ID!, $offerId: ID!) {
    duplicateDocumentAndItsWidgets(documentId: $documentId, offerId: $offerId) {
      ok
      error
      copiedDocument {
          name
          _id
          dates{
            creation
            lastUpdate
          }
      }
    }
  }
`;

export default ({
  documentId,
  offerId
}, done) => {
  const variables = {
    documentId,
    offerId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ duplicateDocumentAndItsWidgets }) => {
        const {
          ok,
          error,
          copiedDocument
        } = duplicateDocumentAndItsWidgets;
        done(ok, error, copiedDocument);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
