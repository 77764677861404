import { Table } from 'antd';
import { prop } from 'ramda';
import React from 'react';
import { RenderLastUpdate } from '../helpers/dates';

const TableDates = ({ renderDate }) => {
  return (
    <>
      <Table.Column
        title="Création"
        dataIndex="dates"
        width={400}
        render={(dates) => dates && renderDate(prop('creation', dates))}
      />

      <RenderLastUpdate renderDate={renderDate}/>
    </>
  );
};

export default TableDates;
