import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation UpdateOfferNameMutation($rootOfferId: ID!, $offerName: String!) {
    updateOfferName(rootOfferId: $rootOfferId, offerName: $offerName) {
      ok
      error
    }
  }
`;

export default ({ rootOfferId, offerName }, done) => {
  const variables = {
    rootOfferId,
    offerName
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateOfferName }) => basicMutationOnCompleted(updateOfferName, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
