import { useState } from 'react';
import * as R from 'ramda';
import UpdateReviewCommentsMutation from '../_graphql/mutations/reviews/UpdateReviewCommentsMutation';
import AcceptReviewMutation from '../_graphql/mutations/reviews/AcceptReviewMutation';
import RejectReviewMutation from '../_graphql/mutations/reviews/RejectReviewMutation';
import { errorMessage, successMessage } from '../utils/messageMutation';

const useReview = (version, reviewComments, item) => {
  const defaultComments = R.propOr('', 'comments', reviewComments);

  const [state, setState] = useState({
    loading: {
      updateComment: false,
      accept: false,
      reject: false
    },
    comments: defaultComments,
    rejectionReason: ''
  });

  const updateState = (k, v) => setState(R.assocPath(k.split('.'), v));

  const isValid = !R.isEmpty(R.prop('comments', state)) && !R.equals(defaultComments, R.prop('comments', state));

  const updateComments = () => {
    updateState('loading.updateComment', true);

    UpdateReviewCommentsMutation({
      versionId: R.prop('versionId', version),
      comments: R.prop('comments', state)
    }, (ok, error) => {
      updateState('loading.updateComment', false);

      if (ok && !error) {
        successMessage('commentaires', 'mis à jour');
      } else {
        errorMessage();
      }
    });
  };

  const acceptReview = () => {
    updateState('loading.accept', true);

    AcceptReviewMutation({ versionId: R.prop('versionId', version), itemId: R.prop('id', item) }, (ok, error) => {
      updateState('loading.accept', false);

      if (ok && !error) {
        successMessage('offre', 'publiée', true);
      } else {
        errorMessage();
      }
    });
  };

  const rejectReview = () => {
    updateState('loading.reject', true);

    RejectReviewMutation({ versionId: R.prop('versionId', version), reason: R.prop('rejectionReason', state), itemId: R.prop('id', item) }, (ok, error) => {
      updateState('loading.reject', false);

      if (ok && !error) {
        successMessage('publication de l\'offre', 'refusée', false, true);
      } else {
        errorMessage();
      }
    });
  };

  return {
    ...state,
    updateState,
    isValid,
    updateComments,
    acceptReview,
    rejectReview,
    isReview: R.propEq('status', 'review', version),
    isDraft: R.propEq('status', 'draft', version),
    reviewIsRejected: R.propEq('status', 'rejected', reviewComments)
  };
};

export default useReview;
