import { commitMutation, graphql } from 'react-relay';

import environment from '../../.';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation CreateDocumentMutation($name: String!, $pages: [DocumentSectionInput!]!, $scope: DocumentScopeEnum!, $offerId: ID, $variables: String, $padding: PaddingInput, $orientation: OrientationEnum!, $renderingEngineVersion: String, $formatPdf: formatPdfEnum) {
    createDocument(name: $name, pages: $pages, scope: $scope, offerId: $offerId,  variables: $variables, padding: $padding, orientation: $orientation, renderingEngineVersion: $renderingEngineVersion, formatPdf: $formatPdf) {
      ok
      error
      documentId
    }
  }
`;

export default ({
  name,
  pages,
  scope,
  offerId,
  variables,
  padding,
  orientation,
  renderingEngineVersion,
  formatPdf
}, done) => {
  const args = {
    name,
    pages,
    scope,
    offerId,
    variables,
    padding,
    orientation,
    renderingEngineVersion,
    formatPdf
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables: args,
      onCompleted: ({ createDocument }) => {
        const {
          ok,
          error,
          documentId
        } = createDocument;
        done(ok, error, documentId);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
