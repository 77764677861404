import { RotateRightOutlined } from '@ant-design/icons';
import { Select, Tooltip } from 'antd';
import React from 'react';

const SelectOrientation = ({ defaultOrientation, changeOrientation, currentOrientation, hasLandscapeWidget, disabled }) => {
  const isDisabled = disabled || (hasLandscapeWidget && currentOrientation === 'landscape');

  return (
    <>
      <Tooltip title="Mise en page" color="#0197DC">
        <div className="inline-flex">
          <RotateRightOutlined
            style={{
              position: 'relative',
              padding: '0 11px',
              color: 'rgba(0, 0, 0, 0.85)',
              fontWeight: 'normal',
              fontSize: '14px',
              textAlign: 'center',
              backgroundColor: '#fafafa',
              border: '1px solid #d9d9d9',
              borderRadius: '4px 0 0 4px',
              transition: 'all 0.3s',
              height: '32px',
              display: 'flex',
              alignItems: 'center'
            }}
          />

          <Select
            style={{
              width: '150px',
              height: '30px',
              textAlign: 'left',
              backgroundColor: 'transparent',
              border: '0',
              borderRadius: '0px 4px 4px 0px',
              outline: '0',
              transition: 'all 0.3s linear',
              WebkitAppearance: 'textfield !important',
              MozAppearance: 'textfield !important',
              appearance: 'textfield !important'
            }}
            defaultValue={defaultOrientation}
            onChange={changeOrientation}
            disabled={isDisabled}
          >
            <Select.Option value="portrait">Portrait</Select.Option>
            <Select.Option value="landscape">Paysage</Select.Option>
          </Select>
        </div>
      </Tooltip>
    </>
  );
};

export default SelectOrientation;
