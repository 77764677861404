/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentRuleActionEnum = "display" | "hide" | "%future added value";
export type DocumentRuleConditionEnum = "isDefined" | "isEqualTo" | "isGreaterThan" | "isLessThan" | "isNotEqualTo" | "isUndefined" | "%future added value";
export type DocumentRuleOperatorEnum = "AND" | "OR" | "%future added value";
export type DocumentScopeEnum = "global" | "offer" | "%future added value";
export type TagInput = {|
  color?: ?string,
  itemType?: ?string,
  itemsIds?: ?$ReadOnlyArray<?string>,
  name?: ?string,
  offerId?: ?string,
  rules?: ?$ReadOnlyArray<?DocumentTagInput>,
  scope?: ?DocumentScopeEnum,
|};
export type DocumentTagInput = {|
  itemId?: ?string,
  rules?: ?$ReadOnlyArray<?DocumentRuleInput>,
|};
export type DocumentRuleInput = {|
  action?: ?DocumentRuleActionEnum,
  conditions?: ?$ReadOnlyArray<?DocumentRuleConditionInput>,
|};
export type DocumentRuleConditionInput = {|
  condition?: ?DocumentRuleConditionEnum,
  operator?: ?DocumentRuleOperatorEnum,
  value?: ?string,
  variable?: ?string,
|};
export type CreateTagMutationVariables = {|
  tag: TagInput
|};
export type CreateTagMutationResponse = {|
  +createTag: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type CreateTagMutation = {|
  variables: CreateTagMutationVariables,
  response: CreateTagMutationResponse,
|};
*/


/*
mutation CreateTagMutation(
  $tag: TagInput!
) {
  createTag(tag: $tag) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tag"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tag",
        "variableName": "tag"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "createTag",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTagMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTagMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fe67c5b7adad6c373dc67b5cd8ae4109",
    "id": null,
    "metadata": {},
    "name": "CreateTagMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTagMutation(\n  $tag: TagInput!\n) {\n  createTag(tag: $tag) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b3d603bc20c7142e08976b2bd01c5d55';

module.exports = node;
