import React from 'react';
import * as R from 'ramda';
import Section from './Section';
import { Collapse, List, Badge } from 'antd';

const getTypeLabel = type => R.propOr(type, type, {
  document: 'Documents'
});

const getStatusData = status => R.propOr({ label: status, color: 'white' }, status, {
  pending: {
    label: 'À traiter',
    color: '#ffd88c'
  },
  accepted: {
    label: 'Validé',
    color: '#00b800'
  },
  rejected: {
    label: 'Rejeté',
    color: '#ff4040'
  }
});

const useItemComments = (comments) => {
  const allComments = R.compose(
    R.flatten,
    R.pluck('comments')
  )(comments);

  const groupedComments = R.groupBy(R.prop('type'))(comments);

  return {
    allComments,
    groupedComments
  };
};

const ItemCommentStatus = ({ status, className = '' }) => {
  return (
    <span
      className={`rounded-xl text-xs px-2 text-center w-90px ${className}`}
      style={{ backgroundColor: getStatusData(status).color, marginTop: '3px' }}
    >
      {getStatusData(status).label}
    </span>
  );
};

const ItemComments = ({
  comments,
  title
}) => {
  const {
    allComments,
    groupedComments
  } = useItemComments(comments);

  if (R.either(R.isNil, R.isEmpty)(comments) || R.either(R.isNil, R.isEmpty)(allComments)) {
    return null;
  }

  return (
    <Section
      title={title}
      style={{ lineHeight: 'unset' }}
      titleStyle={{ marginBottom: '0' }}
    >

      <Collapse bordered={false}>
        {R.compose(
          R.map(type => {
            const itemsComments = R.propOr({}, type, groupedComments);

            if (R.isEmpty(itemsComments)) {
              return null;
            }

            return (
              <Collapse.Panel
                key={`item-comments-${type}`}
                header={getTypeLabel(type)}
              >
                <Collapse>
                  {R.map(itemComment => {
                    let {
                      itemId,
                      name,
                      comments = []
                    } = itemComment;

                    comments = R.reject(R.isNil, comments);

                    if (R.length(comments) === 0) {
                      return null;
                    }

                    return (
                      <Collapse.Panel
                        key={`item-comments-${type}-${itemId}`}
                        header={name}
                        extra={<Badge count={R.length(comments)}/>}
                      >

                        <List
                          bordered
                          size="small"
                          dataSource={comments}
                          renderItem={comment => {
                            const {
                              id,
                              status,
                              messages = []
                            } = comment;

                            const firstMessage = R.nth(0, messages);

                            if (R.isNil(firstMessage) || R.isNil(firstMessage.text)) {
                              return null;
                            }

                            return (
                              <List.Item
                                key={`item-comments-${type}-${itemId}-${id}`}
                              >
                                <List.Item.Meta
                                  title={firstMessage.text}
                                  description={(
                                    <div className="text-black">
                                      <span className="text-sm">(<b>{R.prop('userName', firstMessage)}</b>)&nbsp;</span>

                                      <ItemCommentStatus
                                        status={status}
                                      />
                                    </div>
                                  )}
                                />
                              </List.Item>
                            );
                          }}
                        />

                      </Collapse.Panel>
                    );
                  })(itemsComments)}
                </Collapse>
              </Collapse.Panel>
            );
          }),
          R.keys
        )(groupedComments)}
      </Collapse>

    </Section>
  );
};

export default ItemComments;
