import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../.';
import { basicMutationOnCompleted, onErrorMutation } from './common/common';

const mutation = graphql`
mutation ResetPasswordRequestMutation($email: String!) {
  resetPasswordRequest(email: $email) {
    ok
    error
  }
}
`;

export default (email, done) => {
  const variables = {
    email
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: ({ resetPasswordRequest }) => basicMutationOnCompleted(resetPasswordRequest, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
