import React from 'react';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import { CalendarOutlined } from '@ant-design/icons';

import {
  Row,
  Col
} from 'antd';
import moment from 'moment';
import { getStatusLabel } from '../../lib/status';

const VersionsTreeItem = ({
  id,
  name,
  dates,
  version,
  editLink
}) => {
  const statusIs = status => R.propEq('status', status, version);
  const styles = {
    width: '90%',
    display: 'inline-flex',
    color: '#8A98A5',
    fontSize: '16px'
  };

  return (
    <Row align="middle" style={styles}>
      <Col span={10}>
        {!statusIs('archived')
          ? (
            <Link to={editLink}>
              {name}
            </Link>
          )
          : name
        }
      </Col>

      <Col span={4}>
        <div>
          <CalendarOutlined style={{ marginRight: '7px', fontSize: '18px' }} />
          {moment(R.prop('lastUpdate', dates)).format('DD/MM/YY')}
        </div>
      </Col>

      <Col span={6}>
        <div className={`badge ${R.prop('status', version)}`}>
          {getStatusLabel(R.prop('status', version))}
        </div>
      </Col>

      <Col span={4}>
        {`version ${R.prop('major', version)}.${R.prop('minor', version)}`}
      </Col>
    </Row>
  );
};

export default VersionsTreeItem;
