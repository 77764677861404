import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../index';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';
import { updaterReview } from './helpers/updaterReview';

const mutation = graphql`
  mutation AcceptReviewMutation($versionId: ID!) {
    acceptReview(versionId: $versionId) {
      ok
      error
    }
  }
`;

export default ({ itemId, versionId }, done) => {
  const variables = {
    versionId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { acceptReview }) => updaterReview('accept', proxyStore, acceptReview, itemId, null, versionId),
      onCompleted: ({ acceptReview }) => basicMutationOnCompleted(acceptReview, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
