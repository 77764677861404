export default [{
  key: 'isDefined',
  label: 'est défini'
}, {
  key: 'isUndefined',
  label: 'n\'est pas défini'
}, {
  key: 'isEqualTo',
  label: 'est égal à',
  withValue: true
}, {
  key: 'isNotEqualTo',
  label: 'n\'est pas égal à',
  withValue: true
}, {
  key: 'isGreaterThan',
  label: 'est supérieur à',
  withValue: true
}, {
  key: 'isLessThan',
  label: 'est inférieur à',
  withValue: true
}];
