import React from 'react';

const TrashIcon = () => (
  <svg version="1.1" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
    <title>06F7414B-A8FF-44A7-A5DA-BD66BD20825F</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1383 -717)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(941 367)">
          <g transform="translate(406 350)">
            <g transform="translate(36)">
              <path d="m13.5 2.5h-2.5744l-1.0625-1.7734c-0.2975-0.45072-0.75688-0.72656-1.2819-0.72656h-3.1625c-0.525 0-1.0125 0.27584-1.2812 0.72656l-1.0631 1.7734h-2.5744c-0.27734 0-0.5 0.22281-0.5 0.5v0.5c0 0.27812 0.22266 0.5 0.5 0.5h0.5v10c0 1.1047 0.89531 2 2 2h8c1.1047 0 2-0.89531 2-2v-10h0.5c0.27812 0 0.5-0.22188 0.5-0.5v-0.5c0-0.27719-0.22188-0.5-0.5-0.5zm-8.1281-0.91c0.03125-0.054688 0.09375-0.09 0.15938-0.09h2.9375c0.066406 0 0.12891 0.035156 0.16016 0.089844l0.54609 0.91016h-4.35l0.54688-0.91zm5.6281 12.91h-8c-0.27616 0-0.5-0.22384-0.5-0.5v-10h9v10c0 0.275-0.225 0.5-0.5 0.5zm-4-1.5c0.27638 0 0.5-0.22362 0.5-0.5v-6.5c0-0.27638-0.22362-0.5-0.5-0.5s-0.5 0.225-0.5 0.5v6.5c0 0.275 0.225 0.5 0.5 0.5zm-2.5 0c0.275 0 0.5-0.225 0.5-0.5v-6.5c0-0.27638-0.22362-0.5-0.5-0.5s-0.5 0.225-0.5 0.5v6.5c0 0.275 0.225 0.5 0.5 0.5zm5 0c0.27638 0 0.5-0.22362 0.5-0.5v-6.5c0-0.27638-0.22362-0.5-0.5-0.5s-0.5 0.225-0.5 0.5v6.5c0 0.275 0.225 0.5 0.5 0.5z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default TrashIcon;
