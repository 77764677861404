import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PreviewDocumentHtmlMutation from '../_graphql/mutations/documents/PreviewDocumentHtmlMutation';
import { message } from 'antd';
import CreateDocumentMutation from '../_graphql/mutations/documents/CreateDocumentMutation';
import UpdateDocumentMutation from '../_graphql/mutations/documents/UpdateDocumentMutation';
import DeleteDocumentMutation from '../_graphql/mutations/documents/DeleteDocumentMutation';
import useRules from '../pages/docs/components/rules/useRules';
import { defaultPage, useSections } from './useSections';
import { BASE_PADDING_DOCUMENT, CLASSNAME_DOCUMENT_LANDSCAPE, CLASSNAME_DOCUMENT_PORTAIT, DEFAULT_HEIGHT_PAGE_LANDSCAPE, DEFAULT_HEIGHT_PAGE_PORTRAIT, SCOPE_OFFER } from '../_CONST';
import { useWidgets } from './useWidgets';
import { errorMessage, successMessage } from '../utils/messageMutation';
import { T, always, and, assoc, compose, cond, defaultTo, dissoc, equals, filter, has, ifElse, is, isEmpty, isNil, lensPath, lensProp, map, over, path, prop, propEq, propOr, propSatisfies, reject, toPairs, when, assocPath, uniq, difference } from 'ramda';
import { isNilOrEmpty, isNotEmpty, isNotNil, isNotNilOrEmpty, notEqual } from 'ramda-adjunct';
import UpdateCacheMutation from '../_graphql/mutations/UpdateCacheMutation';
import { getVariablesOnWidgetHtml } from '../pages/helpers/getVariablesOnWidgetHtml';

const useDocument = (
  document = {},
  options = {},
  comments,
  globalVariables,
  predefinedVariables,
  widgetsFetch,
  setRefetch
) => {
  const [widgets, setWidgets] = useState(widgetsFetch);
  const [hasLandscapeWidget, setHasLandscapeWidget] = useState(false);
  const [widgetIdEditing, setWidgetIdEditing] = useState();
  const [widgetCreated, setWidgetCreated] = useState();
  const [isOpenCreateWidgetModal, setIsOpenCreateWidgetModal] = useState(false);
  const [duplicateWidgetPlacement, setDuplicateWidgetPlacement] = useState(false);

  useEffect(() => {
    if (isNotNil(widgetCreated)) {
      const variables = getVariablesOnWidgetHtml(JSON.parse(propOr([], 'contents', widgetCreated)));
      setWidgets([{ ...widgetCreated, variables }, ...widgets]);
      UpdateCacheMutation({ date: new Date(), key: `widgets-${prop('offerId', options)}` }, () => {});
      const globalVariablesNames = map(prop('name'), globalVariables);
      if (isOpenCreateWidgetModal) {
        const { rowIndex, index, sectionIndex, type } = isOpenCreateWidgetModal;
        updateWidget(globalVariablesNames, sectionIndex, type)(rowIndex, index, prop('id', widgetCreated));
        setWidgetCreated();
        setIsOpenCreateWidgetModal(false);
      } else {
        const { rowIndex, index, sectionIndex, type } = duplicateWidgetPlacement;
        updateWidget(globalVariablesNames, sectionIndex, type)(rowIndex, index, prop('id', widgetCreated));
        setWidgetCreated();
        setDuplicateWidgetPlacement(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetCreated]);

  const [rowIndexEditing, setRowIndexEditing] = useState([]);
  const isEdit = compose(
    isNotNil,
    prop('id')
  )(document);

  const history = useHistory();
  const [previewHtmlOnReview, setPreviewHtmlOnReview] = useState();
  const [documentIdModalToShow, setDocumentIdModalToShow] = useState();

  const getInfoPageContent = (p, type) => {
    return over(
      lensProp(type),
      compose(reject(propSatisfies(isEmpty, 'header')), defaultTo([]))
    )(p);
  };
  const [state, setState] = useState({
    values: {
      name: propOr('', 'name', document),
      pages: compose(
        map(
          compose(
            (p) => getInfoPageContent(p, 'header'),
            (p) => getInfoPageContent(p, 'widgets'),
            (p) => getInfoPageContent(p, 'footer'),
            (p) => assoc('breakPage', propOr(true, 'breakPage', p), p),
            (p) => assoc('padding', propOr(BASE_PADDING_DOCUMENT, 'padding', p), p),
            (p) => assoc('rules', propOr([], 'rules', p), p)
          )
        ),
        defaultTo([defaultPage]),
        prop('pages')
      )(document),
      variables: compose(
        map((v) => ifElse(
          has('rows'),
          () => compose(
            dissoc('rows'),
            assoc('value', { rows: prop('rows', v) }),
            assoc('type', 'JSON')
          )(v),
          always(v)
        )(v)),
        JSON.parse,
        propOr('{}', 'variables')
      )(document),
      padding: propOr(BASE_PADDING_DOCUMENT, 'padding', document),
      orientation: propOr('portrait', 'orientation', document),
      renderingEngineVersion: propOr('v2', 'renderingEngineVersion', document),
      formatPdf: propOr('PDF', 'formatPdf', document),
      isVariablesHighlighted: propOr(false, 'isVariablesHighlighted', document)
    },
    loading: false,
    deleteLoading: false,
    previewLoading: false,
    previewHtmlLoading: false,
    previewHtml: undefined,
    documentPreviewUrl: undefined
  });

  const {
    widgetsAreDefined,
    addWidget,
    addRowWidget,
    removeWidget,
    updateWidget,
    moveRowWidget,
    updateBreakPage,
    addWidgetRule,
    removeWidgetRule,
    updateWidgetRule,
    updateManualBreakPage,
    updatePaddingRow,
    addManualBreakPageRule,
    removeManualBreakPageRule,
    updateManualBreakPageRule
  } = useWidgets(state, setState);

  const {
    getRulesAreValid,
    allRulesAreValid,
    getConditionsAreValid
  } = useRules(state);

  const {
    editSections,
    updatePaddingSection,
    addRuleSection,
    updateRuleSection,
    removeRuleSection
  } = useSections(state, setState);

  const pages = path(['values', 'pages'], state);
  useEffect(() => {
    compose(
      map(([sectionIndex, section]) => map((widgets) => compose(
        map(([rowIndex, row]) => {
          const paddingRow = prop('padding', row);
          when(
            isNil,
            () => setState(over(
              lensPath(['values', 'pages', Number(sectionIndex), 'widgets', Number(rowIndex)]),
              assoc('padding', BASE_PADDING_DOCUMENT)
            ))
          )(paddingRow);
        }),
        filter(([_, row]) => and(is(Object, row), prop('widgets', row))),
        toPairs
      )(widgets))(section)),
      toPairs
    )(pages);
  }, [pages]);
  const [classNameOrientationDocument, setClassNameOrientationDocument] = useState(CLASSNAME_DOCUMENT_PORTAIT);
  const [pageHeightDocument, setPageHeightDocument] = useState(DEFAULT_HEIGHT_PAGE_PORTRAIT);

  const previewDocumentHtml = useCallback(() => {
    setState(assoc('previewHtmlLoading', true));
    setState(assoc('previewHtml', undefined));
    setState(assoc('documentPreviewUrl', undefined));

    PreviewDocumentHtmlMutation(
      {
        pages: path(['values', 'pages'], state),
        variables: path(['values', 'variables'], state),
        documentId: propOr('', 'id', document),
        padding: path(['values', 'padding'], state),
        orientation: path(['values', 'orientation'], state),
        renderingEngineVersion: path(['values', 'renderingEngineVersion'], state),
        isVariablesHighlighted: path(['values', 'isVariablesHighlighted'], state)
      },
      (ok, error, html) => {
        setState(assoc('previewHtmlLoading', false));

        if (ok && !error) {
          setState(assoc('previewHtml', html));

          setClassNameOrientationDocument(cond([
            [equals('portrait'), always(CLASSNAME_DOCUMENT_PORTAIT)],
            [equals('landscape'), always(CLASSNAME_DOCUMENT_LANDSCAPE)],
            [T, always(CLASSNAME_DOCUMENT_PORTAIT)]
          ])(path(['values', 'orientation'], state)));

          setPageHeightDocument(cond([
            [equals('portrait'), always(DEFAULT_HEIGHT_PAGE_PORTRAIT)],
            [equals('landscape'), always(DEFAULT_HEIGHT_PAGE_LANDSCAPE)],
            [T, always(DEFAULT_HEIGHT_PAGE_PORTRAIT)]
          ])(path(['values', 'orientation'], state)));
        } else {
          errorMessage();
        }
      }
    );
  }, [document, state]);

  const updatePaddingDocument = (key, value) => setState(over(
    lensPath(['values', 'padding']),
    assoc(key, value)
  ));

  const updateIsVariablesHighlighted = async (isVariablesHighlighted) => {
    await setState(assocPath(['values', 'isVariablesHighlighted'], isVariablesHighlighted));
    previewDocumentHtml();
    message.success(isVariablesHighlighted ? 'Les variables sont désormais surlignées' : 'Les variables ne sont plus surlignées');
  };

  const previewDocumentHtmlOnReview = () => {
    setPreviewHtmlOnReview(undefined);
    setDocumentIdModalToShow(undefined);

    PreviewDocumentHtmlMutation(
      {
        pages: propOr([], 'pages', document),
        variables: propOr([], 'variables', document),
        documentId: propOr('', 'id', document),
        padding: path(['values', 'padding'], state),
        orientation: path(['values', 'orientation'], state),
        renderingEngineVersion: path(['values', 'renderingEngineVersion'], state)
      },
      (ok, error, html) => {
        if (ok && !error) {
          setPreviewHtmlOnReview(html);
          setDocumentIdModalToShow(prop('id', document));
        } else {
          errorMessage();
        }
      }
    );
  };

  const redirect = () => history.push(`/offre/${prop('offerId', options)}/edition`);

  const onSave = (e, quit = false) => {
    setState(assoc('loading', true));

    let pages = path(['values', 'pages'], state);
    if (!allRulesAreValid) {
      const updateRules = over(
        lensProp('rules'),
        reject(rule => isNil(rule.action) || isNilOrEmpty(rule.conditions) || !getConditionsAreValid(rule.conditions))
      );
      const updateTypeContent = (type) => over(
        lensProp(type),
        map(
          over(
            lensProp('widgets'),
            map(updateRules)
          )
        )
      );
      const updatedPages = map(compose(
        updateTypeContent('header'),
        updateTypeContent('widgets'),
        updateTypeContent('footer'),
        updateRules
      ))(path(['values', 'pages'], state));
      pages = updatedPages;
    }
    const pagesWithRemovedEmptyRow = map((page) => {
      const updateTypeContent = (type) => over(
        lensProp(type),
        compose(
          filter((widget) => isNotEmpty(prop('widgets', widget))),
          map(
            over(
              lensProp('widgets'),
              reject((widget) => isNil(prop('widgetId', widget)))
            )
          )
        )
      );

      return compose(
        updateTypeContent('header'),
        updateTypeContent('widgets'),
        updateTypeContent('footer')
      )(page);
    })(pages);
    pages = pagesWithRemovedEmptyRow;
    setState(assocPath(['values', 'pages'], pages));

    const callback = (successMessage, isCreation) => (ok, error, documentId) => {
      setState(assoc('loading', false));

      if (ok && !error) {
        message.success(successMessage);

        if (propEq('scope', SCOPE_OFFER, options)) {
          const newName = path(['values', 'name'], state);
          const nameHasChanged = notEqual(prop('name', document), newName);
          if (nameHasChanged || !isEdit) {
            UpdateCacheMutation({ date: new Date(), key: `documents-${prop('offerId', options)}` }, () => {});
          }

          const getWidgetIds = (data) => {
            return data.reduce((acc, item) => {
              if (item.widgets) {
                acc = acc.concat(getWidgetIds(item.widgets));
              }
              if (item.footer) {
                acc = acc.concat(getWidgetIds(item.footer));
              }
              if (item.header) {
                acc = acc.concat(getWidgetIds(item.header));
              }
              if (item.widgetId) {
                acc.push(item.widgetId);
              }
              return acc;
            }, []);
          };

          const actualWidgetIds = uniq(getWidgetIds(pages));
          const oldWidgetIds = uniq(getWidgetIds(propOr([], 'pages', document)));
          if (isNotEmpty(difference(actualWidgetIds, oldWidgetIds))) {
            UpdateCacheMutation({ date: new Date(), key: `widgets-${prop('offerId', options)}` }, () => {});
          }
        }

        if (!quit && isCreation) {
          const typeUrl = equals(SCOPE_OFFER, prop('scope', options)) ? 'offre' : 'docs';
          history.push(`/${typeUrl}/${documentId}/editer`);
          return;
        }
        quit && redirect();
      } else {
        errorMessage();
      }
    };

    if (isEdit) {
      UpdateDocumentMutation({
        documentId: prop('id', document),
        name: path(['values', 'name'], state),
        pages,
        variables: JSON.stringify(path(['values', 'variables'], state)),
        padding: path(['values', 'padding'], state),
        orientation: path(['values', 'orientation'], state),
        renderingEngineVersion: path(['values', 'renderingEngineVersion'], state),
        formatPdf: path(['values', 'formatPdf'], state),
        isVariablesHighlighted: path(['values', 'isVariablesHighlighted'], state)
      }, callback('Le document a bien été mis à jour.'));
    } else {
      CreateDocumentMutation({
        name: path(['values', 'name'], state),
        pages,
        scope: prop('scope', options),
        offerId: prop('offerId', options),
        variables: JSON.stringify(path(['values', 'variables'], state)),
        padding: path(['values', 'padding'], state),
        orientation: path(['values', 'orientation'], state),
        renderingEngineVersion: path(['values', 'renderingEngineVersion'], state),
        formatPdf: path(['values', 'formatPdf'], state)
      }, callback('Le document a bien été créé.', true));
    }
  };

  const onSaveAndQuit = (e) => onSave(e, true);

  const onDelete = () => {
    if (isEdit) {
      setState(assoc('deleteLoading', true));

      DeleteDocumentMutation({ documentId: prop('id', document) }, (ok, error) => {
        setState(assoc('deleteLoading', false));

        if (ok && !error) {
          successMessage('document', 'supprimé');
          if (propEq('scope', SCOPE_OFFER, options)) {
            UpdateCacheMutation({ date: new Date(), key: `documents-${prop('offerId', options)}` }, () => {});
            if (isNotEmpty(path(['values', 'pages'], state))) {
              UpdateCacheMutation({ date: new Date(), key: `widgets-${prop('offerId', options)}` }, () => {});
            }
          }
          redirect();
        } else {
          errorMessage();
        }
      });
    }
  };

  const nameIsDefined = compose(isNotNilOrEmpty, path(['values', 'name']))(state);

  const title = `${isEdit ? 'Créer' : 'Éditer'} un document`;

  return {
    setState,
    addWidget,
    addRowWidget,
    removeWidget,
    updateWidget,
    addWidgetRule,
    moveRowWidget,
    updateBreakPage,
    removeWidgetRule,
    updateWidgetRule,
    previewDocumentHtml,
    onSave,
    onSaveAndQuit,
    onDelete,
    widgetsAreDefined,
    nameIsDefined,
    isEdit,
    getRulesAreValid,
    allRulesAreValid,
    getConditionsAreValid,
    title,
    updateManualBreakPage,
    updatePaddingRow,
    editSections,
    previewDocumentHtmlOnReview,
    previewHtmlOnReview,
    documentIdModalToShow,
    setPreviewHtmlOnReview,
    updatePaddingDocument,
    updatePaddingSection,
    addRuleSection,
    updateRuleSection,
    removeRuleSection,
    setRefetch,
    widgetIdEditing,
    setWidgetIdEditing,
    rowIndexEditing,
    setRowIndexEditing,
    hasLandscapeWidget,
    setHasLandscapeWidget,
    addManualBreakPageRule,
    removeManualBreakPageRule,
    updateManualBreakPageRule,
    classNameOrientationDocument,
    pageHeightDocument,
    scope: prop('scope', options),
    offerId: prop('offerId', options),
    document,
    comments: propOr([], 'comments', comments),
    widgets,
    setWidgets,
    globalVariables,
    predefinedVariables,
    setWidgetCreated,
    isOpenCreateWidgetModal,
    setIsOpenCreateWidgetModal,
    updateIsVariablesHighlighted,
    setDuplicateWidgetPlacement,

    // State
    values: prop('values', state),
    loading: prop('loading', state),
    deleteLoading: prop('deleteLoading', state),
    previewLoading: prop('previewLoading', state),
    previewHtmlLoading: prop('previewHtmlLoading', state),
    previewHtml: prop('previewHtml', state),
    documentPreviewUrl: prop('documentPreviewUrl', state),
    orientation: path(['values', 'orientation'], state),
    renderingEngineVersion: path(['values', 'renderingEngineVersion'], state),
    formatPdf: path(['values', 'formatPdf'], state)
  };
};

export default useDocument;
