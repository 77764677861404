import { isNotNilOrEmpty } from 'ramda-adjunct';
import React from 'react';
import DefaultValuesCheck from './DefaultValuesCheck';

const VariableContainer = ({ label, icon, onChangeIsDefaultValue, isDefaultValue, value, children }) => (
  <div key={label} className="variables-tab-select my-4 mx-0">
    <div className="flex justify-between">
      <div>
        {icon}&nbsp;
        <span className="font-bold">{label} :</span>
      </div>
      {isNotNilOrEmpty(value) && <DefaultValuesCheck checked={isDefaultValue} onChangeIsDefaultValue={onChangeIsDefaultValue} />}
    </div>
    {children}
  </div>
);

export default VariableContainer;
