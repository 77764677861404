import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../index';
import { basicMutationOnCompleted, onErrorMutation } from './common/common';

const mutation = graphql`
  mutation RemoveCacheMutation($key: String!) {
    removeCache(key: $key) {
      ok
      error
    }
  }
`;

export default (variables, done) => {
  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ removeCache }) => basicMutationOnCompleted(removeCache, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
