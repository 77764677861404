/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QVersionActionsQueryVariables = {|
  versionId?: ?string,
  itemId?: ?string,
|};
export type QVersionActionsQueryResponse = {|
  +versionActions: ?$ReadOnlyArray<?{|
    +userId: ?string,
    +action: ?string,
    +userName: ?string,
    +date: ?string,
    +metadata: ?{|
      +comments: ?string,
      +status: ?string,
      +rejectionReason: ?string,
      +major: ?number,
      +minor: ?number,
      +newVersion: ?boolean,
    |},
  |}>
|};
export type QVersionActionsQuery = {|
  variables: QVersionActionsQueryVariables,
  response: QVersionActionsQueryResponse,
|};
*/


/*
query QVersionActionsQuery(
  $versionId: ID
  $itemId: ID
) {
  versionActions(versionId: $versionId, itemId: $itemId) {
    userId
    action
    userName
    date
    metadata {
      comments
      status
      rejectionReason
      major
      minor
      newVersion
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "versionId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "itemId",
        "variableName": "itemId"
      },
      {
        "kind": "Variable",
        "name": "versionId",
        "variableName": "versionId"
      }
    ],
    "concreteType": "VersionActions",
    "kind": "LinkedField",
    "name": "versionActions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "action",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VersionActionsMetadata",
        "kind": "LinkedField",
        "name": "metadata",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "comments",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rejectionReason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "major",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "newVersion",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QVersionActionsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QVersionActionsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "08357847836882911f7a4a56b1b23988",
    "id": null,
    "metadata": {},
    "name": "QVersionActionsQuery",
    "operationKind": "query",
    "text": "query QVersionActionsQuery(\n  $versionId: ID\n  $itemId: ID\n) {\n  versionActions(versionId: $versionId, itemId: $itemId) {\n    userId\n    action\n    userName\n    date\n    metadata {\n      comments\n      status\n      rejectionReason\n      major\n      minor\n      newVersion\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d891cf427e96eb0658837db48ad9dcf';

module.exports = node;
