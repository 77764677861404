import attributesNames from '../data/attributesNames';
import * as R from 'ramda';

const getElements = (itemRootId) => {
  const elements = document.querySelectorAll(`*[${R.prop('itemPath', attributesNames)}]`);

  return R.filter(element => {
    const itemRoot = R.prop('attributes', element).getNamedItem(R.prop('itemRoot', attributesNames));
    return !R.isNil(itemRoot) && R.prop('value', itemRoot) === itemRootId;
  })(elements);
};

export default getElements;
