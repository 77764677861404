import React from 'react';

const Dot = ({ color = '#8a98a5', width = 10 }) => {
  return (
    <div
      style={{
        width: `${width}px`,
        height: `${width}px`,
        borderRadius: '50%',
        backgroundColor: color,
        transform: `translateY(${width !== 10 ? -2 : 0}px)`
      }}
    />
  );
};

export default Dot;
