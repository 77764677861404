import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../index';
import { basicMutationOnCompleted, onErrorMutation } from './common/common';

const mutation = graphql`
  mutation UpdateCacheMutation($date: String!, $key: String!) {
    updateCache(date: $date, key: $key) {
      ok
      error
    }
  }
`;

export default (variables, done) => {
  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ updateCache }) => basicMutationOnCompleted(updateCache, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
