import * as R from 'ramda';
import sanitizeClassNames from './sanitizeClassNames';
import attributesNames from '../data/attributesNames';
import getCommentElement from './getCommentElement';
import hideComments from './hideComments';
import getElements from './getElements';
import getComment from './getComment';

const processElementEditionMode = (element, comment, config) => {
  if (!R.isNil(comment)) {
    const messages = R.propOr([], 'messages', comment);

    if (!R.isEmpty(messages)) {
      element.className = sanitizeClassNames(R.prop('className', element));
      const borderColor = R.path(['colors', R.prop('status', comment)], config);
      element.style.border = `2px dashed ${borderColor}`;
    }

    const isProcessed = R.prop('attributes', element).getNamedItem(R.prop('itemProcessed', attributesNames)) === 'true';

    if (!isProcessed) {
      const commentElement = getCommentElement(comment);

      if (!R.isNil(commentElement)) {
        element.onclick = () => {
          hideComments();
          commentElement.className = R.replace('hidden', '', R.prop('className', commentElement));
        };

        const itemProcessedAttribute = document.createAttribute(R.prop('itemProcessed', attributesNames));
        itemProcessedAttribute.value = 'true';

        R.prop('attributes', element).setNamedItem(itemProcessedAttribute);
      }
    }
  }
};

const processElementReviewMode = (element, comment, config, createComment) => {
  if (!R.isNil(comment)) {
    processElementEditionMode(element, comment, config);
  } else {
    const itemPath = R.prop('attributes', element).getNamedItem(R.prop('itemPath', attributesNames));
    const itemKey = R.prop('attributes', element).getNamedItem(R.prop('itemKey', attributesNames));

    element.onclick = () => {
      hideComments();

      createComment(R.prop('value', itemPath), R.prop('value', itemKey), comment => {
        const commentElement = getCommentElement(comment);

        if (!R.isNil(commentElement)) {
          commentElement.className = R.replace('hidden', '', R.prop('className', commentElement));
        }
      });
    };
  }
};

const processElements = ({
  config,
  comments,
  mode,
  createComment,
  itemRootId
}) => {
  const elements = getElements(itemRootId);

  R.forEach(element => {
    const comment = getComment(element, comments);

    if (mode === 'edition') {
      processElementEditionMode(element, comment, config);
    } else if (mode === 'review') {
      processElementReviewMode(element, comment, config, createComment);
    }
  })(elements);
};

export default processElements;
