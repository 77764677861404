import * as R from 'ramda';

const statusList = [{
  status: 'draft',
  label: 'En édition'
}, {
  status: 'release',
  label: 'Publiée'
}, {
  status: 'review',
  label: 'En revue'
}, {
  status: 'archived',
  label: 'Archivée'
}];

const getStatusLabel = status => R.compose(
  R.propOr(status, 'label'),
  R.when(R.isNil, R.always({})),
  R.find(R.propEq('status', status))
)(statusList);

export {
  statusList,
  getStatusLabel
};
