/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveGroupMemberMutationVariables = {|
  groupId: string,
  memberId: string,
|};
export type RemoveGroupMemberMutationResponse = {|
  +removeGroupMember: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type RemoveGroupMemberMutation = {|
  variables: RemoveGroupMemberMutationVariables,
  response: RemoveGroupMemberMutationResponse,
|};
*/


/*
mutation RemoveGroupMemberMutation(
  $groupId: ID!
  $memberId: ID!
) {
  removeGroupMember(groupId: $groupId, memberId: $memberId) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "groupId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "memberId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "groupId",
        "variableName": "groupId"
      },
      {
        "kind": "Variable",
        "name": "memberId",
        "variableName": "memberId"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "removeGroupMember",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveGroupMemberMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveGroupMemberMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1b8f93b238af6b60a039243b77da5bb8",
    "id": null,
    "metadata": {},
    "name": "RemoveGroupMemberMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveGroupMemberMutation(\n  $groupId: ID!\n  $memberId: ID!\n) {\n  removeGroupMember(groupId: $groupId, memberId: $memberId) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '49c81f22fe50a4924a8b221bc090d8ea';

module.exports = node;
