import * as R from 'ramda';
import { isNilOrEmpty, isNotEmpty, isTrue } from 'ramda-adjunct';
import { BASE_PADDING_DOCUMENT } from '../_CONST';
export const useWidgets = (state, setState) => {
  const widgetsAreDefined = R.compose(
    isNotEmpty,
    R.flatten,
    R.pluck('widgets'),
    R.pathOr([], ['values', 'pages'])
  )(state);

  const isHeaderOrFooter = (type) => R.or(R.equals('header')(type), R.equals('footer')(type));
  const pathWidgetsSection = (sectionIndex, type) => ['values', 'pages', sectionIndex, type];
  const pathWidgetsRow = (sectionIndex, rowIndex, type) => [...pathWidgetsSection(sectionIndex, type), rowIndex, 'widgets'];
  const pathDataRules = (sectionIndex, rowIndex, widgetIndex, type) => [...pathWidgetsRow(sectionIndex, rowIndex, type), widgetIndex, 'rules'];

  const addWidget = (sectionIndex, type) => (rowIndex) => setState(R.over(
    R.lensPath(pathWidgetsSection(sectionIndex, type)),
    (data) => {
      const widgetData = {
        widgets: [{ rules: [] }],
        padding: BASE_PADDING_DOCUMENT
      };
      if (!isHeaderOrFooter(type)) widgetData.breakPage = false;
      return R.ifElse(
        R.isNil,
        () => R.append(widgetData)(data),
        () => R.insert(rowIndex, widgetData)(data)
      )(rowIndex);
    }
  ));

  const addRowWidget = (sectionIndex, type) => rowIndex => setState(R.over(
    R.lensPath(pathWidgetsRow(sectionIndex, rowIndex, type)),
    R.append({ rules: [] })
  ));

  const updateManualBreakPage = (sectionIndex) => (rowIndex, checked) =>
    setState(
      R.over(
        R.lensPath(['values', 'pages', sectionIndex, 'widgets', rowIndex]),
        R.compose(
          R.ifElse(
            R.compose(isTrue, R.prop('breakPage')),
            R.assoc('rulesBreakPage', []),
            R.dissoc('rulesBreakPage')
          ),
          R.assoc('breakPage', checked)
        )
      )
    );

  const addManualBreakPageRule = (sectionIndex) => (rowIndex) => setState(R.over(
    R.lensPath(['values', 'pages', sectionIndex, 'widgets', rowIndex, 'rulesBreakPage']),
    R.compose(
      R.append({}),
      R.defaultTo([])
    )
  ));

  const removeManualBreakPageRule = (sectionIndex) => (rowIndex) => (ruleIndex) => () => setState(R.over(
    R.lensPath(['values', 'pages', sectionIndex, 'widgets', rowIndex, 'rulesBreakPage']),
    R.remove(ruleIndex, 1)
  ));

  const updateManualBreakPageRule = (sectionIndex) => (rowIndex) => (ruleIndex) => (fn) => setState(R.over(
    R.lensPath(['values', 'pages', sectionIndex, 'widgets', rowIndex, 'rulesBreakPage']),
    R.adjust(ruleIndex, fn)
  ));

  const updatePaddingRow = (sectionIndex, type) => rowIndex => (key, value) => setState(R.over(
    R.lensPath(['values', 'pages', sectionIndex, type, rowIndex, 'padding']),
    R.assoc(key, value)
  ));

  const removeWidget = (sectionIndex, type) => (rowIndex, index) => R.ifElse(
    R.compose(
      R.lt(1),
      R.length,
      R.path(pathWidgetsRow(sectionIndex, rowIndex, type))
    ),
    () => setState(R.over(
      R.lensPath(pathWidgetsRow(sectionIndex, rowIndex, type)),
      R.remove(index, 1)
    )),
    () => {
      if (R.equals('widgets', type)) updateManualBreakPage(sectionIndex)(rowIndex, false);
      setState(R.over(
        R.lensPath(pathWidgetsSection(sectionIndex, type)),
        R.remove(rowIndex, 1)
      ));
    }
  )(state);

  const moveRowWidget = (sectionIndex, type) => (oldIndex, newIndex) => {
    const widgetOldIndex = R.path([...pathWidgetsSection(sectionIndex, type), oldIndex], state);
    const widgetNewIndex = R.path([...pathWidgetsSection(sectionIndex, type), newIndex], state);
    setState(
      R.over(
        R.lensPath(pathWidgetsSection(sectionIndex, type)),
        R.compose(
          R.update(oldIndex, { ...widgetNewIndex, breakPage: R.prop('breakPage', widgetOldIndex), rulesBreakPage: R.prop('rulesBreakPage', widgetOldIndex) }),
          R.update(newIndex, { ...widgetOldIndex, breakPage: R.prop('breakPage', widgetNewIndex), rulesBreakPage: R.prop('rulesBreakPage', widgetNewIndex) })
        )
      )
    );
  };

  const updateWidget = (globalVariablesNames, sectionIndex, type) => (rowIndex, index, widgetId) => {
    const rulesLastWidget = R.path([...pathDataRules(sectionIndex, rowIndex, index, type)], state);
    const newRules = R.compose(
      R.reject((rule) => isNilOrEmpty(R.prop('conditions', rule))),
      R.map((rule) => {
        const conditionsToKeep = R.filter((condition) => R.includes(R.prop('variable', condition), globalVariablesNames))(R.prop('conditions', rule));
        return { ...rule, conditions: conditionsToKeep };
      })
    )(rulesLastWidget);

    setState(R.over(
      R.lensPath(pathWidgetsRow(sectionIndex, rowIndex, type)),
      R.adjust(index, R.compose(
        R.assoc('type', R.equals('widgets', type) ? 'content' : type),
        R.assoc('widgetId', widgetId),
        R.assoc('rules', newRules)
      ))
    ));
  };

  const updateBreakPage = (sectionIndex, value) => setState(
    R.assocPath(['values', 'pages', sectionIndex, 'breakPage'], value)
  );

  const addWidgetRule = (sectionIndex, type) => (rowIndex, widgetIndex) => () => setState(R.over(
    R.lensPath(pathDataRules(sectionIndex, rowIndex, widgetIndex, type)),
    R.compose(
      R.append({}),
      R.defaultTo([])
    )
  ));

  const removeWidgetRule = (sectionIndex, type) => (rowIndex, widgetIndex) => (ruleIndex) => () => setState(R.over(
    R.lensPath(pathDataRules(sectionIndex, rowIndex, widgetIndex, type)),
    R.remove(ruleIndex, 1)
  ));

  const updateWidgetRule = (sectionIndex, type) => (rowIndex, widgetIndex) => (ruleIndex) => (fn) => setState(R.over(
    R.lensPath(pathDataRules(sectionIndex, rowIndex, widgetIndex, type)),
    R.adjust(ruleIndex, fn)
  ));

  return {
    state,
    widgetsAreDefined,
    addWidget,
    addRowWidget,
    removeWidget,
    updateWidget,
    moveRowWidget,
    updateBreakPage,
    addWidgetRule,
    removeWidgetRule,
    updateWidgetRule,
    updateManualBreakPage,
    updatePaddingRow,
    addManualBreakPageRule,
    removeManualBreakPageRule,
    updateManualBreakPageRule
  };
};
