import React from 'react';
import * as R from 'ramda';
import { InputNumber, Tooltip } from 'antd';
import { ExpandOutlined } from '@ant-design/icons';

const translatePadding = {
  top: 'Haut',
  right: 'Droit',
  bottom: 'Bas',
  left: 'Gauche'
};

const PaddingInfo = ({ updatePadding, element }) => (
  <div className="w-full items-center flex justify-between mb-2">
    <Tooltip
      title="Gestion du padding"
      color="#0197DC"
    >
      <ExpandOutlined />
    </Tooltip>
    {R.compose(
      R.map(([key, paddingType]) => {
        return (
          <div key={key} className="flex">
            <p className="mr-2">{R.propOr('', key, translatePadding)}</p>
            <InputNumber
              onChange={(value) => updatePadding(key, value)}
              value={paddingType}
              defaultValue={0}
              min={0}
              size="small"
              formatter={value => `${value}px`}
              parser={value => value.replace('px', '')}
            />
          </div>
        );
      }),
      R.sortBy(([key, _]) => R.indexOf(key, R.keys(translatePadding))),
      R.toPairs
    )(R.prop('padding', element))}
  </div>
);

export default PaddingInfo;
