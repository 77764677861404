/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateVersionStatusMutationVariables = {|
  versionId: string,
  status: string,
|};
export type UpdateVersionStatusMutationResponse = {|
  +updateVersionStatus: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateVersionStatusMutation = {|
  variables: UpdateVersionStatusMutationVariables,
  response: UpdateVersionStatusMutationResponse,
|};
*/


/*
mutation UpdateVersionStatusMutation(
  $versionId: ID!
  $status: String!
) {
  updateVersionStatus(versionId: $versionId, status: $status) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "versionId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "versionId",
        "variableName": "versionId"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "updateVersionStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateVersionStatusMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateVersionStatusMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0e8ba78f2cc08e1982d2edf22f66ea04",
    "id": null,
    "metadata": {},
    "name": "UpdateVersionStatusMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateVersionStatusMutation(\n  $versionId: ID!\n  $status: String!\n) {\n  updateVersionStatus(versionId: $versionId, status: $status) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f5fc1b1d4afe274f2e349a918782bfc0';

module.exports = node;
