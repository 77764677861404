import { commitMutation, graphql } from 'react-relay';
import environment from '../..';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation CreateFolderMutation($folder: FolderInput!) {
    createFolder(folder: $folder) {
      ok
      error
    }
  }
`;

export default ({ folder }, done) => {
  const variables = {
    folder
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ createFolder }) => basicMutationOnCompleted(createFolder, done),
      onErrorMutation: error => onErrorMutation(error, done)
    }
  );
};
