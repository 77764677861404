import { isNil } from 'ramda';
import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../.';
import { onErrorMutation } from './common/common';

const mutation = graphql`
mutation SignOutMutation {
  signOut { 
    ok
    error
    user {
      id
      email
    }
  }
}
`;

export default (done) => {
  const variables = {
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: ({ signOut: { ok, error } }) => {
        if (!isNil(done)) done(ok, error);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
