import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../.';

import {
  populateChildren
} from '../toolbox';

const QElementReviewQuery = graphql`
  query QElementReviewQuery($itemRootId: ID!, $itemId: ID!, $itemType: String!) {
    elementReview(itemRootId: $itemRootId, itemId: $itemId, itemType: $itemType) {
      id
      status
      userId
      itemRootId
      itemId
      groupId
    }
  }
`;

const QElementReview = ({
  children,
  args,
  childDataProp
}) => (
  <Query
    query={QElementReviewQuery}
    args={args}
    caching={false}
  >
    {populateChildren('elementReview')(children, childDataProp)}
  </Query>
);

export default QElementReview;
