import * as R from 'ramda';
import { widgetHeight } from './widget';

const HEIGHT_MAX_A4_WITHOUT_MARGINS = 1123; // max height A4

/**
 * It takes a list of pages, and returns a list of heights for each page
 * @param widgetsHeader - header widgets
 * @param pagesOnSection - an array of pages, each page is an array of widgets
 * @param widgetsFooter - footer widgets
 * @param widgetsList - the list of all widgets in the section
 * @param padding - {top: number, bottom: number}
 */
const getPagesHeight = (widgetsHeader, pagesOnSection, widgetsFooter, widgetsList, padding) => {
  const getWidgetHeight = (rowWidgets) => R.compose(
    R.apply(Math.max),
    R.map((widget) => {
      if (rowWidgets.length > 1) {
        if (widget === rowWidgets[0]) {
          return R.compose(
            R.apply(Math.max),
            R.map((w) => widgetHeight(widgetsList, R.prop('widgetId', w))(w))
          )(rowWidgets);
        }
      }
      return widgetHeight(widgetsList, R.prop('widgetId', widget))(widget);
    })
  )(rowWidgets);

  const contentHeight = (widgetsPage) => R.compose(
    R.reduce(R.add, 0),
    R.map(
      R.compose(
        R.ifElse(
          R.isEmpty,
          R.always(0),
          getWidgetHeight
        ),
        R.filter(R.has('widgetId'))
      )
    ),
    R.map(R.prop('widgets'))
  )(widgetsPage);

  return R.map(
    R.compose(
      R.add(R.prop('top', padding)),
      R.add(R.prop('bottom', padding)),
      R.add(contentHeight(widgetsFooter)),
      R.add(contentHeight(widgetsHeader)),
      contentHeight
    )
  )(pagesOnSection);
};

const getPageWidth = (widgetsPage, widgetsList, padding) => R.compose(
  R.add(R.prop('right', padding)),
  R.add(R.prop('left', padding)),
  R.apply(Math.max),
  R.map(R.compose(
    R.sum,
    R.map(R.compose(
      R.ifElse(
        R.isNil,
        R.always(0),
        R.compose(
          R.apply(Math.max),
          R.map((element) => (R.path(['size', 'width'], element))),
          JSON.parse,
          R.path(['contents'])
        )
      ),
      (id) => R.find(R.propEq('id', id))(widgetsList)
    )),
    R.map(R.path(['widgetId'])),
    R.filter(R.has('widgetId'))
  )),
  R.map(R.path(['widgets']))
)(widgetsPage);

const getWidgetType = (widgetsPage, widgetsList) => R.compose(
  R.flatten,
  R.map(R.compose(
    R.map(R.compose(
      R.ifElse(
        R.isNil,
        R.always(0),
        R.compose(
          R.forEach((element) => (R.prop('type', element))),
          R.path(['variables'])
        )
      ),
      (id) => R.find(R.propEq('id', id))(widgetsList)
    )),
    R.map(R.path(['widgetId'])),
    R.filter(R.has('widgetId'))
  )),
  R.map(R.path(['widgets']))
)(widgetsPage);

export {
  HEIGHT_MAX_A4_WITHOUT_MARGINS,
  getPagesHeight,
  getPageWidth,
  getWidgetType
};
