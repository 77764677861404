import { KeyOutlined, MailOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { pathOr } from 'ramda';
import React from 'react';

const EmailPasswordForm = ({
  setValue,
  fieldIsDefined,
  emailIsValid,
  passwordIsValid
}) => {
  return (
    <>
      <h4>E-mail</h4>
      <Input
        prefix={<MailOutlined />}
        placeholder="E-mail"
        onChange={(e) => setValue('email', pathOr('', ['target', 'value'], e))}
      />
      {fieldIsDefined('email') && !emailIsValid && (
        <div className="account-error">
          <br />
          {"L'email saisi n'est pas valide."}
        </div>
      )}

      <h4>Mot de passe</h4>
      <Input.Password
        prefix={<KeyOutlined />}
        placeholder="Mot de passe"
        onChange={(e) => setValue('password', pathOr('', ['target', 'value'], e))}
      />
      {fieldIsDefined('password') && !passwordIsValid && (
        <div className="account-error">
          <br />
          {
            'Votre mot de passe doit contenir au moins 8 caractères (dont au moins un de type lettre, de type chiffre, de type symbole)'
          }
        </div>
      )}
    </>
  );
};

export default EmailPasswordForm;
