import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation, updaterBasicDelete } from '../common/common';

const mutation = graphql`
  mutation DeleteGlobalVariableMutation($globalVariableId: ID!) {
    deleteGlobalVariable(globalVariableId: $globalVariableId) {
      ok
      error
    }
  }
`;

export default ({
  globalVariableId
}, done) => {
  const variables = {
    globalVariableId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { deleteGlobalVariable: { ok, error } }) => updaterBasicDelete(proxyStore, ok, error, globalVariableId),
      onCompleted: ({ deleteGlobalVariable }) => basicMutationOnCompleted(deleteGlobalVariable, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
