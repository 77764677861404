import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React from 'react';

const SearchBar = ({ searchTerm, setSearchTerm }) => (
  <Input
    className="border-2 my-4"
    placeholder="Recherche"
    value={searchTerm}
    onChange={(e) => { setSearchTerm(e.target.value); }}
    allowClear
    suffix={<SearchOutlined />}
  />
);

export default SearchBar;
