import React, { useState } from 'react';
import { Input, Select, Button, Radio } from 'antd';
import * as R from 'ramda';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import conditions from './conditions';
import actions from './actions';
import operators from './operators';
import Tooltip from 'antd/es/tooltip';
const RA = require('ramda-adjunct');

const mapIndexed = R.addIndex(R.map);

const conditionIsWithValue = condition => R.compose(
  R.propOr(false, 'withValue'),
  R.defaultTo({}),
  R.find(R.propEq('key', condition))
)(conditions);

const CustomSelect = ({
  placeholder,
  value,
  onChange,
  options,
  disabled
}) => {
  return (
    <Select
      showSearch
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      size="small"
      style={{
        width: '100%'
      }}
      disabled={disabled}
    >
      {R.map(({ key, label }) => {
        return (
          <Select.Option
            key={key}
            value={key}
          >
            {label}
          </Select.Option>
        );
      })(options)}
    </Select>
  );
};

const DisplayRadio = ({ value }) => R.map(({ key, label }) => {
  return (
    <Radio
      key={key}
      value={key}
    >
      <span className="font-semibold">{label}</span>
    </Radio>
  );
})(value);

const Condition = ({
  condition,
  conditionIndex,
  onUpdate,
  variables,
  preview,
  userIsAdmin
}) => {
  const [value, setValue] = useState(R.prop('value', condition));
  const isFirst = conditionIndex === 0;
  const conditionVariable = R.prop('variable', condition);
  const variable = R.find(R.propEq('key', conditionVariable), variables);
  const conditionsOptions = R.when(
    () => RA.isNotNil(conditionVariable),
    R.cond([
      [R.propEq('type', 'number'), R.always(conditions)],
      [R.T, R.always(R.filter(
        R.allPass([
          R.compose(
            R.not,
            R.propEq('key', 'isGreaterThan')
          ),
          R.compose(
            R.not,
            R.propEq('key', 'isLessThan')
          )
        ]),
        conditions
      ))]
    ])
  )(variable);

  const PREDIFINED_VARIABLES = ['_pageCourante', '_pageTotal', '_pageCouranteSection', '_pageTotalSection'];

  const optionsWithoutDuplicateKeys = R.compose(
    R.uniqBy(R.prop('key')),
    R.map(
      R.applySpec({
        key: R.prop('key'),
        label: R.prop('key')
      })
    ),
    R.filter(
      R.compose(R.not, R.flip(R.contains)(PREDIFINED_VARIABLES), R.prop('key'))
    )
  )(variables);
  const disableFields = preview || !userIsAdmin;

  return (
    <>
      {!isFirst && (
        <Radio.Group
          onChange={e => onUpdate(R.assocPath(['conditions', conditionIndex, 'operator'], e.target.value))}
          value={R.prop('operator', condition)}
          className="radio-widget-rule"
          style={{ marginTop: '10px' }}
          disabled={disableFields}
        >
          <DisplayRadio value={operators} />
        </Radio.Group>
      )}

      {userIsAdmin && (
        <Tooltip
          title="Supprimer la condition"
          color="#0197DC"
        >
          <button onClick={() => onUpdate(R.dissocPath(['conditions', conditionIndex]))} disabled={preview} >
            <DeleteOutlined />
          </button>
        </Tooltip>
      )}

      {(isFirst || RA.isNotNil(R.prop('operator', condition))) && (
        <CustomSelect
          placeholder="Sélectionner une variable"
          value={conditionVariable}
          onChange={v => onUpdate(R.assocPath(['conditions', conditionIndex, 'variable'], v))}
          options={optionsWithoutDuplicateKeys}
          disabled={disableFields}
        />
      )}

      {RA.isNotNil(conditionVariable) && (
        <CustomSelect
          placeholder="Sélectionner une condition"
          value={R.prop('condition', condition)}
          onChange={v => onUpdate(R.assocPath(['conditions', conditionIndex, 'condition'], v))}
          options={conditionsOptions}
          disabled={disableFields}
        />
      )}

      {RA.isNotNil(R.prop('condition', condition)) && conditionIsWithValue(condition.condition) && (
        <Input
          placeholder="Saisir la valeur"
          value={value}
          onChange={e => setValue(e.target.value)}
          size="small"
          disabled={disableFields}
          onBlur={(e) => onUpdate(R.assocPath(['conditions', conditionIndex, 'value'], e.target.value))}
        />
      )}
    </>
  );
};

const Rule = ({
  variables,
  onUpdate,
  onRemove,
  rule,
  ruleIndex,
  getConditionsAreValid,
  preview,
  userIsAdmin
}) => {
  const conditions = R.propOr([], 'conditions', rule);
  const firstCondition = R.compose(
    R.defaultTo({}),
    R.nth(0)
  )(conditions);
  const extraConditions = R.drop(1, conditions);
  const canEdit = userIsAdmin && !preview;

  return (
    <div>
      <div className="flex items-center">
        <Radio.Group
          onChange={e => onUpdate(R.assoc('action', e.target.value))}
          value={R.prop('action', rule)}
          className="radio-widget-rule"
          disabled={!userIsAdmin || preview}
        >
          <DisplayRadio value={actions} />

        </Radio.Group>

        {canEdit && (
          <Tooltip
            title="Supprimer la règle"
            color="#0197DC"
          >
            <DeleteOutlined
              style={{ color: 'red' }}
              onClick={onRemove}
            />
          </Tooltip>
        )}
      </div>

      {RA.isNotNil(R.prop('action', rule)) && (
        <Condition
          conditionIndex={0}
          condition={firstCondition}
          onUpdate={onUpdate}
          variables={variables}
          preview={preview}
          userIsAdmin={userIsAdmin}
        />
      )}

      {mapIndexed((condition, conditionIndex) => {
        return (
          <Condition
            key={`rule-${ruleIndex}-condition-${conditionIndex + 1}`}
            conditionIndex={conditionIndex + 1}
            condition={condition}
            onUpdate={onUpdate}
            variables={variables}
            preview={preview}
            userIsAdmin={userIsAdmin}
          />
        );
      })(extraConditions)}

      {RA.isNotEmpty(conditions) && getConditionsAreValid(conditions) && canEdit && (
        <Button
          size="small"
          className="small-white-button"
          icon={<PlusCircleOutlined/>}
          onClick={() => onUpdate(R.over(
            R.lensProp('conditions'),
            R.append({})
          ))}
          disabled={!getConditionsAreValid(R.propOr([], 'conditions', rule))}
        >
          Ajouter une condition
        </Button>
      )}

    </div>
  );
};

const DocumentRules = ({
  variables,
  rules,
  rulesAreValid,
  getConditionsAreValid,
  onAdd,
  onRemove,
  onUpdate,
  preview,
  classNameButton,
  userIsAdmin
}) => {
  const hasRightToAddRule = userIsAdmin && rulesAreValid && !preview;

  return (
    <div className="w-80">
      {RA.isNotEmpty(rules) && (
        <div className="flex mt-2 mb-2">
          <div className="border-4 border-gray-300 rounded-sm mr-2.5"/>
          <div className="flex flex-col gap-y-4">
            {mapIndexed((rule, index) => (
              <Rule
                rule={rule}
                variables={variables}
                onUpdate={onUpdate(index)}
                onRemove={onRemove(index)}
                key={`rule${index}`}
                ruleIndex={index}
                getConditionsAreValid={getConditionsAreValid}
                preview={preview}
                userIsAdmin={userIsAdmin}
              />
            ))(rules)}
          </div>
        </div>
      )}

      {hasRightToAddRule && (
        <Button
          size="small"
          className={`add-rules-button small-white-button mt-2 ${classNameButton}`}
          disabled={!rulesAreValid}
          onClick={onAdd}
          icon={<PlusCircleOutlined/>}
        >
          Ajouter une règle
        </Button>
      )}
    </div>
  );
};

export default DocumentRules;
