import { message } from 'antd';
import { isNilOrEmpty, isNotEmpty, isNotNil, isNotNilOrEmpty, notEqual } from 'ramda-adjunct';
import React, { useEffect, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useHistory } from 'react-router-dom';
import { uploadFiles } from '../lib/uploadFiles';
import { changeTypeToElementName, updateMarginStyleInTag } from '../pages/docs/components/helpers/elements';
import { getElementByVersion, getFlexigetElements } from '../pages/docs/components/helpers/flexigets';
import { errorMessage, successMessage } from '../utils/messageMutation';
import { BASE_FRAME_STYLE, SCOPE_OFFER } from '../_CONST';
import CreateWidgetMutation from '../_graphql/mutations/documents/CreateWidgetMutation';
import DeleteWidgetMutation from '../_graphql/mutations/documents/DeleteWidgetMutation';
import UpdateWidgetMutation from '../_graphql/mutations/documents/UpdateWidgetMutation';
import { any, anyPass, applySpec, assoc, assocPath, compose, dissoc, equals, filter, has, head, isEmpty, isNil, length, lensProp, map, over, path, prop, propEq, propOr, propSatisfies, reduce, when } from 'ramda';
import UpdateCacheMutation from '../_graphql/mutations/UpdateCacheMutation';

const NB_COLS_GRID = 12;
const HEIGHT_ROWS_GRID = 5;

const useWidget = ({
  widget = {},
  scope,
  offerId,
  onOfferRedirect,
  flexigets,
  setWidgetCreated
}) => {
  const widgetExist = isNotNil(prop('id', widget));
  const [widgetId, setWidgetId] = useState(null);
  const [elements, setElements] = useState(getFlexigetElements(flexigets));
  const [isEdit, setIsEdit] = useState(false);
  const [state, setState] = useState({
    values: setDefaultValues(widget, {
      scope,
      offerId
    }),
    loading: false,
    deleteLoading: false,
    isEditing: false
  });

  const widgetValues = setDefaultValues(widget, {
    scope,
    offerId
  });

  const prevContents = useRef({});

  useEffect(() => {
    const hasChanged = notEqual(widgetValues, prop('values', state));
    if (widgetExist && hasChanged) {
      setState(assoc('isEditing', true));
    }

    const contents = path(['values', 'contents'], state);
    if (notEqual(prevContents.current, contents)) {
      map((element) => {
        const Component = getElementByVersion(prop('type', element), prop('version', element)).Component;
        setElements(assoc(prop('componentName', Component), Component, elements));
      })(contents);
      prevContents.current = contents;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop('values', state)]);

  useEffect(() => {
    if (widgetExist) {
      setState(assoc('values', widgetValues));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget, scope, offerId, widgetExist]);

  const signatureElements = filter(propEq('type', 'signature'))(path(['values', 'contents'], state));
  if (signatureElements.length > 0) signatureElements.map((e, id) => setState(assocPath(['values', 'contents', id, 'type'], 'text')));

  const variables = compose(
    compose(
      map(
        applySpec({
          type: prop('type'),
          variables: path(['element', 0, 'variables'])
        }))),
    path(['values', 'contents'])
  )(state);

  const history = useHistory();

  const redirect = () => history.push(onOfferRedirect());

  const onSave = async (elements, reFetch, saveAndRedirect) => {
    setState(assoc('loading', true));

    const callback = successMessage => (ok, error, widgetId) => {
      setWidgetId(widgetId);
      setState(assoc('loading', false));

      if (ok && !error) {
        message.success(successMessage);
        const newName = prop('name', values);
        const nameHasChanged = notEqual(prop('name', widget), newName);
        if (equals(SCOPE_OFFER, scope) && (nameHasChanged || !(widgetExist || isEdit))) {
          UpdateCacheMutation({ date: new Date(), key: `widgets-${offerId}` }, () => {});
        }
        if (setWidgetCreated) setWidgetCreated({ ...values, id: widgetId, dates: { creation: new Date() } });
        if (reFetch) {
          history.push(`/offre/${offerId}/widgets/${widgetId}/editer`);
        }
        if (saveAndRedirect) redirect();
      } else {
        errorMessage();
      }
    };

    let values = compose(propOr({}, 'values'))(state);

    const hasImage = compose(
      any(propEq('type', 'image')),
      prop('contents')
    )(values);

    if (hasImage) {
      const contents = await saveImages(values);
      if (any(isNil)(contents)) {
        setState(assoc('loading', false));
        return false;
      }
      values = { ...values, contents };
    }

    when(
      (elementsFlexiget) => equals(length(path(['contents'], values)), length(elementsFlexiget)),
      map((element, key) => {
        if (equals('childrens', prop('type', element))) element.type = 'children';

        const elementIsImage = propEq('type', 'image', element);
        const FlexigetElement = elements[changeTypeToElementName(prop('type', element))];
        const html = path(['element', 0, 'html'], element);
        const elementGetHtml = isNotNilOrEmpty(html);

        let content = elementGetHtml ? html : renderToStaticMarkup(<FlexigetElement value={prop('element', element)} key={key} />);
        if (elementIsImage) content = updateMarginStyleInTag(element, content);
        element.htmlContent = content;

        const elementContentSlate = compose(
          map(
            compose(
              dissoc('children'),
              when(
                (c) => anyPass([equals('paragraph'), equals('signature'), equals('checkbox')])(prop('type', c)),
                assoc('type', 'text')
              )
            )
          ),
          filter(has('children'))
        )(prop('element', element));
        if (isNotEmpty(elementContentSlate)) element.element = elementContentSlate;
      })
    )(prop('contents', values));

    values = compose(
      over(
        lensProp('contents'),
        JSON.stringify
      )
    )(values);

    if (widgetExist || isEdit) {
      const setId = widgetId || prop('id', values);
      UpdateWidgetMutation({ widget: { ...values, id: setId } }, callback('Le widget a bien été mis à jour'));
      setIsEdit(true);
    } else {
      values = dissoc('id', values);
      CreateWidgetMutation({ widget: values }, callback('Le widget a bien été créé.'));
    }
    setState(assoc('isEditing', false));
    return true;
  };

  const onDelete = () => {
    DeleteWidgetMutation({ widgetId: prop('id', widget) }, (ok, error) => {
      if (ok && !error) {
        successMessage('widget', 'supprimé');
        if (equals(SCOPE_OFFER, scope)) {
          UpdateCacheMutation({ date: new Date(), key: `widgets-${offerId}` }, () => {});
        }
        redirect();
      } else {
        errorMessage();
      }
    });
  };

  const isValid = propsAreDefined(['name', 'scope', 'contents'])(prop('values', state));

  return {
    ...state,
    setState,
    onSave,
    redirect,
    isValid,
    isEdit,
    onDelete,
    variables,
    setIsEdit,
    widgetExist,
    isUsed: propOr(false, 'isUsed', widget),
    isUsedBy: propOr([], 'isUsedBy', widget),
    elements
  };
};

const setDefaultValues = (widget, {
  scope,
  offerId
}) => ({
  id: propOr(undefined, 'id', widget),
  name: propOr('', 'name', widget),
  nbColsGrid: propOr(NB_COLS_GRID, 'nbColsGrid', widget),
  heightRowsGrid: propOr(HEIGHT_ROWS_GRID, 'heightRowsGrid', widget),
  contents: JSON.parse(propOr('[]', 'contents', widget)),
  scope,
  offerId,
  orientation: propOr('portrait', 'orientation', widget),
  frameStyle: propOr(BASE_FRAME_STYLE, 'frameStyle', widget)
});

const propsAreDefined = (props) => (object) => reduce((acc, prop) => {
  return acc && !propSatisfies(isNilOrEmpty, prop)(object);
}, true)(props);

const saveImages = async ({ contents }) => {
  return await Promise.all(contents.map(async (content) => {
    const {
      type,
      element
    } = content;
    if (type === 'image' && isNil(path([0, 'fileId'], element))) {
      const uploadImage = compose(propOr({}, 'uploadImage'), head)(element);
      if (isEmpty(uploadImage)) {
        message.error('Pour sauvegarder le widget, veuillez ajouter une image.');
        return;
      } else {
        const { fileId } = await uploadFiles(uploadImage);
        const imageSrc = `/files/${fileId}`;
        const newElement = compose(
          assoc('fileId', fileId),
          assoc('imageSrc', imageSrc)
        )(head(element));
        content = {
          ...content,
          element: [newElement]
        };
      }
    }
    return content;
  }));
};

export default useWidget;
