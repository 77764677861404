/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QStyleQueryVariables = {|
  styleId: string
|};
export type QStyleQueryResponse = {|
  +style: ?{|
    +id: ?string,
    +name: ?string,
    +styles: ?$ReadOnlyArray<?{|
      +format: ?string,
      +type: ?string,
      +value: ?string,
    |}>,
    +version: ?{|
      +versionId: ?string,
      +itemId: ?string,
      +status: ?string,
      +itemScope: ?string,
      +itemType: ?string,
      +major: ?number,
      +minor: ?number,
      +reviewComments: ?{|
        +comments: ?string,
        +rejectionReason: ?string,
        +status: ?string,
      |},
    |},
  |}
|};
export type QStyleQuery = {|
  variables: QStyleQueryVariables,
  response: QStyleQueryResponse,
|};
*/


/*
query QStyleQuery(
  $styleId: ID!
) {
  style(styleId: $styleId) {
    id
    name
    styles {
      format
      type
      value
    }
    version {
      versionId
      itemId
      status
      itemScope
      itemType
      major
      minor
      reviewComments {
        comments
        rejectionReason
        status
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "styleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "styleId",
    "variableName": "styleId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "StyleItem",
  "kind": "LinkedField",
  "name": "styles",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "format",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "versionId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemScope",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemType",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "major",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minor",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comments",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rejectionReason",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QStyleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Style",
        "kind": "LinkedField",
        "name": "style",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReviewComment",
                "kind": "LinkedField",
                "name": "reviewComments",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QStyleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Style",
        "kind": "LinkedField",
        "name": "style",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Version",
            "kind": "LinkedField",
            "name": "version",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReviewComment",
                "kind": "LinkedField",
                "name": "reviewComments",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v7/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8e01eabeefa3ec8ed9fcc2f1b3e11c4",
    "id": null,
    "metadata": {},
    "name": "QStyleQuery",
    "operationKind": "query",
    "text": "query QStyleQuery(\n  $styleId: ID!\n) {\n  style(styleId: $styleId) {\n    id\n    name\n    styles {\n      format\n      type\n      value\n    }\n    version {\n      versionId\n      itemId\n      status\n      itemScope\n      itemType\n      major\n      minor\n      reviewComments {\n        comments\n        rejectionReason\n        status\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6703c5ac2b0c6d112cf27ff30446c2ee';

module.exports = node;
