import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation, updaterBasicDelete } from '../common/common';

const mutation = graphql`
  mutation DeleteColorMutation($colorId: ID!) {
    deleteColor(colorId: $colorId) {
      ok
      error
    }
  }
`;

export default ({
  colorId
}, done) => {
  const variables = {
    colorId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { deleteColor: { ok, error } }) => updaterBasicDelete(proxyStore, ok, error, colorId),
      onCompleted: ({ deleteColor }) => basicMutationOnCompleted(deleteColor, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
