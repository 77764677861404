import React from 'react';
import TagForm from './components/tag/TagForm';
import { useParams } from 'react-router-dom';
import { SCOPE_OFFER } from '../../_CONST';

const CreateTagDocument = () => {
  const { offerId } = useParams();
  return (
    <TagForm
      scope={SCOPE_OFFER}
      offerId={offerId}
    />
  );
};

export default CreateTagDocument;
