/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteDocumentMutationVariables = {|
  documentId: string
|};
export type DeleteDocumentMutationResponse = {|
  +deleteDocument: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type DeleteDocumentMutation = {|
  variables: DeleteDocumentMutationVariables,
  response: DeleteDocumentMutationResponse,
|};
*/


/*
mutation DeleteDocumentMutation(
  $documentId: ID!
) {
  deleteDocument(documentId: $documentId) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "documentId"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "deleteDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteDocumentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteDocumentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e4853e8ed9575cbe00f57eee2fe996a1",
    "id": null,
    "metadata": {},
    "name": "DeleteDocumentMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteDocumentMutation(\n  $documentId: ID!\n) {\n  deleteDocument(documentId: $documentId) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '51abfac7cb26991e91c889ad44d8c90d';

module.exports = node;
