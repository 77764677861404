import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation CreateWidgetMutation($widget: WidgetInput!) {
    createWidget(widget: $widget) {
      ok
      error
      widgetId
    }
  }
`;

export default ({
  widget
}, done) => {
  const variables = {
    widget
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ createWidget }) => {
        const { ok, error, widgetId } = createWidget;
        done(ok, error, widgetId);
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};
