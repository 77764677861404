import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';

import {
  populateChildren
} from '../toolbox';

export const QColorsQuery = graphql`
  query QColorsQuery {
    colors {
      id
      name
      value
      dates {
        creation
        lastUpdate
      }
    }
  }
`;

const QColors = ({
  args = {},
  children,
  childDataProp
}) => (
  <Query
    query={QColorsQuery}
    caching={false}
    args={args}
  >
    {populateChildren('colors')(children, childDataProp)}
  </Query>
);

export default QColors;
