import React from 'react';
import Icon from '@ant-design/icons';

import AddPlusIcon from './AddPlusIcon';
import BgFillIcon from './BgFillIcon';
import BriefCaseIcon from './BriefCaseIcon';
import CheckCircleIcon from './CheckCircleIcon';
import ClockIcon from './ClockIcon';
import CrossIcon from './CrossIcon';
import CubeIcon from './CubeIcon';
import DoubleFontIcon from './DoubleFontIcon';
import DoubleTagsIcon from './DoubleTagsIcon';
import CopyIcon from './CopyIcon';
import DropperIcon from './DropperIcon';
import EditIcon from './EditIcon';
import FontIcon from './FontIcon';
import GlassesIcon from './GlassesIcon';
import LoadingIcon from './LoadingIcon';
import TagIcon from './TagIcon';
import TrashIcon from './TrashIcon';
import UploadIcon from './UploadIcon';
import UserIcon from './UserIcon';

const iconStyle = {
  display: 'inline-block',
  verticalAlign: 'middle'
};

const AddPlus = props => <Icon style={iconStyle} component={AddPlusIcon} {...props} />;
const BgFill = props => <Icon style={iconStyle} component={BgFillIcon} {...props} />;
const BriefCase = props => <Icon style={iconStyle} component={BriefCaseIcon} {...props} />;
const CheckCircle = props => <Icon style={iconStyle} component={CheckCircleIcon} {...props} />;
const Clock = props => <Icon style={iconStyle} component={ClockIcon} {...props} />;
const Copy = props => <Icon style={iconStyle} component={CopyIcon} {...props} />;
const Cross = props => <Icon style={iconStyle} component={CrossIcon} {...props} />;
const Cube = props => <Icon style={iconStyle} component={CubeIcon} {...props} />;
const DoubleFont = props => <Icon style={iconStyle} component={DoubleFontIcon} {...props} />;
const DoubleTags = props => <Icon style={iconStyle} component={DoubleTagsIcon} {...props} />;
const Dropper = props => <Icon style={iconStyle} component={DropperIcon} {...props} />;
const Edit = props => <Icon style={iconStyle} component={EditIcon} {...props} />;
const Font = props => <Icon style={iconStyle} component={FontIcon} {...props} />;
const Glasses = props => <Icon style={iconStyle} component={GlassesIcon} {...props} />;
const Loading = props => <Icon style={iconStyle} component={LoadingIcon} {...props} />;
const Tag = props => <Icon style={iconStyle} component={TagIcon} {...props} />;
const Trash = props => <Icon style={iconStyle} component={TrashIcon} {...props} />;
const Upload = props => <Icon style={iconStyle} component={UploadIcon} {...props} />;
const User = props => <Icon style={iconStyle} component={UserIcon} {...props} />;

export {
  AddPlus,
  BgFill,
  BriefCase,
  CheckCircle,
  Clock,
  Copy,
  Cross,
  Cube,
  DoubleFont,
  DoubleTags,
  Dropper,
  Edit,
  Font,
  Glasses,
  Loading,
  Tag,
  Trash,
  Upload,
  User
};
