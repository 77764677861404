import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation RemoveItemToTagMutation($tagId: ID!, $itemId: ID!) {
    removeItemToTag(tagId: $tagId, itemId: $itemId) {
      ok
      error
    }
  }
`;

export default ({
  tagId,
  itemId
}, done) => {
  const variables = {
    tagId,
    itemId
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ removeItemToTag }) => basicMutationOnCompleted(removeItemToTag, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
