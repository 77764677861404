import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '../../.';
import { basicMutationOnCompleted, onErrorMutation, updaterBasicDelete } from '../common/common';

const mutation = graphql`
  mutation DeleteRawDocumentsMutation($rawDocumentsIds: [ID]!) {
    deleteRawDocuments(rawDocumentsIds: $rawDocumentsIds) {
      ok
      error
    }
  }
`;

export default ({
  rawDocumentsIds
}, done) => {
  const variables = {
    rawDocumentsIds
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      updater: (proxyStore, { deleteRawDocuments: { ok, error } }) => updaterBasicDelete(proxyStore, ok, error, rawDocumentsIds),
      onCompleted: ({ deleteRawDocuments }) => basicMutationOnCompleted(deleteRawDocuments, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
