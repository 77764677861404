import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../index';
import {
  populateChildren
} from './toolbox';

export const QPredefinedVariablesQuery = graphql`
  query QPredefinedVariablesQuery {
    predefinedVariables {
      key
      description
    }
  }
`;

const QPredefinedVariables = ({
  children,
  childDataProp,
  args
}) => {
  return (
    <Query
      query={QPredefinedVariablesQuery}
      caching={false}
      args={args}
    >
      {populateChildren()(children, childDataProp)}
    </Query>
  );
};

export default QPredefinedVariables;
