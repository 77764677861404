import React from 'react';
import { useParams } from 'react-router-dom';
import QWidget from '../../_graphql/queries/documents/QWidget';
import * as R from 'ramda';
import WidgetForm from './components/widget/WidgetForm';

const PreviewWidget = () => {
  const { widgetId } = useParams();

  return (
    <QWidget
      args={{ widgetId }}
    >
      {widget => {
        if (R.isNil(widget)) {
          return null;
        }

        return (
          <WidgetForm
            widget={widget}
            scope={R.propOr('offer', 'scope')(widget)}
            offerId={R.prop('offerId', widget)}
            preview
          />
        );
      }}

    </QWidget>
  );
};

export default PreviewWidget;
