import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import { all, always, compose, concat, cond, equals, filter, find, includes, isEmpty, length, map, path, pathEq, prop, propEq, T, uniq, when } from 'ramda';
import { isNotEmpty, isNotNil, isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { TreeProvider } from '../../../components/treeStructure/treeContext';
import Trees from '../../../components/treeStructure/Trees';
import WidgetTreeRow from './WidgetTreeRow';
import useFetchQuery from '../../../hooks/useFetchQuery';
import { QWidgetsQuery } from '../../../_graphql/queries/documents/QWidgets';
import { Collapse } from '../../../components';
import DeleteWidgetsMutation from '../../../_graphql/mutations/documents/DeleteWidgetsMutation';
import { deleteMutationMessage } from '../../../utils/messageMutation';
import RemoveFoldersMutation from '../../../_graphql/mutations/folders/RemoveFoldersMutation';

const WidgetsSection = (props) => {
  const { userCanEditOffer, args, widgets, setWidgets, setRefetchWidgets, reFetch, reFetchFolders, folders, userIsAdmin, setFullscreenLoading } = props;
  const [treeProps, setTreeProps] = useState({
    addTree: () => {},
    expandedKeys: []
  });
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);

  useEffect(() => {
    let value = sessionStorage.getItem('collapseWidgetIsOpen');
    if (value) {
      value = JSON.parse(value);
    } else {
      value = false;
    }
    setCollapseIsOpen(value);
  }, []);
  useEffect(() => sessionStorage.setItem('collapseWidgetIsOpen', collapseIsOpen), [collapseIsOpen]);

  const { offerId, scope } = args;
  const history = useHistory();
  const handleCreateNewFolder = () => treeProps.addTree(null, treeProps.expandedKeys)();

  const goToWidget = () => compose(
    when(isNotNil, history.push),
    cond([
      [equals('offer'), always(`/offre/${offerId}/edition/widgets/creer`)],
      [T, always(null)]
    ])
  )(scope);

  const widgetsIdUnused = compose(map(prop('id')), filter((widget) => !prop('isUsed', widget)))(widgets);

  const removeUnusedWidgets = () => {
    message.info(`Suppression de ${length(widgetsIdUnused)} widgets inutilisés en cours, veuillez patienter...`);
    const actionsAfterDeleteWidgets = () => {
      const folderElementsLinkedToUnusedWidgets = filter((folder) => includes(path(['element', 'elmId'], folder), widgetsIdUnused))(folders);
      const folderParentsLinkedToUnusedWidgets = filter((folder) => {
        const folderId = prop('id', folder);
        const isLinkedToAnElementToRemove = includes(folderId, map(prop('parent'), folderElementsLinkedToUnusedWidgets));
        if (isLinkedToAnElementToRemove) {
          const folderElementsLinkedToFolderParent = filter((folder) => {
            const isChild = pathEq(['parent'], folderId, folder);
            if (isChild) {
              const isLinkedToUsedWidgets = !includes(path(['element', 'elmId'], folder), widgetsIdUnused);
              return isLinkedToUsedWidgets;
            }
            return false;
          })(folders);
          return isEmpty(folderElementsLinkedToFolderParent);
        }
        return false;
      })(folders);

      const getParentFromParentLinkedToUnusedWidgets = (foldersParent) => compose(
        (foldersParent) => map((id) => {
          const folder = find(propEq('id', id))(folders);
          const foldersWithParent = filter((folder) => isNotNil(prop('parent', folder)))(folders);
          const children = filter(propEq('parent', id))(foldersWithParent);
          const areAllChildrenUnused = all((child) => includes(prop('id', child), map(prop('id'), [...folderElementsLinkedToUnusedWidgets, ...folderParentsLinkedToUnusedWidgets])))(children);
          if (areAllChildrenUnused) {
            folderParentsLinkedToUnusedWidgets.push(folder);
            if (isNotNil(prop('parent', folder))) getParentFromParentLinkedToUnusedWidgets(foldersParent);
          }
        })(foldersParent),
        uniq,
        map(prop('parent')),
        filter((folder) => isNotNil(prop('parent', folder)))
      )(foldersParent);
      getParentFromParentLinkedToUnusedWidgets(folderParentsLinkedToUnusedWidgets);

      const foldersToRemove = concat(folderElementsLinkedToUnusedWidgets, folderParentsLinkedToUnusedWidgets);

      if (isNotEmpty(foldersToRemove)) {
        RemoveFoldersMutation({ foldersIds: map(prop('id'))(foldersToRemove) }, (ok) => {
          if (ok) {
            reFetchFolders();
            setRefetchWidgets(true);
            reFetch();
          }
        });
      } else {
        setRefetchWidgets(true);
        reFetch();
      }
    };
    DeleteWidgetsMutation({ widgetsIds: widgetsIdUnused }, (ok, error) => deleteMutationMessage(ok, error, 'widgets inutilisés', actionsAfterDeleteWidgets, false, false, true));
  };
  return (
    <Collapse
      className="section-widget"
      collapseIsOpen={collapseIsOpen}
      setCollapseIsOpen={setCollapseIsOpen}
      title={equals('global', scope) ? 'Widgets de l\'équipe' : 'Widgets de l\'offre'}
      actions={userCanEditOffer && (
        <>
          {collapseIsOpen && (
            <Button
              onClick={handleCreateNewFolder}
              icon={<PlusOutlined />}
            >
              Créer un dossier racine
            </Button>
          )}
          <Button
            onClick={goToWidget}
            icon={<PlusOutlined />}
          >
            Créer un widget
          </Button>
          <Tooltip
            title="Supprimer les widgets inutilisés"
            color="#0197DC"
          >
            <Button
              disabled={isEmpty(widgetsIdUnused)}
              onClick={removeUnusedWidgets}
              icon={<DeleteOutlined className="btn-remove-unused-widgets"/>}
            />
          </Tooltip>
        </>
      )}
    >
      <Trees elements={concat(widgets, folders)} setElements={setWidgets} ElementRow={<WidgetTreeRow {...props} originalWidgets={widgets} setIsLoading={setFullscreenLoading} /> } setTreeProps={setTreeProps} setRefetchWidgets={setRefetchWidgets} userIsAdmin={userIsAdmin} />
    </Collapse>
  );
};

const WidgetsList = ({ cacheKey, args, refetchWidgets, setRefetchWidgets, setIsLoadingOfferItem, isLoadingOfferItem, ...restProps }) => {
  const { widgets: allWidgets, isLoading, error, reFetch } = useFetchQuery({
    query: QWidgetsQuery,
    args,
    dataProp: 'widgets',
    defaultData: [],
    cacheKey
  });
  const forceRefresh = useRef(false);

  useEffect(() => {
    if (refetchWidgets && reFetch) {
      forceRefresh.current = true;
      reFetch();
      setRefetchWidgets(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reFetch, refetchWidgets]);

  const [widgets, setWidgets] = useState([]);
  useEffect(() => {
    setIsLoadingOfferItem && setIsLoadingOfferItem(isLoading);
    if (!isLoading && (isNotNilOrEmpty(allWidgets) || forceRefresh.current)) {
      forceRefresh.current = false;
      setWidgets(allWidgets);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, allWidgets]);

  useEffect(() => {
    if (!isLoading && length(widgets) !== length(allWidgets)) {
      forceRefresh.current = true;
      reFetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgets, allWidgets]);

  if (isLoading || isLoadingOfferItem) return <Loading/>;
  if (error) return <p>Erreur</p>;

  const props = {
    args,
    widgets,
    setWidgets,
    setRefetchWidgets,
    reFetch,
    ...restProps
  };

  return (
    <TreeProvider {...props} elements={widgets}>
      <WidgetsSection {...props}/>
    </TreeProvider>
  );
};

export default WidgetsList;
